import { discountsVar } from './utils/reactiveVar'

// most functions interactive with the reactive var are methods on the hook
// but this needs to be called from outside of hooks / components so is standalone
export const clearVoucher = () => {
  discountsVar({
    ...discountsVar(),
    voucherDiscountId: null,
  })
}
