import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCategories } from '@src/hooks/useCategories'

import { AlphabetTag } from './AlphabetTag'
import {
  ApplySelectionButton,
  ApplySelectionContainer,
  CategoriesContainer,
  CategoryContainer,
  TopCategoryContainer,
  CategoryHeader,
  CheckboxContainer,
  HeaderContainer,
  LetterContainer,
  InnerHeaderContainer,
  SubHeader,
  CategoriesContentContainer,
  TopCategoryCheckboxContainer,
} from './CategoriesModal.styles'

import { UncontrolledCheckboxInput } from '../Inputs'

export const CategoriesContent: React.FC<{
  setShowCategories: (val: boolean) => void
}> = ({ setShowCategories }) => {
  const { t } = useTranslation()

  const {
    setCategoryNames,
    selectedCategories,
    categoriesByFirstLetter,
    topCategories,
  } = useCategories()

  const [categoriesBeforeApply, setCategoriesBeforeApply] =
    useState<{ name: string; id: string }[]>(selectedCategories)

  const selectCategoriesBeforeApply = (category: {
    name: string
    id: string
  }) => {
    if (categoriesBeforeApply.some(({ id }) => id === category.id)) {
      setCategoriesBeforeApply(
        categoriesBeforeApply.filter(({ id }) => id !== category.id)
      )
    } else {
      setCategoriesBeforeApply([...categoriesBeforeApply, category])
    }
  }
  const updateCategoryParams = () => {
    setCategoryNames({
      categories: categoriesBeforeApply.map(({ name }) => name),
    })
  }

  return (
    <>
      <CategoriesContainer>
        <HeaderContainer>
          <InnerHeaderContainer>
            <SubHeader>{t('categories')}</SubHeader>
            <AlphabetTag activeLetters={Object.keys(categoriesByFirstLetter)} />
          </InnerHeaderContainer>
          <TopCategoryContainer>
            {topCategories.map(item => (
              <TopCategoryCheckboxContainer key={item.id}>
                <UncontrolledCheckboxInput
                  label={t(item.id)}
                  name={item.id}
                  onChange={() => selectCategoriesBeforeApply(item)}
                  checked={categoriesBeforeApply.some(
                    ({ id }) => id === item.id
                  )}
                />
              </TopCategoryCheckboxContainer>
            ))}
          </TopCategoryContainer>
        </HeaderContainer>
        <CategoriesContentContainer>
          {Object.entries(categoriesByFirstLetter).map(
            ([letter, categories]) => (
              <LetterContainer key={letter}>
                <CategoryHeader id={letter}>{letter}</CategoryHeader>
                <CategoryContainer>
                  {categories.map(category => (
                    <CheckboxContainer key={category.id}>
                      <UncontrolledCheckboxInput
                        label={category.name}
                        name={category.id}
                        onChange={() => {
                          selectCategoriesBeforeApply(category)
                        }}
                        checked={categoriesBeforeApply.some(
                          ({ id }) => id === category.id
                        )}
                      />
                    </CheckboxContainer>
                  ))}
                </CategoryContainer>
              </LetterContainer>
            )
          )}
        </CategoriesContentContainer>
      </CategoriesContainer>
      <ApplySelectionContainer>
        <ApplySelectionButton
          onClick={() => {
            setShowCategories(false)
            updateCategoryParams()
          }}
          content={'Apply Selections'}
        />
      </ApplySelectionContainer>
    </>
  )
}
