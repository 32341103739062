import React from 'react'

import {
  StyledBottomSheet,
  StyledBottomSheetBackdrop,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { useBreakpoint } from '@src/hooks/useBreakpoint'

import 'react-spring-bottom-sheet/dist/style.css'

export const ServicePopover: React.FC<{
  children: React.ReactNode
  open: boolean
  onClose: () => void
  headerColor?: string
  height?: number
  maxWidth?: number
  backgroundColor?: string
  isMinimised?: boolean
  showBackdrop?: boolean
}> = ({
  children,
  open,
  onClose,
  headerColor,
  height = 0.87,
  maxWidth,
  backgroundColor,
  isMinimised,
  showBackdrop = true,
}) => {
  const { isSmallerThanDesktop } = useBreakpoint()

  return (
    <StyledBottomSheet
      onClick={e => {
        e.stopPropagation()
      }}
      open={open}
      onDismiss={onClose}
      snapPoints={({ minHeight, maxHeight }) =>
        isSmallerThanDesktop
          ? [minHeight, height * maxHeight]
          : [height * maxHeight]
      }
      sibling={
        // custom backdrop to prevent propagation of click events which were triggering other popover components' "on-click-outside" type events
        open && (
          <StyledBottomSheetBackdrop
            role="presentation"
            showBackdrop={showBackdrop}
            // data-rsbs-backdrop="true"
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
          />
        )
      }
      $headerColor={headerColor}
      $maxWidth={maxWidth}
      $backgroundColor={backgroundColor}
      $isMinimised={isMinimised}
      blocking={false}
    >
      {children}
    </StyledBottomSheet>
  )
}
