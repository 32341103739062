export const signUp = {
  sign_up: 'Create Account',
  sign_in: 'Sign In',
  continue: 'By continuing you agree to our',
  terms_and_conditions: 'Terms & Conditions',
  and: 'and',
  privacy_policy: 'Privacy Policy',
  offers: 'Offers',
  already_have_an_account: 'Already have an account?',
  offers_and_updates: 'Sign Up for offers and updates',

  first_name: {
    label: 'First Name',
    validation: {
      required_error: 'Please enter your first name.',
      minimum_required_characters: 'Needs to be 2 letters or more.',
    },
  },
  last_name: {
    label: 'Last Name',
    validation: {
      required_error: 'Please enter your last name.',
      minimum_required_characters: 'Needs to be 2 letters or more.',
    },
  },
  phone_number: {
    label: 'Mobile Number',
    validation: {
      required_error: 'Please enter your mobile number.',
      invalid_type_error: 'Please enter a valid mobile number.',
    },
  },
  email_address: {
    label: 'Email Address',
    validation: {
      required_error: 'Please enter your email address.',
      invalid_type_error: 'Invalid email address.',
    },
  },
  password: {
    title: 'Create Password',
    label: 'Password (min 8 characters)',
    validation: {
      required_error: 'Please enter your password.',
      minimum_required_characters: 'Password requires 8 or more characters.',
    },
  },
  confirm_password: {
    label: 'Confirm Password',
    validation: {
      required_error: 'Please confirm your password.',
      invalid_type_error: 'Password is not valid.',
      passwords_do_not_match: 'Passwords do not match.',
    },
  },
}
