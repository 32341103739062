import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  ArrayParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { useMarketplace } from './useMarketplace'

const queryParamsConfig: Record<string, QueryParamConfig<any, any>> = {
  categories: withDefault(ArrayParam, []),
  deliveryZone: StringParam,
}

export const useCategories = () => {
  const marketplace = useMarketplace()
  const [categoryNames, setCategoryNames] = useQueryParams(queryParamsConfig)
  const history = useHistory()
  const isOutletListRoute = useRouteMatch(
    `/:merchantCategory(${marketplace.urlPath})`
  )

  const setCategoryNamesAndRedirectIfNotOutletList = ({
    categories,
  }: {
    categories: string[]
  }) => {
    if (!isOutletListRoute) {
      //redirect if not outlet list
      const url = `/${marketplace.urlPath}?categories=${categories.join(
        '&categories='
      )}`

      history.push(url)
    } else {
      setCategoryNames({ categories: categories })
    }
  }

  const topCategories = marketplace.categories.filter(
    ({ id }) => id === 'deals' || id === 'featured'
  )

  const allCategories = marketplace.categories.filter(
    ({ id }) => id !== 'deals' && id !== 'featured'
  )

  const categoriesByFirstLetter = groupBy(allCategories, category =>
    category.name.slice(0, 1)
  )

  const selectedCategories = useMemo(() => {
    return marketplace.categories.filter(({ name }) =>
      categoryNames.categories.includes(name)
    )
  }, [categoryNames, marketplace])

  return {
    topCategories,
    categoriesByFirstLetter,
    selectedCategories,
    setCategoryNames: setCategoryNamesAndRedirectIfNotOutletList,
  }
}
