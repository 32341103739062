import styled from 'styled-components'

import { Button } from '@src/components/Button/Button'

export const SupportContainer = styled.div`
  height: 100%;
`

export const HelpQuestion = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  margin: 0;
`

export const FAQsText = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 4px 0 24px 0;
`

export const SubmitButton = styled(Button)`
  margin: 16px 0px;
  font-size: 18px;
`
