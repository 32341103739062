import styled, { css } from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div``

export const Order = styled(AccountSectionNavButton)<{
  lastOrder: boolean
  active: boolean
}>`
  all: unset;
  display: flex;
  border-bottom: 2px solid #ebecf0;
  border-radius: 1px;
  cursor: pointer;
  padding-bottom: 16px;
  margin-bottom: 16px;

  ${props =>
    props.active &&
    css`
      background: #ebecf0;

      ${RestaurantLogo} {
        background: #fff;
      }
    `}
  width: 100%;
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
}>`
  height: 72px;
  width: 72px;
  border-radius: 4px;
  background-color: #f6f6f9;
  background-size: cover;
  background-position: 50%;
  margin-right: 12px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};
`

export const OutletNameAndTotal = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const OutletName = styled.h4`
  margin: 4px 0px 2px 0px;
  font-weight: 700;
  font-size: 18px;
`
export const OrderedAt = styled.div`
  font-weight: 400;
  font-size: 12px;
`

export const OutletTotal = styled.div`
  margin: 8px 0 0 0;
  font-weight: 700;
  font-size: 14px;
`

export const OrderStatusContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
`

export const NumberOfOrdersShown = styled.p`
  display: flex;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  strong {
    margin: 0 5px;
  }
`

export const StatusIcon = styled.img`
  width: 16px;
  height: 16px;
  padding-top: 40px;
  padding: 0px 0px 16px 0px;
`

export const YearsPickerContainer = styled.div`
  z-index: 0;
  height: 72px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-top: 0;
  }
`

export const NoIdealStatePage = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 32px;
  align-items: center;
  border: 2px solid #ebecf0;
  border-radius: 8px;
`

export const OrderInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonContainer = styled.div`
  margin-top: 16px;
`
