import React from 'react'

export const BagDeliverySVG: React.FC<{
  id: string
  width?: number
  height?: number
}> = ({ id, width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 24 24"
  >
    <title id={`${id}Title`} />
    <g
      strokeLinecap="square"
      strokeWidth="2"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="miter"
      className="nc-icon-wrapper"
      strokeMiterlimit="10"
    >
      <polyline points="7 9 7 7 23 7 23 21 7 21 7 19"></polyline>{' '}
      <line x1="1" y1="16" x2="9" y2="16" stroke="currentColor"></line>{' '}
      <line x1="3" y1="12" x2="11" y2="12" stroke="currentColor"></line>{' '}
      <path
        d="M12,7V4a3,3,0,0,1,3-3h0a3,3,0,0,1,3,3V7"
        data-cap="butt"
        strokeLinecap="butt"
      ></path>
    </g>
  </svg>
)
