import React from 'react'
import { useTranslation } from 'react-i18next'

export const LeftArrowSVG: React.VFC<{
  id?: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('back_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <path
        d="M16 5.8333V4.1666H3.1917L6.175 1.175L5 0L0 5L5 10L6.175 8.825L3.1917 5.8333H16Z"
        fill="currentColor"
      />
    </svg>
  )
}
