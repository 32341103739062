import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { config, useTransition } from 'react-spring'

import { UncontrolledCheckboxInput } from '@src/components/Inputs'
import { PopoverOptionContainer } from '@src/components/ServicePopover/ServicePopover.styles'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

// This component is used for service dropdown in the OUTLET LIST page
export const FulfilmentOverlay: React.FC<{
  availableFulfilmentMethods: NarrowFulfilmentMethodInputType[]
  selectedFulfilmentMethods: NarrowFulfilmentMethodInputType[]
  setSelectedFulfilmentMethods: (
    fulfilmentMethods: NarrowFulfilmentMethodInputType[]
  ) => void
}> = ({
  availableFulfilmentMethods,
  selectedFulfilmentMethods,
  setSelectedFulfilmentMethods,
}) => {
  const { t } = useTranslation('serviceOverlay')
  const [show, setShow] = useState(false)

  // TODO: why do we set it to true on first render and not default to true in the state
  // and why is setShow not used anywhere else?
  // Is it so the animation plays on first render?
  useEffect(() => {
    setShow(true)
  }, [])

  const transition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: show,
    config: config.default,
  })

  return transition(
    (styles, item) =>
      item && (
        <>
          {availableFulfilmentMethods.map(availableFulfilmentMethod => {
            const translatedLabel = t(availableFulfilmentMethod.toLowerCase())
            return (
              <PopoverOptionContainer key={availableFulfilmentMethod}>
                <UncontrolledCheckboxInput
                  label={translatedLabel}
                  name={translatedLabel}
                  onChange={() => {
                    setSelectedFulfilmentMethods(
                      selectedFulfilmentMethods.includes(
                        availableFulfilmentMethod
                      )
                        ? selectedFulfilmentMethods.filter(
                            method => method !== availableFulfilmentMethod
                          )
                        : [
                            ...selectedFulfilmentMethods,
                            availableFulfilmentMethod,
                          ]
                    )
                  }}
                  checked={selectedFulfilmentMethods.includes(
                    availableFulfilmentMethod
                  )}
                />
              </PopoverOptionContainer>
            )
          })}
        </>
      )
  )
}
