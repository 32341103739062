import { ApolloError } from '@apollo/client'
import { Field, ErrorMessage, useField } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  RadioButton,
  Label,
  Text,
  UnavailableText,
  AddressAndFriendlyNameContainer,
  AddressContainer,
} from '@src/components/CheckoutModal/FormElements.styles'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { CustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddressesQuery.graphql-interface'

import { AddressRadioButtonContainer } from './DeliveryFulfilmentStep.styles'

export const ExistingAddressSection: React.FC<{
  data: CustomerDetailsAndAddressesQuery | undefined
  loading: boolean
  error: ApolloError | undefined
}> = ({ data, loading, error }) => {
  const [field] = useField('addressId')
  const { t } = useTranslation('checkout')

  // TODO: Skeleton
  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <>{'Error loading addresses'}</>
  }

  const addresses = data?.customerDetails.deliveryAddresses || []

  return (
    // Address radio buttons
    <>
      {addresses.map(address => (
        <AddressRadioButtonContainer key={address.id}>
          <div>
            <Field
              id={`address:${address.id}`}
              name="addressId"
              value={address.id}
              type="radio"
              as={RadioButton}
              disabled={!address.acceptsDelivery}
            />
          </div>
          <AddressAndFriendlyNameContainer>
            <Label htmlFor={`address:${address.id}`}>{address.name}</Label>
            {field.value === address.id && (
              <AddressContainer>
                <Text>
                  {address.firstLine} {address?.secondLine}
                </Text>
                <Text>
                  {address.city}
                  {', '}
                  {address.postcode}
                </Text>
                {!address.acceptsDelivery && (
                  <UnavailableText>{t('delivery_unavailable')}</UnavailableText>
                )}
              </AddressContainer>
            )}
          </AddressAndFriendlyNameContainer>
          <ErrorMessage name={`addressId.${address.id}`} />
        </AddressRadioButtonContainer>
      ))}
    </>
  )
}
