import { Locale, format, formatRelative } from 'date-fns'
import { enGB } from 'date-fns/locale'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'

import { LiveFormattedASAPLabel } from '../LiveFormattedASAPLabel/LiveFormattedASAPLabel'

const FORMAT_RELATIVE_LOCALE_START: Record<string, string> = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday  '",
  today: "'Today  ' HH:mm",
  tomorrow: "'Tomorrow  'HH:mm",
  nextWeek: "iii dd MMM'  'HH:mm",
  other: "iii dd MMM'  'HH:mm",
}
// TODO: LOCALISATION
const LOCALE: Locale = {
  ...enGB,
  formatRelative: token =>
    FORMAT_RELATIVE_LOCALE_START[token] || FORMAT_RELATIVE_LOCALE_START.other,
}

export const FulfilmentWhenLabel: React.FC<{
  stateType: OutletFulfilmentStateType
}> = ({ stateType }) => {
  const {
    outlet,
    data: { currentFulfilment },
  } = useOutletFulfilment({ stateType })
  const { t } = useTranslation('serviceNavigation')

  if (
    !outlet.isOrderable &&
    currentFulfilment.narrowType !== NarrowFulfilmentMethodInputType.TABLE
  ) {
    return <>{t('closed')}</>
  }

  // DELIVERY
  if (
    currentFulfilment.narrowType === NarrowFulfilmentMethodInputType.DELIVERY
  ) {
    // PREORDER
    if (currentFulfilment.deliveryPreorderWindow) {
      const formattedStart = formatRelative(
        new Date(currentFulfilment.deliveryPreorderWindow.start),
        new Date(),
        { locale: LOCALE }
      )
      const formattedEnd = format(
        new Date(currentFulfilment.deliveryPreorderWindow.end),
        'HH:mm'
      )
      const formattedStartToEnd = `${formattedStart} - ${formattedEnd}`

      return <>{formattedStartToEnd}</>
    }

    // ASAP
    return (
      <>
        {t('arriving_by')}{' '}
        <LiveFormattedASAPLabel
          prepTime={outlet.prepTime}
          isDelivery
          openingTimes={outlet.openingTimesArray}
          format={date => format(date, `HH:mm`)}
        />
      </>
    )
  }

  // COLLECTION
  if (
    currentFulfilment.narrowType === NarrowFulfilmentMethodInputType.COLLECTION
  ) {
    // PREORDER
    if (currentFulfilment.collectionPreorderDatetime) {
      return (
        <>
          {formatRelative(
            new Date(currentFulfilment.collectionPreorderDatetime),
            new Date(),
            { locale: LOCALE }
          )}
        </>
      )
    }

    // ASAP
    return (
      <>
        {t('ready_by')}{' '}
        <LiveFormattedASAPLabel
          openingTimes={outlet.openingTimesArray}
          prepTime={outlet.prepTime}
          format={date => format(date, `HH:mm`)}
        />
      </>
    )
  }

  // TABLE
  return null
}
