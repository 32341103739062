import React from 'react'
import { useTranslation } from 'react-i18next'

import { DropdownInput } from '@src/components/DropdownInput'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { DeliveryZone } from '@src/graphql-types'
import { useMarketplaceDeliveryZonesQuery } from '@src/hooks/sharedQueries/useMarketplaceDeliveryZones/useMarketplaceDeliveryZones'

export const DeliveryZoneInput: React.FC<{
  className?: string
  onSubmit: (zone: DeliveryZoneIdAndName) => void
  selectedDeliveryZoneId: string | null
}> = ({ className, onSubmit, selectedDeliveryZoneId }) => {
  const { t } = useTranslation()

  const { data, loading, error } = useMarketplaceDeliveryZonesQuery()

  if (loading) {
    return null
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data) {
    return <ErrorPage />
  }

  const deliveryZones = data.deliveryZonesByMarketplaceId

  return (
    <DropdownInput
      className={className}
      fieldName="delivery-zone-input"
      placeholder={t('ordering_from')}
      items={deliveryZones.map(zoneToItem)}
      onItemClick={deliveryZone => onSubmit(itemToZone(deliveryZone))}
      activeItemId={selectedDeliveryZoneId}
      borderless
    />
  )
}

type DeliveryZoneIdAndName = Pick<DeliveryZone, 'id' | 'name'>
const zoneToItem = (zone: DeliveryZoneIdAndName) => ({
  id: zone.id,
  text: zone.name,
})
const itemToZone = (item: { id: string; text: string }) => ({
  id: item.id,
  name: item.text,
})
