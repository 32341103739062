import { MutationHookOptions, useMutation } from '@apollo/client'

import {
  CreateAddressDocument,
  CreateAddressMutation,
  CreateAddressMutationVariables,
} from './queries/__generated__/createAddress.graphql-interface'

import { CustomerDetailsAndAddressesDocument } from '../useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddressesQuery.graphql-interface'

export type CreateAddressMutationInput = CreateAddressMutationVariables['input']
export type CreateAddress = ReturnType<typeof useCreateAddressMutation>[0]

export const useCreateAddressMutation = (
  mutationOptions?: MutationHookOptions<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >
) =>
  useMutation(CreateAddressDocument, {
    ...mutationOptions,
    update(cache, { data }) {
      const address = data?.createAddress.address
      if (address) {
        const customerDetails = cache.readQuery({
          query: CustomerDetailsAndAddressesDocument,
        })
        if (customerDetails) {
          cache.modify({
            // Object is spread into identify function as the generated typescript type
            // for query does not satisfy index type of StoreObject
            // https://github.com/apollographql/apollo-tooling/issues/2151
            id: cache.identify({ ...customerDetails.customerDetails }),
            fields: {
              deliveryAddresses(
                existingItems: readonly Record<string, any>[] = []
              ) {
                if (address.default) {
                  return [
                    address,
                    existingItems.map(item => ({ ...item, default: false })),
                  ]
                }
                return [address, ...existingItems]
              },
            },
          })
        }
      }
    },
  })
