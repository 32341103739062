import { BasketItem, BasketItemsSchema } from '../validation'

const BASKET_ITEMS_LOCAL_STORAGE_KEY = 'basketItems'

export const persistBasketItems = (basketItems: BasketItem[]): void => {
  localStorage.setItem(
    BASKET_ITEMS_LOCAL_STORAGE_KEY,
    JSON.stringify(basketItems)
  )
}

export const retrieveBasketItems = (): BasketItem[] => {
  // get the basket items from local storage
  const basketItemsFromLS = localStorage.getItem(BASKET_ITEMS_LOCAL_STORAGE_KEY)
  if (!basketItemsFromLS) return []

  // attempt to parse
  let basketItems
  try {
    basketItems = JSON.parse(basketItemsFromLS)
  } catch (err) {
    console.error(
      'Failed to parse basket items from local storage. Removing from local storage',
      err
    )
    localStorage.removeItem(BASKET_ITEMS_LOCAL_STORAGE_KEY)
    return []
  }

  // validate
  let parsedBasketItems: BasketItem[]
  try {
    parsedBasketItems = BasketItemsSchema.parse(basketItems)
  } catch (err) {
    console.error(
      'Failed to validate basket items from local storage. Removing from local storage.',
      err,
      {
        basketItems,
      }
    )
    localStorage.removeItem(BASKET_ITEMS_LOCAL_STORAGE_KEY)
    return []
  }

  return parsedBasketItems
}
