import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CleanOrderStatus,
  NarrowFulfilmentMethodInputType,
} from '@src/../__generated__/globalTypes'

import { getSingleOrder_customerDetails } from './queries/__generated__/getSingleOrder'
import { singleOrderCustomType } from './queries/getSingleOrderCustomType'
import {
  DeliveryContainer,
  BoldTitle,
  LightText,
  OutletNumber,
} from './SingleOrder.styles'

const formattedFulfilmentNotesTranslationMap: Record<
  NarrowFulfilmentMethodInputType,
  string
> = {
  [NarrowFulfilmentMethodInputType.COLLECTION]: 'collection_note',
  [NarrowFulfilmentMethodInputType.DELIVERY]: 'delivery_note',
  [NarrowFulfilmentMethodInputType.TABLE]: 'table_note',
}

export const DeliveryAndNotes: React.FC<{
  myOrder: singleOrderCustomType
  customerDetails: getSingleOrder_customerDetails
  borderBottom?: boolean
}> = ({ myOrder, customerDetails }) => {
  const { t } = useTranslation('singleOrder')

  const {
    customerBillingAddress,
    customerDeliveryAddress,
    customerOrderNotes,
    customerDeliveryNotes,
    outlet,
    cancellationNotes,
    cleanOrderStatus,
  } = myOrder
  const { firstName, lastName } = customerDetails

  const { restaurant, outletAddress, outletPhone } = outlet
  const showBillingAddress =
    customerBillingAddress?.firstLine &&
    customerBillingAddress?.city &&
    customerBillingAddress?.postcode

  return (
    <>
      {cancellationNotes && (
        <DeliveryContainer>
          <BoldTitle>
            {cleanOrderStatus === CleanOrderStatus.REJECTED
              ? t('rejection_notes')
              : t('cancellation_notes')}
          </BoldTitle>
          <LightText>{cancellationNotes}</LightText>
        </DeliveryContainer>
      )}
      {myOrder.narrowFulfilmentMethod ===
        NarrowFulfilmentMethodInputType.DELIVERY &&
        customerDeliveryAddress && (
          <>
            <DeliveryContainer>
              <BoldTitle>{t('delivery_address')}</BoldTitle>
              <LightText>
                {firstName} {lastName} <br />
                {customerDeliveryAddress.firstLine &&
                  customerDeliveryAddress.firstLine}{' '}
                {customerDeliveryAddress.secondLine && (
                  <>
                    {customerDeliveryAddress.secondLine + ', '}
                    <br />
                  </>
                )}
                {customerDeliveryAddress.thirdLine && (
                  <>
                    {customerDeliveryAddress.thirdLine + ', '}
                    <br />
                  </>
                )}
                {customerDeliveryAddress.city + ', '}
                <br />
                {customerDeliveryAddress.postcode}
              </LightText>
            </DeliveryContainer>
          </>
        )}

      <DeliveryContainer>
        <BoldTitle>
          {t(
            formattedFulfilmentNotesTranslationMap[
              myOrder.narrowFulfilmentMethod
            ]
          )}
        </BoldTitle>
        <LightText>{customerDeliveryNotes || t('none')}</LightText>
      </DeliveryContainer>
      <DeliveryContainer>
        <BoldTitle>{t('order_note')}</BoldTitle>
        <LightText>{customerOrderNotes || t('none')}</LightText>
      </DeliveryContainer>

      <DeliveryContainer>
        <BoldTitle>{t('order_enquiries')}</BoldTitle>
        <LightText>
          {restaurant.name}
          <br />
          {outletAddress.firstLine + ', '}
          <br />
          {outletAddress.secondLine && (
            <>
              {outletAddress.secondLine + ', '}
              <br />
            </>
          )}
          {outletAddress.thirdLine && (
            <>
              {outletAddress.thirdLine + ', '}
              <br />
            </>
          )}
          {outletAddress.city + ','}
          <br />
          {outletAddress.postcode}
        </LightText>
        <OutletNumber>{outletPhone}</OutletNumber>
      </DeliveryContainer>
      {showBillingAddress && (
        <DeliveryContainer noBorder={true}>
          <BoldTitle>{t('billing_address')}</BoldTitle>
          <LightText>
            {customerDetails.firstName} {customerDetails.lastName} <br />
            {customerBillingAddress.firstLine + ','}
            <br />
            {customerBillingAddress?.secondLine && (
              <>
                {customerBillingAddress.secondLine}
                <br />
              </>
            )}
            {customerBillingAddress.thirdLine && (
              <>
                {customerBillingAddress.thirdLine}
                <br />
              </>
            )}
            {customerBillingAddress.city},<br />
            {customerBillingAddress.postcode}
          </LightText>
        </DeliveryContainer>
      )}
    </>
  )
}
