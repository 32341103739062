import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const BackButtonContainer = styled.div`
  padding: 24px 24px 0 24px;
  @media (min-width: ${breakpoints.tablet}px) {
    padding: 24px 32px 0 24px;
  }
`

export const NavigationCaption = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: #2e3333;
`
