import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-content: center;
  padding: 0px 32px 52px 32px;
`

export const HeaderContainer = styled.div<{ isCheckout: boolean }>`
  align-items: center;
  justify-content: ${({ isCheckout }) =>
    isCheckout ? 'space-between' : 'flex-start'};
  display: flex;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`

export const Header = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin: 16px 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.mainText};
`

export const Copy = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0;
`

export const ResetPasswordConfirm = styled.div`
  background-color: ${({ theme }) => theme.colors.brandLight};
  display: flex;
  flex-direction: column;
  justify-items: center;
  border-radius: 8px;
  padding: 0px 16px 18px 16px;
  margin-bottom: 16px;
`

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
`

export const StyledButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-top: 4px;
`

export const UserEmail = styled.p`
  font-size: 16px;
  font-weight: 400;
  padding-top: 16px;
  text-align: center;
  margin: 0px;
`
