import styled, { css } from 'styled-components'

export const Container = styled.table<{ isCheckoutTextVisible: boolean }>`
  ${({ theme, isCheckoutTextVisible }) => css`
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0px;
    margin: 0px 0 16px 0px;
    padding-top: 8px;
    padding-bottom: ${isCheckoutTextVisible ? '8px' : '12px'};
    width: 100%;
    color: ${theme.colors.brand};
  `}
`

export const TableHeaderRow = styled.tr`
  // Hide the table headers visually but show them to screen readers
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  font-size: 0px;
`

export const Row = styled.tr`
  height: 24px;
  font-size: 14px;
  font-weight: 400;
`

export const TableData = styled.td`
  width: 100%;
  white-space: nowrap;
  color: #333333;

  &:nth-child(even) {
    max-width: 160px;
    text-align: right;
  }
`

export const TotalsRow = styled.tr``

export const TotalsData = styled.td`
  padding: 8px 0 0 0;
  font-weight: 700;
  font-size: 16px;
  &:nth-child(2) {
    text-align: right;
  }
`
