export const contactDetails = {
  my_account: 'My Account',
  required: '*Required',
  change_or_update_your_contact_details:
    'Change or update your contact details:',
  update: 'Update',
  your_details_have_been_successfully_updated:
    'Your details have been successfully updated.',
  first_name: 'First Name',
  last_name: 'Last Name',
  email_address: 'Email Address',
  mobile_number: 'Mobile Number',
  login: 'Login Details',
  registered_email: 'Registered email',
  change_password: 'Change Password',
  reset_password: 'Reset Password',
  sign_out: 'Sign Out',
  reset_password_email_sent: 'Reset password email sent successfully',
  delete_account: 'Delete Account',
  delete_account_title: 'Delete Account?',
  delete_account_desc: 'Account deletion is permanent and irreversible.',
  delete_account_confirm: 'Confirm Permanent Account Deletion',
  cancel: 'Cancel',
  account_deleted: 'Account successfully deleted',
  logout_title: 'Sign Out?',
  logout_desc: 'Are you sure you want to sign out?',
  logout_confirm: 'Sign Out',
  reset_email_sent: 'Reset email sent!',
}
