import { format, isToday, isTomorrow } from 'date-fns'

import { NarrowFulfilmentMethodInputType } from '@src/../__generated__/globalTypes'

export const getFormattedDate = (
  fulfillmentMethod: NarrowFulfilmentMethodInputType,
  endWindow: Date,
  startWindow?: Date,
  formattedDeliveryDate?: Date,
  formattedCompletionTime?: Date
): string | undefined => {
  // TODO this function needs converting to an object argument that makes sense on some level. Also needs to exist where its used this is a utility which is incredibly specific to the SingleOrder
  if (!formattedDeliveryDate) {
    return undefined
  }
  if (fulfillmentMethod === NarrowFulfilmentMethodInputType.DELIVERY) {
    const startTimeFormat = startWindow ? format(startWindow, '(HH:mm') : null
    const endTimeFormat = format(endWindow, 'HH:mm)')
    if (startWindow && startTimeFormat && isToday(startWindow)) {
      return `Today ${startTimeFormat ? `${startTimeFormat} - ` : ''}
      ${endTimeFormat}`
    } else if (startWindow && startTimeFormat && isTomorrow(startWindow)) {
      return `Tomorrow ${startTimeFormat} - ${endTimeFormat}`
    } else {
      return `${format(formattedDeliveryDate, 'dd/MM/yyyy')} ${
        startTimeFormat ? `${startTimeFormat} - ` : ''
      }  ${endTimeFormat}`
    }
  }
  if (
    fulfillmentMethod === NarrowFulfilmentMethodInputType.COLLECTION ||
    fulfillmentMethod === NarrowFulfilmentMethodInputType.TABLE
  ) {
    if (isToday(formattedCompletionTime ?? formattedDeliveryDate)) {
      return `Today ${format(
        formattedCompletionTime ?? formattedDeliveryDate,
        '(HH:mm)'
      )}`
    } else if (isTomorrow(formattedCompletionTime ?? formattedDeliveryDate)) {
      return `Tomorrow ${format(
        formattedCompletionTime ?? formattedDeliveryDate,
        '(HH:mm)'
      )}`
    } else {
      return format(
        formattedCompletionTime ?? formattedDeliveryDate,
        'dd/mm/yyyy (HH:mm)'
      )
    }
  }
}
