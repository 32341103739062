import { MainRouteName } from '@src/hooks/useAccountRouter'

export const bigPanel = [
  MainRouteName.HELP,
  MainRouteName.TERMS,
  MainRouteName.PRIVACY,
  MainRouteName.ALLERGY,
  MainRouteName.COOKIES,
  MainRouteName.MISC,
]
