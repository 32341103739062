import { useEffect, useMemo, useRef, useCallback } from 'react'

export const useIntersectionObservableRefCallback = ({
  intersectionObserver,
}: {
  intersectionObserver: IntersectionObserver
}): [setRef: (currentNode: HTMLElement | null) => void] => {
  const ref = useRef<HTMLElement | null>(null)
  const setRef = useCallback(
    currentNode => {
      const lastNode = ref.current
      if (lastNode) {
        intersectionObserver.unobserve(lastNode)
      }

      if (currentNode) {
        intersectionObserver.observe(currentNode)
      }

      ref.current = currentNode
    },
    [intersectionObserver]
  )

  return [setRef]
}

export const useIntersectionObserver = ({
  callback,
  options,
}: {
  callback: IntersectionObserverCallback
  options?: IntersectionObserverInit
}): { intersectionObserver: IntersectionObserver } => {
  const intersectionObserver = useMemo(
    () => new IntersectionObserver(callback, options),
    [callback, options]
  )

  useEffect(() => {
    return () => {
      intersectionObserver.disconnect()
    }
  }, [intersectionObserver])

  return { intersectionObserver }
}
