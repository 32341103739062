import React from 'react'

import {
  Checkmark,
  CheckmarkCheck,
  CheckmarkCircle,
  Wrapper,
} from './LoaderCheck.styles'

export const LoaderCheck: React.VFC = () => {
  return (
    <Wrapper>
      <Checkmark xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <CheckmarkCircle cx="26" cy="26" r="25" fill="none" />
        <CheckmarkCheck fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </Checkmark>
    </Wrapper>
  )
}
