import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ActiveOrdersSlider } from '@src/components/ActiveOrdersSlider/ActiveOrdersSlider'
import {
  DrawerHeader,
  DrawerHeaderActions,
} from '@src/components/Drawer/DrawerHeader'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'
import { jwtVar } from '@src/models/customer/jwt'
import { apolloErrorParser } from '@src/utils/apolloErrorParser/apolloErrorParser'

import {
  Container,
  EmailText,
  NameText,
  SignInContainer,
  SignInText,
  LinkSpan,
  StyledAccountSectionButton,
} from './AccountMain.styles'
import { AccountNavigationLinks } from './AccountNavigationLinks'
import { DefaultLinks } from './DefaultLinks/DefaultLinks'

const LoggedInAccountMain: React.FC = () => {
  useNotLoggedInRedirect()
  const history = useHistory()
  const { setRoute } = useAccountRouter()

  const { data, error, loading } = useCustomerDetailsAndAddressesQuery()

  useEffect(() => {
    if ((!loading && !data) || error) {
      const isAuthError = error
        ? apolloErrorParser(error).customAuthenticationErrors.length > 0
        : false
      if (isAuthError) {
        setRoute({ mainRouteName: MainRouteName.LOGIN })
      }
    }
  }, [history, data, loading, error, setRoute])

  if (loading) {
    return <LoadingSpinner />
  }

  const { firstName, lastName, email } = data?.customerDetails || {}
  return (
    <>
      <DrawerHeader action={DrawerHeaderActions.CLOSE} />
      <Container>
        <NameText>
          {firstName} {lastName}
        </NameText>
        <EmailText>{email}</EmailText>
        <ActiveOrdersSlider isAccountPage={true} />
        <AccountNavigationLinks />
        <DefaultLinks />
      </Container>
    </>
  )
}

export const AccountMain: React.FC = () => {
  const jwt = useReactiveVar(jwtVar)
  const { t } = useTranslation('accountMain')
  const { featureLogin, featureRegister } = useMarketplace()

  if (jwt) {
    return <LoggedInAccountMain />
  }

  if (!featureLogin && !featureRegister) {
    return (
      <>
        <DrawerHeader action={DrawerHeaderActions.CLOSE} />
        <Container>
          <DefaultLinks />
        </Container>
      </>
    )
  }

  return (
    <>
      <DrawerHeader action={DrawerHeaderActions.CLOSE} />
      <Container>
        <SignInContainer>
          <SignInText>
            {featureLogin && (
              <StyledAccountSectionButton
                route={{ mainRouteName: MainRouteName.LOGIN }}
              >
                {t('sign_in')}
              </StyledAccountSectionButton>
            )}
            {featureLogin && featureRegister && <LinkSpan>{t('or')}</LinkSpan>}
            {featureRegister && (
              <StyledAccountSectionButton
                route={{ mainRouteName: MainRouteName.REGISTER }}
              >
                {t('sign_up')}
              </StyledAccountSectionButton>
            )}
          </SignInText>
        </SignInContainer>
        <DefaultLinks />
      </Container>
    </>
  )
}
