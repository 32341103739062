import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client'

import { useMarketplace } from '@src/hooks/useMarketplace'

import {
  marketplaceDeliveryZonesDocument,
  marketplaceDeliveryZonesQuery,
  marketplaceDeliveryZonesQueryVariables,
} from './queries/__generated__/marketplaceDeliveryZones.graphql-interface'

type Args = {
  useQueryArgs?: Omit<
    QueryHookOptions<
      marketplaceDeliveryZonesQuery,
      marketplaceDeliveryZonesQueryVariables
    >,
    'variables'
  >
}
export const useMarketplaceDeliveryZonesQuery = ({
  useQueryArgs,
}: Args = {}) => {
  const { id: marketplaceId } = useMarketplace()
  return useQuery(marketplaceDeliveryZonesDocument, {
    ...useQueryArgs,
    variables: {
      marketplaceId,
    },
  })
}

export const useMarketplaceDeliveryZonesLazyQuery = () => {
  const { id: marketplaceId } = useMarketplace()
  return useLazyQuery(marketplaceDeliveryZonesDocument, {
    variables: {
      marketplaceId,
    },
  })
}
