import styled from 'styled-components'

import { DoubleArrowSVG } from '@src/components/SVGS/DoubleArrowSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  background-color: #fff;

  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  padding: 0;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 16px;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

export const InnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-right: 8px;
  height: 100%;
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`

export const Subtitle = styled.p`
  font-size: 13px;
  font-weight: 400;
  margin: 0;
`

export const StyledDoubleArrowSVG = styled(DoubleArrowSVG)`
  align-self: center;
  margin-left: 10px;
`
