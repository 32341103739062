import styled from 'styled-components'

import { Button } from '@src/components/Button/Button'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  @media (min-width: ${breakpoints.wideDesktop}px) {
    margin-top: 0px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: 16px;
    font-size: 16px;
    line-height: 28px;
  }
`

export const PostcodeInput = styled.div`
  width: 160px;
  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 200px;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  padding-top: 16px;
`

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 24px 0px;
  display: flex;
`

export const ConfirmContainer = styled.div`
  margin-top: 30%;
`

export const TitleText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
`

export const SubtitleText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`
