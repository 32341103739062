import {
  NonBasketOutletFulfilment,
  CurrentFulfilmentType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { FulfilmentFilter } from '@src/hooks/useFulfilmentFilter/validation'

import { LocationType } from '../graphql-types'

// calculates the updated location data for the fulfilment filter
// from the current fulfilment data
export const calculateFulfilmentFilterLocationFromCurrentFulfilment = (
  currentFulfilment: NonBasketOutletFulfilment['currentFulfilment']
): FulfilmentFilter['where']['location'] => {
  switch (currentFulfilment.type) {
    case CurrentFulfilmentType.DELIVERY_POSTCODE:
      return {
        type: LocationType.POSTCODE,
        postAndCountryCode: currentFulfilment.postAndCountryCode,
      }
    case CurrentFulfilmentType.DELIVERY_COORDINATES:
      return {
        type: LocationType.COORDINATES,
        coordinates: currentFulfilment.coordinates,
      }
    case CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS:
      return {
        type: LocationType.ADDRESS,
        addressId: currentFulfilment.addressId,
      }
    case CurrentFulfilmentType.DELIVERY_ZONE:
      return {
        type: LocationType.DELIVERY_ZONE,
        zoneId: currentFulfilment.zoneId,
      }
    // everything else is EVERYWHERE (collection, table, etc)
    default:
      return {
        type: LocationType.EVERYWHERE,
      }
  }
}
