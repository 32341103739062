export const copy = {
  preferences:
    'Tick the box to sign up for our latest offers via email. You can change your preferences at any time.',
  continue: 'By continuing you agree to our',
  terms_and_conditions: 'Terms & Conditions',
  and: 'and',
  privacy_policy: 'Privacy Policy',
  search_holder: 'Restaurants, Pizza, Burger...',
  enter_email_or_mobile: 'Enter Email Address or Mobile Number',
  password_requirements: 'Password (min 8 characters)',
  sign_in: 'Sign In to Proceed',
  fastest_delivery: 'Fastest Delivery',
  restricted_items: 'Restricted Items',
  request_contactless: 'Request delivery to be left at door',
  table: 'Table Service',
  delivery: 'Delivery',
  collection: 'Collection',
  open_now: 'Open Now',
  no_outlets_found: 'No {{urlPath}} found',
  all: 'All',
  now_on: 'Now on {{marketplaceName}}',
  no_all_outlets:
    'Sorry, there are no {{urlPath}} available for your location.',
  no_collection_outlets:
    'Sorry, there are no {{urlPath}} available for your location.',
  no_delivery_outlets:
    'Sorry, there are no {{urlPath}} delivering to your location.',
  back_to_all_outlets: 'Back To All {{urlPath}}',
  back_to_outlets_near: 'Back To {{urlPath}} Near {{destination}}',
  back_to_outlets: 'Back To {{urlPath}}',
}
