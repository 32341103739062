import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

import { getBaseDataFromExtendedData } from '../extendData/extendFulfilmentFilterData'
import { CurriedFulfilmentFilterHookMethodArgs } from '../types'

export const setActiveFulfilmentMethods =
  ({
    currentExtendedData,
    updateFulfilmentFilter,
  }: CurriedFulfilmentFilterHookMethodArgs) =>
  (activeFulfilmentMethods: NarrowFulfilmentMethodInputType[]): void => {
    const baseData = getBaseDataFromExtendedData(currentExtendedData)
    // update isActive values for each fulfilment method
    updateFulfilmentFilter({
      ...baseData,
      where: {
        ...baseData.where,
        fulfilmentMethods: activeFulfilmentMethods,
      },
    })
  }
