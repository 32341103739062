import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetCustomerCardsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetCustomerCardsQuery = {
  customerDetails: {
    __typename: 'Customer'
    id: string
    cards: {
      __typename: 'CustomerCards'
      message: string | null
      cards: Array<{
        __typename: 'CardPartial'
        id: string
        last4: string
        exp_month: string | null
        exp_year: string | null
        brand: string | null
      }> | null
    }
  }
}

export const GetCustomerCardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerCards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cards' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last4' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exp_month' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exp_year' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerCardsQuery,
  GetCustomerCardsQueryVariables
>
