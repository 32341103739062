import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@src/components/Button'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  ConfirmationText,
  Container,
  EmailText,
  OrderNoText,
  TitleText,
} from './CheckoutSuccess.styles'
import { CheckoutSuccessDataDocument } from './queries/__generated__/CheckoutSuccessData.graphql-interface'

export const CheckoutSuccess: React.FC = () => {
  const checkoutRouter = useCheckoutRouter()
  const skip = checkoutRouter.route !== CheckoutRoute.COMPLETE
  const { data, loading } = useQuery(
    CheckoutSuccessDataDocument,
    skip
      ? {
          skip,
        }
      : {
          variables: {
            orderId: checkoutRouter.orderId,
          },
        }
  )
  // const orderDetails = orderDetailsVar()
  const { t } = useTranslation(['checkout'])
  const accountRouter = useAccountRouter()
  const { width } = useReactiveVar(screenResolutionVar)

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkoutRouter.reset()
    }, 10_000)

    return function () {
      clearTimeout(timeout)
    }
  }, [checkoutRouter, width])

  if (loading || skip || !data) {
    return <LoadingSpinner />
  }

  // TODO for now we dont have to worry about multiple orders so just get first
  const { id: orderId, orderNumber, outlet } = data.order
  return (
    <Container>
      <LoaderCheck />

      <TitleText>
        {t('order_confirmed')}
        <br />
        {outlet.displayName}
      </TitleText>
      <OrderNoText>
        {t('order_no', {
          orderNumber,
        })}
      </OrderNoText>

      <ConfirmationText>
        {t('confirmation_updates_sent_to')}{' '}
        <EmailText>{data.customerDetails.email}</EmailText>
      </ConfirmationText>

      <Button
        onClick={() => {
          accountRouter.setRoute({
            mainRouteName: MainRouteName.ORDERS,
            pageDataId: orderId,
          })
          checkoutRouter.reset()
        }}
        content={t('track_order')}
      />
    </Container>
  )
}
