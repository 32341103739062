import React from 'react'

import { StyledReactMarkdown } from './Markdown.styles'

export const Markdown: React.VFC<{
  markdown: string
  twoCols?: boolean
}> = ({ markdown, twoCols = false }) => {
  return <StyledReactMarkdown twoCols={twoCols}>{markdown}</StyledReactMarkdown>
}
