import React from 'react'
import { useTranslation } from 'react-i18next'

export const CaretUpSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title, id, className, desc }) => {
  const { t } = useTranslation(['svgs'])
  return (
    <svg
      id={id}
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('caret_up_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <path
        d="M11.41 19L16 14.6734L20.59 19L22 17.668L16 12L10 17.668L11.41 19Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  )
}
