import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type menuItemGroupsQueryVariables = Types.Exact<{
  outletId: Types.Scalars['ID']
  fulfilmentMethods:
    | Array<Types.MenuItemGroupFulfillmentMethod>
    | Types.MenuItemGroupFulfillmentMethod
  endOfPrep?: Types.InputMaybe<Types.Scalars['DateTime']>
  getUnavailableBySchedule?: Types.InputMaybe<Types.Scalars['Boolean']>
}>

export type menuItemGroupsQuery = {
  menuItemGroupsForOutlet: Array<{
    __typename: 'OutletMenuItemGroup'
    id: string
    outletMenuItems: Array<{
      __typename: 'OutletMenuItem'
      id: string
      name: string
      soldOut: boolean | null
      menuItemId: string
      options: Array<{
        __typename: 'Option'
        id: string
        name: string
        optionItems: Array<{
          __typename: 'OptionItem'
          id: string
          name: string
          soldOut: boolean | null
        }>
      }>
    }>
  }>
}

export const menuItemGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'menuItemGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentMethods' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'MenuItemGroupFulfillmentMethod',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endOfPrep' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'getUnavailableBySchedule' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'menuItemGroupsForOutlet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfillmentMethods' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fulfilmentMethods' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endOfPrep' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endOfPrep' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUnavailableBySchedule' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'getUnavailableBySchedule' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletMenuItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'soldOut' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'menuItemId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'soldOut' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<menuItemGroupsQuery, menuItemGroupsQueryVariables>
