import { isString, reduce } from 'lodash'

type EnumType = { [s: number]: string | number }

/**
 * Enums look like Objects with values which relate to both the string value underpinning
 * the enum
 * Eg
 *  Enum FooBar {
 *    FOO,
 *    BAR
 *  }
 *
 *  result in:
 *
 *  const FooBar = {
 *    0: 'FOO,
 *    1: 'BAR',
 *    BAR: 1,
 *    FOO: 0
 *  }
 */

// Essentially this strips off all the values that are not strings leaving all the remaining
// string properties, ie the enums!
export const mapEnumToStrings = (enumberable: EnumType): string[] =>
  reduce(
    enumberable,
    (strings, val) => (isString(val) ? [...strings, val] : strings),
    [] as string[]
  )
