import { useQuery } from '@apollo/client'

import { useShowInactiveQueryParam } from '@src/hooks/useShowInactiveQueryParam'

import { outletFulfilmentVariables } from './queries/__generated__/outletFulfilment'
import { outletFulfilmentDocument } from './queries/__generated__/outletFulfilment.graphql-interface'

// hook which fetches data about an outlet, used to populate the CurrentOutletContext and BasketOutletContext
// which in turn is consumed by useOutletFulfilment and useBasket to calculate fulfilment times etc
export const useOutletFulfilmentData = ({
  outletId,
  fulfilmentLocation,
  acceptedFulfilmentMethods,
  // allow inactive outlets for basket - error will come on checkout but still allows context to init
  showInactiveOverride = false,
}: Pick<
  outletFulfilmentVariables,
  'fulfilmentLocation' | 'acceptedFulfilmentMethods'
> & {
  outletId: string | null
  showInactiveOverride?: boolean
}) => {
  const [showInactive] = useShowInactiveQueryParam()
  return useQuery(
    outletFulfilmentDocument,
    outletId === null
      ? { skip: true }
      : {
          variables: {
            outletId,
            showInactive: showInactiveOverride ?? showInactive ?? false,
            acceptedFulfilmentMethods,
            fulfilmentLocation,
          },
        }
  )
}
