import styled from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'

export const Container = styled.div`
  padding: 16px 0px 88px 32px;

  color: ${({ theme }) => theme.colors.mainText};
  background-color: #fff;
  overflow: hidden;
`

export const NameText = styled.p`
  font-size: 27px;
  font-weight: 700;
  margin: 16px 32px 5px 0;
  text-transform: capitalize;
`

export const EmailText = styled.p`
  margin: 0px 32px 32px 0px;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.8;
`

export const SignInContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  height: 64px;
`

export const SignInText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 20px;
`

export const StyledAccountSectionButton = styled(AccountSectionNavButton)`
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  font-size: 24px;
  line-height: 64px;
  transition: 0.2s color ease;
`

export const LinkSpan = styled.span`
  font-size: 20px;
  margin: 0px 12px;
`

export const AccountSectionNavButtonContent = styled.p`
  display: block;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
  text-align: left !important;
`

export const StyledAccountSectionNavButton = styled(AccountSectionNavButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  margin-right: 32px;
  width: 100%;

  &.active,
  &:hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.brand} !important;
  }
`
