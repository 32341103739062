import React from 'react'

export const CloseButton: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'close-button', id, className, desc }) => (
  <svg
    id={id}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <title className="iconTitle">{title}</title>
    <circle cx="16" cy="16" r="16" fill="white" />
    {desc && <desc className="iconDescription">{desc}</desc>}
    <path
      d="M15.7143 1.83903L14.1604 0.285156L7.99999 6.44556L1.83958 0.285156L0.285706 1.83903L6.44611 7.99944L0.285706 14.1599L1.83958 15.7137L7.99999 9.55332L14.1604 15.7137L15.7143 14.1599L9.55387 7.99944L15.7143 1.83903Z"
      fill="currentColor"
    />
  </svg>
)
