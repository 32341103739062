export const footer = {
  terms: 'Terms & Conditions',
  privacy: 'Privacy Policy',
  allergy: 'Allergy Policy',
  cookies: 'Cookies',
  help: 'Help & Support',
  download: 'download app',
  play_store: 'Download Play Store',
  vat_no: 'VAT #',
  support: 'Support',
  support_email: 'Email',
  support_phone: 'Phone',
  company_no: 'Company #',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twitter: 'X.com',
  tiktok: 'TikTok',
  misc_policies: 'Other Policies',
  title_social: 'Social Media',
  title_legal: 'Legal & Help',
  title_download: 'Apps',
}
