import React from 'react'

export const FacebookSVG: React.FC<{
  width: number
  height: number
  className?: string
}> = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.4103 13.0485L15.8767 9.81553H12.9589V7.71751C12.9589 6.83302 13.3665 5.97088 14.6734 5.97088H16V3.21846C16 3.21846 14.796 3 13.645 3C11.2419 3 9.67125 4.54835 9.67125 7.35146V9.81553H7V13.0485H9.67125V20.8641C10.2069 20.9535 10.7559 21 11.3151 21C11.8743 21 12.4233 20.9535 12.9589 20.8641V13.0485H15.4103Z"
        fill="#141414"
      />
    </svg>
  )
}
