import { OutletMenuItemGroupTree } from '@src/pages/OutletPage/menuItemGroupTreeType'

interface ModalData {
  fullMenuItemGroupTree: OutletMenuItemGroupTree
  modalMenuItemId: string | null | undefined
}

// use id from menuItemQueryParam to find:
// 1. the menuItem
// 2. the menuItem's menu item group
// 3. the available fulfilment methods of the menu item group and it's parent
export const useFindMenuItemModalData = ({
  fullMenuItemGroupTree,
  modalMenuItemId,
}: ModalData) => {
  const findMenuItemModalData = ({
    fullMenuItemGroupTree,
    modalMenuItemId,
  }: ModalData) => {
    // no modal menu item id so no need to find the menu item etc
    if (!modalMenuItemId) {
      return null
    }
    // loop through menu item groups to find the menu item
    for (const treeNode of fullMenuItemGroupTree) {
      // start with parent menu's menu items
      for (const outletMenuItem of treeNode.parentMenu.outletMenuItems) {
        if (outletMenuItem.menuItemId === modalMenuItemId) {
          return {
            outletMenuItem: outletMenuItem,
            outletMenuItemGroup: treeNode.parentMenu,
          }
        }
      }
      // next try each submenu sequentially
      // if a menu item is part of 2 submenus, we'll only return the first one
      for (const subMenu of treeNode.subMenus) {
        for (const outletMenuItem of subMenu.outletMenuItems) {
          if (outletMenuItem.menuItemId === modalMenuItemId) {
            return {
              outletMenuItem: outletMenuItem,
              outletMenuItemGroup: subMenu,
            }
          }
        }
      }
    }

    return null
  }

  return {
    modalData: findMenuItemModalData({
      fullMenuItemGroupTree,
      modalMenuItemId,
    }),
  }
}
