import React from 'react'

export const MinusSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  return (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      width="12"
      viewBox="0 0 12 12"
    >
      <title id={`${id}Title`}>{title || 'minus'}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g fill="currentColor" className="nc-icon-wrapper">
        <path
          d="M11,7H1A1,1,0,0,1,1,5H11a1,1,0,0,1,0,2Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  )
}
