export const allergyTags = {
  vegetarian: 'Vegetarian',
  vegan: 'Vegan',
  gluten_free: 'Gluten Free',
  dairy_free: 'Dairy Free',
  keto: 'Keto',
  allergy_info: `If you, or someone you are ordering for, have a food allergy or intolerance, ALWAYS call the restaurant before placing an order.`,
  allergens: 'Allergens',
  view_allergy_policy: 'View Allergy Policy',
  call_restaurant: 'Call Restaurant',
  age_restricted_content: '18',
  age_restricted: 'Age Restricted',
}
