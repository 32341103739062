import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
`

export const CheckmarkCircle = styled.circle`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke: ${({ theme }) => theme.colors.brand};
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
`

export const Checkmark = styled.svg`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: white;
  stroke-miterlimit: 10;
  stroke: ${({ theme }) => theme.colors.brand};
  margin: 28px auto;
  animation: fill 0.4s ease-in-out 0.4s forwards;
  scale: 0.3s ease-in-out 0.9s both;

  @keyframes scale {
    0% {
    }
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 40px ${({ theme }) => theme.colors.brand};
    }
  }
`

export const CheckmarkCheck = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
`
