import { isEqual, sortBy } from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import { clearVoucher } from '@src/hooks/useDiscounts/clearVoucher'

import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'
import { BasketItem } from '../validation'

// adds an item to the basket, or updates the quantity if the item is already in the basket
export const addItem =
  ({
    basketOutletId,
    updateBasketOutletId,
    existingItems,
    navigateToBasket,
  }: HookMethodArgs) =>
  ({
    item,
    outletId,
    shouldNavigateToBasket = false,
  }: {
    item: Omit<BasketItem, 'id' | 'availability'>
    outletId: string
    shouldNavigateToBasket?: boolean
  }): void => {
    // if item is for a different outlet to the one currently in the basket
    // update the basket's outlet id, reset the basket items, clear any discount codes
    const itemWithId: BasketItem = {
      ...item,
      id: uuidV4(),
      availability: {},
    }

    // navigate back to the basket
    shouldNavigateToBasket && navigateToBasket()

    // reset all basket state if the outlet has changed
    if (basketOutletId !== outletId) {
      updateBasketOutletId(outletId)
      clearVoucher()
      basketItemsVar([itemWithId])
    }

    // otherwise just add the item to the basket
    else {
      const duplicateItemIndex = existingItems.findIndex(
        existingItem => existingItem.menuItemId === item.menuItemId
      )
      const duplicateItem = existingItems[duplicateItemIndex]

      const isSameOptions = isEqual(
        sortBy(duplicateItem?.optionItemIds || []),
        sortBy(item.optionItemIds)
      )
      const isSameNotes =
        duplicateItem?.singleItemNotes === item.singleItemNotes

      // if item is already in the basket, update the quantity
      const clonedItems = [...existingItems]
      if (duplicateItem && isSameOptions && isSameNotes) {
        clonedItems[duplicateItemIndex] = {
          ...duplicateItem,
          quantity: duplicateItem.quantity + item.quantity,
        }
      } else {
        clonedItems.push(itemWithId)
      }
      basketItemsVar(clonedItems)
    }
  }
