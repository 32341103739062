import React from 'react'

export const TikTokSVG: React.FC<{
  width: number
  height: number
  className?: string
}> = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_150_8227)">
        <path
          d="M17.6099 6.60782C16.6216 5.97567 15.9086 4.96387 15.6861 3.78513C15.638 3.53052 15.6113 3.26823 15.6113 3H12.4573L12.4519 15.4018C12.3992 16.7908 11.2341 17.9052 9.80585 17.9052C9.36193 17.9052 8.944 17.7966 8.5759 17.6063C7.73185 17.1707 7.15408 16.3028 7.15408 15.3043C7.15408 13.8699 8.3438 12.7027 9.8055 12.7027C10.0785 12.7027 10.3402 12.7471 10.5876 12.8228V9.66382C10.3313 9.62924 10.0711 9.60829 9.8055 9.60829C6.60443 9.60829 4 12.1631 4 15.3043C4 17.2312 4.98111 18.9366 6.47876 19.9676C7.42143 20.6176 8.56878 21 9.80585 21C13.0073 21 15.6113 18.4448 15.6113 15.3043V9.01525C16.8484 9.8863 18.3642 10.3997 20 10.3997V7.30494C19.1189 7.30494 18.2984 7.04824 17.6099 6.60782Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_150_8227">
          <rect
            width="16"
            height="18"
            fill="white"
            transform="translate(4 3)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
