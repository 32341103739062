import React, { Fragment } from 'react'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs/CheckboxInput/CheckboxInput'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { PaymentCard } from '@src/components/PaymentCard/PaymentCard'
import {
  Card,
  usePaymentCardsQuery,
} from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'

export const CheckoutPaymentCards: React.FC<{
  onSelect: (card: Card) => void
  selectedCardToken: string | null
  disabled: boolean
}> = ({ onSelect, selectedCardToken, disabled }) => {
  const { data, loading, error, refetch } = usePaymentCardsQuery()

  if (loading) return <LoadingSpinner />
  if (!data || error) return <ErrorPage logError={error} />

  return (
    <>
      {(data.customerDetails.cards.cards || []).map(card => {
        const isSelected = card.id === selectedCardToken
        return (
          <UncontrolledRadioInput
            key={card.id}
            value={card.id}
            disabled={disabled}
            label={<PaymentCard card={card} refetchList={refetch} />}
            checked={isSelected}
            style={CheckboxInputStyle.TICK}
            onChange={() => onSelect(card)}
          />
        )
      })}
    </>
  )
}
