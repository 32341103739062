import styled, { css } from 'styled-components'

import { CancelSVG } from '@src/components/SVGS/CancelSVG'
import { EyeIconSVG } from '@src/components/SVGS/EyeIconSVG'

export const Container = styled.div`
  position: relative;
  margin-bottom: 8px;
`

export const Error = styled.p`
  color: #e1292f;
  background-color: #ffe7ec;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  border-radius: 0 0 8px 8px;
  padding: 8px;
`

export const Label = styled.label<{ $isPlaceHolder: boolean }>`
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #a0a4b6;
  cursor: text;
  z-index: 11; // to be above the input otherwise will be covered by autofill yellow.
  transition: 0.2s ease;
  transition-property: top;

  ${props =>
    props.$isPlaceHolder &&
    css`
      top: 16px;
      font-size: 16px;
    `}
`

export const Required = styled.span`
  margin-left: 2px;
  color: #000000;
`

export const Input = styled.input<{ visible: boolean; isTextArea: boolean }>`
  position: absolute;
  top: ${({ isTextArea }) => (isTextArea ? '18px' : 0)};
  right: 0px;
  bottom: ${({ isTextArea }) => (isTextArea ? 'none' : 0)};
  left: 0px;
  border: 0;
  outline: 0;
  padding: 0 ${props => (props.type === 'password' ? '52px' : '8px')} 0 16px;
  font-weight: 400;
  font-size: 16px;
  background: none;
  cursor: text;
  z-index: 10;
  border-radius: 6px;
  box-sizing: border-box;
  // fix for firefox
  width: 100%;

  ::placeholder {
    color: #a0a4b6;
  }

  transition: 0.2s ease;
  transition-property: padding-top, opacity;

  ${props =>
    props.visible
      ? css`
          padding-top: 20px;
          opacity: 1;
        `
      : css`
          padding-top: 11px;
          opacity: 0;
        `}
`

export const TextArea = styled.textarea<{ visible: boolean }>`
  position: absolute;
  top: 18px;
  right: 0px;
  left: 0px;
  border: 0;
  outline: 0;
  padding: 0 8px 0 16px;
  font-weight: 400;
  font-size: 16px;
  background: none;
  font-weight: 400;
  cursor: text;
  z-index: 10;
  box-sizing: border-box;
  // fix for firefox
  width: 100%;

  transition: 0.2s ease;
  transition-property: padding-top, opacity;

  ${props =>
    props.visible
      ? css`
          padding-top: 20px;
          opacity: 1;
        `
      : css`
          padding-top: 11px;
          opacity: 0;
        `}

  min-height: 95%;
  max-height: 95%;
  min-width: 100%;
  max-width: 100%;
`

export const InputContainer = styled.div<{
  borderless: boolean
  errorBorder: boolean
  focusBorder: boolean
  disabled?: boolean
  isTextArea: boolean
}>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: ${({ isTextArea }) => (isTextArea ? '320px' : '56px')};
  padding: 0 16px;
  border: 2px solid;
  border-color: ${props => {
    if (props.errorBorder) {
      return '#E1292F'
    }
    if (props.disabled) {
      return '#ebecf0'
    }
    if (props.borderless) {
      return '#fff'
    }
    return '#ebecf0'
  }};
  border-radius: ${props => {
    if (props.errorBorder) {
      return '8px 8px 0px 0px'
    }
    return '8px'
  }};
  background: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.1)' : '#fff')};

  :focus-within {
    border: ${props => (props.focusBorder ? '2px solid #000' : 'none')};
    ${Label} {
      top: 8px;
      font-size: 12px;
    }

    ${Input} {
      padding-top: 20px;
      opacity: 1;
    }
  }
`

export const StyledEyeIcon = styled(EyeIconSVG)<{ isActive: boolean }>`
  .eye-path {
    fill: ${props => (props.isActive ? '#2E3333' : '#ABABAB')};
  }
`

export const TogglePasswordContainer = styled.button`
  all: unset;
  z-index: 11;
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 19px;
`

export const ClearButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  right: 6px;
  top: 6px;
  z-index: 10;
  padding: 8px;
  position: absolute;
  cursor: pointer;
`

export const ClearButtonIcon = styled(CancelSVG)`
  cursor: pointer;
  justify-self: flex-end;
  &:hover {
    opacity: 0.8;
  }
`
