import { Form } from 'formik'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import styled, { css } from 'styled-components'

import { Button } from '@src/components/Button'
import { plusSVGDataURL } from '@src/components/SVGS/PlusSVG'
import { tickInCircleSVGDataURL } from '@src/components/SVGS/TickInCircleSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const FlexGrowForm = styled(Form)<{
  $windowHeight: number
  $hasSafeArea: boolean
}>`
  height: ${props => props.$windowHeight - 160}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: ${breakpoints.tablet}px) {
    height: ${({ $hasSafeArea, $windowHeight }) =>
      $hasSafeArea ? `${$windowHeight - 180}px` : `100vh`};
  }
`

export const FlexGrowScrollContainer = styled(Scrollbars)`
  flex-grow: 1;
`
const FlexGrowScrollInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

FlexGrowScrollContainer.defaultProps = {
  renderView: props => <FlexGrowScrollInnerContainer {...props} />,
}

export const CheckoutStepForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px 32px 32px;
  box-sizing: border-box;
`

export const ScrollContainer = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
`

export const TitleAndErrorContainer = styled.div<{ addressError: boolean }>`

${({ addressError }) =>
  addressError &&
  css`
    animation: shake-effect 0.8s, highlight-red 3s;
  `}


  @keyframes highlight-red {
    0% {
      color: red;
    }
  }

  @keyframes shake-effect {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(4px);
    }
    30% {
      transform: translateX(-4px);
    }
    50% {
      transform: translateX(4px);
    }
    70% {
      transform: translateX(-4px);
    }
    90% {
      transform: translateX(4px);
    }
    100% {
      transform: translateX(0px);
    }

`

export const ErrorMessage = styled.p`
  color: #e1292f;
  border-radius: 4px;
  border: 2px solid #e1292f;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
`

export const StepTitle = styled.h3`
  font-size: 20px;
  margin-top: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FulfilmentSectionContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border: none;
  margin: 0 0 16px 0;
  padding: 0;
`

export const RadioButton = styled.input.attrs({
  type: 'radio',
})<{
  $uncheckedSVGName?: string
  $checkedSVGName?: string
}>`
  /* Hide the default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Custom radio button style: black circle */
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #ebecf0;
  border-radius: 50%;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;

  &:checked {
    background-color: #000;
    border: 2px solid #000;
  }

  &:not(:checked)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin: 2px;
    background-image: ${props => props.$uncheckedSVGName || 'non'};
    background-repeat: no-repeat;
    background-position: center;
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin: 2px;
    background-image: ${props =>
      props.$checkedSVGName || tickInCircleSVGDataURL};
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:focus {
    /* Custom style for radio button focus state */
    outline: none;
  }

  &:hover {
    /* Custom style for radio button focus state */
    outline: none;
    border: 2px solid #000;
  }
`

export const AddressAndFriendlyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const Label = styled.label`
  font-weight: 700;
  cursor: pointer;
  padding-left: 8px;
`

export const Text = styled.p`
  font-weight: 400;
  margin: 0px;
  font-size: 15px;
  padding-left: 8px;
  line-height: 20px;
`

export const UnavailableText = styled.p`
  font-weight: 400;
  margin: 0px;
  font-size: 12px;
  padding-left: 8px;
  line-height: 20px;
`

export const SelectedLabel = styled.label`
  font-weight: 500;
  cursor: pointer;
  padding-left: 8px;
  margin-bottom: 3px;
`

export const NoteLabel = styled.label`
  font-weight: 700;
  cursor: pointer;
`

// A radio button which renders a plus icon when unchecked and the default tick icon when checked
export const PlusUncheckedRadioButton = styled(RadioButton).attrs({
  $uncheckedSVGName: plusSVGDataURL,
})``

export const CheckoutButton = styled(Button)`
  border-radius: 28px;
  font-size: 16px;
  height: 56px;
`

export const SpaceBetweenContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
`

export const FulfilmentNoteContainer = styled.div``

export const ContentWrapper = styled.div`
  padding: 8px 16px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 16px 32px;
  }
`
