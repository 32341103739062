import React from 'react'
import { useTranslation } from 'react-i18next'

export const StarSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  fill?: string
}> = ({ id, className, title, desc, fill = '#ffd764' }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      width="20"
      viewBox="0 0 48 48"
    >
      <title id={`${id}Title`}>{title || t('featured')}</title>
      {desc && <desc id={id}>{desc}</desc>}
      <g>
        <path
          d="M46,18H30.19L24.952,1.694a1,1,0,0,0-1.9,0L17.81,18H2a1,1,0,0,0-.612,1.791L14,29.554,8.752,45.691a1,1,0,0,0,1.539,1.118L24,36.85l13.709,9.959a1,1,0,0,0,1.539-1.118L34,29.554l12.608-9.763A1,1,0,0,0,46,18Z"
          fill={fill}
        ></path>
      </g>
    </svg>
  )
}
