import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type archiveCustomerAccountMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type archiveCustomerAccountMutation = {
  archiveCustomer: {
    __typename: 'ArchiveCustomerResponse'
    message: string
    archivedId: string
  }
}

export const archiveCustomerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'archiveCustomerAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveCustomer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  archiveCustomerAccountMutation,
  archiveCustomerAccountMutationVariables
>
