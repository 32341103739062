import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useRef, useState } from 'react'

import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'
import {
  TreeOutletMenuItem,
  TreeOutletMenuItemGroup,
} from '@src/pages/OutletPage/menuItemGroupTreeType'
import { imageJitURL } from '@src/utils/imageJitURL'

import { AddMenuItemForm } from './AddMenuItemForm'
import { SingleMenuItemModalContainer } from './MenuItemModal.styles'
import { outletMenuItemOptionsDocument } from './queries/__generated__/outletMenuItemOptions.graphql-interface'

export const MenuItemModal: React.FC<{
  modalData: {
    outletMenuItem: TreeOutletMenuItem
    outletMenuItemGroup: TreeOutletMenuItemGroup
  }
  closeModal: () => void
}> = ({ modalData, closeModal }) => {
  const [showModalFooter, setShowModalFooter] = useState(false)
  const screenResolution = useReactiveVar(screenResolutionVar)

  const { outletMenuItem, outletMenuItemGroup } = modalData

  const { data, loading, error } = useQuery(outletMenuItemOptionsDocument, {
    variables: {
      outletMenuItemId: outletMenuItem.id,
    },
  })

  const contentRef = useRef<HTMLDivElement>(null)

  const scrollToFooter = (): void => {
    contentRef?.current?.scrollTo({
      top: contentRef.current.offsetTop + 144,
      left: 0,
      behavior: 'smooth',
    })
  }

  if (loading) return <LoadingSpinner />
  if (error || !data) {
    return null
  }

  const image = outletMenuItem.image

  const getScreenWidth = () => {
    if (screenResolution.width < breakpoints.tablet) {
      return 375
    } else {
      return 565
    }
  }

  const imageURL = image
    ? imageJitURL(image, {
        resize: {
          width: getScreenWidth(),
          height: 256,
        },
      })
    : null

  return (
    <SingleMenuItemModalContainer
      ref={contentRef}
      showModalFooter={showModalFooter}
    >
      <AddMenuItemForm
        outletMenuItem={outletMenuItem}
        options={data.optionsByOutletMenuItemId}
        imageURL={imageURL}
        showModalFooter={showModalFooter}
        setShowModalFooter={setShowModalFooter}
        close={closeModal}
        scrollToFooter={scrollToFooter}
        narrowFulfilmentMethods={outletMenuItemGroup.narrowFulfilmentMethods}
      />
    </SingleMenuItemModalContainer>
  )
}
