import { useReactiveVar } from '@apollo/client'
import { without } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  NarrowFulfilmentMethodInputType,
  LocationType,
} from '@src/graphql-types'
import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { outletFulfilmentQuery } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/queries/__generated__/outletFulfilment.graphql-interface'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'

import { DeliveryLocationSection } from './DeliveryLocationSection'
import { SectionHeader } from './SectionHeader'
import {
  SectionContentContainer,
  PopoverOptionContainer,
  OptionLabel,
  OptionContentLabels,
  OptionContentTitle,
  OptionContentSubTitle,
} from './ServicePopover.styles'
import { TableSection } from './TableSection'

import { UncontrolledRadioInput, CheckboxInputStyle } from '../Inputs'

export const AllLocationSection: React.FC<{
  setIsSecondaryPopoverOpen: (v: boolean) => void
  handleCancelWhenNavigatingAway: () => void
  localOutletFulfilmentData?: outletFulfilmentQuery['outlet']
}> = ({
  setIsSecondaryPopoverOpen,
  handleCancelWhenNavigatingAway,
  localOutletFulfilmentData,
}) => {
  const { t } = useTranslation('serviceNavigation')
  const marketplace = useMarketplace()
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.LOCAL,
  })
  const isUserLoggedIn = Boolean(useReactiveVar(jwtVar))
  const { basketOutletId } = useBasketOutletId()

  const selectedFulfilmentIsAvailable = localOutletFulfilmentData
    ? localOutletFulfilmentData.prioritisedFulfilmentMethods.includes(
        outletFulfilment.data.currentFulfilment.narrowType
      )
    : false

  const {
    // this is the outlet fulfilment data for the saved data
    outlet,
    data: { currentFulfilment },
  } = outletFulfilment

  if (!outlet.isOrderable) {
    return null
  }

  if (
    currentFulfilment.narrowType === NarrowFulfilmentMethodInputType.DELIVERY
  ) {
    return (
      <>
        <SectionHeader
          header={t('location_header')}
          subheader={
            currentFulfilment.narrowType ===
            NarrowFulfilmentMethodInputType.DELIVERY
              ? t('delivery_location_subheader')
              : t('location_subheader')
          }
        />
        <SectionContentContainer>
          <DeliveryLocationSection
            basketOutletId={basketOutletId}
            availableLocationTypes={
              isUserLoggedIn
                ? without(
                    marketplace.availableLocationTypes,
                    LocationType.EVERYWHERE
                  )
                : without(
                    marketplace.availableLocationTypes,
                    LocationType.EVERYWHERE,
                    LocationType.ADDRESS
                  )
            }
            selectedLocation={outletFulfilment.data.currentFulfilment.location}
            selectedFulfilmentIsAvailable={selectedFulfilmentIsAvailable}
            setSelectedLocation={selectedLocation => {
              if (
                currentFulfilment.narrowType !==
                  NarrowFulfilmentMethodInputType.DELIVERY ||
                selectedLocation.type === LocationType.EVERYWHERE
              ) {
                return
              }

              switch (selectedLocation.type) {
                case LocationType.ADDRESS:
                  return outletFulfilment.setCurrentFulfilment({
                    type: CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS,
                    addressId: selectedLocation.addressId,
                    deliveryPreorderWindow:
                      currentFulfilment.deliveryPreorderWindow,
                  })
                case LocationType.POSTCODE:
                  return outletFulfilment.setCurrentFulfilment({
                    type: CurrentFulfilmentType.DELIVERY_POSTCODE,
                    postAndCountryCode: selectedLocation.postAndCountryCode,
                    deliveryPreorderWindow:
                      currentFulfilment.deliveryPreorderWindow,
                  })
                case LocationType.COORDINATES:
                  return outletFulfilment.setCurrentFulfilment({
                    type: CurrentFulfilmentType.DELIVERY_COORDINATES,
                    coordinates: selectedLocation.coordinates,
                    deliveryPreorderWindow:
                      currentFulfilment.deliveryPreorderWindow,
                  })
                case LocationType.DELIVERY_ZONE:
                  return outletFulfilment.setCurrentFulfilment({
                    type: CurrentFulfilmentType.DELIVERY_ZONE,
                    zoneId: selectedLocation.zoneId,
                    deliveryPreorderWindow:
                      currentFulfilment.deliveryPreorderWindow,
                  })
              }
            }}
            historicalData={outletFulfilment.data.historicalData}
            isPopover={true}
            onSecondaryServicePopoverToggle={setIsSecondaryPopoverOpen}
            handleCancelWhenNavigatingAway={handleCancelWhenNavigatingAway}
          />
        </SectionContentContainer>
      </>
    )
  }

  if (
    currentFulfilment.narrowType === NarrowFulfilmentMethodInputType.COLLECTION
  ) {
    const addressOutput = outlet.outletAddress
      ? [
          outlet.outletAddress.firstLine
            ? `${outlet.outletAddress.firstLine},`
            : '',
          outlet.outletAddress.secondLine
            ? `${outlet.outletAddress.secondLine},`
            : '',
          outlet.outletAddress.thirdLine
            ? `${outlet.outletAddress.thirdLine},`
            : '',
          outlet.outletAddress.postcode
            ? `${outlet.outletAddress.postcode}`
            : '',
        ].join(' ')
      : ''

    return (
      <>
        <SectionHeader
          header={t('location_header')}
          subheader={t('location_subheader')}
        />
        <SectionContentContainer>
          <PopoverOptionContainer>
            <UncontrolledRadioInput
              label={
                <>
                  <OptionLabel>
                    <OptionContentLabels>
                      <OptionContentTitle>
                        {t('collect_from')}
                      </OptionContentTitle>
                      {addressOutput.length > 0 && (
                        <OptionContentSubTitle>
                          {addressOutput}
                        </OptionContentSubTitle>
                      )}
                    </OptionContentLabels>
                  </OptionLabel>
                </>
              }
              style={CheckboxInputStyle.TICK}
              checked={true}
            />
          </PopoverOptionContainer>
        </SectionContentContainer>
      </>
    )
  }

  // only show table when outlet is open
  if (
    outlet.isOpen &&
    outlet.isOnline &&
    currentFulfilment.narrowType === NarrowFulfilmentMethodInputType.TABLE
  ) {
    return (
      <>
        <SectionHeader
          header={t('table_header')}
          subheader={t('table_subheader')}
        />
        <TableSection />
      </>
    )
  }

  return null
}
