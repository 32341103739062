import React from 'react'

export const CategorySVG: React.FC<{ id: string }> = ({ id }) => {
  return (
    <svg
      id={id}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`} />
      <rect
        width="8.33333"
        height="1.85185"
        transform="matrix(-1 0 0 1 8.83301 12.7046)"
        fill="currentColor"
      />
      <circle
        cx="3.7037"
        cy="3.7037"
        r="2.7037"
        transform="matrix(-1 0 0 1 15.3145 9.92627)"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
      />
      <rect
        x="6.98145"
        y="3.44482"
        width="8.33333"
        height="1.85185"
        fill="currentColor"
      />
      <circle
        cx="4.2037"
        cy="4.37021"
        r="2.7037"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  )
}
