import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { accountMain } from './accountMain'
import { addBusiness } from './addBusiness'
import { addOnItems } from './addOnItems'
import {
  addressBook,
  addressBookAddAddress,
  addressBookEditAddress,
  addressForm,
} from './addressBook'
import { allergyTags } from './allergyTags'
import { burgerMenu } from './burgerMenu'
import { checkout } from './checkout'
import { common } from './common'
import { contactDetails } from './contactDetails'
import { copy } from './copy'
import { datePicker } from './datePicker'
import { daysPicker } from './daysPicker'
import { deliveryModal } from './deliveryModal'
import { deliveryTimeHandlers } from './deliveryTimeHandlers'
import { dirtyBasket } from './dirtyBasket'
import { discounts } from './discounts'
import { distance } from './distance'
import { errors } from './errors'
import { footer } from './footer'
import { helpAndSupport } from './helpAndSupport'
import { homePage } from './homePage'
import { locationOverlay } from './locationOverlay'
import { marketing_preferences } from './marketingPreferences'
import { menuItem } from './menuItem'
import { myOrdersPage } from './myOrdersPage'
import { navigation } from './navigation'
import { navigationLinks } from './navigationLinks'
import { outlet } from './outlet'
import { outletCard } from './outletCard'
import { outletDetails } from './outletDetails'
import { outletHeader } from './outletHeader'
import { outletOverview } from './outletOverview'
import { outletStatus } from './outletStatus'
import { paymentDetails } from './paymentDetails'
import { policies } from './policies'
import { serviceNavigation } from './serviceNavigation'
import { serviceOverlay } from './serviceOverlay'
import { resetPassword, signIn, signInError, changePassword } from './signIn'
import { signUp } from './signUp'
import { singleOrder } from './singleOrder'
import { supportForm } from './supportForm'
import { svgs } from './svgs'
import { yearsPicker } from './yearsPicker'

const resources = {
  en: {
    accountMain,
    burgerMenu,
    common,
    navigation,
    copy,
    errors,
    distance,
    svgs,
    deliveryModal,
    checkout,
    singleOrder,
    deliveryTimeHandlers,
    marketing_preferences,
    myOrdersPage,
    outlet,
    outletCard,
    contactDetails,
    addressBook,
    addressBookAddAddress,
    addressBookEditAddress,
    addressForm,
    policies,
    paymentDetails,
    helpAndSupport,
    signIn,
    signInError,
    changePassword,
    signUp,
    navigationLinks,
    yearsPicker,
    resetPassword,
    footer,
    serviceNavigation,
    serviceOverlay,
    addBusiness,
    outletHeader,
    outletDetails,
    outletOverview,
    daysPicker,
    datePicker,
    discounts,
    menuItem,
    locationOverlay,
    outletStatus,
    addOnItems,
    allergyTags,
    supportForm,
    dirtyBasket,
    homePage,
    fallbackLng: 'en',
  },
}

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: 'common',
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

// eslint-disable-next-line import/no-default-export
export default i18n
