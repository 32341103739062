import { startCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import {
  MobileNavigationPopoverContentWrapper,
  MobileNavigationPopoverContent,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
  DoneButton,
  OptionContentLabels,
  OptionContentSubTitle,
  OptionContentTitle,
  OptionLabel,
  PopoverNoAddressContainer,
  PopoverOptionContainer,
  SectionContentContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { SharedAddress } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'

import { Button, ButtonType } from '../Button'

export const AddressesPopover: React.FC<{
  isOpen: boolean
  onClose: () => void
  onChange?: (addressId: string) => void
  onDone: (addressId: string) => void
  selectedAddressId?: string | null
  addresses: SharedAddress[]
  // required to close the parent when navigating to add address
  handleCancelWhenNavigatingAway: () => void
  isPopover: boolean
}> = ({
  isOpen,
  onClose,
  onChange,
  onDone,
  selectedAddressId = null,
  addresses,
  handleCancelWhenNavigatingAway,
  isPopover,
}) => {
  const { t } = useTranslation('serviceNavigation')
  const [addressId, setAddressId] = useState(selectedAddressId)
  const { setRoute } = useAccountRouter()

  // if state is being managed by parent, update local state when parent state changes
  useEffect(() => {
    setAddressId(selectedAddressId)
  }, [selectedAddressId])

  const handleClose = () => {
    onClose()
    setAddressId(selectedAddressId)
  }

  return (
    <ServicePopover
      open={isOpen}
      onClose={handleClose}
      headerColor="#fff"
      backgroundColor={'#fff'}
      maxWidth={584}
      showBackdrop={!isPopover}
    >
      <MobileNavigationPopoverContentWrapper>
        <MobileNavigationPopoverContent>
          <SectionHeader
            header={t('select_saved_address')}
            subheader={t('choose_from_saved_addresses')}
          />
          <SectionContentContainer>
            {!addresses.length && (
              <PopoverOptionContainer>
                <PopoverNoAddressContainer>
                  {t('no_saved_addresses')}
                </PopoverNoAddressContainer>
                <Button
                  onClick={e => {
                    e?.stopPropagation()
                    // navigate to add address on account page
                    handleClose()
                    handleCancelWhenNavigatingAway()
                    setRoute({
                      mainRouteName: MainRouteName.ADDRESSES,
                    })
                  }}
                  label={t('add_address')}
                  content={t('add_address')}
                  buttonType={ButtonType.TERTIARY}
                />
              </PopoverOptionContainer>
            )}
            {addresses.map(
              ({
                id,
                name,
                firstLine,
                secondLine,
                thirdLine,
                city,
                postcode,
                acceptsDelivery,
              }) => (
                <PopoverOptionContainer key={id}>
                  <UncontrolledRadioInput
                    label={
                      <>
                        <OptionLabel>
                          <OptionContentLabels>
                            <OptionContentTitle>
                              {name ? startCase(name) : ''}
                            </OptionContentTitle>
                            <OptionContentSubTitle disabled={!acceptsDelivery}>
                              {acceptsDelivery
                                ? [
                                    startCase(firstLine),
                                    startCase(secondLine || ''),
                                    startCase(thirdLine || ''),
                                    startCase(city || ''),
                                    postcode,
                                  ]
                                    .filter(Boolean)
                                    .join(', ')
                                : t('delivery_not_available', {
                                    locationType: t('address').toLowerCase(),
                                  })}
                            </OptionContentSubTitle>
                          </OptionContentLabels>
                        </OptionLabel>
                      </>
                    }
                    style={CheckboxInputStyle.TICK}
                    disabled={!acceptsDelivery}
                    checked={addressId === id}
                    onClick={e => {
                      e?.stopPropagation()
                      onChange ? onChange(id) : setAddressId(id)
                    }}
                  />
                </PopoverOptionContainer>
              )
            )}
          </SectionContentContainer>
        </MobileNavigationPopoverContent>
      </MobileNavigationPopoverContentWrapper>
      <MobileNavigationPopoverFooter>
        <MobileNavigationPopoverFooterContent>
          <MobileNavigationResetLink
            onClick={e => {
              e?.stopPropagation()
              handleClose()
            }}
          >
            {t('cancel')}
          </MobileNavigationResetLink>

          <DoneButton
            content={t('done')}
            width="180px"
            onClick={e => {
              e?.stopPropagation()
              addressId && onDone(addressId)
            }}
            type={'button'}
            disabled={!addressId}
          />
        </MobileNavigationPopoverFooterContent>
      </MobileNavigationPopoverFooter>
    </ServicePopover>
  )
}
