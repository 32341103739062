import styled from 'styled-components'

import { Button } from '@src/components/Button/Button'

export const FormContainer = styled.div`
  margin-bottom: 12px;
`

export const UpdateButton = styled(Button)`
  margin-top: 8px;
`

export const FormCopyText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px;
  color: ${({ theme }) => theme.colors.mainText};
`

export const ContentDiv = styled.div<{ textAlign?: string }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background: #f6f6f9;
  margin-top: 16px;

  :first-child {
    margin-top: 0;
  }
`

export const StyledLogoutSVG = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  margin-top: 1px;
  background: #fff;
  height: 42px;
  width: 42px;
  padding: 27px;
  border-radius: 80px;
  margin-bottom: 32px;
`

export const DeleteText = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 48px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.danger};
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.danger};
  }
`
