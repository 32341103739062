import React from 'react'
import { useTranslation } from 'react-i18next'

export const BasketSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('basket_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <path
        d="M22.9091 7.72287H17.6836L12.9055 0.4647C12.6982 0.1549 12.3491 0 12 0C11.6509 0 11.3018 0.1549 11.0945 0.475764L6.31636 7.72287H1.09091C0.490909 7.72287 0 8.22076 0 8.82929C0 8.92887 0.0109091 9.02845 0.0436363 9.12803L2.81455 19.3846C3.06545 20.314 3.90545 21 4.90909 21H19.0909C20.0945 21 20.9345 20.314 21.1964 19.3846L23.9673 9.12803L24 8.82929C24 8.22076 23.5091 7.72287 22.9091 7.72287ZM12 3.07587L15.0545 7.72287H8.94545L12 3.07587ZM19.0909 18.7871L4.92 18.7982L2.52 9.93572H21.4909L19.0909 18.7871ZM12 12.1486C10.8 12.1486 9.81818 13.1444 9.81818 14.3614C9.81818 15.5785 10.8 16.5743 12 16.5743C13.2 16.5743 14.1818 15.5785 14.1818 14.3614C14.1818 13.1444 13.2 12.1486 12 12.1486Z"
        fill="currentColor"
      />
    </svg>
  )
}
