import { useQuery } from '@apollo/client'
import React from 'react'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/LoadingSpinner'

import { Container, Title } from './BusinessHome.styles'
import { BusinessOutletMenu } from './BusinessOutletMenu'
import { GetBusinessByCnameDocument } from './queries/__generated__/GetBusinessByCname.graphql-interface'

export const BusinessHome = function (): JSX.Element {
  const { data, loading, error } = useQuery(GetBusinessByCnameDocument, {
    variables: { cname: window.location.hostname },
  })

  if (loading) return <LoadingSpinner />
  if (error) return <ErrorPage logError={error} />
  if (!data) return <ErrorPage />

  const business = data.businessByCname
  const [firstOutlet] = data.businessByCname.outlets
  if (!firstOutlet) {
    throw `No outlet available for ${business.name}`
  }

  return (
    <Container>
      <Title>{business.name}</Title>
      <BusinessOutletMenu outletId={firstOutlet.id} />
    </Container>
  )
}
