import React from 'react'

export const CaretDown: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'caret-down', id, className, desc }) => (
  <svg
    id={id}
    className={className}
    width="28"
    height="32"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" transform="rotate(-180 16 16)" />
    {desc && <desc id="myIConDesc">{desc}</desc>}
    <title id={`${id}Title`}>{title}</title>
    <path
      d="M20.59 13L16 17.3266L11.41 13L10 14.332L16 20L22 14.332L20.59 13Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
)
