import Color from 'color'
import styled, { StyledComponent, DefaultTheme, css } from 'styled-components'

import { Link } from '@src/components/Router/Link'

import { ButtonType } from './ButtonTypesEnum'

export const Spinner = styled.div`
  margin: -16px;
  display: flex;
  align-self: center;

  div {
    width: 24px;
    height: 24px;
    border: 4px solid rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

type DefaultButtonProps = {
  width?: string
  $loading?: boolean // dollar prefix prevents prop from being passed to DOM
  $rounded?: boolean
  $isDisabled?: boolean
}

const disabledStyles = css`
  opacity: 0.6;
  cursor: not-allowed;
  // overwrite global hover style for 'a' tag
  pointer-events: none;
`

const PrimaryButton = styled.button<DefaultButtonProps>`
  all: unset;
  text-align: center;
  box-sizing: border-box;
  min-height: 48px;
  width: ${({ width }) => width ?? '100%'};
  border-radius: ${({ $rounded }) => ($rounded ? '24px' : '8px')};
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  cursor: ${({ $loading }) => ($loading ? 'wait' : 'pointer')};
  transition: all 0.3s;
  white-space: nowrap;
  user-select: none;

  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brandForeground};
  border-color: ${({ theme }) => theme.colors.brand};

  // You cant extend ButtonLink with props so this is the cleanest way to share disabled styles
  &:disabled {
    ${disabledStyles}
  }

  ${({ $isDisabled }) =>
    $isDisabled
      ? disabledStyles
      : css`
          &:hover,
          &:hover:enabled {
            box-shadow: inset 0 10px 30px 5px rgba(0, 0, 0, 0.1);
            border-color: ${({ theme }) => theme.colors.brand};
          }
        `}
`

const PrimaryButtonLink = PrimaryButton.withComponent(Link)

const secondaryStyles = ({
  theme,
  $loading,
  $isDisabled,
}: DefaultButtonProps & { theme: DefaultTheme }) => {
  return css`
    background-color: #fff;
    color: ${theme.colors.brand};
    border-color: ${theme.colors.brand};

    ${$loading &&
    css`
      background-color: ${theme.colors.brand};
    `}

    ${$isDisabled
      ? disabledStyles
      : css`
          &:hover,
          &:hover:enabled {
            background-color: ${theme.colors.brand};
            color: ${theme.colors.brandForeground};
            border-color: ${theme.colors.brand};
          }
        `}
  `
}
const SecondaryButton = styled(PrimaryButton)`
  ${props => secondaryStyles(props)}
`
const SecondaryButtonLink = styled(PrimaryButtonLink)`
  ${props => secondaryStyles(props)}
`

const tertiaryStyles = ({
  $isDisabled,
}: DefaultButtonProps & { theme: DefaultTheme }) => {
  return css`
    border: 2px solid;
    height: 48px;
    font-size: 16px;
    border-color: rgba(200, 200, 200, 0.5);
    background-color: rgba(200, 200, 200, 0.3);
    color: rgb(60, 60, 60);

    ${$isDisabled
      ? disabledStyles
      : css`
          &:hover:enabled {
            border-color: rgba(200, 200, 200, 0.4);
            background-color: rgba(200, 200, 200, 0.3);
            color: rgb(60, 60, 60);
          }
        `};
  `
}
const TertiaryButton = styled(PrimaryButton)`
  ${props => tertiaryStyles(props)}
`
const TertiaryButtonLink = styled(PrimaryButtonLink)`
  ${props => tertiaryStyles(props)}
`

const minimalStyles = ({
  theme,
  $loading,
  $isDisabled,
}: DefaultButtonProps & { theme: DefaultTheme }) =>
  css`
    border: none;
    min-height: 24px;
    font-size: 14px;
    background-color: transparent;
    color: ${theme.colors.darkText};

    ${$loading &&
    css`
      background-color: ${theme.colors.brand};
    `}

    ${$isDisabled
      ? disabledStyles
      : css`
          &:hover {
            background-color: ${theme.colors.brand};
            color: ${theme.colors.brandForeground};
          }
        `}
  `

const MinimalButton = styled(PrimaryButton)`
  ${props => minimalStyles(props)}
`

const MinimalButtonLink = styled(PrimaryButtonLink)`
  ${props => minimalStyles(props)}
`

const dangerStyles = ({
  theme,
}: DefaultButtonProps & { theme: DefaultTheme }) =>
  css`
    background-color: ${theme.colors.danger};
    color: #fff;
    border-color: ${theme.colors.danger};

    &:hover:enabled {
      background-color: ${Color(theme.colors.danger).lighten(0.1).string()};
      color: #fff;
      border-color: ${theme.colors.danger};
    }
  `

const DangerButton = styled(PrimaryButton)`
  ${props => dangerStyles(props)}
`
const DangerButtonLink = styled(PrimaryButtonLink)`
  ${props => dangerStyles(props)}
`

export const buttonMap: {
  [key in ButtonType]: StyledComponent<
    'button',
    DefaultTheme,
    DefaultButtonProps,
    never
  >
} = {
  PRIMARY: PrimaryButton,
  SECONDARY: SecondaryButton,
  MINIMAL: MinimalButton,
  TERTIARY: TertiaryButton,
  DANGER: DangerButton,
}

export const buttonLinkMap: {
  [key in ButtonType]: StyledComponent<
    typeof Link,
    DefaultTheme,
    DefaultButtonProps,
    never
  >
} = {
  PRIMARY: PrimaryButtonLink,
  SECONDARY: SecondaryButtonLink,
  TERTIARY: TertiaryButtonLink,
  DANGER: DangerButtonLink,
  MINIMAL: MinimalButtonLink,
}
