import '@formatjs/intl-locale/polyfill'
import 'react-app-polyfill/ie9' // IE 9
import 'react-app-polyfill/stable' // All others
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en' // locale-data for en
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import './i18n/i18n'
import { App } from './App'

Sentry.init({
  dsn: 'https://c66d6c05af4249afa923a3da6ba6b71a@o10154.ingest.sentry.io/4505028954947584',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysOnErrorSampleRate: 0.1,
  ignoreErrors: [
    'ResizeObserver loop',
    'The email or password you have entered is incorrect',
  ],
  beforeSend(event) {
    // only send events when SEND_SENTRY_EVENTS is true
    if (import.meta.env.VITE_SEND_SENTRY_EVENTS !== 'true') {
      return null
    }
    return event
  },
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
