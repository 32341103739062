import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.main<{ width: string }>`
  display: flex;
  position: absolute;
  width: 100vw;
  min-height: 100px;
  top: 64px;
  background-color: white;
  z-index: 5;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    width: ${({ width }) => `calc(${width} - 30px)`};
    border-radius: 12px;
    right: 8px;
    top: 56px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    top: 64px;
    right: 24px;
    width: ${({ width }) => `calc(${width} - 17px)`};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    right: 24px;
    width: ${({ width }) => `calc(${width} - 20px)`};
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    right: 23px;
    width: ${({ width }) => `calc(${width} - 18px)`};
  }
  filter: drop-shadow(rgba(100, 100, 100, 0.2) 0px 1px 2px);
`

export const Underlay = styled.div`
  width: 100vw;
  height: 200vh;
  overflow: hidden;
  position: absolute;
  top: 64px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    top: 64px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    top: 80px;
    left: 0;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    top: 60px;
    width: 100%;
  }
`

export const NonIdealContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 16px;
  max-height: 80px;
`

export const OutletName = styled.p`
  margin: 0 8px;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
`

export const Cuisine = styled.p`
  margin: 0 8px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`

export const ResultsContainer = styled.div`
  display: flex;
  overflow-y: auto;
  max-height: 60vh;
  flex-direction: column;
  margin: 8px 8px;
  width: 100%;
  cursor: pointer;
  justify-content: center;
`

export const EmojiContainer = styled.div`
  font-size: 28px;
  text-align: center;
  height: 38px;
  width: 38px;
`

export const ImageContainer = styled.div<{ imageUrl: string }>`
  min-height: 38px;
  width: 38px;
  background-size: contain;
  background-repeat: no-repeat;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};
`

export const SingleResultContainer = styled.div<{
  firstChild: boolean
  hasImage?: boolean
}>`
  ${({ firstChild }) =>
    // nb: cannot do this with &:firstChild
    !firstChild &&
    css`
      border-top: 1px dotted #e6e6e6;
      &:hover {
        border-top: 1px dotted #ffffff;
      }
    `};

  padding: ${({ hasImage }) => (hasImage ? '0px 16px 0px 16px' : '0px 16px')};
  display: flex;
  align-items: center;
  min-height: 64px;

  cursor: pointer;

  &:hover {
    background-color: #f6f6f9;
    border-radius: 8px;
  }
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
}>`
  height: 38px;
  width: 38px;
  margin-right: 0;
  border-radius: 4px;
  background-size: cover;
  background-position: 50%;
  z-index: 1;
  background-image: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`};
`
