import React from 'react'

export const TwitterXSVG: React.FC<{
  width: number
  height: number
  className?: string
}> = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.32162 2.87988L10.1457 12.8286L2.98975 21.1199H4.51506L10.8216 13.813L15.8335 21.1199H20.6401L13.4991 10.7099L20.2576 2.87988H18.7332L12.8241 9.72551L8.12818 2.87988H3.32162Z"
        fill="#141414"
      />
    </svg>
  )
}
