import styled from 'styled-components'

import { Button } from '@src/components/Button/Button'
import { Link } from '@src/components/Router/Link'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{ isCheckout?: boolean }>`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-content: center;
  padding: 0px 32px 52px 32px;
`

export const InnerForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.h1`
  font-size: 32px;
  font-weight: 700;

  text-align: left;
  color: ${({ theme }) => theme.colors.mainText};
  margin: 16px 0;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SubmitButton = styled(Button)`
  margin: 16px 0px;
  font-size: 18px;

  @media (min-width: ${breakpoints.desktop}px) {
    height: 56px;
  }
`

const CheckboxLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.mainText};

  @media (min-width: 1280px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
`

export const SignInLink = styled.span`
  text-decoration-line: underline;
  text-underline-position: under;
  font-size: 18px;
  font-weight: 700;
`

export const CreateAccountButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mainText};
  border: none;
  background-color: #fff;
  cursor: pointer;
  margin: 16px 0px;
  padding: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

export const TermsCheckboxContainer = styled(CheckboxLabel)`
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
`

export const TermsText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

export const LinkText = styled(Link)`
  margin: 0px;
  color: ${({ theme }) => theme.colors.mainText};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const TopRow = styled.div<{ isCheckout?: boolean }>`
  text-align: left;
  margin-top: ${props => (props.isCheckout ? '0' : '32px')};
  margin-bottom: ${props => (props.isCheckout ? '0' : '28px')};
`

export const JoinNowLink = styled(Link)`
  all: unset;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ theme }) => theme.colors.mainText};
  cursor: pointer;
`

export const InputTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #2e3333;
  margin: 18px 0px 12px 0px;
`
