import styled from 'styled-components'

import { Link } from '@src/components/Router/Link'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.form`
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  justify-items: space-evenly;
`

export const DiscountContainer = styled.form`
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const AcceptedContainer = styled.div`
  padding: 32px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`

export const ErrorContainer = styled.div`
  padding: 32px;
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.h1`
  font-size: 32px;
  margin-top: 32px;
  text-align: center;
`

export const CentredHeader = styled.h1`
  font-size: 24px;
  margin: 24px 0px;
  text-align: center;
`

export const CentredErrorHeader = styled.h1`
  font-size: 24px;
  margin: 48px 0px;
  text-align: center;
`

export const HeaderSmall = styled.h1`
  font-size: 24px;
  margin: 32px 0px 24px;
`

export const InnerContainer = styled.div`
  text-align: center;
`
export const InnerDiscountContainer = styled.div`
  text-align: center;
  margin: 16px 0px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.phablet}px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
  }
`

export const CreateAccountButton = styled.button`
  all: unset;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.mainText};
  cursor: pointer;
`

export const CreateAccountSpan = styled.span`
  text-decoration-line: underline;
  text-underline-position: under;
  font-size: 16px;
  font-weight: 700;
`

export const ErrorText = styled.p`
  border-radius: 8px;
  padding: 16px;
  background-color: #ffe7ec;
  color: #e1292f;
  margin-bottom: 16px;
  text-align: center;
`

export const ForgotPasswordButton = styled(Link)`
  all: unset;
  text-decoration-line: underline;
  border: none;

  background: none;
  color: ${({ theme }) => theme.colors.mainText};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  text-underline-position: under;
`

export const Span = styled.span`
  font-size: 16px;
`
