import React from 'react'
import { useTranslation } from 'react-i18next'

export const CaretDownSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  color?: string
}> = ({ title, id, className, desc, color = 'currentColor' }) => {
  const { t } = useTranslation(['svgs'])
  return (
    <svg
      id={id}
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('caret_down_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <path
        d="M20.59 13L16 17.3266L11.41 13L10 14.332L16 20L22 14.332L20.59 13Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  )
}
