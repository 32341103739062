export const menuItem = {
  confirm: 'confirm',
  new_order_confirm: 'Start New Order',
  new_order_title: 'Start a new order?',
  new_order_description:
    'Empty your basket and start a new order with this outlet.',
  cancel: 'Cancel',
  add_to_basket: 'Add To Basket',
  special_instructions: 'Special Instructions',
  add_note: 'Add a special instruction to your order...',
  form: {
    option_item_ids: {
      minimum_options_error: `{{option_name}} requires a minimum of {{minimum_options}} item selected`,
      maximum_options_error: `Too many options selected allows a maximum of {{maximum_options}} item selected`,
    },
  },
  sold_out: 'Sold Out',
  select: 'Select',
  remove_item: 'Remove Item',
  add_item: 'Add Item',
  menu_item_error: 'Sorry, something went wrong.',
  menu_item_not_found: 'Sorry, this item could not be found.',
  required: 'Required',
  exact_amount_text: 'Choose {{amount}} options',
  exact_amount_1_text: 'Choose one option',
  min_max_text: 'Choose {{min}} - {{max}} options',
  min_1_text: 'At least one option required',
  min_text: 'Min {{min}} options',
  max_1_text: 'Max one option',
  max_text: 'Max {{max}} options',
  not_available_for:
    'Sorry, This item is not available for {{fulfilmentMethodName}}, please make another selection or change fulfilment type.',
  delivery: 'delivery',
  collection: 'collection',
  table: 'table service',
  change_fulfilment_to: 'Change Fulfilment To {{fulfilmentMethod}}',
  item_not_available: 'This item is not available',
  unavailable: 'Unavailable',
}
