import React, { useCallback } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'

import { AllergenTags } from '@src/components/AllergenTags/AllergenTags'
import { StyledCheckoutButton } from '@src/components/CheckoutModal/CheckoutButton.styles'
import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import { ItemCounter } from '@src/components/ItemCounter/ItemCounter'
import { Allergen } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { BasketItem } from '@src/hooks/useBasketItems/validation'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  Container,
  Description,
  DetailsContainer,
  ImageContainer,
  ItemImage,
  Name,
  Price,
  PriceContainer,
  SingleItemContainer,
  SpaceBetweenRow,
  StrikethroughPrice,
} from './AddOnItems.styles'
import { addOnMenuItemsQuery } from './queries/__generated__/addOnMenuItems.graphql-interface'

export const AddOnItems: React.FC<{
  restaurantName: string
  addOnItems: addOnMenuItemsQuery['addOnMenuItems']
}> = ({ restaurantName, addOnItems }) => {
  const { addItems } = useBasketItems()
  const marketplace = useMarketplace()
  const { t } = useTranslation('addOnItems')
  const formatCurrency = useFormatCurrency()

  const [selectedAddOnItems, setSelectedAddOnItems] = React.useState<
    Omit<BasketItem, 'id' | 'availability'>[]
  >([])

  const { override } = useCheckoutRouter()

  const selectAddOnItem = useCallback(
    (item: Omit<BasketItem, 'id' | 'availability'>) => {
      const index = selectedAddOnItems.findIndex(
        ({ menuItemId }) => menuItemId === item.menuItemId
      )

      if (index === -1) {
        setSelectedAddOnItems([...selectedAddOnItems, item])
      } else {
        const currentItem = selectedAddOnItems[index] as BasketItem
        const newItem = {
          ...currentItem,
          quantity: (selectedAddOnItems[index]?.quantity || 1) + 1,
        }

        setSelectedAddOnItems([
          ...selectedAddOnItems.filter(
            ({ menuItemId }) => menuItemId !== item.menuItemId
          ),
          newItem,
        ])
      }
    },
    [selectedAddOnItems]
  )

  const removeItem = useCallback(
    (item: Pick<BasketItem, 'menuItemId'>) => {
      const index = selectedAddOnItems.findIndex(
        ({ menuItemId }) => menuItemId === item.menuItemId
      )

      if (index === -1) {
        return
      }

      const currentItem = selectedAddOnItems[index] as BasketItem

      if (currentItem.quantity === 1) {
        setSelectedAddOnItems(
          selectedAddOnItems.filter(
            ({ menuItemId }) => menuItemId !== item.menuItemId
          )
        )
      } else {
        const newItem = {
          ...currentItem,
          quantity: currentItem.quantity - 1,
        }

        setSelectedAddOnItems([
          ...selectedAddOnItems.filter(
            ({ menuItemId }) => menuItemId !== item.menuItemId
          ),
          newItem,
        ])
      }
    },
    [selectedAddOnItems]
  )

  return (
    <>
      <Container windowHeight={window.innerHeight}>
        <Description>{t('offers_desc', { restaurantName })}</Description>
        {addOnItems.map(
          ({
            name,
            id: outletMenuItemId,
            menuItemId,
            price,
            addOnMaxPrice,
            isDairyFree,
            isGlutenFree,
            isVegan,
            isVegetarian,
            ageRestricted,
            image,
          }) => {
            const presentAllergens = () => {
              const allergensArr = []
              if (isDairyFree) allergensArr.push(Allergen.DAIRY_FREE)
              if (isGlutenFree) allergensArr.push(Allergen.GLUTEN_FREE)
              if (isVegan) allergensArr.push(Allergen.VEGAN)
              if (isVegetarian) allergensArr.push(Allergen.VEGETARIAN)
              return allergensArr
            }

            const itemImage = imageJitURL(image, {
              resize: {
                width: 88,
                height: 88,
                fit: 'cover',
              },
            })

            const quantityOfSelectedItem =
              selectedAddOnItems.find(item => item.menuItemId === menuItemId)
                ?.quantity || 0

            return (
              <SingleItemContainer key={outletMenuItemId}>
                <ImageContainer>
                  {!!itemImage && (
                    <ItemImage
                      role="img"
                      aria-label={name}
                      imageURL={itemImage}
                    />
                  )}

                  <DetailsContainer>
                    <Name>{name}</Name>
                    <AllergenTags
                      allergens={presentAllergens()}
                      iconView
                      ageRestricted={!!ageRestricted}
                    />
                  </DetailsContainer>
                </ImageContainer>
                <SpaceBetweenRow>
                  <PriceContainer>
                    <Price>{formatCurrency(price)}</Price>
                    {!!addOnMaxPrice && price !== addOnMaxPrice && (
                      <StrikethroughPrice>
                        {formatCurrency(addOnMaxPrice)}
                      </StrikethroughPrice>
                    )}
                  </PriceContainer>
                  <ItemCounter
                    price={price}
                    count={quantityOfSelectedItem}
                    allowZeroCount
                    handleAdd={() =>
                      selectAddOnItem({
                        menuItemId,
                        price,
                        quantity: 1,
                        outletMenuItemId,
                        name,
                        optionItemIds: [],
                        singleItemNotes: '',
                      })
                    }
                    handleRemove={() => {
                      removeItem({
                        menuItemId,
                      })
                    }}
                  />
                </SpaceBetweenRow>
              </SingleItemContainer>
            )
          }
        )}
      </Container>
      <CheckoutButtonWrapperMobile>
        <StyledCheckoutButton
          content={selectedAddOnItems.length ? t('continue') : t('no_thanks')}
          onClick={() => {
            if (selectedAddOnItems.length) {
              addItems({
                items: selectedAddOnItems,
              })
              ReactPixel.track('AddToCart', {
                content_ids: selectedAddOnItems.map(item => item.menuItemId),
                content_type: 'product',
                content_name: 'AddOnItems',
                value: selectedAddOnItems.reduce(
                  (acc, { price = 0, quantity }) => acc + price * quantity,
                  0
                ),
                currency: marketplace.country.currency.iso4217,
              })
            }

            void override(CheckoutRoute.PAYMENT)
          }}
        />
      </CheckoutButtonWrapperMobile>
    </>
  )
}
