import { useReactiveVar } from '@apollo/client'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  Container,
  OutletDetailsContainer,
  OutletName,
  OutletOpeningContainer,
  OpenStatusContainer,
  OutletStatus,
  LongSquareButton,
} from './OutletSlideSkeleton.styles'

import 'react-loading-skeleton/dist/skeleton.css'

export const OutletSlideSkeleton: React.VFC = () => {
  const { width } = useReactiveVar(screenResolutionVar)
  return (
    <Container>
      <div>
        <Skeleton
          circle={false}
          height={width > breakpoints.tablet ? 160 : 120}
        />
      </div>
      <OutletDetailsContainer>
        <OutletName>
          <Skeleton count={2} />
        </OutletName>
        <OutletOpeningContainer>
          <OpenStatusContainer>
            <OutletStatus>
              <Skeleton />
            </OutletStatus>
          </OpenStatusContainer>
          <LongSquareButton>
            <Skeleton height={25} />
          </LongSquareButton>
        </OutletOpeningContainer>
      </OutletDetailsContainer>
    </Container>
  )
}
