import React from 'react'

import { SelectInput } from '@src/components/Inputs/SelectInput/SelectInput'
import { Table } from '@src/graphql-types'

import { Container } from './TableSelect.styles'

export const TableSelect: React.FC<{
  tables: Pick<Table, 'id' | 'friendlyName'>[]
  selectedTableId?: string
  setSelectedTableId: (tableId: string) => void
}> = ({ tables, selectedTableId, setSelectedTableId }) => {
  const tableOptions = tables.map(table => ({
    value: table.id,
    name: table.friendlyName,
  }))

  return (
    <>
      <Container>
        <SelectInput
          label={''}
          name={'tableId'}
          options={tableOptions}
          required
          onChange={table => {
            setSelectedTableId(table.value)
          }}
          selectedOptionValue={selectedTableId}
        />
      </Container>
    </>
  )
}
