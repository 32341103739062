import { format, startOfDay, isEqual, isToday, isTomorrow } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import {
  OptionContentLabels,
  OptionContentSubTitle,
  OptionContentTitle,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'

export const DaysPicker: React.FC<{
  preOrderDays: Date[]
  setSelectedDate: (dateSelected: Date) => void
  selectedDate: Date | null
}> = ({ preOrderDays, setSelectedDate, selectedDate }) => {
  const { t } = useTranslation('serviceNavigation')

  const calendarDayOfWeek = (date: Date) => {
    if (isToday(date)) {
      return t('today')
    } else if (isTomorrow(date)) {
      return t('tomorrow')
    } else {
      return format(date, 'EEEE')
    }
  }

  return (
    <>
      {preOrderDays.map(preOrderDate => {
        return (
          <PopoverOptionContainer key={preOrderDate.toISOString()}>
            <UncontrolledRadioInput
              label={
                <>
                  <OptionLabel>
                    <OptionContentLabels>
                      <OptionContentTitle>
                        {calendarDayOfWeek(preOrderDate)}
                      </OptionContentTitle>
                      <OptionContentSubTitle>
                        {format(preOrderDate, 'd MMMM')}
                      </OptionContentSubTitle>
                    </OptionContentLabels>
                  </OptionLabel>
                </>
              }
              style={CheckboxInputStyle.TICK}
              checked={Boolean(
                selectedDate &&
                  isEqual(startOfDay(preOrderDate), startOfDay(selectedDate))
              )}
              onClick={e => {
                e.stopPropagation()
                setSelectedDate(preOrderDate)
              }}
            />
          </PopoverOptionContainer>
        )
      })}
    </>
  )
}
