import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SupportForm } from '@src/components/BurgerMenu/Policies/SupportForm/SupportForm'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import {
  HelpChildName,
  MainRouteName,
  useAccountRouter,
} from '@src/hooks/useAccountRouter'

import {
  Container,
  FAQAnswer,
  FAQContainer,
  FAQHeader,
  FAQsContainer,
  InnerContainer,
  SubTitle,
  TabContainer,
  TabLink,
} from './HelpAndSupportStyling'
import { getFAQsDocument } from './queries/__generated__/getFAQs.graphql-interface'

import { BurgerSection } from '../../Drawer/DrawerHeader'

const Content: React.FC = () => {
  const { t } = useTranslation('helpAndSupport')
  const { route } = useAccountRouter()

  const { data, loading, error } = useQuery(getFAQsDocument, {
    variables: { cname: window.location.hostname },
  })

  if (loading) return <LoadingSpinner />
  if (error) return <ErrorPage errorMessage={error.message} />
  if (!data) return <ErrorPage />
  const FAQS = data.marketplaceByCname.faqs
  return (
    <Container>
      <TabContainer>
        <TabLink
          route={{
            mainRouteName: MainRouteName.HELP,
            childRouteName: HelpChildName.SUPPORT,
          }}
        >
          {t('help')}
        </TabLink>
        {data.marketplaceByCname.featureFAQ && (
          <TabLink
            route={{
              mainRouteName: MainRouteName.HELP,
              childRouteName: HelpChildName.FAQS,
            }}
          >
            {t('faqs')}
          </TabLink>
        )}
      </TabContainer>
      <InnerContainer>
        {route?.mainRouteName === MainRouteName.HELP &&
          route?.childRouteName === HelpChildName.FAQS && (
            <>
              <SubTitle>{t('faqs')}</SubTitle>
              <FAQsContainer>
                {FAQS.map(({ id, answer, question }) => (
                  <FAQContainer key={id}>
                    <FAQHeader>{question}</FAQHeader>
                    <FAQAnswer>{answer}</FAQAnswer>
                  </FAQContainer>
                ))}
              </FAQsContainer>
            </>
          )}
        {route?.mainRouteName === MainRouteName.HELP &&
          route?.childRouteName === HelpChildName.SUPPORT && (
            <>
              <SupportForm marketplaceId={data.marketplaceByCname.id} />
            </>
          )}
      </InnerContainer>
    </Container>
  )
}

export const HelpAndSupport: React.FC = () => {
  const { t } = useTranslation('helpAndSupport')
  return (
    <BurgerSection
      whiteBackgroundColor={true}
      header={{ title: t('help_and_support') }}
      noPadding={true}
      twoLineHeader={false}
    >
      <Content />
    </BurgerSection>
  )
}
