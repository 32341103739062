import { FormikErrors } from 'formik'
import React from 'react'

import { ErrorContainer, ErrorText } from './MenuItemModal.styles'
import { FormValues } from './types'

export const AddMenuItemFormError: React.FC<{
  extraDanger: boolean
  errors: FormikErrors<FormValues>
}> = ({ extraDanger, errors }) => {
  const getFirstErrorText = (errors: Record<string, string | string[]>) => {
    const firstError = Object.keys(errors)[0]
    if (firstError) {
      const erroredOptionEl = document.getElementById(firstError)
      if (erroredOptionEl) {
        erroredOptionEl.scrollIntoView({ behavior: 'smooth' })
      }
      return errors[firstError]
    }
  }

  return (
    <ErrorContainer extraDanger={extraDanger}>
      <ErrorText>{getFirstErrorText(errors)}</ErrorText>
    </ErrorContainer>
  )
}
