import React from 'react'
import { useTranslation } from 'react-i18next'

export const LogoutSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation('svgs')

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('logout')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <path
        d="M3.33333 40C2.44444 40 1.66667 39.6667 1 39C0.333333 38.3333 0 37.5556 0 36.6667V3.33333C0 2.44444 0.333333 1.66667 1 1C1.66667 0.333333 2.44444 0 3.33333 0H19.5V3.33333H3.33333V36.6667H19.5V40H3.33333ZM30.3333 29.7222L27.9444 27.3333L33.6111 21.6667H14.1667V18.3333H33.5L27.8333 12.6667L30.2222 10.2778L40 20.0556L30.3333 29.7222Z"
        fill="black"
      />
    </svg>
  )
}
