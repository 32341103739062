import styled, { css } from 'styled-components'

import { ErrorText } from '@src/components/AcceptDiscountModal/AcceptDiscount.styles'
import {
  Label,
  RadioButton,
} from '@src/components/CheckoutModal/FormElements.styles'
import { breakpoints } from '@src/constants/breakpoints'

export const AddressRadioButtonContainer = styled.div<{
  isAddAddress?: boolean
}>`
  padding: 16px;
  display: flex;
  border-radius: 8px;
  flex-direction: row;

  ${({ isAddAddress }) => css`
    flex-wrap: ${isAddAddress && 'wrap'};
  `}

  &:has(${RadioButton}:checked) {
    background-color: #f6f6f9;
  }

  &:has(${RadioButton}:disabled) {
    opacity: 0.6;
    ${Label} {
      cursor: not-allowed;
    }
  }

  &:has(${ErrorText}) {
    background-color: #ffe7ec;
  }
`

export const AddressRadioButtonAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${Label} {
    flex-grow: 1;
  }
  ${RadioButton} {
    flex-grow: 0;
  }
`

export const InnerScrollContainer = styled.div`
  border: none;
  margin: 0 16px;
  padding: 0;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    margin: 0 32px;
  }
`

export const AddAddressContainer = styled.div`
  margin: 16px;
  width: 100%;
`

export const DeliveryNoteContainer = styled.div`
  margin-top: 24px;
`
