import { baseUrlImages } from '@src/config/urls'
import { s3Bucket } from '@src/constants/s3Bucket'

// imageJitURL is leveraging the work done by Os to setup a serverless image handler on the redbox stack.
// https://bitbucket.org/korelogic-developers/redbox-services/src/master/serverless/image-handler/image-handler/
// This work leverages sharp the imageConfig is passed to sharp behind the scenes.
// https://sharp.pixelplumbing.com
// If you need to extend the image config type make sure to use the sharp documentation.
// Please document stuff here thats not obvious to save time looking around docs in the future.

type ImageConfig = {
  resize?: {
    width?: number
    height?: number
    fit?:
      | 'cover' // (default) Preserving aspect ratio, ensure the image covers both provided dimensions by cropping/clipping to fit.
      | 'contain' // Preserving aspect ratio, contain within both provided dimensions using "letterboxing" where necessary.
      | 'fill' // Ignore the aspect ratio of the input and stretch to both provided dimensions.
      | 'inside' // Preserving aspect ratio, resize the image to be as large as possible while ensuring its dimensions are less than or equal to both those specified.
      | 'outside' // Preserving aspect ratio, resize the image to be as small as possible while ensuring its dimensions are greater than or equal to both those specified.
  }
}

export const imageJitURL = (
  key: string | null | undefined,
  imageConfig: ImageConfig
): string => {
  if (key) {
    const url =
      baseUrlImages +
      '/img/' +
      window.btoa(
        JSON.stringify({
          bucket: s3Bucket,
          key: key,
          edits: imageConfig,
        })
      )

    return url
  }

  return ''
}
