import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{ shake: boolean }>`
  margin-top: 16px;
  ${({ shake }) =>
    shake &&
    css`
      animation: shake-effect 0.8s, highlight-red 3s;
    `}

  @media (min-width: ${breakpoints.tabletMisc}px) {
    margin-top: 24px;
    margin-right: 16px;
  }

  @keyframes highlight-red {
    0% {
      color: red;
    }
  }

  @keyframes shake-effect {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(4px);
    }
    30% {
      transform: translateX(-4px);
    }
    50% {
      transform: translateX(4px);
    }
    70% {
      transform: translateX(-4px);
    }
    90% {
      transform: translateX(4px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`
