import { Listbox } from '@headlessui/react'
import styled, { css } from 'styled-components'

import { CaretDownSVG } from '@src/components/SVGS/CarretDownSVG'

export const StyledListBoxButton = styled(Listbox.Button)<{
  $errorBorder: boolean
  open: boolean
  children: React.ReactNode
}>`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  border: 2px solid;
  border-color: ${props => {
    if (props.$errorBorder) {
      return '#E1292F'
    } else if (props.open) {
      return '#000'
    } else {
      return '#ebecf0'
    }
  }};
  border-radius: ${props => (props.open ? '8px 8px 0px 0px' : '8px')};
  outline: 0;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 8px;

  :focus-within {
    border-color: #000;
  }
`

export const StyledCaretDownSVG = styled(CaretDownSVG)<{ open: boolean }>`
  height: 100%;
  position: absolute;
  right: 13px;
  transition: transform 0.2s ease;

  ${props =>
    props.open &&
    css`
      transform: scaleY(-1);
    `}
`

export const StyledListBoxLabel = styled(Listbox.Label)<{
  $isPlaceHolder: boolean
}>`
  position: absolute;
  top: 9px;
  left: 16px;
  font-weight: 500;
  font-size: 12px;
  color: #ababab;

  transition: 0.2s ease;
  transition-property: top;

  ${props =>
    props.$isPlaceHolder &&
    css`
      top: 18px;
      font-size: 16px;
    `}
`

export const Required = styled.span`
  margin-left: 2px;
  color: #000000;
`

export const Value = styled.div<{ visible: boolean; hasLabel: boolean }>`
  position: absolute;
  left: 16px;
  right: 18px;
  border: 0;
  outline: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  background: none;

  transition: 0.2s ease;
  transition-property: top, opacity;

  ${props =>
    props.visible
      ? css`
          top: ${props.hasLabel ? '26px' : '17px'};
          opacity: 1;
        `
      : css`
          top: 18px;
          opacity: 0;
        `}
`

export const StyledListBoxOptions = styled(Listbox.Options)`
  padding: 0;
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  border-top: 0;
  border-radius: 0px 0px 8px 8px;
  list-style: none;
  margin: 0px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 20;
  box-shadow: 0 8px 6px -3px rgba(0, 0, 0, 0.1);
  margin-top: -10px;
`

export const StyledListBoxOption = styled(Listbox.Option)`
  padding: 14px 16px;
  cursor: pointer;
  z-index: 20;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`
