import React from 'react'
import { useTranslation } from 'react-i18next'

import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import {
  useCheckoutRouter,
  CheckoutRoute,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

import { AlertModal } from '../Modal/AlertModal'

// alert which displays when a customer has add-on items in their basket
// but is not on the checkout
export const AddOnItemsNonCheckoutAlert: React.FC = () => {
  const { t } = useTranslation('checkout')
  const basketItems = useBasketItems()
  const checkoutRouter = useCheckoutRouter()

  const hasAddOnItems = basketItems.addOnItems.length > 0
  const isNotOnCheckout = [
    undefined,
    CheckoutRoute.BASKET,
    CheckoutRoute.FULFILMENT,
  ].includes(checkoutRouter.route)

  const removeAddOnItems = () => {
    basketItems.addOnItems.forEach(item => {
      basketItems.removeItem({ basketItemId: item.id })
    })
  }

  return (
    <AlertModal
      isOpen={hasAddOnItems && isNotOnCheckout}
      title={t('basket_contains_offers')}
      subTitle={t('continuing_will_remove_offers')}
      action={{
        text: t('back_to_checkout'),
        onClick: () => {
          checkoutRouter.override(CheckoutRoute.PAYMENT)
        },
      }}
      cancel={{
        text: t('continue_and_remove'),
        onClick: removeAddOnItems,
      }}
    />
  )
}
