import styled from 'styled-components'

export const FormError = styled.div`
  border-radius: 8px;
  padding: 16px;
  background-color: #ffe7ec;
  color: #e1292f;
  margin-bottom: 16px;
  text-align: center;
`
