import React from 'react'

export const LocationPinSVG = ({ id }: { id: string }) => (
  <svg
    id={id}
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={`${id}Title`} />
    <path
      d="M9.98492 11.8114C10.6083 11.8114 11.1463 11.5985 11.5988 11.1729C12.0513 10.7473 12.2775 10.2346 12.2775 9.63482C12.2775 9.03507 12.0513 8.51753 11.5988 8.08223C11.1463 7.64692 10.6083 7.42926 9.98492 7.42926C9.36149 7.42926 8.82856 7.64692 8.38612 8.08223C7.94369 8.51753 7.72247 9.03507 7.72247 9.63482C7.72247 10.2346 7.94369 10.7473 8.38612 11.1729C8.82856 11.5985 9.36149 11.8114 9.98492 11.8114ZM9.98492 20.8077C12.539 18.5635 14.4294 16.532 15.6561 14.7134C16.8829 12.8948 17.4962 11.2793 17.4962 9.86699C17.4962 7.68077 16.7722 5.89117 15.3243 4.49819C13.8763 3.1052 12.0965 2.40871 9.98492 2.40871C7.8733 2.40871 6.09854 3.1052 4.66063 4.49819C3.22273 5.89117 2.50377 7.68077 2.50377 9.86699C2.50377 11.2793 3.1272 12.89 4.37406 14.6989C5.62092 16.5079 7.4912 18.5441 9.98492 20.8077ZM9.98492 24C6.64656 21.2527 4.14781 18.7086 2.48869 16.3676C0.829563 14.0266 0 11.8597 0 9.86699C0 6.84885 1.0005 4.44982 3.00151 2.66989C5.00251 0.889964 7.33032 0 9.98492 0C12.6194 0 14.9472 0.8948 16.9683 2.6844C18.9894 4.474 20 6.8682 20 9.86699C20 11.8597 19.1654 14.0266 17.4962 16.3676C15.827 18.7086 13.3233 21.2527 9.98492 24Z"
      fill="currentColor"
    />
  </svg>
)
