import styled from 'styled-components'

export const Container = styled.fieldset`
  all: unset;
  margin: 0px 0px 0px 0px;
`

export const ScrollToContainer = styled.div`
  width: 20px;
  height: 20px;
  background-color: transparent;
  position: relative;
  top: -50px;
`

export const Legend = styled.h3`
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
`

export const MinMaxLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

export const RequiredContainer = styled(MinMaxLegendContainer)`
  float: right;
`

export const OptionRow = styled.div<{ disabled: boolean; error: boolean }>`
  border-bottom: 1px solid #e6e6e6;
  color: ${({ error }) => (error ? '#cf3d39' : 'unset')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px !important;
  padding: 8px 0;
`

export const OptionPrice = styled.p`
  margin: 0 16px 0 auto;
  font-size: 13px;
  text-align: right;
  width: 25%;
`
export const SoldOut = styled(OptionPrice)`
  color: #cf3d39;
`

export const RequiredText = styled.p<{ error: boolean }>`
  font-weight: 400;
  font-size: 13px;
  margin: 8px 0;
  color: ${({ error }) => (error ? '#cf3d39' : 'unset')};
`

export const RequiredIcon = styled.img`
  width: 10px;
  padding-top: 2px;
  padding-right: 4px;
`
