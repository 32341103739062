import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Helmet } from '@src/components/Helmet'
import { Markdown } from '@src/components/Markdown/Markdown'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { formatOpeningTimes } from '@src/utils/formatOpeningTimes/formatOpeningTimes'
import { FULFILMENT_INPUT_METHOD_TO_FRIENDLY_NAME } from '@src/utils/fulfilment'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  Content,
  ContentContainer,
  Header,
  HeaderContainer,
  ListItem,
  Logo,
  Section,
  SectionHeader,
  ShadedSection,
  StyledLink,
  Table,
  TableData,
  TableHeaderRow,
  TableRow,
} from './OutletDetailsStyles'

export const OutletDetails: React.FC = () => {
  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { t } = useTranslation('outletDetails')

  const formatCurrency = useFormatCurrency()
  const {
    prioritisedFulfilmentMethods,
    collectionMinimumOrderValue,
    deliveryMinimumOrderValue,
    outletAddress,
    hygieneRatingId,
    enableHygieneRating,
  } = outlet
  const priorityFulfilmentMethod = prioritisedFulfilmentMethods[0]

  const scriptOutputDiv = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!enableHygieneRating || !hygieneRatingId || !scriptOutputDiv.current) {
      return
    }

    const script = document.createElement('script')

    script.src = 'https://ratings.food.gov.uk/embed/embed-badge.js'
    script.async = true
    script.setAttribute('data-business-id', hygieneRatingId.toString())
    script.setAttribute('data-rating-style', '1')
    script.setAttribute('data-welsh', 'false')

    const current = scriptOutputDiv.current
    current.appendChild(script)

    // MutationObserver to observe changes in the scriptOutputDiv
    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type !== 'childList') continue

        // Process added nodes
        for (const node of mutation.addedNodes) {
          // Check if the node is an HTMLAnchorElement and set target to _blank
          if (node instanceof HTMLAnchorElement) {
            node.target = '_blank'
          }

          // Check for <img> elements in the added nodes and apply styles
          if (node instanceof HTMLElement) {
            const img = node.querySelector('img')
            if (img) {
              img.style.minWidth = '100px'
              img.style.maxWidth = '500px'
            }
          }
        }
      }
    })

    // Start observing the scriptOutputDiv for child additions
    observer.observe(current, { childList: true, subtree: true })

    return () => {
      current.removeChild(script)
      observer.disconnect() // Stop observing when component unmounts
    }
  }, [enableHygieneRating, hygieneRatingId])

  const fulfilmentCostTitles = prioritisedFulfilmentMethods
    .filter(method => method !== NarrowFulfilmentMethodInputType.TABLE)
    .map(method => FULFILMENT_INPUT_METHOD_TO_FRIENDLY_NAME[method])

  const formattedOpeningTimes = formatOpeningTimes(outlet.openingTimesArray)

  const { restaurant, displayName, outletLogoOverride } = outlet

  return (
    <>
      <Helmet title={`${displayName} Details`} />
      <HeaderContainer>
        <Header>{displayName}</Header>
        <Logo
          role="img"
          aria-label={`${displayName} logo`}
          imageUrl={imageJitURL(outletLogoOverride || restaurant.image, {
            resize: {
              width: 64,
              height: 64,
              fit: 'cover',
            },
          })}
        />
      </HeaderContainer>
      <main>
        <Section>
          <SectionHeader>{t('profile')}</SectionHeader>
          <ContentContainer>
            <Content>
              {restaurant.description && (
                <Markdown markdown={restaurant.description} />
              )}
            </Content>
          </ContentContainer>
          <ContentContainer>
            <Content>
              {outletAddress.firstLine}
              <br />
              {outletAddress?.secondLine}
              {outletAddress.secondLine && <br />}
              {outletAddress?.thirdLine}
              {outletAddress.thirdLine && <br />}
              {outletAddress.city}
              <br />
              {outletAddress.postcode}
            </Content>
          </ContentContainer>
        </Section>

        <Section>
          <SectionHeader>{fulfilmentCostTitles.join(' / ')}</SectionHeader>
          <ContentContainer>
            {priorityFulfilmentMethod ===
              NarrowFulfilmentMethodInputType.DELIVERY && (
              <ListItem>
                <Content>{t('delivery_min')}</Content>
                <Content>
                  <strong>
                    {formatCurrency(deliveryMinimumOrderValue || 0)}
                  </strong>
                </Content>
              </ListItem>
            )}
            {priorityFulfilmentMethod ===
              NarrowFulfilmentMethodInputType.COLLECTION && (
              <ListItem>
                <Content>{t('collection_min')}</Content>
                <Content>
                  <strong>
                    {formatCurrency(collectionMinimumOrderValue || 0)}
                  </strong>
                </Content>
              </ListItem>
            )}
          </ContentContainer>
        </Section>

        <ShadedSection>
          <SectionHeader>{t('opening_times')}</SectionHeader>
          <Table>
            {/* Non-visible headers for accessibility  */}
            <TableHeaderRow>
              <th>{t('day')}</th>
              <th>{t('opening_times')}</th>
            </TableHeaderRow>
            {formattedOpeningTimes.map(({ day, times }) => (
              <TableRow key={day}>
                <TableData>{day}</TableData>
                <TableData>
                  {times?.map(time => (
                    <p key={time}>{time}</p>
                  ))}
                </TableData>
              </TableRow>
            ))}
          </Table>
        </ShadedSection>

        {enableHygieneRating && hygieneRatingId && (
          <Section>
            <SectionHeader>{t('hygiene_rating')}</SectionHeader>
            <ContentContainer>
              <Content>
                <div ref={scriptOutputDiv}></div>
                {t('hygiene_desc')}{' '}
                <StyledLink
                  target="_blank"
                  href={`
                  https://ratings.food.gov.uk/business/${hygieneRatingId}
                  `}
                  rel="noreferrer"
                  aria-label="opens hygiene ratings in new window"
                >
                  {t('view_hygiene_rating')}
                </StyledLink>
              </Content>
            </ContentContainer>
          </Section>
        )}
      </main>
    </>
  )
}
