import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'

import { baseUrlImages } from '@src/config/urls'

import { useMarketplace } from '../hooks/useMarketplace'

interface HelmetProps {
  title?: string | null
  description?: string | null
  keywords?: string | null
  faviconImage?: string | null
  storeURLApple?: string | null
}

export const Helmet: React.FC<HelmetProps> = ({
  title,
  description,
  keywords,
  faviconImage,
  storeURLApple,
}) => {
  const { id } = useMarketplace()
  // Extract the Apple App ID from the URL
  const appleAppID = storeURLApple?.split('id')[1] || null
  const faviconUrl = faviconImage && `${baseUrlImages}/${faviconImage}`

  return (
    <ReactHelmet>
      <link rel="manifest" href={`/api/marketplace/${id}/manifest.json`} />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {faviconUrl && (
        <link rel="shortcut icon" href={faviconUrl} type="image/x-icon" />
      )}
      {appleAppID && (
        <meta name="apple-itunes-app" content={`app-id=${appleAppID}`} />
      )}
    </ReactHelmet>
  )
}
