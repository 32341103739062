import React from 'react'

import { OptionItem as OptionItemType } from '@src/graphql-types'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import { ItemName, IndividualOption, ItemPrice, Dot } from './OptionItem.styles'

type OptionItemProps = {
  optionItem: Pick<OptionItemType, 'id' | 'name' | 'price'>
  isOrderSummary?: boolean
  quantity: number
}

export const OptionItem: React.FC<OptionItemProps> = ({
  optionItem,
  isOrderSummary = false,
  quantity,
}) => {
  const formatCurrency = useFormatCurrency()
  return (
    <IndividualOption key={optionItem.id}>
      <ItemName>
        <Dot isOrderSummary={isOrderSummary}>{'•'}</Dot>
        {optionItem.name}
      </ItemName>
      <ItemPrice isOrderSummary={isOrderSummary}>
        {formatCurrency(optionItem.price * quantity)}
      </ItemPrice>
    </IndividualOption>
  )
}
