import {
  addDays,
  addMinutes,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  startOfDay,
} from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as SwiperType } from 'swiper/types'

import { SwiperDaysPicker } from '@src/components/DatePicker/SwiperDaysPicker'
import {
  UncontrolledRadioInput,
  CheckboxInputStyle,
} from '@src/components/Inputs'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import {
  OptionContentLabels,
  OptionContentText,
  OptionLabel,
  PopoverOptionContainer,
  TimeFullSpan,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { SingleOutlet } from '@src/components/SingleOutlet/types'
import {
  ExtendedCurrentFulfilmentDeliveryEverywhere,
  ExtendedCurrentFulfilmentDeliveryPostcode,
  ExtendedCurrentFulfilmentDeliveryCoordinates,
  ExtendedCurrentFulfilmentDeliverySavedAddress,
  ExtendedCurrentFulfilmentDeliveryZone,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { filterOutUnavailableDeliveryPreorderWindows } from '@src/utils/fulfilmentTimes/filterUnavailableDeliveryPreorderWindows'

import {
  DeliveryWindowsOfTheDayContainer,
  DeliveryWindowsOfTheDayContainerInner,
  NoSlotsCard,
} from './DatePicker.styles'

export const DeliveryDatePicker: React.FC<{
  outletData: Pick<
    SingleOutlet,
    | 'closedUntil'
    | 'daysOfferedInAdvanceMin'
    | 'daysOfferedInAdvanceMax'
    | 'prepTime'
    | 'ASAPDeliveryDuration'
    | 'openingTimesArray'
    | 'deliveryPreorderWindows'
  >
  currentFulfilment:
    | ExtendedCurrentFulfilmentDeliveryEverywhere
    | ExtendedCurrentFulfilmentDeliveryPostcode
    | ExtendedCurrentFulfilmentDeliveryCoordinates
    | ExtendedCurrentFulfilmentDeliverySavedAddress
    | ExtendedCurrentFulfilmentDeliveryZone
}> = ({ outletData, currentFulfilment }) => {
  const {
    daysOfferedInAdvanceMin,
    daysOfferedInAdvanceMax,
    prepTime,
    deliveryPreorderWindows,
  } = outletData
  const { updateHistoricalData } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.LOCAL,
  })

  const sortedDeliveryWindows: SingleOutlet['deliveryPreorderWindows'] =
    useMemo(
      () =>
        filterOutUnavailableDeliveryPreorderWindows(outletData).sort((a, b) =>
          isBefore(new Date(a.end), new Date(b.end)) ? -1 : 1
        ),
      [outletData]
    )

  const defaultDate = sortedDeliveryWindows[0]
    ? sortedDeliveryWindows[0].end
    : null

  const { t } = useTranslation('datePicker')
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    currentFulfilment.deliveryPreorderWindow
      ? startOfDay(new Date(currentFulfilment.deliveryPreorderWindow.end))
      : defaultDate
  )

  const [selectedDateDeliveryWindows, setSelectedDateDeliveryWindows] =
    useState<SingleOutlet['deliveryPreorderWindows']>([])

  const [daysSwiperInstance, setDaysSwiperInstance] =
    useState<SwiperType | null>(null)

  useEffect(() => {
    // when selectedDate changes, swipe to the correct day
    if (selectedDate && daysSwiperInstance) {
      const indexToSlideTo = preOrderDays.findIndex(day =>
        isEqual(
          startOfDay(new Date(day.date)),
          startOfDay(new Date(selectedDate))
        )
      )

      daysSwiperInstance.slideTo(indexToSlideTo, 500)
    }
  }, [selectedDate, daysSwiperInstance])

  useEffect(() => {
    // delivery windows change, clear and wait to animate transition
    if (!selectedDate || !sortedDeliveryWindows) {
      return
    }
    setSelectedDateDeliveryWindows(
      sortedDeliveryWindows.filter(
        ({ start, end }) =>
          isEqual(
            startOfDay(new Date(start ? start : end)),
            startOfDay(new Date(selectedDate))
          ) &&
          isAfter(
            addMinutes(new Date(start ? start : end), prepTime),

            new Date()
          )
      )
    )
  }, [selectedDate])

  //   preorder days used to show sliders
  const {
    preOrderDays,
  }: {
    preOrderDays: { date: Date; full: boolean }[]
  } = useMemo(() => {
    const days = []

    for (
      let i = outletData.daysOfferedInAdvanceMin;
      i <= outletData.daysOfferedInAdvanceMax;
      i++
    ) {
      const currentDate = addDays(new Date(), i)
      days.push({
        date: currentDate,
        full: !outletData.deliveryPreorderWindows.filter(
          window =>
            window.isFull && isSameDay(currentDate, new Date(window.start))
        ).length,
      })
    }

    days.sort((a: { date: Date }, b: { date: Date }) =>
      isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1
    )

    return {
      preOrderDays: days,
    }
  }, [
    daysOfferedInAdvanceMax,
    daysOfferedInAdvanceMin,
    deliveryPreorderWindows,
  ])

  return (
    <>
      {preOrderDays.length > 0 && (
        <>
          <SectionHeader
            header={t('select_date')}
            subheader={t(
              'this_is_the_day_you_would_like_to_receive_your_order'
            )}
          />
          <SwiperDaysPicker
            setDaysSwiperInstance={setDaysSwiperInstance}
            preOrderDays={preOrderDays}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate ?? defaultDate}
          />
        </>
      )}

      {selectedDate && (
        <>
          <SectionHeader
            header={t('select_fulfilment_time', {
              fulfilment: currentFulfilment.narrowType.toLowerCase(),
            })}
            subheader={t('your_delivery_will_aim_to_arrive_within_these_times')}
          />
          <DeliveryWindowsOfTheDayContainer
            id={'windowsOfTheDayContainer'}
            role="tabpanel"
            aria-labelledby={`day-tab-${format(selectedDate, 'ddMM')}`}
          >
            {
              // column delivery windows
              <DeliveryWindowsOfTheDayContainerInner>
                {!selectedDateDeliveryWindows.length ||
                selectedDateDeliveryWindows.every(window => window.isFull) ? (
                  <NoSlotsCard>
                    {selectedDateDeliveryWindows.length > 0
                      ? t('slots_full_day_msg')
                      : t('closed_for_the_day')}
                  </NoSlotsCard>
                ) : (
                  selectedDateDeliveryWindows.map(({ start, end, isFull }) => {
                    return (
                      <PopoverOptionContainer key={end.toISOString()}>
                        <UncontrolledRadioInput
                          disabled={isFull}
                          label={
                            <>
                              <OptionLabel>
                                <OptionContentLabels>
                                  <OptionContentText>
                                    {start
                                      ? format(new Date(start), `HH:mm - `)
                                      : null}
                                    {format(new Date(end), 'HH:mm')}
                                    {isFull && (
                                      <TimeFullSpan>
                                        {t('delivery_time_full')}
                                      </TimeFullSpan>
                                    )}
                                  </OptionContentText>
                                </OptionContentLabels>
                              </OptionLabel>
                            </>
                          }
                          style={CheckboxInputStyle.TICK}
                          checked={Boolean(
                            currentFulfilment.deliveryPreorderWindow &&
                              isEqual(
                                new Date(
                                  currentFulfilment.deliveryPreorderWindow.end
                                ),
                                new Date(end)
                              )
                          )}
                          onClick={() => {
                            updateHistoricalData({
                              deliveryPreorderWindow: {
                                start,
                                end,
                              },
                            })
                          }}
                        />
                      </PopoverOptionContainer>
                    )
                  })
                )}
              </DeliveryWindowsOfTheDayContainerInner>
            }
          </DeliveryWindowsOfTheDayContainer>
        </>
      )}
    </>
  )
}
