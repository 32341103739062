import { QueryHookOptions, useQuery } from '@apollo/client'

import {
  GetCustomerCards,
  GetCustomerCards_customerDetails_cards_cards,
} from './queries/__generated__/GetCustomerCards'
import {
  GetCustomerCardsDocument,
  GetCustomerCardsQueryVariables,
} from './queries/__generated__/GetCustomerCards.graphql-interface'

type Args = {
  useQueryArgs?: QueryHookOptions<
    GetCustomerCards,
    GetCustomerCardsQueryVariables
  >
}
export const usePaymentCardsQuery = ({ useQueryArgs }: Args = {}) =>
  useQuery(GetCustomerCardsDocument, useQueryArgs)

export type Card = GetCustomerCards_customerDetails_cards_cards
