import { makeVar, useReactiveVar } from '@apollo/client'
import { useContext } from 'react'

import { BasketOutletContext } from '../outletFulfilmentAndBasketHooks/useOutletFulfilment/context/BasketOutletProvider'
import { CurrentOutletContext } from '../outletFulfilmentAndBasketHooks/useOutletFulfilment/context/CurrentOutletProvider'

enum ServiceComponentModalLocation {
  BASKET = 'BASKET',
  CURRENT_OUTLET = 'CURRENT_OUTLET',
}

// This state is used to toggle the relevant service component modal (current outlet or basket - outlet list is handled separately)
// It is stored globally so that the checkout modal can be closed when the service component modal is opened
// which solves an infinite-recursion bug where the service component BottomSheet and the checkout Modal fight to trap focus
// It uses context to know which modal to open
export const serviceComponentModalLocationReactiveVar =
  makeVar<ServiceComponentModalLocation | null>(null)

export const useServiceComponentModal = () => {
  const serviceComponentModalLocation = useReactiveVar(
    serviceComponentModalLocationReactiveVar
  )
  const isBasketContext = Boolean(useContext(BasketOutletContext))
  const isCurrentOutletContext = Boolean(useContext(CurrentOutletContext))

  const isServiceComponentOpen =
    (isBasketContext &&
      serviceComponentModalLocation === ServiceComponentModalLocation.BASKET) ||
    (isCurrentOutletContext &&
      serviceComponentModalLocation ===
        ServiceComponentModalLocation.CURRENT_OUTLET)

  const openServiceComponentModal = () =>
    serviceComponentModalLocationReactiveVar(
      isBasketContext
        ? ServiceComponentModalLocation.BASKET
        : ServiceComponentModalLocation.CURRENT_OUTLET
    )

  return {
    isServiceComponentOpen,
    openServiceComponentModal: openServiceComponentModal,
    closeServiceComponentModal: () =>
      serviceComponentModalLocationReactiveVar(null),
  }
}
