import { Discounts, MemberDiscount, MemberDiscountSchema } from '../validation'

const VOUCHER_DISCOUNT_ID_SESSION_STORAGE_KEY = 'voucherDiscountId'
const MEMBER_DISCOUNT_LOCAL_STORAGE_KEY = 'memberDiscount'

export const persistDiscounts = (discounts: Discounts): void => {
  if (discounts.memberDiscount) {
    localStorage.setItem(
      MEMBER_DISCOUNT_LOCAL_STORAGE_KEY,
      JSON.stringify(discounts.memberDiscount)
    )
  } else {
    localStorage.removeItem(MEMBER_DISCOUNT_LOCAL_STORAGE_KEY)
  }
  if (discounts.voucherDiscountId) {
    sessionStorage.setItem(
      VOUCHER_DISCOUNT_ID_SESSION_STORAGE_KEY,
      discounts.voucherDiscountId
    )
  } else {
    sessionStorage.removeItem(VOUCHER_DISCOUNT_ID_SESSION_STORAGE_KEY)
  }
}

const retrieveMemberDiscount = (): MemberDiscount | null => {
  const memberDiscountFromLS = localStorage.getItem(
    MEMBER_DISCOUNT_LOCAL_STORAGE_KEY
  )
  if (!memberDiscountFromLS) {
    return null
  }

  // attempt to parse
  let parsedMemberDiscount
  try {
    parsedMemberDiscount = JSON.parse(memberDiscountFromLS)
  } catch (err) {
    console.error(
      'Failed to parse discounts from session storage. Removing from session storage',
      err
    )
    sessionStorage.removeItem(MEMBER_DISCOUNT_LOCAL_STORAGE_KEY)
    return null
  }

  // validate
  try {
    const validMemberDiscount = MemberDiscountSchema.parse(parsedMemberDiscount)
    return validMemberDiscount
  } catch (err) {
    console.error(
      'Failed to validate member discount from local storage. Removing from local storage.',
      err,
      {
        parsedMemberDiscount,
      }
    )
    sessionStorage.removeItem(MEMBER_DISCOUNT_LOCAL_STORAGE_KEY)
    return null
  }
}

export const retrieveDiscounts = (): Discounts => {
  // get the voucher discount id from session storage
  const voucherDiscountId = sessionStorage.getItem(
    VOUCHER_DISCOUNT_ID_SESSION_STORAGE_KEY
  )
  // get the member discount from local storage (and parse and validate)
  const memberDiscount = retrieveMemberDiscount()
  return {
    memberDiscount,
    voucherDiscountId,
  }
}
