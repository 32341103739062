import styled from 'styled-components'

import { CircleCrossSVG } from '@src/components/SVGS/CircleCrossSVG'

export const Container = styled.div`
  width: 75%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
  justify-content: center;
  height: 100%;
`

export const TitleText = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin: 0px 0px 16px 0px;
  color: ${({ theme }) => theme.colors.mainText};
`

export const OrderNoText = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.mainText};
`

export const ConfirmationText = styled.p`
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  margin: 18px 0px;
  color: ${({ theme }) => theme.colors.mainText};
  margin: 16px 16px 64px 16px;
`

export const EmailText = styled.span``

export const CrossButtonContainer = styled.div`
  height: 20px;
`

export const StyledCircleCrossSVG = styled(CircleCrossSVG)`
  padding: 8px;

  .circle,
  .cross {
    transition: 0.2s ease;
    transition-property: fill;
  }

  .circle {
    fill: #fff;
    stroke: #fff;
  }

  .cross {
    fill: ${({ theme }) => theme.colors.brand};
  }

  :hover {
    circle {
      fill: ${({ theme }) => theme.colors.brand};
      stroke: ${({ theme }) => theme.colors.brand};
    }
    path {
      fill: ${({ theme }) => theme.colors.brand};
    }
  }
`
