import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  isDisabled: boolean
  finalElement: boolean
}>`
  all: unset;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ finalElement }) =>
    !finalElement && '1px solid rgba(150, 150, 150, 0.2)'};
  border-radius: 1px;
  margin: 0;

  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};

  @media (min-width: ${breakpoints.tablet}px) {
    break-inside: avoid;
    align-content: space-between;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    margin: 0px 16px 0px 16px;
    border-bottom-width: 2px;
  }

  &:hover {
    h4 {
      color: ${({ theme }) => theme.colors.brandHighlight};
    }
    span {
      color: ${({ theme }) => theme.colors.brandHighlight};
    }
    svg {
      color: ${({ theme }) => theme.colors.brandHighlight};
    }
  }
`

export const FlexRowContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 8px;
  height: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

export const ExtraDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 0px 0px;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 12px 0px 0px 0px;
  }
`

export const ImageContainer = styled.div<{ imageUrl?: string }>`
  height: 104px;
  min-width: 96px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
      background-size: cover;
    `};

  background-size: cover;
  background-position: 50%;
  cursor: pointer;

  @media (min-width: ${breakpoints.desktop}px) {
    height: 128px;
    min-width: 128px;
  }
`

export const ItemDetailsContainer = styled.div<{
  isDisabled: boolean
  noImage: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

export const ItemDetailsHeader = styled.h4`
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  color: #2e3333;
  padding-bottom: 4px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 16px;
  }
`

export const ItemDetailsHeaderAndCounter = styled.div<{
  noImage?: boolean
}>`
  ${({ noImage }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${noImage && '4px'};
    `
  }}
`
export const MenuItemCounter = styled.div<{
  isOutletCard?: boolean
  isMobile?: boolean
}>`
  display: flex;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  position: ${({ isMobile, isOutletCard }) =>
    (isOutletCard || isMobile) && 'absolute'};
  left: 52px;
  top: -8px;
  border: 2px solid rgba(255, 255, 255);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand};

  @media (max-width: ${breakpoints.tablet}px) {
    width: 22px;
    height: 22px;
    left: ${({ isOutletCard }) => !isOutletCard && '60px'};
  }
`

export const MenuItemCounterText = styled.p`
  color: white;
  font-weight: 700;
  font-size: 11px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 14px;
  }
`

export const SoldOutText = styled.p`
  color: #616161;
  font-weight: 700;
  font-size: 16px;
  margin-right: 4px;
  margin-bottom: 0px;
`

export const MenuDescription = styled.p`
  font-size: 12px;
  margin: 0px;
  font-weight: 400;
  line-height: 16px;
  color: #2e3333;
  white-space: pre-wrap;
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.desktop}px) {
    padding-right: 0px;
    font-size: 13px;
    line-height: 17px;
  }
`

export const AllergenFavouriteContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}px) {
    text-align: inherit;
  }
`
