import React from 'react'
import { useTranslation } from 'react-i18next'

import { useMarketplace } from '@src/hooks/useMarketplace'
import playStore from '@src/images/google-play-badge.png'

import {
  Address,
  AppButtonContainer,
  ButtonsContainer,
  ContactContainer,
  ContactEmail,
  ContactText,
  Container,
  Title,
  PhoneTextLink,
  PolicyContainer,
  StyledLink,
  SocialContainer,
  StyledNewTabLink,
  StyledAppStore,
  StyledPlayStore,
  StyledATag,
  StyledTikTokIconSVG,
  StyledTwitterXIconSVG,
  StyledFacebookIconSVG,
  StyledInstagramIconSVG,
  StyledLinkWrapper,
  StyledIconWrapper,
  AppButtonWrapper,
} from './Footer.styles'

export const Footer: React.FC = () => {
  const { t } = useTranslation('footer')

  const {
    supportEmail,
    supportPhone,
    socialURLInstagram,
    socialURLFacebook,
    socialURLTwitter,
    socialURLTikTok,
    storeURLApple,
    storeURLGooglePlay,
    vatNumber,
    companyNumber,
    contactAddress,
    footerLinks,
    companyLegalName,
    legalAllergy,
    legalPolicy,
    legalTerms,
    legalPrivacy,
    legalCookies,
  } = useMarketplace()

  const { firstLine, secondLine, thirdLine, city, postcode, country } =
    contactAddress

  const addressArray = [
    firstLine,
    secondLine,
    thirdLine,
    city,
    postcode,
    country && country.name,
  ]

  return (
    <Container>
      <ContactContainer>
        <Title>{companyLegalName}</Title>
        <Address>
          {addressArray.map(
            (item, index) =>
              item &&
              item.length > 0 && (
                <div key={item}>
                  {item}
                  {index + 1 !== addressArray.length && (
                    <>
                      {`,`}
                      <br />
                    </>
                  )}
                </div>
              )
          )}
        </Address>
        {companyNumber && (
          <ContactText>
            {t('company_no')}
            {companyNumber}
          </ContactText>
        )}
        {vatNumber && (
          <ContactText>
            {t('vat_no')}
            {vatNumber}
          </ContactText>
        )}

        {(supportEmail || supportPhone) && <Title>{t('support')}</Title>}
        {supportEmail && (
          <ContactText>
            {t('support_email')}:<br />
            <ContactEmail href={`mailto:${supportEmail}`}>
              {supportEmail}
            </ContactEmail>
          </ContactText>
        )}
        {supportPhone && (
          <ContactText>
            {t('support_phone')}:<br />
            <PhoneTextLink href={`tel:${supportPhone}`}>
              {supportPhone}
            </PhoneTextLink>
          </ContactText>
        )}
      </ContactContainer>
      {(socialURLInstagram ||
        socialURLFacebook ||
        socialURLTwitter ||
        socialURLTikTok) && (
        <SocialContainer>
          <Title>{t('title_social')}</Title>
          {socialURLInstagram && (
            <StyledLinkWrapper>
              <StyledNewTabLink to={{ pathname: socialURLInstagram }}>
                <StyledIconWrapper>
                  <StyledInstagramIconSVG
                    className={'instagram-icon'}
                    width={16}
                    height={16}
                  />
                </StyledIconWrapper>
                {t('instagram')}
              </StyledNewTabLink>
            </StyledLinkWrapper>
          )}
          {socialURLFacebook && (
            <StyledLinkWrapper>
              <StyledNewTabLink to={{ pathname: socialURLFacebook }}>
                <StyledIconWrapper>
                  <StyledFacebookIconSVG
                    className={'facebook-icon'}
                    width={16}
                    height={16}
                  />
                </StyledIconWrapper>
                {t('facebook')}
              </StyledNewTabLink>
            </StyledLinkWrapper>
          )}
          {socialURLTwitter && (
            <StyledLinkWrapper>
              <StyledNewTabLink to={{ pathname: socialURLTwitter }}>
                <StyledIconWrapper>
                  <StyledTwitterXIconSVG
                    className={'twitter-X-icon'}
                    width={16}
                    height={16}
                  />
                </StyledIconWrapper>
                {t('twitter')}
              </StyledNewTabLink>
            </StyledLinkWrapper>
          )}
          {socialURLTikTok && (
            <StyledLinkWrapper>
              <StyledNewTabLink to={{ pathname: socialURLTikTok }}>
                <StyledIconWrapper>
                  <StyledTikTokIconSVG
                    className={'tik-tok-icon'}
                    width={16}
                    height={16}
                  />
                </StyledIconWrapper>
                {t('tiktok')}
              </StyledNewTabLink>
            </StyledLinkWrapper>
          )}
        </SocialContainer>
      )}
      <PolicyContainer>
        <Title>{t('title_legal')}</Title>
        {legalTerms && (
          <StyledLink to={{ pathname: '/', search: '?account=terms' }}>
            {t('terms')}
          </StyledLink>
        )}
        {legalPrivacy && (
          <StyledLink to={{ pathname: '/', search: '?account=privacy' }}>
            {t('privacy')}
          </StyledLink>
        )}
        {legalAllergy && (
          <StyledLink to={{ pathname: '/', search: '?account=allergy' }}>
            {t('allergy')}
          </StyledLink>
        )}
        {legalCookies && (
          <StyledLink to={{ pathname: '/', search: '?account=cookies' }}>
            {t('cookies')}
          </StyledLink>
        )}
        {legalPolicy && (
          <StyledLink to={{ pathname: '/', search: '?account=misc' }}>
            {t('misc_policies')}
          </StyledLink>
        )}
        <StyledLink to={{ pathname: '/', search: '?account=help' }}>
          {t('help')}
        </StyledLink>
        {footerLinks.map(footerLink => (
          <StyledATag
            key={footerLink.id}
            href={footerLink.link}
            target="_blank"
          >
            {footerLink.title}
          </StyledATag>
        ))}
      </PolicyContainer>
      <ButtonsContainer>
        {(storeURLApple || storeURLGooglePlay) && (
          <>
            <Title>{t('title_download')}</Title>
            <AppButtonWrapper>
              {storeURLApple && (
                <AppButtonContainer to={{ pathname: storeURLApple }}>
                  <StyledAppStore id={t('download')} />
                </AppButtonContainer>
              )}
              {storeURLGooglePlay && (
                <AppButtonContainer to={{ pathname: storeURLGooglePlay }}>
                  <StyledPlayStore src={playStore} alt={t('playStore')} />
                </AppButtonContainer>
              )}
            </AppButtonWrapper>
          </>
        )}
      </ButtonsContainer>
    </Container>
  )
}
