import { z } from 'zod'

import i18n from '@src/i18n/i18n'

const t = (key: string) => i18n.t(key, { ns: 'changePassword' })

export const changePasswordSchema = z
  .object({
    currentPassword: z
      .string({
        required_error: t('required_error'),
      })
      .min(8, {
        message: t('password_length'),
      }),

    newPassword: z
      .string({
        required_error: t('required_error'),
      })
      .min(8, {
        message: t('password_length'),
      }),
    newPasswordConfirm: z
      .string({
        required_error: t('required_error'),
      })
      .min(8, {
        message: t('password_length'),
      }),
  })
  .superRefine(({ newPassword, newPasswordConfirm }, ctx) => {
    if (newPassword !== newPasswordConfirm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('password_match_error'),
        path: ['newPasswordConfirm'],
      })
    }
  })
