import { Optional } from 'ts-toolbelt/out/Object/Optional'

import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'
import { BasketItem } from '../validation'

export const patchItem =
  ({ existingItems, navigateToBasket }: HookMethodArgs) =>
  ({
    basketItemId,
    patchData,
  }: {
    basketItemId: string
    patchData: Optional<Pick<BasketItem, 'name' | 'singleItemNotes'>>
  }) => {
    const basketItemIndex = existingItems.findIndex(
      basketItem => basketItem.id === basketItemId
    )
    const basketItem = existingItems[basketItemIndex]
    if (!basketItem) {
      throw new Error('Error updating basket: basket item not found.')
    }

    // navigate back to the basket
    navigateToBasket()

    const clonedItems = [...existingItems]
    clonedItems[basketItemIndex] = {
      ...basketItem,
      ...patchData,
    }
    basketItemsVar(clonedItems)
  }
