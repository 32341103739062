import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getSearchOutletsAndCuisinesMobileQueryVariables = Types.Exact<{
  marketplaceId: Types.Scalars['String']
  nameContains: Types.Scalars['String']
}>

export type getSearchOutletsAndCuisinesMobileQuery = {
  searchOutletsAndCategoriesByName: Array<
    | {
        __typename: 'Cuisine'
        id: string
        name: string
        emoji: string | null
        imageSrc: string | null
      }
    | {
        __typename: 'Outlet'
        id: string
        displayName: string
        outletLogoOverride: string | null
        restaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
          image: string | null
          cuisines: Array<{
            __typename: 'Cuisine'
            id: string
            name: string
            emoji: string | null
            imageSrc: string | null
          }>
        }
      }
  >
}

export const getSearchOutletsAndCuisinesMobileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSearchOutletsAndCuisinesMobile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nameContains' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOutletsAndCategoriesByName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameContains' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nameContains' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Outlet' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outletLogoOverride' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restaurant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cuisines' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'emoji' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageSrc' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Cuisine' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageSrc' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getSearchOutletsAndCuisinesMobileQuery,
  getSearchOutletsAndCuisinesMobileQueryVariables
>
