import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BusinessSegmentOutlets } from '@src/components/BusinessSegments/BusinessSegmentOutlets'
import { FeaturedOutlets } from '@src/components/FeaturedOutlets'
import { imageJitURL } from '@src/utils/imageJitURL'

import { Hero } from './Hero/Hero'
import { Container } from './Home.styles'
import { getBusinessSegmentsDocument } from './queries/__generated__/getBusinessSegments.graphql-interface'
import { getMarketplaceHeroDocument } from './queries/__generated__/getMarketplaceHero.graphql-interface'

export const Home: React.FC = () => {
  const { t } = useTranslation('homePage')
  const { data, loading, error } = useQuery(getMarketplaceHeroDocument, {
    variables: { cname: window.location.hostname },
  })
  const segmentsResponse = useQuery(getBusinessSegmentsDocument, {
    variables: { marketplaceId: data?.marketplaceByCname.id ?? '' },
    skip: !data?.marketplaceByCname.id,
  })

  if (loading || !data || error) return null

  const { name, heroText, heroTextSecondary, heroImage } =
    data.marketplaceByCname

  return (
    <>
      <Container
        imageUrl={imageJitURL(heroImage, {
          resize: {
            width: 1280,
            height: 900,
            fit: 'cover',
          },
        })}
      >
        <Hero
          heroText={heroText || ''}
          heroTextSecondary={heroTextSecondary || ''}
        />
      </Container>
      {!segmentsResponse.data?.businessSegments.length && (
        <FeaturedOutlets
          title={t('now_on', {
            marketplaceName: name,
          })}
          onboardingPage
        />
      )}
      {data?.marketplaceByCname.id &&
        !!segmentsResponse.data?.businessSegments.length &&
        segmentsResponse.data.businessSegments.map((segment, index) => (
          <BusinessSegmentOutlets
            key={segment.id}
            segmentId={segment.id}
            title={segment.name}
            index={index}
          />
        ))}
    </>
  )
}
