import { calculateFulfilmentFilterLocationFromCurrentFulfilment } from '@src/utils/calculateFulfilmentFilterLocationFromCurrentFulfilment'

import { fulfilmentFilterReactiveVar } from './utils/reactiveVar'

import {
  NonBasketOutletFulfilment,
  CurrentFulfilmentType,
} from '../outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'

// takes the fulfilment from the Outlet Fulfilment reactive var state
// and updates the Fulfilment Filter's location state to match
// eg if the customer updates their postcode on the outlet / basket service component, then it will be updated
// in the list view
export const syncCurrentFulfilmentWithLocation = ({
  outletFulfilment,
}: {
  outletFulfilment: NonBasketOutletFulfilment['currentFulfilment']
}): void => {
  const existingFulfilmentFilter = fulfilmentFilterReactiveVar()
  const postAndCountryCode =
    outletFulfilment.type === CurrentFulfilmentType.DELIVERY_POSTCODE
      ? outletFulfilment.postAndCountryCode
      : existingFulfilmentFilter.where.historicalData.postAndCountryCode
  const coordinates =
    outletFulfilment.type === CurrentFulfilmentType.DELIVERY_COORDINATES
      ? outletFulfilment.coordinates
      : existingFulfilmentFilter.where.historicalData.coordinates
  const addressId =
    outletFulfilment.type === CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS
      ? outletFulfilment.addressId
      : existingFulfilmentFilter.where.historicalData.addressId
  const zoneId =
    outletFulfilment.type === CurrentFulfilmentType.DELIVERY_ZONE
      ? outletFulfilment.zoneId
      : existingFulfilmentFilter.where.historicalData.zoneId

  fulfilmentFilterReactiveVar({
    ...existingFulfilmentFilter,
    where: {
      ...existingFulfilmentFilter.where,
      // sync fulfilment filter location with current fulfilment
      location:
        calculateFulfilmentFilterLocationFromCurrentFulfilment(
          outletFulfilment
        ),
      historicalData: {
        postAndCountryCode,
        coordinates,
        addressId,
        zoneId,
      },
    },
  })
}
