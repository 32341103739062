export const serviceOverlay = {
  service: 'Service',
  all: 'All',
  delivery: 'Delivery',
  collection: 'Collection',
  table: 'Table Service',
  when: 'When',
  where: 'Where',
  search: 'Search',
  clear: 'Clear',
  reset: 'Reset',
  location_instructions: 'Search outlets in your area:',
  zone_location_instructions: 'Enter your location:',
  use_current_location: 'Use current location',
  postcode: 'Postcode',
  search_placeholder: 'Restaurants, Pizza, Burgers...',
  continue: 'continue',
  enter_postcode: 'Postcode',
  select_your_table: 'Select your table',
  non_ideal_state: 'Sorry, there are no matches to your search',
  all_results_for: 'All results for {{searchTerm}}',
  collection_address: 'Collection Address',
  everywhere: 'Everywhere',
}
