import { useQuery } from '@apollo/client'
import { format, formatISO } from 'date-fns'
import { startCase, toLower, truncate } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType } from '@src/components/Button/Button'
import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Helmet } from '@src/components/Helmet'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { YearsPicker } from '@src/components/YearsPicker/YearsPicker'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'
import TickCircle from '@src/images/c-check.svg'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  Order,
  OrderStatusContainer,
  OutletName,
  OutletNameAndTotal,
  OutletTotal,
  NumberOfOrdersShown,
  StatusIcon,
  OrderedAt,
  YearsPickerContainer,
  NoIdealStatePage,
  RestaurantLogo,
  OrderInfoContainer,
  ButtonContainer,
  Container,
} from './MyOrders.styles'
import { GetMyOrdersDocument } from './queries/__generated__/GetMyOrders.graphql-interface'

const PAGE_SIZE = 5 as const

const MAXIMUM_TITLE_LENGTH = 30

export const MyOrders: React.VFC = () => {
  useNotLoggedInRedirect()

  const { t } = useTranslation(['myOrdersPage'])

  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  )
  const { route } = useAccountRouter()

  const formatCurrency = useFormatCurrency()

  const { loading, data, error, fetchMore } = useQuery(GetMyOrdersDocument, {
    variables: {
      input: { pageSize: PAGE_SIZE },
      createdAt_gte: formatISO(new Date(selectedYear.toString())),
      createdAt_lte: formatISO(new Date((selectedYear + 1).toString())),
    },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <LoadingSpinner />

  if (error || !data) return <ErrorPage />

  const {
    totalCount,
    edges: orders,
    pageInfo: { hasNextPage, endCursor },
  } = data.orders

  const { registeredAt } = data.customerDetails

  return (
    <>
      <Helmet title={t('title')} />
      <BurgerSection
        twoLineHeader={false}
        header={{
          title: t('title'),
          secondRow: registeredAt ? (
            <YearsPickerContainer>
              <YearsPicker
                setSelectedYear={setSelectedYear}
                selectedYear={selectedYear}
                registeredAt={registeredAt}
              />
            </YearsPickerContainer>
          ) : null,
          thirdRow: orders.length ? (
            <NumberOfOrdersShown>
              {`${t('showing')}`} <strong>{orders.length}</strong> {t('of')}{' '}
              <strong>{totalCount}</strong>
            </NumberOfOrdersShown>
          ) : undefined,
        }}
      >
        {!!orders.length && registeredAt ? (
          <Container>
            {orders.map(({ node: order }, index) => {
              const longTitle = truncate(
                order.outlet.displayName || undefined,
                {
                  length: MAXIMUM_TITLE_LENGTH,
                  separator: ' ',
                }
              )

              return (
                <Order
                  key={order.id}
                  route={{
                    mainRouteName: MainRouteName.ORDERS,
                    pageDataId: order.id,
                  }}
                  lastOrder={index === orders.length - 1}
                  active={
                    route?.mainRouteName === MainRouteName.ORDERS &&
                    route.pageDataId === order.id
                  }
                >
                  <RestaurantLogo
                    role="img"
                    aria-label={`${order.outlet.displayName} logo`}
                    imageUrl={imageJitURL(
                      order.outlet.outletLogoOverride ||
                        order.outlet.restaurant.image,
                      {
                        resize: {
                          width: 80,
                          height: 80,
                          fit: 'cover',
                        },
                      }
                    )}
                  />
                  <OutletNameAndTotal>
                    <OutletName>{longTitle}</OutletName>
                    <OrderInfoContainer>
                      <div>
                        <OrderedAt>
                          {t('placed')}{' '}
                          {format(new Date(order.createdAt), 'dd/MM/yy')}
                        </OrderedAt>
                        <OutletTotal>
                          {formatCurrency(order.grossTotal)}
                        </OutletTotal>
                      </div>
                      <OrderStatusContainer>
                        {startCase(toLower(order.cleanOrderStatus || ''))}
                        {order.orderStatus?.includes('READY') && (
                          <StatusIcon src={TickCircle} alt="tick" />
                        )}
                      </OrderStatusContainer>
                    </OrderInfoContainer>
                  </OutletNameAndTotal>
                </Order>
              )
            })}

            {hasNextPage && (
              <ButtonContainer>
                <Button
                  buttonType={ButtonType.PRIMARY}
                  onClick={() => {
                    void fetchMore({
                      variables: {
                        input: { startKey: endCursor, pageSize: PAGE_SIZE },
                        createdAt_gte: formatISO(
                          new Date(selectedYear.toString())
                        ),
                        createdAt_lte: formatISO(
                          new Date((selectedYear + 1).toString())
                        ),
                      },
                    })
                  }}
                  content={
                    <>
                      {t('view_next')} {PAGE_SIZE}
                      {'/'}
                      {totalCount}
                    </>
                  }
                />
              </ButtonContainer>
            )}
          </Container>
        ) : (
          <NoIdealStatePage>
            <p>{t('non_ideal_orders')}</p>
          </NoIdealStatePage>
        )}
      </BurgerSection>
    </>
  )
}
