import { ErrorMessage, FormikContextType, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  SelectInput,
  UncontrolledCheckboxInput,
  UncontrolledTextInput,
} from '@src/components/Inputs'

import {
  AddAddressFriendlyNameText,
  UncontrolledCheckboxInputContainer,
} from './AddAddressInputs.styles'
import {
  AddressType,
  DeliveryFulfilmentFormSchema,
  NewAddressSchema,
} from './useDeliveryFulfilmentFormikProps/schema'

function isNewAddressFormikContext(
  formikContext: any
): formikContext is FormikContextType<NewAddressSchema> {
  return formikContext.values.addressType === AddressType.NEW
}

export const AddAddressInputs: React.FC<{
  borderless?: boolean
}> = ({ borderless = false }) => {
  const { t } = useTranslation('addressForm')
  const formikContext = useFormikContext<DeliveryFulfilmentFormSchema>()

  if (!isNewAddressFormikContext(formikContext)) {
    return null
  }
  const { values, handleChange, touched, errors } = formikContext

  return (
    <>
      <UncontrolledTextInput
        name={'addAddress.firstLine'}
        label={t('house_number.label')}
        value={values.addAddress.firstLine}
        touched={!!touched.addAddress?.firstLine}
        onChange={handleChange}
        required
        borderless={borderless}
      />
      <ErrorMessage name="addAddress.firstLine" />

      <UncontrolledTextInput
        name={'addAddress.secondLine'}
        label={t('street_name.label')}
        value={values.addAddress.secondLine}
        touched={!!touched.addAddress?.secondLine}
        onChange={handleChange}
        required
        error={errors.addAddress?.secondLine}
        borderless={borderless}
      />

      <UncontrolledTextInput
        name={'addAddress.city'}
        label={t('city.label')}
        value={values.addAddress.city}
        touched={!!touched.addAddress?.city}
        onChange={handleChange}
        required
        error={errors.addAddress?.city}
        borderless={borderless}
      />

      <SelectInput
        name="addAddress.countryCode"
        label={t('country_code.label')}
        // (temp default)
        options={[{ value: 'GB', name: 'United Kingdom' }]}
        required
      />

      <UncontrolledTextInput
        name={'addAddress.postcode'}
        label={t('postcode.label')}
        value={values.addAddress.postcode}
        touched={!!touched.addAddress?.postcode}
        onChange={handleChange}
        required
        error={errors.addAddress?.postcode}
        borderless={borderless}
        toUpperCase
      />

      {/* Prompt user to name address if they haven't already */}
      <AddAddressFriendlyNameText>{t('name.title')}</AddAddressFriendlyNameText>
      <UncontrolledTextInput
        name={'addAddress.name'}
        label={t('name.label')}
        value={values.addAddress.name}
        touched={!!touched.addAddress?.name}
        onChange={handleChange}
        required
        error={errors.addAddress?.name}
        borderless={borderless}
        placeholder={t('name.placeholder')}
      />

      <UncontrolledCheckboxInputContainer>
        <UncontrolledCheckboxInput
          name="addAddress.default"
          checked={values.addAddress.default}
          autoComplete="off"
          onChange={handleChange}
          label={t('default.label')}
        />
      </UncontrolledCheckboxInputContainer>
    </>
  )
}
