import {
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { fulfilmentFilterReactiveVar } from '@src/hooks/useFulfilmentFilter/utils/reactiveVar'

import { calculateCollectionPreorderDateTime } from './calculateCollectionPreorderDateTime'
import { calculateDeliveryPreorderWindow } from './calculateDeliveryPreorderWindow'

import { OutletFulfilmentContextData } from '../context/types'
import { NonBasketOutletFulfilment, CurrentFulfilmentType } from '../validation'

export const getInitialOutletFulfilment = ({
  outlet,
}: {
  outlet: OutletFulfilmentContextData
}): NonBasketOutletFulfilment => {
  // get current fulfilment filter state
  const fulfilmentFilter = fulfilmentFilterReactiveVar()

  // calculate the most appropriate preorder delivery window (null if asap or no windows available)
  const deliveryPreorderWindow = calculateDeliveryPreorderWindow({
    outlet,
    fulfilmentFilterWhen: fulfilmentFilter.when,
  })
  // calculate the most appropriate preorder datetime (null if asap or no datetimes available)
  const collectionPreorderDatetime = calculateCollectionPreorderDateTime({
    outlet,
    fulfilmentFilterWhen: fulfilmentFilter.when,
  })

  const historicalData = {
    collectionPreorderDatetime,
    deliveryPreorderWindow,
    tableId: null,
    tableNotes: null,
    ageVerificationConfirmed: false,
    deliveryNotes: null,
    collectionNotes: null,
    outlet,
    fulfilmentId: null,
    orderNotes: null,
    previousFulfilmentType: null,
  }

  // use available fulfilment methods to determine the best fulfilment type
  // Attempt DELIVERY types first
  if (
    outlet.prioritisedFulfilmentMethods.includes(
      NarrowFulfilmentMethodInputType.DELIVERY
    )
  ) {
    switch (fulfilmentFilter.where.location.type) {
      case LocationType.EVERYWHERE:
        return {
          outletId: outlet.id,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_EVERYWHERE,
            deliveryPreorderWindow,
          },
          historicalData,
        }
      case LocationType.POSTCODE:
        return {
          outletId: outlet.id,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_POSTCODE,
            deliveryPreorderWindow,
            postAndCountryCode:
              fulfilmentFilter.where.location.postAndCountryCode,
          },
          historicalData,
        }
      case LocationType.COORDINATES:
        return {
          outletId: outlet.id,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_COORDINATES,
            deliveryPreorderWindow,
            coordinates: fulfilmentFilter.where.location.coordinates,
          },
          historicalData,
        }
      case LocationType.ADDRESS:
        return {
          outletId: outlet.id,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS,
            deliveryPreorderWindow,
            addressId: fulfilmentFilter.where.location.addressId,
            deliveryNotes: historicalData.deliveryNotes,
            fulfilmentId: historicalData.fulfilmentId,
          },
          historicalData,
        }
      case LocationType.DELIVERY_ZONE:
        return {
          outletId: outlet.id,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_ZONE,
            deliveryPreorderWindow,
            zoneId: fulfilmentFilter.where.location.zoneId,
            deliveryNotes: historicalData.deliveryNotes,
          },
          historicalData,
        }
    }
  }

  // Attempt COLLECTION next
  if (
    outlet.prioritisedFulfilmentMethods.includes(
      NarrowFulfilmentMethodInputType.COLLECTION
    )
  ) {
    return {
      outletId: outlet.id,
      currentFulfilment: {
        type: CurrentFulfilmentType.COLLECTION,
        collectionPreorderDatetime,
        collectionNotes: historicalData.collectionNotes,
      },
      historicalData,
    }
  }

  // Attempt TABLE last
  if (
    outlet.prioritisedFulfilmentMethods.includes(
      NarrowFulfilmentMethodInputType.TABLE
    )
  ) {
    return {
      outletId: outlet.id,
      currentFulfilment: {
        type: CurrentFulfilmentType.TABLE_UNSELECTED,
        tableNotes: null,
      },
      historicalData: historicalData,
    }
  }

  // Fallbacks (no fulfilment methods are available to location)
  // Attempt Delivery first
  if (
    outlet.availableFulfilmentInputMethods.includes(
      NarrowFulfilmentMethodInputType.DELIVERY
    )
  ) {
    return {
      outletId: outlet.id,
      currentFulfilment: {
        type: CurrentFulfilmentType.DELIVERY_EVERYWHERE,
        deliveryPreorderWindow,
      },
      historicalData: historicalData,
    }
  }

  // Attempt Collection next
  if (
    outlet.availableFulfilmentInputMethods.includes(
      NarrowFulfilmentMethodInputType.COLLECTION
    )
  ) {
    return {
      outletId: outlet.id,
      currentFulfilment: {
        type: CurrentFulfilmentType.COLLECTION,
        collectionPreorderDatetime,
        collectionNotes: historicalData.collectionNotes,
      },
      historicalData: historicalData,
    }
  }

  // Attempt Table next
  if (
    outlet.availableFulfilmentInputMethods.includes(
      NarrowFulfilmentMethodInputType.TABLE
    )
  ) {
    return {
      outletId: outlet.id,
      currentFulfilment: {
        type: CurrentFulfilmentType.TABLE_UNSELECTED,
        tableNotes: historicalData.tableNotes,
      },
      historicalData: historicalData,
    }
  }

  // Fallback to delivery everywhere,
  // even though the outlet cannot accept any orders
  // as it does not offer any fulfilment methods
  return {
    outletId: outlet.id,
    currentFulfilment: {
      type: CurrentFulfilmentType.DELIVERY_EVERYWHERE,
      deliveryPreorderWindow,
    },
    historicalData: historicalData,
  }
}
