import { keys } from 'lodash'
import { U } from 'ts-toolbelt'

import {
  CurrentFulfilmentCollectionSchema,
  CurrentFulfilmentDeliveryCoordinatesSchema,
  CurrentFulfilmentDeliveryEverywhereSchema,
  CurrentFulfilmentDeliveryPostcodeSchema,
  CurrentFulfilmentDeliverySavedAddressSchema,
  CurrentFulfilmentDeliveryZoneSchema,
  CurrentFulfilmentTableSchema,
  CurrentFulfilmentTableUnselectedSchema,
  CurrentFulfilmentType,
} from '../validation'

const CurrentFulfilmentTypeToSchema = {
  [CurrentFulfilmentType.DELIVERY_EVERYWHERE]:
    CurrentFulfilmentDeliveryEverywhereSchema.shape,
  [CurrentFulfilmentType.DELIVERY_POSTCODE]:
    CurrentFulfilmentDeliveryPostcodeSchema.shape,
  [CurrentFulfilmentType.DELIVERY_COORDINATES]:
    CurrentFulfilmentDeliveryCoordinatesSchema.shape,
  [CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS]:
    CurrentFulfilmentDeliverySavedAddressSchema.shape,
  [CurrentFulfilmentType.DELIVERY_ZONE]:
    CurrentFulfilmentDeliveryZoneSchema.shape,
  [CurrentFulfilmentType.COLLECTION]: CurrentFulfilmentCollectionSchema.shape,
  [CurrentFulfilmentType.TABLE]: CurrentFulfilmentTableSchema.shape,
  [CurrentFulfilmentType.TABLE_UNSELECTED]:
    CurrentFulfilmentTableUnselectedSchema.shape,
} as const

export const getKeysForCurrentFulfilmentType = (
  currentFulfilmentType: CurrentFulfilmentType
) =>
  keys(
    CurrentFulfilmentTypeToSchema[currentFulfilmentType]
  ) as unknown as keyof U.Merge<
    typeof CurrentFulfilmentTypeToSchema[CurrentFulfilmentType]
  >
