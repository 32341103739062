import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'
import { between } from '@src/utils/breakpoints'

export const OutletListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 8px 0px 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 24px 16px 0px 16px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 24px 0px 16px 16px;
  }
`

export const OutletCard = styled.div`
  height: 140px;
  margin: 8px 0px;
  width: 100%;
  display: flex;

  @media (max-width: ${breakpoints.wideDesktop}px) {
    ${between({
      start: 'tablet',
      end: 'desktop',
      styles: css`
        height: 160px;
        width: calc(50% - (32px / 2));
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      `,
    })}

    @media (min-width: ${breakpoints.desktop}px) {
      width: calc((100% / 3) - (64px / 3));
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    width: 22%;
  }
`

export const ImageContainer = styled(Skeleton)`
  height: 82px;
  width: 82px;
  margin: 16px;
`

export const HeaderSkeleton = styled(Skeleton)`
  width: 200px;
  height: 20px;
`

export const DetailsSkeleton = styled(Skeleton)`
  width: 100%;
  height: 16px;
  margin-top: 8px;
`

export const InnerDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ButtonSkeleton = styled(Skeleton)`
  width: 100px;
  height: 40px;
  margin-top: 72px;
  margin-right: 16px;
  border-radius: 40px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 0;
    margin-bottom: 8px;
    margin-right: 0;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 16px;
  }
`
