import { z } from 'zod'

import i18n from '@src/i18n/i18n'
import { isValidPhoneNumber } from '@src/utils/isValidPhoneNumber'

const t = (key: string) => i18n.t(key, { ns: 'signUp' })

export const newUserSchema = z
  .object({
    firstName: z
      .string({ required_error: t('first_name.validation.required_error') })
      .min(2, {
        message: t('first_name.validation.minimum_required_characters'),
      }),
    lastName: z
      .string({
        required_error: t('last_name.validation.required_error'),
      })
      .min(2, {
        message: t('last_name.validation.minimum_required_characters'),
      }),
    phoneNumber: z
      .string({
        required_error: t('phone_number.validation.required_error'),
        invalid_type_error: t('phone_number.validation.invalid_type_error'),
      })
      .refine(
        val => isValidPhoneNumber(val, 'GB'),
        t('phone_number.validation.invalid_type_error')
      ),
    password: z
      .string({ required_error: t('password.validation.required_error') })
      .min(8, {
        message: t('password.validation.minimum_required_characters'),
      }),
    passwordConfirm: z.string({
      required_error: t('confirm_password.validation.required_error'),
      invalid_type_error: t('confirm_password.validation.invalid_type_error'),
    }),
    email: z
      .string({
        required_error: t('email_address.validation.required_error'),
        invalid_type_error: t('email_address.validation.invalid_type_error'),
      })
      .email(),
  })
  .refine(
    ({ password, passwordConfirm }) => {
      return password === passwordConfirm
    },
    {
      message: t('confirm_password.validation.passwords_do_not_match'),
      path: ['passwordConfirm'],
    }
  )

export type NewUser = z.infer<typeof newUserSchema>
