import styled, { css } from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'
import { Link } from '@src/components/Router/Link'
import { MenuSVG } from '@src/components/SVGS/MenuSVG'
import { basketWidth } from '@src/constants/basketWidth'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  position: fixed;
  display: flex;
  width: 100vw;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
`

export const MenuContainer = styled.div`
  padding: 8px;
  height: 44px;
  background: ${({ theme }) => theme.colors.brand};
  z-index: 2;
  display: flex;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 8px 16px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    justify-content: flex-start;
  }
`

export const LogoAndBurgerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  @media (min-width: ${breakpoints.wideDesktop}px) {
    justify-content: center;
    width: calc(100vw - 560px);
  }
`

export const ServiceNavigationContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey};
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 2px 8px;

  @media (min-width: ${breakpoints.largeTablet}px) {
    padding: 16px 24px 16px 24px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    width: calc(100vw - ${basketWidth.regular}px - 30px);
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    width: calc(100vw - ${basketWidth.superWideDesktop}px - 30px);
  }
`

export const BurgerMenuIcon = styled(MenuSVG)`
  text-align: center;
  margin-top: 4px;
`

export const BurgerButton = styled(AccountSectionNavButton)`
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 100%;
    flex: none;
    background: ${({ theme }) => theme.colors.brand};
      .default};
    color: ${({ theme }) => theme.colors.brandForeground};
    cursor: pointer;

    transition: 0.3s ease;
    transition-property: color, background;

    &:hover {
      background: ${({ theme }) => theme.colors.siteBackground};
      color: ${({ theme }) => theme.colors.darkText};
    }

    @media (min-width: ${breakpoints.wideDesktop}px) {
      position: absolute;
      left: 16px;
    }
`

export const LogoContainer = styled(Link)`
  width: 200px;
`

export const MarketplaceLogo = styled.div<{ imageUrl: string }>`
  min-height: 38px;
  width: 100%;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  @media (min-width: ${breakpoints.tablet}px) {
    min-height: 38px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    min-height: 38px;
  }
`

export const RightIconsContainer = styled.div``
