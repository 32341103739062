import { z } from 'zod'

export enum DiscountType {
  MEMBER_DISCOUNT = 'MEMBER_DISCOUNT',
  VOUCHER_DISCOUNT = 'VOUCHER_DISCOUNT',
}

export const MemberDiscountSchema = z
  .object({
    businessId: z.string(),
    discountId: z.string(),
  })
  .nullable()
export type MemberDiscount = z.infer<typeof MemberDiscountSchema>

export type Discounts = {
  // business-specific discounts applied when the customer visits the site with an enrolment key query param
  memberDiscount: MemberDiscount
  // discounts applied when the customer enters a voucher code
  voucherDiscountId: string | null
}
