import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type DeleteCustomerCardMutationVariables = Types.Exact<{
  cardId: Types.Scalars['String']
}>

export type DeleteCustomerCardMutation = {
  deleteCard: {
    __typename: 'DeleteCustomerCard'
    message: string | null
    deletedCardId: string | null
  }
}

export const DeleteCustomerCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomerCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cardId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cardId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletedCardId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomerCardMutation,
  DeleteCustomerCardMutationVariables
>
