import { format, formatDistanceToNow } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { getFormattedDate } from '@src/utils/getFormattedDate'
import { imageJitURL } from '@src/utils/imageJitURL'

import { OrderProgress } from './OrderProgress'
import {
  RestaurantLogo,
  StatusBoxContainer,
  StatusBoxPlaced,
  StatusBoxText,
  StatusBoxTextHeader,
  StatusBoxUpdate,
  StatusInfoContainer,
  StatusPhotoText,
} from './StatusBox.styles'

export const PreparingStatusBox: React.VFC<{
  updatedAt: string
  restaurantName: string
  restaurantLogo: string | null
  fulfillmentMethod: NarrowFulfilmentMethodInputType
  isAccountPage: boolean
  startWindow?: Date
  endWindow?: Date
  formattedDeliveryDate?: Date
  formattedCollectionDate?: Date
}> = ({
  updatedAt,
  restaurantName,
  restaurantLogo,
  startWindow,
  endWindow = new Date(), // TODO: should be required when live, default date for now cause of missing data
  fulfillmentMethod,
  formattedCollectionDate,
  formattedDeliveryDate,
  isAccountPage,
}) => {
  const { t } = useTranslation('singleOrder')
  const orderUpdatedAt = formatDistanceToNow(new Date(updatedAt), {
    addSuffix: true,
  })

  return (
    <StatusBoxContainer isAccountPage={isAccountPage}>
      <StatusBoxPlaced>
        {format(new Date(updatedAt), 'dd MMM yyyy')}
      </StatusBoxPlaced>
      <StatusBoxUpdate>
        {t('updated')} {orderUpdatedAt}
      </StatusBoxUpdate>
      <StatusPhotoText>
        <RestaurantLogo
          role="img"
          aria-label={`${restaurantName} logo`}
          imageUrl={imageJitURL(restaurantLogo, {
            resize: {
              width: 64,
              height: 64,
              fit: 'cover',
            },
          })}
        />
        <StatusInfoContainer>
          <StatusBoxTextHeader>{restaurantName}</StatusBoxTextHeader>
          <StatusBoxText>
            {t('preparing_order')}
            <br />
            <strong>
              {getFormattedDate(
                fulfillmentMethod,
                endWindow,
                startWindow,
                formattedDeliveryDate,
                formattedCollectionDate
              )}
            </strong>
          </StatusBoxText>
        </StatusInfoContainer>
      </StatusPhotoText>
      <OrderProgress valueNow={2} />
    </StatusBoxContainer>
  )
}
