import React from 'react'
import { useTranslation } from 'react-i18next'

import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Markdown } from '@src/components/Markdown/Markdown'
import { useMarketplace } from '@src/hooks/useMarketplace'

const Content: React.FC = () => {
  const marketplace = useMarketplace()

  if (
    !marketplace.legalAllergy ||
    marketplace?.legalAllergy?.trim()?.length === 0
  ) {
    return <ErrorPage />
  }

  return <Markdown markdown={marketplace.legalAllergy} twoCols />
}

export const Allergy: React.FC = () => {
  const { t } = useTranslation('policies')
  return (
    <BurgerSection header={{ title: t('allergy') }}>
      <Content />
    </BurgerSection>
  )
}
