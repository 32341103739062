import { useReactiveVar } from '@apollo/client'

import { basketOutletIdReactiveVar } from './outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/reactiveVars'

export const useBasketOutletId = () => {
  const basketOutletId = useReactiveVar(basketOutletIdReactiveVar)

  return {
    basketOutletId,
    updateBasketOutletId: basketOutletIdReactiveVar,
  }
}
