import { clearVoucher } from '@src/hooks/useDiscounts/clearVoucher'

import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'

export const clear =
  ({ updateBasketOutletId, navigateToBasket }: HookMethodArgs) =>
  ({ shouldResetCheckoutRouter }: { shouldResetCheckoutRouter: boolean }) => {
    shouldResetCheckoutRouter && navigateToBasket()
    basketItemsVar([])
    clearVoucher()
    updateBasketOutletId(null)
  }
