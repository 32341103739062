import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  position: fixed;
  background-color: white;
  height: 600px;
  padding: 8px;
  z-index: 10;
  height: auto;
  top: 100px;
  left: calc(50% - 125px); // center
  width: 250px;
  border-radius: 4px;

  @media (min-width: ${breakpoints.tablet}px) {
    left: calc(50% - 200px); // center
    width: 400px;
  }
`

export const Underlay = styled.div`
  background-color: #191919;
  opacity: 0.7;
  width: 100vw;
  height: 200vh;
  overflow: hidden;
  position: fixed;
  z-index: 9;
  top: 0;
`
