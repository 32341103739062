import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const TickInCircleSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="24"
      height="24"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || 'checked'}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <path
        d="M27.5143 46L17 35.4159L21.5714 31.0442L27.5143 37.0265L44.4286 20L49 24.6018L27.5143 46Z"
        fill="white"
      />
    </svg>
  )
}

// Static version of your SVG for use in CSS
const staticSVG = ReactDOMServer.renderToString(<TickInCircleSVG id="static" />)

// Template literal for use as a data URL in CSS
export const tickInCircleSVGDataURL = `url("data:image/svg+xml;utf8,${encodeURIComponent(
  staticSVG
)}")`
