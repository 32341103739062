import { useQuery } from '@apollo/client'
import { capitalize } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BackToOutletListLink } from '@src/components/BackToOutletListLink/BackToOutletListLink'
import { getOutletSearchBySegmentVariables } from '@src/components/BusinessSegments/getSegmentOutletSearchVariables'
import { getBusinessSegmentDocument } from '@src/components/BusinessSegments/queries/__generated__/getBusinessSegment.graphql-interface'
import { getBusinessSegmentOutletsDocument } from '@src/components/BusinessSegments/queries/__generated__/getBusinessSegmentOutlets.graphql-interface'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Description } from '@src/components/FeaturedOutlets/FeaturedOutlets.styles'
import { TextClamp } from '@src/components/Text/Clamp'
import { useCategories } from '@src/hooks/useCategories'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { ALL_FULFILMENT_METHODS_SORTED } from '@src/utils/fulfilment'

import { CategoryFilteredOutlets } from '../OutletList/CategoryFilteredOutlets'
import { OutletCards } from '../OutletList/OutletCards'
import {
  HeaderContainer,
  OutletsOuterContainer,
} from '../OutletList/OutletList.styles'
import { OutletListSkeleton } from '../OutletList/OutletListSkeleton'

export const SegmentOutletList: React.FC<{
  segmentId: string | undefined
}> = ({ segmentId }) => {
  const fulfilmentFilter = useFulfilmentFilter()
  const marketplace = useMarketplace()

  const { t } = useTranslation(['copy'])

  const { selectedCategories } = useCategories()

  //gotta use state cause its not detecting selected date change
  const variables = segmentId
    ? getOutletSearchBySegmentVariables({
        fulfilmentFilterData: fulfilmentFilter.data,
        marketplaceId: marketplace.id,
        businessSegmentId: segmentId,
      })
    : undefined

  const { data, loading, error } = useQuery(
    getBusinessSegmentOutletsDocument,
    variables ? { variables } : { skip: true }
  )

  const segmentResponse = useQuery(getBusinessSegmentDocument, {
    variables: segmentId ? { id: segmentId } : undefined,
  })

  if (loading) {
    return <OutletListSkeleton />
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data) {
    return <ErrorPage />
  }

  const outlets = data.businessSegmentOutlets.map(
    ({ nextOpenDate, ...outlet }) => ({
      nextOpenDate: new Date(nextOpenDate),
      ...outlet,
    })
  )

  const getAllOutletsTitle = (): string => {
    if (
      fulfilmentFilter.data.nonEmptyFulfilmentMethods.length ===
      ALL_FULFILMENT_METHODS_SORTED.length
    ) {
      return marketplace.allOutletsText || t('all')
    }
    const translatedFulfilmentMethods =
      fulfilmentFilter.data.nonEmptyFulfilmentMethods.map(fulfilment =>
        t(fulfilment.toLowerCase())
      )
    const capitalizedWords = translatedFulfilmentMethods.map(word =>
      capitalize(word)
    )
    const capitalizedList = capitalizedWords.join(', ')
    return capitalizedList
  }

  return selectedCategories.length ? (
    <CategoryFilteredOutlets outlets={outlets} />
  ) : (
    <OutletsOuterContainer>
      <BackToOutletListLink showContainer />
      {outlets.length > 0 && (
        <HeaderContainer>{`${segmentResponse.data?.businessSegment?.name ?? ''}
            ${` - `}${getAllOutletsTitle()}`}</HeaderContainer>
      )}
      {!!segmentResponse.data?.businessSegment?.description?.length && (
        <Description>
          <TextClamp clamp={5}>
            {segmentResponse.data?.businessSegment?.description}
          </TextClamp>
        </Description>
      )}
      <OutletCards outlets={outlets}></OutletCards>
    </OutletsOuterContainer>
  )
}
