import styled from 'styled-components'

import { CloseButton } from '@src/components/SVGS/CloseButton'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  margin: 0;

  @media (min-width: ${breakpoints.wideDesktop}px) {
    margin: 8px 0px;
  }
`

export const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: 700;
  padding: 11px 0px;
  margin: 0;
  @media (min-width: ${breakpoints.tablet}px) {
    font-weight: 700;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  align-items: flex-start;
  margin: 0 8px;
  width: auto;
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0 32px 16px 32px;
  }
`

export const CategoriesHeader = styled.div`
  font-size: 24px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 32px;
  }
`

export const SubheaderAndBackButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`

export const StyledCloseButton = styled(CloseButton)`
  circle {
    display: none;
  }
  background-color: none;
  color: ${({ theme }) => theme.colors.brandLight};
`

export const SingleCategoryButton = styled.button`
  height: 24px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 8px;
  padding: 16px 10px;
  margin: 4px 8px 0 0;
  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brandLight};
  cursor: pointer;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 40px;
    padding-right: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.brandLight};
    color: ${({ theme }) => theme.colors.brand};

    ${StyledCloseButton} {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export const CategoryName = styled.p`
  font-size: 16px;
  font-weight: 700;
  padding-right: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 18px;
  }
`

export const MainContainer = styled.div<{ permanentCheckoutOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${breakpoints.wideDesktop}px) {
    width: ${({ permanentCheckoutOpen }) =>
      permanentCheckoutOpen ? 'calc(100vw - 540px)' : '100%'};
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    width: ${({ permanentCheckoutOpen }) =>
      permanentCheckoutOpen ? 'calc(100vw - 555px)' : '100%'};
  }
`

export const NonIdealCategories = styled.div`
  font-size: 16px;
  margin: 8px 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 24px;
  }
`
