import styled from 'styled-components'

import { Button } from '@src/components/Button'
import { breakpoints } from '@src/constants/breakpoints'

export const ButtonContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px 5px 10px;
  background-color: white;
  margin-bottom: 10px;
  box-sizing: border-box;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    margin-bottom: 0;
  }
`

export const StyledCheckoutButton = styled(Button)`
  border-radius: 28px;
  font-size: 16px;
  height: 56px;
`

export const SpaceBetweenContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
`
