import { v4 as uuidV4 } from 'uuid'

import { clearVoucher } from '@src/hooks/useDiscounts/clearVoucher'

import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'
import { BasketItem } from '../validation'

// adds multiple items to the basket
// does not check for duplicates
export const addItems =
  ({ existingItems, basketOutletId }: HookMethodArgs) =>
  ({
    items,
    removeExistingItems = false,
  }: {
    items: Omit<BasketItem, 'id' | 'availability'>[]
    removeExistingItems?: boolean
  }): void => {
    const outletIds = [
      ...new Set(items.map(item => item.outletMenuItemId.split(':')[0])),
    ]

    if (outletIds.length > 1) {
      throw new Error('Cannot add items from multiple outlets.')
    }

    if (basketOutletId !== outletIds[0] && !removeExistingItems) {
      throw new Error(
        'Cannot add items from a different outlet without first removing existing items'
      )
    }

    if (removeExistingItems) {
      clearVoucher()
    }

    const itemsWithIds = items.map(item => ({
      ...item,
      id: uuidV4(),
      availability: {},
    }))

    const existingItemsToKeep = removeExistingItems ? [] : existingItems
    basketItemsVar([...existingItemsToKeep, ...itemsWithIds])
  }
