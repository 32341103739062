import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Header2 = styled.p`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin: 0px 0px 14px 0px;
  padding: 0px;
  color: #ababab;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 34px;
    line-height: 48px;
  }
  @media (min-width: ${breakpoints.wideDesktop}px) {
    line-height: 56px;
  }
  @media (min-width: ${breakpoints.superWideDesktop}px) {
    line-height: 72px;
    margin-bottom: 30px;
  }
`

export const removeScrollbar = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
