import { isBefore, addDays, startOfDay, isEqual, isAfter } from 'date-fns'
import { orderBy } from 'lodash'

import { FulfilmentFilterData } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { MarketplaceCnameLookupFragment_categories } from '@src/hooks/useMarketplace/queries/__generated__/MarketplaceCnameLookupFragment'
import { outletList_outlets } from '@src/pages/OutletList/queries/__generated__/outletList'

import { DateifyOutlet } from './fulfilmentTimes/parsers'

import { Outlet, NarrowFulfilmentMethodInputType } from '../graphql-types'

const preorderAvailable = (outlet: Outlet) =>
  outlet.allowPreorders &&
  (!outlet.closedUntil ||
    (outlet.closedUntil &&
      isBefore(
        new Date(outlet.closedUntil),
        addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMax)
      ))) &&
  // check now + daysofferedinadvance >= next opening time
  (outlet.isOpen ||
    (!outlet.isOpen &&
      (isEqual(
        addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMin),
        startOfDay(new Date(outlet.nextOpenDate))
      ) ||
        isAfter(
          addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMin),
          startOfDay(new Date(outlet.nextOpenDate))
        ))) ||
    isEqual(
      addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMax),
      startOfDay(new Date(outlet.nextOpenDate))
    ) ||
    isAfter(
      addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMax),
      startOfDay(new Date(outlet.nextOpenDate))
    ))

export const sortOutlets = (
  outlets: DateifyOutlet<outletList_outlets>[],
  fulfilmentFilterData: FulfilmentFilterData,
  selectedCategories?: MarketplaceCnameLookupFragment_categories[]
): DateifyOutlet<outletList_outlets>[] => {
  const sort: {
    iteratees: (string | ((outlet: Outlet) => any))[]
    orders: ('desc' | 'asc')[]
  } = {
    iteratees: [
      'isOnline',
      'isOpen',
      (outlet: Outlet) =>
        outlet.isOnline &&
        (outlet.isOpen || preorderAvailable(outlet)) &&
        !!outlet.restaurant.promoted,
      (outlet: Outlet) => outlet.isOnline && preorderAvailable(outlet),
    ],
    orders: ['desc', 'desc', 'desc', 'desc'],
  }

  if (
    fulfilmentFilterData.priorityFulfilmentMethod ===
      NarrowFulfilmentMethodInputType.COLLECTION ||
    fulfilmentFilterData.priorityFulfilmentMethod ===
      NarrowFulfilmentMethodInputType.TABLE
  ) {
    sort.iteratees.push('distanceFromUserKM')
    sort.orders.push('asc')
  }

  const sortedOutlets = orderBy(
    fulfilmentFilterData.priorityFulfilmentMethod ===
      NarrowFulfilmentMethodInputType.DELIVERY
      ? (outlets as any[]) // actual workaround solution from similar lodash git issue 25758
      : outlets,
    sort.iteratees || [],
    sort.orders || []
  )

  const filteredOutlets =
    selectedCategories && selectedCategories.length
      ? sortedOutlets.sort((a, b) => {
          // sort the outlets based on whichever ones have the most cuisine matches to the selected categories
          const cuisinesA = a.restaurant.cuisines
            .map(({ name }: { name: string }) => name)
            .filter((cuisine: string) =>
              selectedCategories.some(({ name }) => name === cuisine)
            )
          const cuisinesB = b.restaurant.cuisines
            .map(({ name }: { name: string }) => name)
            .filter((cuisine: string) =>
              selectedCategories.some(({ name }) => name === cuisine)
            )

          return cuisinesB.length - cuisinesA.length
        })
      : sortedOutlets

  return filteredOutlets
}
