import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

import { CurrentFulfilmentType } from '../validation'

export const CURRENT_FULFILMENT_TO_NARROW_FULFILMENT: {
  [K in CurrentFulfilmentType]: NarrowFulfilmentMethodInputType
} = {
  [CurrentFulfilmentType.DELIVERY_EVERYWHERE]:
    NarrowFulfilmentMethodInputType.DELIVERY,
  [CurrentFulfilmentType.DELIVERY_POSTCODE]:
    NarrowFulfilmentMethodInputType.DELIVERY,
  [CurrentFulfilmentType.DELIVERY_COORDINATES]:
    NarrowFulfilmentMethodInputType.DELIVERY,
  [CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS]:
    NarrowFulfilmentMethodInputType.DELIVERY,
  [CurrentFulfilmentType.DELIVERY_ZONE]:
    NarrowFulfilmentMethodInputType.DELIVERY,
  [CurrentFulfilmentType.COLLECTION]:
    NarrowFulfilmentMethodInputType.COLLECTION,
  [CurrentFulfilmentType.TABLE]: NarrowFulfilmentMethodInputType.TABLE,
  [CurrentFulfilmentType.TABLE_UNSELECTED]:
    NarrowFulfilmentMethodInputType.TABLE,
}
