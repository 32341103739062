import { makeVar } from '@apollo/client'

import { proxyReactiveVar } from '@src/utils/proxyReactiveVar'

import {
  persistBasketOutlet,
  persistBasketOutletId,
  persistCurrentOutlet,
  retrieveBasketOutletId,
  retrieveOutletFulfilment,
} from './persistence'

import { NonBasketOutletFulfilments } from '../types/types'

// IMPORTANT: These variable should not be updated directly,
// but instead updated via the useCurrentOutletFulfilment / useBasket / useBasketOutletId hook.
// The _can_ be consumed directly if you do not need your component / hook to react to the changes
// such as in an onClick event handler

// This state describes the options chosen by the customer for where and when they want to order
// contains the selections for both the outlet being viewed and the outlet in the basket (which can be the same or different)
export const outletFulfilmentReactiveVar = proxyReactiveVar<
  NonBasketOutletFulfilments | null,
  NonBasketOutletFulfilments
>({
  reactiveVar: makeVar<NonBasketOutletFulfilments | null>(
    retrieveOutletFulfilment()
  ),
  onWriteSideEffect: updateData => {
    const basketOutletId = basketOutletIdReactiveVar()
    for (const outlet of Object.values(updateData.outlets)) {
      if (outlet.outletId === basketOutletId) {
        persistBasketOutlet(outlet)
      } else {
        persistCurrentOutlet(outlet)
      }
    }
  },
})

export const basketOutletIdReactiveVar = proxyReactiveVar({
  reactiveVar: makeVar<string | null>(retrieveBasketOutletId()),
  onWriteSideEffect: persistBasketOutletId,
})
