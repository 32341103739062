import React from 'react'

export const CaretUp: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'caret-down', id, className, desc }) => (
  <svg
    id={id}
    className={className}
    width="28"
    height="32"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title className="iconTitle">{title}</title>
    <circle cx="16" cy="16" r="16" />
    {desc && <desc className="iconDescription">{desc}</desc>}
    <path
      d="M11.41 19L16 14.6734L20.59 19L22 17.668L16 12L10 17.668L11.41 19Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
)
