export const dirtyBasket = {
  items_not_available_for_delivery:
    'Your basket contains items not available to order for delivery',
  items_not_available_for_collection:
    'Your basket contains items not available to order for collection',
  items_not_available_for_table:
    'Your basket contains items not available to order to table',
  options_not_available: 'Some options in your basket are no longer available',
  items_not_available: 'Some items in your basket are no longer available',
  items_and_options_not_available:
    'Some items and options in your basket are no longer available',
  remove_unavailable_items: 'Remove Unavailable Items',
  remove_unavailable_options: 'Remove Unavailable Options',
  remove_unavailable_options_and_items: 'Remove Unavailable Options and Items',
  switch_back_to_fulfilment: 'Switch Back To {{fulfilment}}',
  card_payment_failed: 'Card payment failed',
  check_card_fields:
    'Could not add payment card, please check your card details and try again.',
  view_outlet_list: 'View Available Outlets',
  view_outlet_menu: 'Back To {{restaurantName}} Menu',
  outlet_closed: 'This outlet is now closed.',
}
