import { mapValues } from 'lodash'

import { apolloClient } from '@src/apolloClient'
import {
  basketOutletIdReactiveVar,
  outletFulfilmentReactiveVar,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/reactiveVars'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { basketItemsVar } from '@src/hooks/useBasketItems/utils/reactiveVar'
import { jwtVar } from '@src/models/customer/jwt'

import { resetFulfilmentFilter } from '../hooks/useFulfilmentFilter/utils/reactiveVar'

export const logout = async (): Promise<void> => {
  const outletFulfilment = outletFulfilmentReactiveVar()

  // first clear all reactive vars
  jwtVar(null)
  basketItemsVar([])
  basketOutletIdReactiveVar(null)

  // remove any saved addresses
  const outlets = mapValues(outletFulfilment.outlets, outletFulfilment => {
    if (
      outletFulfilment.currentFulfilment.type ===
      CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS
    ) {
      return {
        ...outletFulfilment,
        currentFulfilment: {
          type: CurrentFulfilmentType.DELIVERY_EVERYWHERE,
          deliveryPreorderWindow: null,
        } as const,
        historicalData: { ...outletFulfilment.historicalData, addressId: null },
      }
    }

    return {
      ...outletFulfilment,
      historicalData: { ...outletFulfilment.historicalData, addressId: null },
    }
  })

  outletFulfilmentReactiveVar({
    outlets,
    unsavedState: null,
  })

  resetFulfilmentFilter()

  // wipe the storage
  localStorage.clear()
  sessionStorage.clear()
  await apolloClient.clearStore()

  // and refetch all queries
  await apolloClient.refetchQueries({
    include: 'active',
  })
}
