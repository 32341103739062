import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonType } from '@src/components/Button'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'

import {
  ButtonContainer,
  Container,
  StyledButton,
  Title,
  Underlay,
  SubTitle,
} from './MenuItemModalAlertStyles'

// TODO: This should probably just be swapped out for a generic alert modal
export const BasketClearRequiredAlert: React.FC<{
  onCancel: () => void
  onSubmit: () => void
}> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation('menuItem')
  const basketItems = useBasketItems()

  const handleSubmit = () => {
    basketItems.clear({ shouldResetCheckoutRouter: false })
    onSubmit()
  }

  return (
    <>
      <Underlay onClick={onCancel} />
      <Container>
        <Title>{t('new_order_title')}</Title>
        <SubTitle>{t('new_order_description')}</SubTitle>
        <ButtonContainer>
          <StyledButton
            buttonType={ButtonType.PRIMARY}
            content={t('new_order_confirm')}
            onClick={handleSubmit}
          />
          <StyledButton
            buttonType={ButtonType.SECONDARY}
            content={t('cancel')}
            onClick={onCancel}
          />
        </ButtonContainer>
      </Container>
    </>
  )
}
