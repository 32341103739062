import { useReactiveVar } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'

import { ExtendedNonBasketOutletFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import { useLazyOutletAvailabilityQuery } from '@src/hooks/sharedQueries/outletAvailabilityQuery/useOutletAvailabilityQuery'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'

import { BasketOutletContext } from './context/BasketOutletProvider'
import { CurrentOutletContext } from './context/CurrentOutletProvider'
import { extendData } from './extendData/extendData'
import { setASAP } from './hookMethods/setASAP'
import {
  SetCurrentFulfilment,
  setCurrentFulfilment,
} from './hookMethods/setCurrentFulfilment'
import {
  SetCurrentFulfilmentFromNarrowFulfilment,
  setCurrentFulfilmentFromNarrowFulfilment,
} from './hookMethods/setCurrentFulfilmentFromNarrowFulfilment'
import {
  UpdateFulfilmentTimesIfExpired,
  updateFulfilmentTimesIfExpired,
} from './hookMethods/updateFulfilmentTimesIfExpired'
import {
  updateHistoricalData,
  UpdateHistoricalData,
} from './hookMethods/updateHistoricalData'
import { HookMethodArgs } from './types/types'
import { getInitialOutletFulfilment } from './utils/getInitialOutletFulfilment'
import {
  basketOutletIdReactiveVar,
  outletFulfilmentReactiveVar,
} from './utils/reactiveVars'
import { updateOutletFulfilment } from './utils/updateOutletFulfilment'
import { CurrentFulfilmentType, NonBasketOutletFulfilment } from './validation'

import { outletFulfilment_outlet } from '../useOutletFulfilmentData/queries/__generated__/outletFulfilment'

export type UseOutletFulfilment = {
  data: ExtendedNonBasketOutletFulfilment
  outlet: DateifyOutlet<outletFulfilment_outlet>
  setCurrentFulfilment: SetCurrentFulfilment
  setCurrentFulfilmentFromNarrowFulfilment: SetCurrentFulfilmentFromNarrowFulfilment
  setASAP: () => void
  updateFulfilmentTimesIfExpired: UpdateFulfilmentTimesIfExpired
  updateHistoricalData: UpdateHistoricalData
  save: () => void
  reset: () => void
  reinitialise: () => void
}

export enum OutletFulfilmentStateType {
  GLOBAL = 'GLOBAL',
  LOCAL = 'LOCAL',
}

export type UseOutletFulfilmentArgs = {
  reinitialiseIfNotBasket?: boolean
  stateType: OutletFulfilmentStateType
}

// hook for consuming and updating the outlet's fulfilment values
// ie the time and location that the customer wants the
// current/prospective outlet to fulfil their order
// If it is called from a component within the BasketContextProvider, it will update the basket state
// if it is called from a component within the CurrentOutletContextProvider, it will update the current outlet state
// If somehow the contexts overlap, basket is prioritised
// Else, an Error is thrown
export const useOutletFulfilment = ({
  stateType,
  reinitialiseIfNotBasket = false,
}: UseOutletFulfilmentArgs): UseOutletFulfilment => {
  const currentOutlet = useContext(CurrentOutletContext)
  const basketOutlet = useContext(BasketOutletContext)
  const context = currentOutlet || basketOutlet
  if (!context) {
    throw new Error(
      'useOutletFulfilment must be called within a BasketOutletContextProvider or CurrentOutletContextProvider'
    )
  }
  const { outlet } = context

  const {
    savedData: {
      where: { historicalData: fulfilmentFilterHistoricalData },
    },
  } = useFulfilmentFilter()

  // tracks initialisation
  const [isInitialised, setIsInitialised] = useState(false)
  // get saved data for all outlets from reactive var
  const savedOutletFulfilments = useReactiveVar(outletFulfilmentReactiveVar)
  const basketOutletId = useReactiveVar(basketOutletIdReactiveVar)
  // get saved data for this outlet
  const savedOutletFulfilment = savedOutletFulfilments.outlets[outlet.id]

  // if the outlet is not the basket outlet, and reinitialiseIfNotBasket is true, initialise the outlet fulfilment, unless it is already initialised
  // or if there is no saved outlet fulfilment, initialise the outlet fulfilment
  const isInitialisationRequired =
    (reinitialiseIfNotBasket && !isInitialised && basketOutlet !== null) ||
    !savedOutletFulfilment
  // for the rest of the hook use the initialised or saved outlet fulfilment
  const outletFulfilment = isInitialisationRequired
    ? getInitialOutletFulfilment({ outlet })
    : savedOutletFulfilment

  // save the initialised outlet fulfilment to the reactive var
  useEffect(() => {
    if (isInitialisationRequired) {
      updateOutletFulfilment({
        outletFulfilmentData: outletFulfilment,
      })
      // prevent reinitialisation
      setIsInitialised(true)
    }
  }, [
    isInitialisationRequired,
    isInitialised,
    outletFulfilment,
    reinitialiseIfNotBasket,
    savedOutletFulfilment,
  ])
  const [outletAvailabilityQuery] = useLazyOutletAvailabilityQuery()

  // clone state to support deferred updates
  const { unsavedState } = savedOutletFulfilments

  const deferredState =
    unsavedState?.outletId === outlet.id ? unsavedState : outletFulfilment

  const setUnsavedData = (data: NonBasketOutletFulfilment) => {
    outletFulfilmentReactiveVar({
      ...savedOutletFulfilments,
      unsavedState: data,
    })
  }

  // create data and a function which is supplied to curried methods
  // so that they can update the saved / unsaved data, based on the deferredUpdates flag
  const hookMethodArgs: HookMethodArgs =
    stateType === OutletFulfilmentStateType.GLOBAL
      ? {
          existingData: extendData({
            data: outletFulfilment,
            fulfilmentFilterHistoricalData,
            basketOutletId,
            outlet,
          }),
          updateDataFn: (outletFulfilmentData: NonBasketOutletFulfilment) =>
            updateOutletFulfilment({
              outletFulfilmentData,
            }),
          outlet,
        }
      : {
          existingData: extendData({
            data: deferredState,
            fulfilmentFilterHistoricalData,
            basketOutletId,
            outlet,
          }),
          updateDataFn: setUnsavedData,
          outlet,
        }

  return {
    data: hookMethodArgs.existingData,
    outlet,
    setCurrentFulfilment: setCurrentFulfilment(hookMethodArgs),
    setCurrentFulfilmentFromNarrowFulfilment:
      setCurrentFulfilmentFromNarrowFulfilment(hookMethodArgs),
    setASAP: setASAP(hookMethodArgs),
    updateFulfilmentTimesIfExpired: updateFulfilmentTimesIfExpired({
      ...hookMethodArgs,
      outletAvailabilityQuery,
      // when slots expire we need to update both the saved and unsaved data
      updateDataFn: (data: NonBasketOutletFulfilment) => {
        outletFulfilmentReactiveVar({
          ...savedOutletFulfilments,
          outlets: {
            ...savedOutletFulfilments.outlets,
            [outlet.id]: data,
          },
          unsavedState: data,
        })
      },
    }),
    updateHistoricalData: updateHistoricalData(hookMethodArgs),
    save: () => {
      stateType === OutletFulfilmentStateType.LOCAL &&
        updateOutletFulfilment({
          outletFulfilmentData: deferredState,
        })
    },
    reset: () => {
      stateType === OutletFulfilmentStateType.LOCAL &&
        setUnsavedData(outletFulfilment)
    },
    reinitialise: () => {
      const initialOutletFulfilment = getInitialOutletFulfilment({ outlet })
      const currentFulfilment = hookMethodArgs.existingData.currentFulfilment
      const outletFulfilmentData: NonBasketOutletFulfilment =
        currentFulfilment.type === CurrentFulfilmentType.TABLE
          ? {
              ...initialOutletFulfilment,
              currentFulfilment: {
                ...initialOutletFulfilment.currentFulfilment,
                type: CurrentFulfilmentType.TABLE,
                tableId: currentFulfilment.tableId,
                tableNotes: null,
              },
              historicalData: {
                ...initialOutletFulfilment.historicalData,
                tableId: currentFulfilment.tableId,
                tableNotes: null,
              },
            }
          : initialOutletFulfilment

      updateOutletFulfilment({
        outletFulfilmentData,
      })
    },
  }
}
