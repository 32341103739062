import React from 'react'

import { SingleOutlet } from '../SingleOutlet/SingleOutlet'
import { SingleOutlet as SingleOutletType } from '../SingleOutlet/types'

export const OutletSlide: React.VFC<{
  outlet: Pick<
    SingleOutletType,
    | 'id'
    | 'displayName'
    | 'prepTime'
    | 'restaurant'
    | 'availableFulfilmentInputMethods'
    | 'deliveryMinimumOrderValue'
    | 'collectionMinimumOrderValue'
    | 'distanceFromUserKM'
    | 'coverImage'
    | 'statusText'
    | 'isOpen'
    | 'isOnline'
    | 'nextOpenDate'
    | 'openingTimesArray'
    | 'asapAllowed'
    | 'allowPreorders'
    | 'collectionPreorderTimes'
    | 'ASAPDeliveryDuration'
    | 'outletLogoOverride'
    | 'fulfilmentRange'
  > | null
  showFeaturedFlags?: boolean
  showDiscountLabels?: boolean
}> = ({ outlet, showFeaturedFlags, showDiscountLabels }) => {
  if (!outlet) return null

  return (
    <SingleOutlet
      outlet={outlet}
      showFeaturedFlags={showFeaturedFlags}
      showDiscountLabels={showDiscountLabels}
      isSlide
    />
  )
}
