export const helpAndSupport = {
  help_and_support: 'Help & Support',
  help: 'Help',
  check_out_our: 'Check out our',
  section_or_contact: 'section or contact',
  customer_support: 'Customer Support',
  support: 'Support',
  faqs: 'FAQs',
  get_in_touch: 'Get In Touch',
  enquiries_contact: 'If you have any enquiries please contact us via email:',
}
