export const breakpoints = {
  mobile: 375,
  phablet: 500,
  tablet: 744,
  tabletMisc: 600,
  largeTabletMisc: 860,
  largeTablet: 1024,
  desktop: 1280,
  wideDesktop: 1728,
  superWideDesktop: 2338,
} as const
