import { z } from 'zod'

import i18n from '@src/i18n/i18n'

const t = (key: string) => i18n.t(key, { ns: 'resetPassword' })

export const passwordResetSchema = z.object({
  email: z
    .string({
      required_error: t('email.validation.required_error'),
    })
    .email({ message: t('email.validation.invalid_email_address') }),
})

export const enterNewPasswordSchema = z
  .object({
    password: z
      .string({ required_error: t('password_reset_form.required_error') })
      .min(8, {
        message: t('password_reset_form.password_length'),
      }),
    confirmPassword: z
      .string({
        required_error: t('password_reset_form.repeat_password_error'),
      })
      .min(8, {
        message: t('password_reset_form.password_length'),
      }),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('password_reset_form.password_match_error'),
        path: ['confirmPassword'],
      })
    }
  })

export type ResetPassword = z.infer<typeof passwordResetSchema>
