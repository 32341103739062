import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  EmptyBasketContainer,
  EmptyBasketOuterContainer,
} from './EmptyBasket.styles'

export const EmptyBasket: React.FC = () => {
  const { t } = useTranslation('checkout')
  return (
    <EmptyBasketOuterContainer>
      <EmptyBasketContainer>
        <p>{t('empty_basket')}</p>
      </EmptyBasketContainer>
    </EmptyBasketOuterContainer>
  )
}
