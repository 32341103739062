import { z } from 'zod'

// Shamelessly stolen from `https://github.com/robertLichtnow/zod-formik-adapter/blob/master/index.ts`
export const mapZodToFormikErrors = <T>(zodError: z.ZodError<T>) => {
  const result: Record<string, string> = {}

  for (const error of zodError.errors) {
    result[error.path.filter(Boolean).join('.')] = error.message
  }

  return result
}
