import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'

import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { jwtVar } from '@src/models/customer/jwt'

// Check the users JWT status if they are not logged in redirect them to the /login route.
export const useNotLoggedInRedirect = (): void => {
  const jwt = useReactiveVar(jwtVar)
  const { setRoute } = useAccountRouter()

  useEffect(() => {
    if (jwt === null) {
      setRoute({ mainRouteName: MainRouteName.LOGIN })
    }
  }, [jwt, setRoute])
}
