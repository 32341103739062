import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { OrderProgress } from './OrderProgress'
import {
  StatusBoxContainer,
  StatusBoxPlaced,
  StatusBoxText,
  StatusBoxUpdate,
  FailedHeader,
} from './StatusBox.styles'

export const CancelledStatusBox: React.VFC<{
  updatedAt: string
  restaurantName: string
  isAccountPage: boolean
}> = ({ restaurantName, isAccountPage, updatedAt }) => {
  const { t } = useTranslation('singleOrder')

  const orderUpdatedAt = formatDistanceToNow(new Date(updatedAt), {
    addSuffix: true,
  })

  return (
    <StatusBoxContainer isAccountPage={isAccountPage}>
      <StatusBoxPlaced>
        {format(new Date(updatedAt), 'dd MMM yyyy')}
      </StatusBoxPlaced>
      <StatusBoxUpdate>
        {t('updated')} {orderUpdatedAt}
      </StatusBoxUpdate>
      <FailedHeader isAccountPage={isAccountPage}>
        {t('cancelled')}
      </FailedHeader>

      <StatusBoxText>
        {t('order_marked_cancelled', { restaurantName })}
      </StatusBoxText>
      <OrderProgress valueNow={4} isOrderCancelledOrRejected={true} />
    </StatusBoxContainer>
  )
}
