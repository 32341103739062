import { Listbox } from '@headlessui/react'
import { useField } from 'formik'
import React, { useState } from 'react'

import {
  Value,
  StyledCaretDownSVG,
  StyledListBoxButton,
  StyledListBoxLabel,
  Required,
  StyledListBoxOptions,
  StyledListBoxOption,
} from './SelectInput.styles'

import { InputWrapper } from '../InputWrapper'

type Option = { value: string; name: string; disabled?: boolean }
function defaultOnChange() {
  // Empty function body
}
export const SelectInput: React.VFC<{
  label?: string
  name: string
  options: Option[]
  onChange?: (data: Option) => void
  required?: boolean
  selectedOptionValue?: string
}> = ({
  label,
  name,
  options,
  required = false,
  onChange = defaultOnChange,
  selectedOptionValue,
}) => {
  const [field, meta, helpers] = useField<string>({
    name,
  })
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options.find(option =>
      selectedOptionValue
        ? option.value === selectedOptionValue
        : option.value === field.value
    ) ?? null
  )
  return (
    <InputWrapper meta={meta}>
      <Listbox
        value={selectedOption}
        onChange={option => {
          setSelectedOption(option)
          helpers.setValue(option?.value ?? '')
          option && onChange(option)
        }}
      >
        {({ open }) => (
          <>
            <StyledListBoxButton
              open={open}
              $errorBorder={meta.touched && !!meta.error}
            >
              {label && (
                <>
                  <StyledListBoxLabel $isPlaceHolder={!selectedOption}>
                    {label}
                    {required && <Required>*</Required>}
                  </StyledListBoxLabel>
                </>
              )}
              <Value visible={!!selectedOption} hasLabel={!!label}>
                {selectedOption?.name}
              </Value>
              <StyledCaretDownSVG
                id={`${name}-select-input-arrow`}
                open={open}
              />
            </StyledListBoxButton>

            <StyledListBoxOptions>
              {options.map(option => (
                <StyledListBoxOption
                  key={option.value}
                  value={option}
                  disabled={option.disabled}
                >
                  {option.name}
                </StyledListBoxOption>
              ))}
            </StyledListBoxOptions>
          </>
        )}
      </Listbox>
    </InputWrapper>
  )
}
