import { useQuery } from '@apollo/client'
import { shuffle } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '@src/components/Carousel'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { OutletSlide } from '@src/components/OutletSlide'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { getOutletSearchVariables } from '@src/pages/OutletList/getOutletSearchVariables'
import { outletList_outlets } from '@src/pages/OutletList/queries/__generated__/outletList'
import { outletListDocument } from '@src/pages/OutletList/queries/__generated__/outletList.graphql-interface'
import {
  DateifyOutlet,
  parseOutletDates,
} from '@src/utils/fulfilmentTimes/parsers'

import {
  FeaturedOutletsContainer,
  HeroContainer,
  OutletContainer,
  SliderArrowsContainer,
  HeaderLink,
} from './FeaturedOutlets.styles'
import { FeaturedOutletsSkeleton } from './FeaturedOutletsSkeleton'

import { IconButton } from '../Button/IconButton'

type Props = {
  title: string
  onboardingPage?: boolean
}

const NUMBER_OF_OUTLETS_TO_SHOW = 7

export const FeaturedOutlets: React.FC<Props> = ({
  title,
  onboardingPage = false,
}) => {
  const marketplace = useMarketplace()
  const fulfilmentFilter = useFulfilmentFilter()

  const variables = getOutletSearchVariables({
    fulfilmentFilter: fulfilmentFilter.data,
    marketplaceId: marketplace.id,
    featured: true,
  })

  const { data, loading, error } = useQuery(
    outletListDocument,
    variables ? { variables } : { skip: true }
  )

  const [shuffledOutlets, setShuffledOutlets] = React.useState<
    DateifyOutlet<outletList_outlets>[]
  >([])

  useEffect(() => {
    if (data?.outlets) {
      setShuffledOutlets(
        shuffle(data?.outlets.map(parseOutletDates)).slice(
          0,
          NUMBER_OF_OUTLETS_TO_SHOW
        )
      )
    }
  }, [data])

  const { t } = useTranslation(['navigation'])
  if (loading) {
    return <FeaturedOutletsSkeleton onboardingPage={onboardingPage} />
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data) {
    return <ErrorPage />
  }
  return (
    <>
      {shuffledOutlets.length > 0 && (
        <OutletContainer>
          <HeroContainer isOnboardingPage={onboardingPage}>
            <HeaderLink to="/featured">
              <h2>{title}</h2>
            </HeaderLink>
            <SliderArrowsContainer>
              <IconButton
                className="swiper-swipe-left"
                size={32}
                icon={'caretLeft'}
              />
              <IconButton
                className="swiper-swipe-right"
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </SliderArrowsContainer>
          </HeroContainer>
          <FeaturedOutletsContainer isOnboardingPage={onboardingPage}>
            <Carousel
              slides={shuffledOutlets.map(featuredOutlet => (
                <OutletSlide key={featuredOutlet.id} outlet={featuredOutlet} />
              ))}
            />
          </FeaturedOutletsContainer>
        </OutletContainer>
      )}
    </>
  )
}
