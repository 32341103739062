import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledAccountSectionNavButton } from '@src/components/BurgerMenu/AccountMain/AccountMain.styles'
import { HelpChildName, MainRouteName } from '@src/hooks/useAccountRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { LinkText, StyledAContainer } from './DefaultLinks.styles'

export const DefaultLinks: React.FC = () => {
  const { t } = useTranslation('navigationLinks')

  const {
    allowOnboarding,
    legalAllergy,
    legalPolicy,
    legalTerms,
    legalPrivacy,
    legalCookies,
    headerLinks,
  } = useMarketplace()

  return (
    <>
      {legalTerms && (
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.TERMS }}
        >
          <LinkText>{t('terms_and_conditions')}</LinkText>
        </StyledAccountSectionNavButton>
      )}

      {legalPrivacy && (
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.PRIVACY }}
        >
          <LinkText>{t('privacy_policy')}</LinkText>
        </StyledAccountSectionNavButton>
      )}

      {legalAllergy && (
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.ALLERGY }}
        >
          <LinkText>{t('allergy_policy')}</LinkText>
        </StyledAccountSectionNavButton>
      )}

      <StyledAccountSectionNavButton
        route={{
          mainRouteName: MainRouteName.HELP,
          childRouteName: HelpChildName.SUPPORT,
        }}
      >
        <LinkText>{t('help_and_support')}</LinkText>
      </StyledAccountSectionNavButton>

      {legalCookies && (
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.COOKIES }}
        >
          <LinkText>{t('cookies')}</LinkText>
        </StyledAccountSectionNavButton>
      )}

      {legalPolicy && (
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.MISC }}
        >
          <LinkText>{t('misc_policies')}</LinkText>
        </StyledAccountSectionNavButton>
      )}

      {allowOnboarding && (
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.ADD_BUSINESS }}
        >
          <LinkText>{t('add_your_business')}</LinkText>
        </StyledAccountSectionNavButton>
      )}

      {headerLinks.map(({ title, link }) => (
        <StyledAContainer key={link} href={link} target="_blank">
          <LinkText>{title}</LinkText>
        </StyledAContainer>
      ))}
    </>
  )
}
