import { Formik } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { mapZodToFormikErrors } from '@src/utils/mapZodToFormikErrors'

import { CollectionFulfilmentForm } from './CollectionFulfilmentForm'
import { useCollectionFulfilmentFormikProps } from './useCollectionFulfilmentFormikProps/useCollectionFulfilmentFormikProps'

export const CollectionFulfilmentStep: React.FC = () => {
  const formikProps = useCollectionFulfilmentFormikProps()
  const validate = useCallback(
    async values => {
      const parsed = await formikProps.zodValidationSchema.safeParseAsync(
        values
      )
      if (!parsed.success) {
        return mapZodToFormikErrors(parsed.error)
      }
    },
    [formikProps.zodValidationSchema]
  )

  const memoizedInitialValues = useMemo(() => formikProps.initialValues, [])

  return (
    <Formik
      enableReinitialize={false}
      initialValues={memoizedInitialValues}
      onSubmit={formikProps.handleSubmit}
      validate={validate}
      component={CollectionFulfilmentForm}
    />
  )
}
