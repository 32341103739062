import React from 'react'
import { useTranslation } from 'react-i18next'

import { useMarketplace } from '@src/hooks/useMarketplace'

import { Container, Header1, HeroSubText } from './Hero.styles'

import { LocationInput } from '../LocationInput/LocationInput'

export const Hero: React.VFC<{
  heroText: string
  heroTextSecondary: string
}> = ({ heroText, heroTextSecondary }) => {
  const { featureOrdering } = useMarketplace()
  const { t } = useTranslation('homePage')
  return (
    <Container>
      <Header1>{heroText}</Header1>
      <HeroSubText>{heroTextSecondary}</HeroSubText>
      {featureOrdering ? (
        <LocationInput />
      ) : (
        <HeroSubText>{t('coming_soon')}</HeroSubText>
      )}
    </Container>
  )
}
