import { useLazyQuery, useQuery } from '@apollo/client'

import { Outlet } from '@src/graphql-types'

import { OutletTablesDocument } from './queries/__generated__/tables.graphql-interface'

export const useTablesQuery = ({ outletId }: { outletId: Outlet['id'] }) => {
  return useQuery(OutletTablesDocument, {
    variables: {
      outletId,
    },
  })
}

export const useLazyTablesQuery = ({
  outletId,
}: {
  outletId: Outlet['id']
}) => {
  return useLazyQuery(OutletTablesDocument, {
    variables: {
      outletId,
    },
  })
}
