import { FieldMetaProps, useField } from 'formik'
import React from 'react'

import {
  HiddenInput,
  Label,
  CheckboxIcon,
  StyledCheckbox,
  RadioIcon,
  StyledRadio,
  TickIcon,
  TickStyledCheckbox,
  StyledCrossSVG,
  DescriptionSpan,
  SpinnerSVGStyled,
} from './CheckboxInput.styles'

import { InputWrapper } from '../InputWrapper'

enum CheckboxInputType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum CheckboxInputStyle {
  DEFAULT,
  TICK,
  ADD,
}

export const CheckboxInput: React.FC<{
  label?: string
  labelOnLeft?: boolean
  name: string
  style?: CheckboxInputStyle
  type?: CheckboxInputType
  autoComplete?: string
  last?: boolean
  disabled?: boolean
  description?: string
  afterChange?: React.ChangeEventHandler<HTMLInputElement>
  showMetaError?: boolean
}> = ({
  label,
  name,
  type = CheckboxInputType.CHECKBOX,
  style = CheckboxInputStyle.DEFAULT,
  labelOnLeft = false,
  autoComplete,
  last = false,
  disabled = false,
  description,
  afterChange,
  showMetaError = true,
}) => {
  const [field, meta] = useField({
    type: 'checkbox',
    name,
  })
  return (
    <InputWrapper
      meta={meta}
      last={last}
      disabled={disabled}
      showMetaError={showMetaError}
    >
      <HiddenInput
        type="checkbox"
        id={name}
        autoComplete={autoComplete}
        disabled={disabled}
        {...field}
        onChange={e => {
          field.onChange(e)
          afterChange?.(e)
        }}
      />
      <Label
        labelOnLeft={labelOnLeft}
        htmlFor={name}
        disabled={disabled}
        bold={!!description}
      >
        {type === CheckboxInputType.CHECKBOX &&
          (style === CheckboxInputStyle.TICK ? (
            <TickStyledCheckbox isChecked={!!field.value} disabled={disabled}>
              <TickIcon id="tick-icon" />
            </TickStyledCheckbox>
          ) : (
            <StyledCheckbox isChecked={!!field.value} disabled={disabled}>
              <CheckboxIcon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </CheckboxIcon>
            </StyledCheckbox>
          ))}

        {type === CheckboxInputType.RADIO &&
          (style === CheckboxInputStyle.TICK ? (
            <TickIcon id="tickIcon" />
          ) : (
            <StyledRadio isChecked={!!field.value} disabled={disabled}>
              <RadioIcon viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50" />
              </RadioIcon>
            </StyledRadio>
          ))}
        <div>
          {label}
          {description && <DescriptionSpan>{description}</DescriptionSpan>}
        </div>
      </Label>
    </InputWrapper>
  )
}

export const UncontrolledCheckboxInput: React.FC<{
  label?: React.ReactNode
  labelOnLeft?: boolean
  name: string
  type?: CheckboxInputType
  style?: CheckboxInputStyle
  autoComplete?: string
  last?: boolean
  disabled?: boolean
  checked?: boolean
  className?: string
  meta?: Pick<FieldMetaProps<any>, 'touched' | 'error'>
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}> = ({
  label,
  name,
  type = CheckboxInputType.CHECKBOX,
  style = CheckboxInputStyle.DEFAULT,
  labelOnLeft = false,
  autoComplete,
  last = false,
  disabled = false,
  meta = { touched: false },
  checked = false,
  onChange,
  onBlur,
  className,
}) => {
  return (
    <InputWrapper
      meta={meta}
      last={last}
      disabled={disabled}
      className={className}
    >
      <HiddenInput
        type="checkbox"
        id={name}
        autoComplete={autoComplete}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Label labelOnLeft={labelOnLeft} htmlFor={name} disabled={disabled}>
        {type === CheckboxInputType.CHECKBOX &&
          (style === CheckboxInputStyle.TICK ? (
            <TickStyledCheckbox isChecked={checked} disabled={disabled}>
              <TickIcon id="tick-icon" />
            </TickStyledCheckbox>
          ) : (
            <StyledCheckbox isChecked={checked} disabled={disabled}>
              <CheckboxIcon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </CheckboxIcon>
            </StyledCheckbox>
          ))}

        {type === CheckboxInputType.RADIO && (
          <StyledRadio isChecked={checked} disabled={disabled}>
            {style === CheckboxInputStyle.TICK ||
            style === CheckboxInputStyle.ADD ? (
              <>
                <TickIcon id="tickIcon" />
                {style === CheckboxInputStyle.ADD && (
                  <StyledCrossSVG id="add-icon" />
                )}
              </>
            ) : (
              <RadioIcon viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50" />
              </RadioIcon>
            )}
          </StyledRadio>
        )}
        {label}
      </Label>
    </InputWrapper>
  )
}

export const RadioInput: React.FC<{
  label: string
  name: string
  value: string
  last?: boolean
  disabled?: boolean
  style?: CheckboxInputStyle
  // use this field to disable showing errors
  showErrorMeta?: boolean
}> = ({
  label,
  name,
  value,
  last = false,
  disabled = false,
  style = CheckboxInputStyle.DEFAULT,
  showErrorMeta = true,
}) => {
  const [field, meta, helpers] = useField({
    type: 'radio',
    name,
    value,
  })
  return (
    <UncontrolledRadioInput
      label={label}
      value={value}
      last={last}
      disabled={disabled}
      meta={meta}
      checked={field.checked}
      style={style}
      onChange={() => {
        helpers.setValue(value)
        helpers.setTouched(true)
      }}
      onBlur={() => {
        helpers.setTouched(true)
      }}
      showErrorMeta={showErrorMeta}
    />
  )
}

export const UncontrolledRadioInput: React.FC<{
  label: React.ReactNode
  value?: string
  checked?: boolean
  last?: boolean
  disabled?: boolean
  meta?: Pick<FieldMetaProps<any>, 'touched' | 'error'>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  className?: string
  style?: CheckboxInputStyle
  showErrorMeta?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLLabelElement>
  loading?: boolean
}> = ({
  label,
  value,
  checked = false,
  last = false,
  disabled = false,
  meta = { touched: false },
  onChange = () => undefined,
  onBlur,
  className,
  style = CheckboxInputStyle.DEFAULT,
  showErrorMeta = true,
  onClick,
  loading,
}) => {
  // TODO: Accessibility - the radio button should be
  //focusable with the keyboard but it is a div with
  // an onClick handler. This should be changed to a button
  return (
    <InputWrapper
      meta={meta}
      last={last}
      disabled={disabled}
      className={className}
      showMetaError={showErrorMeta}
    >
      <HiddenInput
        type="radio"
        id={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Label
        htmlFor={value}
        disabled={disabled}
        onClick={e => {
          if (!disabled && onClick) {
            onClick(e)
          }
        }}
      >
        {loading ? (
          <SpinnerSVGStyled id="loadingSVG" width={24} height={24} />
        ) : (
          <StyledRadio
            isChecked={!!checked}
            disabled={disabled}
            onClick={e => {
              if (!disabled && onClick) {
                onClick(e)
              }
            }}
          >
            {style === CheckboxInputStyle.TICK ||
            style === CheckboxInputStyle.ADD ? (
              <>
                <TickIcon id="tickIcon" />
                {style === CheckboxInputStyle.ADD && (
                  <StyledCrossSVG id="add-icon" />
                )}
              </>
            ) : (
              <RadioIcon viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50" />
              </RadioIcon>
            )}
          </StyledRadio>
        )}

        {label}
      </Label>
    </InputWrapper>
  )
}
