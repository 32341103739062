import React from 'react'

import { SectionHeaderContent } from '@src/components/ServicePopover/ServicePopover.styles'

export const SectionHeader = ({
  header,
  subheader,
}: {
  header: string
  subheader: string
}) => (
  <SectionHeaderContent>
    <h3>{header}</h3>
    <h4>{subheader}</h4>
  </SectionHeaderContent>
)
