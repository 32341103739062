import { addDays, max, startOfDay, startOfMinute } from 'date-fns'

import { OutletFulfilmentContextData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/types'

import { getAsapFulfilmentTime } from './getASAPTimes'

// earliest possible collection time / start of delivery window
export const getEarliestFulfilmentTime = ({
  prepTime,
  openingTimesArray,
  daysOfferedInAdvanceMin,
  closedUntil,
}: Pick<
  OutletFulfilmentContextData,
  'prepTime' | 'openingTimesArray' | 'daysOfferedInAdvanceMin' | 'closedUntil'
>): Date => {
  // calculates the asap time for the current day (using now if the outlet is closed)
  const asapTime = getAsapFulfilmentTime({
    prepTime,
    openingTimes: openingTimesArray,
  })

  // calculate the earliest time for a future date (ie if daysOfferedInAdvanceMin is > 0, meaning it's only available for preordering)
  const earliestFutureDateTime =
    daysOfferedInAdvanceMin === 0 || !asapTime
      ? asapTime
      : startOfDay(addDays(new Date(), daysOfferedInAdvanceMin))

  // return whichever is latest between the asapTime, earliestFutureDateTime, and closedUntil (if it exists)
  const possibleDates = [
    asapTime,
    earliestFutureDateTime,
    closedUntil,
    new Date(),
  ].filter((dateOrNull): dateOrNull is Date => dateOrNull !== null)
  return startOfMinute(max(possibleDates))
}
