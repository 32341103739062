import React from 'react'

import {
  StatusBoxProgressCont,
  StatusBoxProgressBarEmpty,
  LoadingBarContainer,
  LoadingBarFill,
  StatusBoxProgressBarCompleted,
} from '../SingleOrder.styles'

const TOTAL_SECTIONS = 4

const LoaderBar = () => (
  <LoadingBarContainer>
    <LoadingBarFill />
  </LoadingBarContainer>
)

export const OrderProgress: React.VFC<{
  valueNow: number
  isOrderCancelledOrRejected?: boolean
}> = ({ valueNow, isOrderCancelledOrRejected = false }) => {
  const progressSections = Array.from(
    { length: TOTAL_SECTIONS },
    (_, index) => {
      if (
        // do not show loader animation when order is complete or stage is complete
        index + 1 < valueNow ||
        (index + 1 === valueNow && index + 1 === TOTAL_SECTIONS)
      ) {
        return (
          <StatusBoxProgressBarCompleted
            key={index}
            isDanger={isOrderCancelledOrRejected}
          />
        )
      }
      if (index + 1 === valueNow) {
        return <LoaderBar key={index} />
      } else {
        return <StatusBoxProgressBarEmpty key={index} />
      }
    }
  )

  return (
    <StatusBoxProgressCont
      role="progressbar"
      aria-valuemax={TOTAL_SECTIONS}
      aria-valuenow={valueNow}
      aria-label="Order Progress"
    >
      {progressSections}
    </StatusBoxProgressCont>
  )
}
