import { Form } from 'formik'
import styled from 'styled-components'

import { Button } from '@src/components/Button'
import { CancelSVG } from '@src/components/SVGS/CancelSVG'

export const Container = styled.div`
  padding: 0px;
`

export const ApplyButton = styled(Button)`
  max-width: 104px;
  font-size: 14px;
  height: 56px;
  margin-left: 16px;
`

export const Title = styled.p`
  color: #2e3333;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  text-decoration-line: underline;
  margin-bottom: 8px;

  &:hover {
    text-decoration: none;
  }
`

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActiveDiscountContainer = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
`

export const ActiveDiscount = styled.p`
  padding: 0 4px;
  padding-left: 8px;
`

export const ActiveDiscountName = styled(ActiveDiscount)`
  color: #939191;
  padding-left: 4px;
`

export const DiscountForm = styled(Form)`
  display: flex;
  align-items: center;
  margin-bottom: auto;
  align-items: flex-start;
`

export const ClearButton = styled(CancelSVG)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const SpaceBetweenButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0;
`
