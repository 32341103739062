/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Allergen {
  DAIRY_FREE = "DAIRY_FREE",
  GLUTEN_FREE = "GLUTEN_FREE",
  KETO = "KETO",
  VEGAN = "VEGAN",
  VEGETARIAN = "VEGETARIAN",
}

export enum BroadFulfilmentMethodInputType {
  ADDRESS = "ADDRESS",
  COLLECTION = "COLLECTION",
  COORDINATES = "COORDINATES",
  FIXED_ZONE_DELIVERY = "FIXED_ZONE_DELIVERY",
  POSTCODE = "POSTCODE",
  TABLE = "TABLE",
}

export enum CategoryEmojiOrImage {
  EMOJI = "EMOJI",
  IMAGE = "IMAGE",
}

export enum CleanOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  PENDING = "PENDING",
  PREPARING = "PREPARING",
  READY = "READY",
  REJECTED = "REJECTED",
}

export enum CountryCodeType {
  GB = "GB",
}

export enum DefaultDatepickerOption {
  ANYTIME = "ANYTIME",
  ASAP = "ASAP",
  PREORDER = "PREORDER",
}

export enum DefaultFulfilmentOption {
  ALL = "ALL",
  COLLECTION = "COLLECTION",
  DELIVERY = "DELIVERY",
  TABLE = "TABLE",
}

export enum DefaultLocationOption {
  EVERYWHERE = "EVERYWHERE",
  NEARME = "NEARME",
  POSTCODE = "POSTCODE",
}

export enum DeliveryCostType {
  NETWORK_ESTIMATE = "NETWORK_ESTIMATE",
  NETWORK_FALLBACK = "NETWORK_FALLBACK",
  OUTLET_FIXED = "OUTLET_FIXED",
}

export enum DetailedPaymentMethod {
  CARD = "CARD",
  CARD_IN_PERSON = "CARD_IN_PERSON",
  CASH = "CASH",
  MANUAL_CONFIRMATION_CARD = "MANUAL_CONFIRMATION_CARD",
}

export enum FulfillmentMethodType {
  COLLECTION = "COLLECTION",
  COURIER = "COURIER",
  DELIVERY = "DELIVERY",
  TABLE = "TABLE",
}

export enum FulfilmentFilterWhenType {
  ANYTIME = "ANYTIME",
  ASAP = "ASAP",
  PREORDER = "PREORDER",
}

export enum FulfilmentMethodInputType {
  COLLECTION = "COLLECTION",
  DELIVERY = "DELIVERY",
  FIXED_ZONE_DELIVERY = "FIXED_ZONE_DELIVERY",
  TABLE = "TABLE",
}

export enum FulfilmentTimeType {
  ASAP = "ASAP",
  PREORDER = "PREORDER",
}

export enum LocationType {
  ADDRESS = "ADDRESS",
  COORDINATES = "COORDINATES",
  DELIVERY_ZONE = "DELIVERY_ZONE",
  EVERYWHERE = "EVERYWHERE",
  POSTCODE = "POSTCODE",
}

export enum MenuItemGroupFulfillmentMethod {
  COLLECTION = "COLLECTION",
  DELIVERY = "DELIVERY",
  TABLE = "TABLE",
}

export enum MerchantType {
  RESTAURANT = "RESTAURANT",
  RETAIL = "RETAIL",
}

export enum NarrowFulfilmentMethodInputType {
  COLLECTION = "COLLECTION",
  DELIVERY = "DELIVERY",
  TABLE = "TABLE",
}

export enum OrderFulfillmentMethods {
  COLLECTION = "COLLECTION",
  COURIER = "COURIER",
  DELIVERY = "DELIVERY",
  NETWORK = "NETWORK",
  TABLE = "TABLE",
}

export enum OrderMode {
  LIST = "LIST",
  POSTCODE = "POSTCODE",
  SINGLE = "SINGLE",
}

export enum OrderStatus {
  AUTH_ACTION_FAILURE = "AUTH_ACTION_FAILURE",
  AUTH_ACTION_PENDING = "AUTH_ACTION_PENDING",
  AWAITING_DELIVERY_NETWORK = "AWAITING_DELIVERY_NETWORK",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  COMPLETE = "COMPLETE",
  DELIVERY_FAILURE_REFUND = "DELIVERY_FAILURE_REFUND",
  DELIVERY_NETWORK_FAILURE = "DELIVERY_NETWORK_FAILURE",
  ORDER_FAILURE_REFUND = "ORDER_FAILURE_REFUND",
  PENDING = "PENDING",
  PREPARING = "PREPARING",
  READY = "READY",
  REFUND_CANCELLED = "REFUND_CANCELLED",
  REFUND_FAILED = "REFUND_FAILED",
  REFUND_RELEASED = "REFUND_RELEASED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REJECTED = "REJECTED",
}

export enum PaymentMethod {
  CARD = "CARD",
  CASH = "CASH",
}

export interface AddressCreateInput {
  firstLine: string;
  secondLine?: string | null;
  thirdLine?: string | null;
  city: string;
  postcode: string;
  countryCode: CountryCodeType;
}

/**
 * This is similar to the fulfilmentInput type, but with the addition of fulfilment methods that are to broad to place an order with, eg POSTCODE.
 *  Validation strictly asserts input must match one of these schemas:
 * 
 *     ```
 *     | {
 *       broadFulfilmentMethod: COLLECTION
 *       preorderFor?: DateTime
 *     }
 *     | {
 *       broadFulfilmentMethod: TABLE
 *       tableId: String
 *     }
 *     | {
 *       broadFulfilmentMethod: ADDRESS
 *       addressId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     | {
 *       broadFulfilmentMethod: POSTCODE
 *       postcode: String
 *       countryCode: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     | {
 *       broadFulfilmentMethod: COORDINATES
 *       latitude: Float
 *       longitude: Float
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     | {
 *       broadFulfilmentMethod: FIXED_ZONE_DELIVERY
 *       deliveryZoneId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *     }
 *     ```
 */
export interface BroadFulfilmentInput {
  broadFulfilmentMethod: BroadFulfilmentMethodInputType;
  deliveryZoneId?: string | null;
  addressId?: string | null;
  preorderFor?: DateTime | null;
  preorderDeliveryWindow?: PreorderDeliveryWindowInput | null;
  postcode?: string | null;
  countryCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  tableId?: string | null;
}

export interface BusinessSegmentOutletsInput {
  locationType?: LocationType | null;
  postAndCountryCode?: PostAndCountryCodeInput | null;
  addressId?: string | null;
  coordinates?: CoordinatesInput | null;
  deliveryZoneId?: string | null;
  marketplaceId?: string | null;
  fulfilmentMethods?: OrderFulfillmentMethods[] | null;
  narrowFulfilmentMethods?: NarrowFulfilmentMethodInputType[] | null;
  selectedDate?: DateTime | null;
  businessSegmentId: string;
  whenType?: FulfilmentFilterWhenType | null;
}

export interface CheckoutBasketTotalsInput {
  outletId: string;
  nonAddOnBasketItemsTotal: number;
  addOnBasketItemsTotal?: number | null;
  fulfilment: BroadFulfilmentInput;
  voucherDiscountId?: string | null;
}

export interface ConnectionInputObject {
  startKey?: string | null;
  pageSize?: number | null;
}

export interface CoordinatesInput {
  lng: number;
  lat: number;
}

export interface CreateAddressInput {
  name?: string | null;
  firstLine: string;
  secondLine?: string | null;
  thirdLine?: string | null;
  city: string;
  postcode: string;
  countryCode: CountryCodeType;
  default?: boolean | null;
}

export interface CreateOrderInput {
  orderItems: OrderItemInput[];
  customerOrderNotes?: string | null;
  customerDeliveryNotes?: string | null;
  discountId?: string | null;
  voucherKey?: string | null;
  payment: PaymentInput;
  fulfilment: FulfilmentInput;
}

export interface EditAddressInput {
  id: string;
  name?: string | null;
  firstLine?: string | null;
  secondLine?: string | null;
  thirdLine?: string | null;
  city?: string | null;
  postcode?: string | null;
  countryCode?: CountryCodeType | null;
  default?: boolean | null;
}

/**
 * Validation strictly asserts input must match one of these schemas:
 * 
 *     ```
 *     | {
 *       fulfilmentMethod: COLLECTION
 *       preorderFor?: DateTime
 *       notes?: String
 *     }
 *     | {
 *       fulfilmentMethod: DELIVERY
 *       fulfilmentId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *       notes?: String
 *     }
 *     | {
 *       fulfilmentMethod: FIXED_ZONE_DELIVERY
 *       deliveryZoneId: String
 *       addressId: String
 *       preorderDeliveryWindow?: {
 *         start: DateTime
 *         end: DateTime
 *       }
 *       notes?: String
 *     }
 *     | {
 *       fulfilmentMethod: TABLE
 *       tableId: String
 *       notes?: String
 *     }
 *     ```
 */
export interface FulfilmentInput {
  fulfilmentMethod: FulfilmentMethodInputType;
  fulfilmentId?: string | null;
  deliveryZoneId?: string | null;
  addressId?: string | null;
  tableId?: string | null;
  preorderFor?: DateTime | null;
  preorderDeliveryWindow?: PreorderDeliveryWindowInput | null;
  notes?: string | null;
}

/**
 * Validation strictly asserts input must match one of these schemas:
 * 
 *     ```
 *     | {
 *       type: EVERYWHERE
 * 
 *     }
 *     | {
 *       type: DELIVERY_ZONE
 *       deliveryZoneId: String!
 *     }
 *     | {
 *       type: POSTCODE
 *       postAndCountryCode: { postcode: 'LS12 2DS', countryCode: 'UK' }
 *     }
 *     | {
 *       type: ADDRESS
 *       addressId: String!
 *     }
 *     | {
 *       type: COORDINATES
 *       coordinates: {
 *         lat: Float!
 *         lng: Float!
 *       },
 *     }
 *     ```
 */
export interface FulfilmentLocationInput {
  type: LocationType;
  postAndCountryCode?: PostAndCountryCodeInput | null;
  addressId?: string | null;
  coordinates?: CoordinatesInput | null;
  deliveryZoneId?: string | null;
}

export interface OrderItemInput {
  outletMenuItemId: string;
  optionItemIds?: string[] | null;
  singleItemNotes?: string | null;
  price?: number | null;
}

/**
 * Validation strictly asserts input must match one of these schemas:
 * 
 *     ```
 *     | {
 *       locationType: EVERYWHERE
 *       marketplaceId: String
 *       marketplaceKey: String
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: DELIVERY_ZONE
 *       deliveryZoneId: String!
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: POSTCODE
 *       marketplaceId: String
 *       marketplaceKey: String
 *       postAndCountryCode: { postcode: 'LS12 2DS', countryCode: 'UK' }
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: ADDRESS
 *       marketplaceId: String
 *       marketplaceKey: String
 *       addressId: String!
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     | {
 *       locationType: COORDINATES
 *       marketplaceId: String
 *       marketplaceKey: String
 *       coordinates: {
 *         lat: Float!
 *         lng: Float!
 *       },
 *       cuisineIds: [String!]
 *       narrowFulfilmentMethods: [DELIVERY, COLLECTION, TABLE]!
 *       featured: boolean
 *       selectedDate: Date
 *       whenType: FulfilmentFilterWhenType
 *     }
 *     ```
 */
export interface OutletsInput {
  locationType: LocationType;
  postAndCountryCode?: PostAndCountryCodeInput | null;
  addressId?: string | null;
  coordinates?: CoordinatesInput | null;
  deliveryZoneId?: string | null;
  marketplaceId?: string | null;
  marketplaceKey?: string | null;
  cuisineIds?: string[] | null;
  fulfilmentMethods?: OrderFulfillmentMethods[] | null;
  narrowFulfilmentMethods?: NarrowFulfilmentMethodInputType[] | null;
  selectedDate?: DateTime | null;
  featured?: boolean | null;
  whenType?: FulfilmentFilterWhenType | null;
}

/**
 * Validation strictly asserts input matches one of these schemas:
 *     
 *     ```
 *     | {
 *       paymentMethod: CASH
 *     }
 *     | {
 *       paymentMethod: CARD
 *       cardPaymentToken?: String
 *     }
 *     | {
 *       paymentMethod: MANUAL_CONFIRMATION_CARD
 *       saveCard?: Boolean
 *     }
 *     | {
 *       paymentMethod: CARD_IN_PERSON
 *     }
 *     ```
 */
export interface PaymentInput {
  paymentMethod: DetailedPaymentMethod;
  cardPaymentToken?: string | null;
  saveCard?: boolean | null;
}

export interface PostAndCountryCodeInput {
  postcode: string;
  countryCode?: string | null;
}

export interface PreorderDeliveryWindowInput {
  start: DateTime;
  end: DateTime;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
