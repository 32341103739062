import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const MenuDescriptionContainer = styled.div`
  display: flex;
  overflow: hidden;
  white-space: normal;
  transition: ease-in-out 0.3s;
  margin-top: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 16px 0;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 90%;
  }
`

export const MenuDescriptionText = styled.span<{ compactStyle: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: black;
  align-items: flex-start;
  line-height: 15px;
  display: ${({ compactStyle }) => (compactStyle ? 'none' : 'relative')};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 15px;
    line-height: 20px;
  }
`
