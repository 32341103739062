import { useReactiveVar } from '@apollo/client'

import { breakpoints } from '../constants/breakpoints'
import { screenResolutionVar } from '../models/screenResolution'

type UseBreakpointReturnType = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isWideDesktop: boolean
  isTabletOrGreater: boolean
  isSmallerThanDesktop: boolean
  isLargeTablet: boolean
}

export const useBreakpoint = (): UseBreakpointReturnType => {
  const { width } = useReactiveVar(screenResolutionVar)

  const isMobile = width < breakpoints.tablet
  const isTablet = !isMobile && width < breakpoints.largeTablet
  const isDesktop = !isMobile && !isTablet && width <= breakpoints.wideDesktop
  const isWideDesktop = width > breakpoints.wideDesktop
  const isLargeTablet = !isMobile && !isTablet && width < breakpoints.desktop

  return {
    isMobile,
    isTablet,
    isDesktop,
    isWideDesktop,
    isLargeTablet,

    // UI is often shown/hidden based on groups of breakpoints, so we provide some helpers
    isTabletOrGreater: isTablet || isDesktop || isWideDesktop,
    isSmallerThanDesktop: isMobile || isTablet,
  }
}
