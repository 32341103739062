export const addBusiness = {
  add_business: 'Add Your Business',
  required: '* Required',
  add_business_desc:
    'If you would like to join {{marketplaceName}} please complete this application and we will be in touch with more details.',
  business_details: 'Business Details',
  business_name: {
    label: 'Business Name',
    required: 'Business name is required',
    invalid_length: 'Business name must contain 3 or more characters',
  },
  owner_name: { label: 'Owner Name', required: 'Owner Name is required' },
  owner_details: 'Owner Details',
  owner_address: {
    label: 'Owner Address',
    first_line: {
      label: 'House Number/Name',
      required: 'House number/name is required',
    },
    second_line: {
      label: 'Street Name',
      required: 'Street name is required',
      invalid_length: 'Street name must contain 2 or more characters',
    },
    city: {
      label: 'Town',
      required: 'Town is required',
      invalid_length: 'Town must have at least two characters',
    },
    postcode: {
      label: 'Postcode',
      required: 'Postcode is required',
    },
    country_code: {
      label: 'Country',
      required: 'Country is required',
    },
  },
  owner_phone: {
    label: 'Owner Phone Number',
    required: 'Phone number is required',
    invalid_format: 'Invalid phone number',
  },
  owner_email: {
    label: 'Owner Email Address',
    required: 'Owner Email is required',
    invalid_format: 'Valid email is required',
  },
  submit: 'Send Request',
  request_sent: 'Application Sent',
  request_sent_desc:
    "We will be in touch once we've processed your application to join {{marketplaceName}}.",
  error_submitting: 'Something went wrong',
}
