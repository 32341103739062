import React from 'react'

export const CircleArrowLeftSVG: React.VFC<{
  id?: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'back', id, className, desc }) => {
  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <circle
        cx="24"
        cy="24"
        r="23"
        stroke="currentColor"
        fill="currentColor"
      />
      <path
        d="M36 25.25V22.7499H16.7875L21.2625 18.2625L19.5 16.5L12 24L19.5 31.5L21.2625 29.7375L16.7875 25.25H36Z"
        fill="currentColor"
      />
    </svg>
  )
}
