import { useQuery } from '@apollo/client'
import React from 'react'

import { LoadingSpinner } from '@src/components/LoadingSpinner'

import { marketplaceCnameLookupDocument } from './queries/__generated__/marketplaceCnameLookup.graphql-interface'

import { MarketplaceContext, MarketplaceContextData } from '.'

// provider which fetches the data and stores it in the context
export const MarketplaceProvider: React.FC = ({ children }) => {
  const { data, loading, error } = useQuery(marketplaceCnameLookupDocument, {
    variables: {
      cname: window.location.hostname,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    throw error
  }

  if (!data) {
    throw new Error('No data returned from marketplace query')
  }

  let marketplaceContextData: MarketplaceContextData | null = null
  if (data.cnameLookup.__typename === 'Marketplace') {
    marketplaceContextData = data.cnameLookup
  }
  if (data.cnameLookup.__typename === 'Restaurant') {
    const business = data.cnameLookup
    const [outlet, ...otherOutlets] = business.outlets
    if (!outlet) {
      throw new Error(
        `Single Outlet marketplace business does not have an outlets associated with it`
      )
    }
    if (otherOutlets.length > 0) {
      throw new Error(
        `Single Outlet marketplace business has more than one outlet associated with it`
      )
    }
    marketplaceContextData = {
      ...outlet.marketplace,
      businessId: business.id,
      outletId: outlet.id,
    }
  }
  if (marketplaceContextData === null) {
    throw new Error(
      `Marketplace context data is null after fetching marketplace data`
    )
  }

  return (
    <MarketplaceContext.Provider value={marketplaceContextData}>
      {children}
    </MarketplaceContext.Provider>
  )
}
