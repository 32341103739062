import { ApolloError, GraphQLErrors } from '@apollo/client/errors'
import { GraphQLError } from 'graphql'
import { groupBy } from 'lodash'
import { z } from 'zod'

import { OrderLimitReachedSchema } from '@src/utils/apolloErrorParser/dataSchemas/orderLimitReached'

import { AddressUnavailableSchema } from './dataSchemas/addressUnavailable'
import { DeliveryUnavailableSchema } from './dataSchemas/deliveryUnavailable'
import {
  ItemsUnavailableForFulfilmentSchema,
  ItemsUnavailableForTimeSchema,
  ItemsUnavailableSchema,
} from './dataSchemas/itemsUnavailable'
import { OptionItemsUnavailableSchema } from './dataSchemas/optionItemsUnavailable'
import { OutletClosedSchema } from './dataSchemas/outletClosed'

const GenericClientErrorSchema = z.object({
  code: z.literal('CLIENT_ERROR'),
  id: z.string().optional(),
  message: z.string(),
  data: z.unknown().optional(),
})

export type GenericClientError = z.infer<typeof GenericClientErrorSchema>
export function isGenericClientError(
  error: unknown
): error is GenericClientError {
  return GenericClientErrorSchema.safeParse(error).success
}

const CustomClientErrorSchema = z.object({
  code: z.literal('CLIENT_ERROR'),
  id: z.string().optional(),
  message: z.string(),
  data: z.discriminatedUnion('errorResolutionType', [
    AddressUnavailableSchema,
    DeliveryUnavailableSchema,
    ItemsUnavailableSchema,
    OptionItemsUnavailableSchema,
    OutletClosedSchema,
    ItemsUnavailableForTimeSchema,
    ItemsUnavailableForFulfilmentSchema,
    OrderLimitReachedSchema,
  ]),
})

export type CustomClientError = z.infer<typeof CustomClientErrorSchema>
export function isCustomClientError(
  error: unknown
): error is CustomClientError {
  return CustomClientErrorSchema.safeParse(error).success
}

const CustomAuthenticationErrorSchema = z.object({
  code: z.literal('AUTHENTICATION_ERROR'),
  message: z.string(),
  data: z.record(z.any()).optional(),
})
export type CustomAuthenticationError = z.infer<
  typeof CustomAuthenticationErrorSchema
>
export function isCustomAuthenticationError(
  error: unknown
): error is CustomAuthenticationError {
  return CustomAuthenticationErrorSchema.safeParse(error).success
}

export type ApolloErrorWithCustomClientErrors = ApolloError & {
  customErrorsByResolutionType: Record<string, CustomClientError[]>
  customClientErrors: CustomClientError[]
  genericClientErrors: GenericClientError[]
  customAuthenticationErrors: CustomAuthenticationError[]
  unexpectedErrors: GraphQLError[]
}

export const graphqlErrorGrouper = (
  graphqlErrors: GraphQLError[] | GraphQLErrors // GraphQLErrors is readonly :shrug:
) =>
  [...graphqlErrors].reduce<{
    customClientErrors: CustomClientError[]
    genericClientErrors: GenericClientError[]
    customAuthenticationErrors: CustomAuthenticationError[]
    unexpectedErrors: GraphQLError[]
  }>(
    (acc, error) => {
      if (isCustomClientError(error)) {
        acc.customClientErrors.push(error)
      } else if (isGenericClientError(error)) {
        acc.genericClientErrors.push(error)
      } else if (isCustomAuthenticationError(error)) {
        acc.customAuthenticationErrors.push(error)
      } else {
        acc.unexpectedErrors.push(error)
      }
      return acc
    },
    {
      customClientErrors: [],
      genericClientErrors: [],
      customAuthenticationErrors: [],
      unexpectedErrors: [],
    }
  )

export const apolloErrorParser = (
  error: ApolloError
): ApolloErrorWithCustomClientErrors => {
  const {
    customClientErrors,
    genericClientErrors,
    customAuthenticationErrors,
    unexpectedErrors,
  } = graphqlErrorGrouper(error.graphQLErrors)
  const customErrorsByResolutionType = groupBy(
    customClientErrors,
    'data.errorResolutionType'
  )
  return {
    ...error,
    customErrorsByResolutionType,
    customClientErrors,
    genericClientErrors,
    customAuthenticationErrors,
    unexpectedErrors,
  }
}
