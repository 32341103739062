import React from 'react'
import { useTranslation } from 'react-i18next'

export const TrashSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('trash_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g
        strokeLinecap="round"
        strokeWidth="2"
        fill="none"
        stroke="#494949"
        strokeLinejoin="round"
        className="nc-icon-wrapper"
      >
        <path d="M27,10V28a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10"></path>{' '}
        <line x1="16" y1="15" x2="16" y2="25"></line>{' '}
        <line x1="11" y1="15" x2="11" y2="25"></line>{' '}
        <line x1="21" y1="15" x2="21" y2="25"></line>{' '}
        <polyline
          data-cap="butt"
          points="11 6 11 1 21 1 21 6"
          stroke="#494949"
        ></polyline>{' '}
        <line x1="30" y1="6" x2="2" y2="6" stroke="#494949"></line>
      </g>
    </svg>
  )
}
