import React, { createContext } from 'react'
import { useHistory } from 'react-router-dom'

import { useOutletFulfilmentData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/useOutletFulfilmentData'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useShowInactiveQueryParam } from '@src/hooks/useShowInactiveQueryParam'
import { OutletPageSkeleton } from '@src/pages/OutletPage/OutletPageSkeleton/OutletPageSkeleton'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'

import { OutletFulfilmentContext } from './types'

// context used to store fetched current outlet data
export const CurrentOutletContext = createContext<OutletFulfilmentContext>(null)

// provider which fetches the data for the outlet currently being viewed
// and stores it in the context for use in the useCurrentOutletFulfilment hook
export const CurrentOutletProvider: React.FC<{ outletId: string }> = ({
  outletId,
  children,
}) => {
  const marketplace = useMarketplace()
  const history = useHistory()
  const {
    data: { where: fulfilmentFilterWhere, nonEmptyFulfilmentMethods },
  } = useFulfilmentFilter()

  const [showInactive] = useShowInactiveQueryParam()

  const {
    data: updatedData,
    loading,
    error,
    previousData,
  } = useOutletFulfilmentData({
    outletId,
    fulfilmentLocation: fulfilmentFilterWhere.location,
    acceptedFulfilmentMethods: nonEmptyFulfilmentMethods,
    showInactiveOverride: showInactive ?? undefined,
  })

  if (loading && !previousData) {
    return <OutletPageSkeleton />
  }

  if (error) {
    console.error(error, 'Error fetching outlet data')
    // TODO: handle error
    history.push('/' + marketplace.urlPath)
    // dont return children as hooks will error and crash the app before redirecting
    return null
  }

  const data = updatedData || previousData

  if (!data?.outlet) {
    console.error('Outlet not found')
    // TODO: handle missing outlet
    history.push('/' + marketplace.urlPath)
    return null
  }

  if (data.outlet.marketplace.id !== marketplace.id) {
    console.error('Outlet does not belong to current marketplace')

    history.push('/' + marketplace.urlPath)
    return null
  }

  const outletFulfilmentContextData = parseOutletDates(data.outlet)

  return (
    <CurrentOutletContext.Provider
      value={{ outlet: outletFulfilmentContextData }}
    >
      {children}
    </CurrentOutletContext.Provider>
  )
}
