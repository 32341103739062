import { LazyQueryExecFunction } from '@apollo/client'
import { max } from 'date-fns'

import { HookMethodArgs } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/types/types'
import { calculateCollectionPreorderDateTime } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/calculateCollectionPreorderDateTime'
import { calculateDeliveryPreorderWindow } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/calculateDeliveryPreorderWindow'
import {
  OutletAvailabilityQuery,
  OutletAvailabilityQueryVariables,
} from '@src/hooks/sharedQueries/outletAvailabilityQuery/queries/__generated__/OutletAvailability.graphql-interface'
import { FulfilmentFilterWhenType } from '@src/hooks/useFulfilmentFilter/validation'
import { debugLogger } from '@src/utils/debugLogger'
import { findOpeningTime } from '@src/utils/fulfilmentTimes/getCurrentTimeDeliveryLength'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'
import { ParsedDeliveryPreorderWindow } from '@src/utils/fulfilmentTimes/types'

import { calculateFulfilmentTimesFromASAPTime } from './calculateFulfilmentTimesFromASAPTime'

/** Customer has a preorder time. If it has expired:
 *  + and the outlet offers asap and is still within the ordering times then set to asap
 *  + and the outlet does not offer asap, or is outside the ordering times, then:
 *    ~ if the outlet has preorder times available, set to the next available time
 *    ~ if the outlet has no preorder times available, then refetch the outlet to update the ui to closed
 **/
export const calculateFulfilmentTimesFromPreorderTimes = async ({
  hookMethodArgs,
  existingCollectionPreorderDatetime,
  existingDeliveryPreorderWindow,
}: {
  hookMethodArgs: HookMethodArgs & {
    outletAvailabilityQuery: LazyQueryExecFunction<
      OutletAvailabilityQuery,
      OutletAvailabilityQueryVariables
    >
  }
  existingCollectionPreorderDatetime: Date | null
  existingDeliveryPreorderWindow: Pick<
    ParsedDeliveryPreorderWindow,
    'start' | 'end'
  > | null
}): Promise<{
  // null means asap, Date means preorder, undefined means no change
  collectionPreorderDatetime?: Date | null
  deliveryPreorderWindow?: Pick<
    ParsedDeliveryPreorderWindow,
    'start' | 'end'
  > | null
}> => {
  if (!existingCollectionPreorderDatetime && !existingDeliveryPreorderWindow) {
    console.error(
      'calculateFulfilmentTimeFromPreorderTime called without existing preorder times. `calculateFulfilmentTimesFromASAPTime` should be used instead'
    )
    return calculateFulfilmentTimesFromASAPTime(hookMethodArgs)
  }

  const nextAvailableCollectionPreorderDatetime =
    calculateCollectionPreorderDateTime({
      outlet: hookMethodArgs.outlet,
      fulfilmentFilterWhen: {
        type: FulfilmentFilterWhenType.PREORDER,
        preorderDate: existingCollectionPreorderDatetime || new Date(),
      },
    })
  const nextAvailableDeliveryPreorderWindow = calculateDeliveryPreorderWindow({
    outlet: hookMethodArgs.outlet,
    fulfilmentFilterWhen: {
      type: FulfilmentFilterWhenType.PREORDER,
      preorderDate: existingDeliveryPreorderWindow?.start || new Date(),
    },
  })

  // collection times expired if:
  // - no collection time could be calculated from the current outlet data
  // - no collection time was set
  // - next collection time is different to existing collection time
  const collectionTimeHasExpired =
    !nextAvailableCollectionPreorderDatetime ||
    !existingCollectionPreorderDatetime ||
    nextAvailableCollectionPreorderDatetime.valueOf() !==
      existingCollectionPreorderDatetime.valueOf()
  // delivery times expired if:
  // - no delivery time could be calculated from the current outlet data
  // - no delivery time was set
  // - next delivery time is different to existing delivery time
  const deliveryTimeHasExpired =
    !nextAvailableDeliveryPreorderWindow ||
    !existingDeliveryPreorderWindow ||
    nextAvailableDeliveryPreorderWindow.start.valueOf() !==
      existingDeliveryPreorderWindow.start.valueOf()

  if (!collectionTimeHasExpired && !deliveryTimeHasExpired) {
    debugLogger('Fulfilment times are still valid', {
      existingCollectionPreorderDatetime,
      existingDeliveryPreorderWindow,
      nextAvailableCollectionPreorderDatetime,
      nextAvailableDeliveryPreorderWindow,
    })
    return {
      collectionPreorderDatetime: existingCollectionPreorderDatetime,
      deliveryPreorderWindow: existingDeliveryPreorderWindow,
    }
  }

  // whichever is latest, the closedUntil date or now
  const now = new Date()
  const earliestPossibleOpenDateTime = hookMethodArgs.outlet.closedUntil
    ? max([new Date(hookMethodArgs.outlet.closedUntil), now])
    : now
  // outlet offers asap, and outlet is open
  const asapIsAvailable =
    hookMethodArgs.outlet.asapAllowed &&
    earliestPossibleOpenDateTime.valueOf() <= now.valueOf() &&
    findOpeningTime({
      openingTimes: hookMethodArgs.outlet.openingTimesArray,
      at: now,
    })
  if (asapIsAvailable) {
    debugLogger(
      'Fulfilment times have expired. Outlet allows ASAP. Setting to ASAP'
    )
    return {
      collectionPreorderDatetime: collectionTimeHasExpired ? null : undefined,
      deliveryPreorderWindow: deliveryTimeHasExpired ? null : undefined,
    }
  }

  // asap not available, use new preorder times
  if (
    nextAvailableCollectionPreorderDatetime ||
    nextAvailableDeliveryPreorderWindow
  ) {
    debugLogger('Fulfilment times have expired. Updating with new times', {
      nextAvailableCollectionPreorderDatetime,
      nextAvailableDeliveryPreorderWindow,
    })
    return {
      collectionPreorderDatetime: collectionTimeHasExpired
        ? nextAvailableCollectionPreorderDatetime
        : undefined,
      deliveryPreorderWindow: deliveryTimeHasExpired
        ? nextAvailableDeliveryPreorderWindow
        : undefined,
    }
  }

  debugLogger('No fulfilment times available. Refetching outlet')
  const { data } = await hookMethodArgs.outletAvailabilityQuery({
    variables: {
      outletId: hookMethodArgs.outlet.id,
      fulfilmentMethod:
        hookMethodArgs.existingData.currentFulfilment.narrowType,
    },
    fetchPolicy: 'network-only',
  })

  if (!data?.outlet) {
    debugLogger('No outlet data returned. Unable to update fulfilment times')
    return {
      collectionPreorderDatetime: existingCollectionPreorderDatetime,
      deliveryPreorderWindow: existingDeliveryPreorderWindow,
    }
  }

  const updatedOutlet = parseOutletDates(data.outlet)

  if (updatedOutlet.isOrderable) {
    const nextCollectionPreorderDatetime = calculateCollectionPreorderDateTime({
      outlet: updatedOutlet,
      fulfilmentFilterWhen: {
        type: FulfilmentFilterWhenType.ANYTIME,
      },
    })
    const nextDeliveryPreorderWindow = calculateDeliveryPreorderWindow({
      outlet: updatedOutlet,
      fulfilmentFilterWhen: {
        type: FulfilmentFilterWhenType.ANYTIME,
      },
    })
    debugLogger('Calculated updated fulfilment time from new data', {
      nextCollectionPreorderDatetime,
      nextDeliveryPreorderWindow,
    })
    return {
      collectionPreorderDatetime: nextCollectionPreorderDatetime,
      deliveryPreorderWindow: nextDeliveryPreorderWindow,
    }
  }

  debugLogger('Outlet is unorderable. Unable to update fulfilment times')
  return {
    collectionPreorderDatetime: existingCollectionPreorderDatetime,
    deliveryPreorderWindow: existingDeliveryPreorderWindow,
  }
}
