import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { breakpoints } from '@src/constants/breakpoints'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { screenResolutionVar } from '@src/models/screenResolution'
import { TreeOutletMenuItemGroup } from '@src/pages/OutletPage/menuItemGroupTreeType'

import {
  ItemContainer,
  ItemsContainer,
  Header,
  HeaderContainer,
  Description,
  ObservableContainer,
  ScrollToContainer,
  SubMenuHeader,
} from './MenuGroup.styles'

import { MenuItem } from '../MenuItem/MenuItem'

export const MenuGroup: React.VFC<{
  outletId: string
  activeMenuId: string | null
  setActiveMenuId: (val: string) => void
  outletParentMenu: TreeOutletMenuItemGroup
  outletSubMenus: TreeOutletMenuItemGroup[]
  manualNavigation: boolean
}> = ({
  outletId,
  outletParentMenu,
  outletSubMenus,
  activeMenuId,
  setActiveMenuId,
  manualNavigation,
}) => {
  const { width } = useReactiveVar(screenResolutionVar)
  const { displayPermanentCheckout } = useCheckoutRouter()

  const rootMargin = (): string => {
    if (width > breakpoints.superWideDesktop) {
      return `${-window.innerHeight * 0.7}px`
    }
    if (width > breakpoints.tablet) {
      return `${-window.innerHeight * 0.65}px`
    } else return `${-window.innerHeight * 0.55}px`
  }

  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: `0px 0px  ${rootMargin()}
     0px`,
    threshold: 1,
    trackVisibility: true,
    delay: 300,
  })

  useEffect(() => {
    if (
      inView &&
      entry?.isIntersecting &&
      activeMenuId !== outletParentMenu.id &&
      !manualNavigation
    ) {
      setActiveMenuId(outletParentMenu.id)
    }
  }, [ref, inView])

  const isElementOnFinalRow = (
    elementsPerRow: number,
    totalElements: number,
    indexOfElement: number
  ): boolean => {
    const lastRowIndex = Math.ceil(totalElements / elementsPerRow) - 1
    const lastRowStartIndex = lastRowIndex * elementsPerRow
    const lastRowEndIndex =
      lastRowStartIndex + (totalElements % elementsPerRow || elementsPerRow) - 1

    return (
      indexOfElement >= lastRowStartIndex && indexOfElement <= lastRowEndIndex
    )
  }

  const isFinalElementInBreakpoint = (
    totalElements: number,
    indexOfElement: number
  ): boolean => {
    // this has to be done outside of styled-components as last-child will not work with repeated menu item groups

    // always remove border on final element
    if (width < breakpoints.tablet) {
      return indexOfElement + 1 === totalElements
    }

    // tablet: rows of 2
    if (width < breakpoints.largeTablet) {
      return isElementOnFinalRow(2, totalElements, indexOfElement)
    }

    // desktop: rows of 3
    if (width < breakpoints.wideDesktop || displayPermanentCheckout) {
      return isElementOnFinalRow(3, totalElements, indexOfElement)
    }

    return isElementOnFinalRow(4, totalElements, indexOfElement)
  }

  return (
    <div>
      <ScrollToContainer id={outletParentMenu.id} aria-hidden />
      <ObservableContainer ref={ref} aria-hidden />
      <HeaderContainer hasDescription>
        <Header hasDescription>{outletParentMenu.name}</Header>
        {outletParentMenu.description && (
          <Description>{outletParentMenu.description}</Description>
        )}
      </HeaderContainer>
      <ItemsContainer>
        {outletParentMenu.outletMenuItems.map(({ id, menuItemId }, i) => (
          <ItemContainer key={id}>
            <MenuItem
              finalElement={isFinalElementInBreakpoint(
                outletParentMenu.outletMenuItems.length,
                i
              )}
              outletId={outletId}
              menuItemId={menuItemId}
            />
          </ItemContainer>
        ))}
      </ItemsContainer>
      {outletSubMenus.map(subMenu => (
        <div key={subMenu.id}>
          <SubMenuHeader>
            {subMenu.name}
            {subMenu.description && (
              <Description>{subMenu.description}</Description>
            )}
          </SubMenuHeader>
          <ItemsContainer>
            {subMenu.outletMenuItems.map(({ id, menuItemId }, i) => (
              <ItemContainer key={id}>
                <MenuItem
                  outletId={outletId}
                  menuItemId={menuItemId}
                  finalElement={isFinalElementInBreakpoint(
                    subMenu.outletMenuItems.length,
                    i
                  )}
                />
              </ItemContainer>
            ))}
          </ItemsContainer>
        </div>
      ))}
    </div>
  )
}
