import { addMinutes } from 'date-fns'

import { OpeningTime } from '@src/graphql-types'

import {
  findOpeningTime,
  getCurrentTimeDeliveryLength,
} from './getCurrentTimeDeliveryLength'
import { ParsedDeliveryPreorderWindow } from './types'

import { roundToNearestMinutes } from '../roundToNearestMinutes'

export const getAsapDeliveryWindow = ({
  prepTime,
  openingTimes,
}: {
  prepTime: number
  openingTimes: OpeningTime[]
}): Omit<ParsedDeliveryPreorderWindow, 'isFull'> | null => {
  const roundedNow = roundToNearestMinutes(new Date(), {
    roundingMethod: 'ceil',
    nearestTo: 5,
  })
  const currentTimeDeliveryLengthInMinutes = getCurrentTimeDeliveryLength(
    openingTimes,
    roundedNow
  )

  if (!currentTimeDeliveryLengthInMinutes) {
    return null
  }

  // rounding should take the seconds into account
  const roundedEndOfPrep = addMinutes(roundedNow, prepTime)
  const roundedEndOfDelivery = roundToNearestMinutes(
    addMinutes(roundedEndOfPrep, currentTimeDeliveryLengthInMinutes),
    { roundingMethod: 'ceil', nearestTo: 5 }
  )

  return {
    start: roundedEndOfPrep,
    end: roundedEndOfDelivery,
  }
}

export const getAsapFulfilmentTime = ({
  prepTime,
  openingTimes,
}: {
  prepTime: number
  openingTimes: OpeningTime[]
}): Date | null => {
  const roundedNow = roundToNearestMinutes(new Date(), {
    roundingMethod: 'ceil',
    nearestTo: 5,
  })
  // if the outlet is closed now return null
  if (!findOpeningTime({ openingTimes, at: roundedNow })) {
    return null
  }
  const roundedEndOfPrep = addMinutes(roundedNow, prepTime)
  return roundedEndOfPrep
}
