import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

import { HookMethodArgs } from '../types/types'

export const setASAP = (hookMethodArgs: HookMethodArgs) => (): void => {
  // get the current fulfilment
  const { narrowType } = hookMethodArgs.existingData.currentFulfilment

  if (narrowType === NarrowFulfilmentMethodInputType.DELIVERY) {
    // if delivery, set asap
    hookMethodArgs.updateDataFn({
      ...hookMethodArgs.existingData,
      currentFulfilment: {
        ...hookMethodArgs.existingData.currentFulfilment,
        deliveryPreorderWindow: null,
      },
      historicalData: {
        ...hookMethodArgs.existingData.historicalData,
        deliveryPreorderWindow: null,
      },
    })
  }
  if (narrowType === NarrowFulfilmentMethodInputType.COLLECTION) {
    // if collection, set asap
    hookMethodArgs.updateDataFn({
      ...hookMethodArgs.existingData,
      currentFulfilment: {
        ...hookMethodArgs.existingData.currentFulfilment,
        collectionPreorderDatetime: null,
      },
      historicalData: {
        ...hookMethodArgs.existingData.historicalData,
        collectionPreorderDatetime: null,
      },
    })
  }
}
