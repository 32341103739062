import { useFormikContext } from 'formik'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { OrderSummary } from '@src/components/CheckoutModal/Checkout/OrderSummary/OrderSummary'
import { CheckoutPaymentCards } from '@src/components/CheckoutModal/Checkout/PaymentStep/PaymentMethods/CheckoutPaymentCards'
import { RestrictedItems } from '@src/components/CheckoutModal/Checkout/RestrictedItems/RestrictedItems'
import {
  ErrorContainer,
  ErrorMessage,
} from '@src/components/Errors/error.styles'
import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs/CheckboxInput/CheckboxInput'
import { PaymentElement } from '@src/components/Stripe/PaymentElement'
import { WarningTriangleSVG } from '@src/components/SVGS/WarningTriangleSVG'
import { Totals } from '@src/components/Totals'
import { DetailedPaymentMethod } from '@src/graphql-types'
import { Card } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'

import {
  Container,
  InnerContainer,
  PaymentMethodContainer,
  RadioWrapper,
  Description,
  RadioInfoLabel,
  AddCardContainer,
  CheckoutText,
  CheckoutTextContainer,
  IconContainer,
} from './PaymentMethods.styles'

import { PaymentStepFormValues } from '../PaymentStep'

export const PaymentMethods: React.FC<{
  availablePaymentMethods: DetailedPaymentMethod[]
  paymentValue: PaymentStepFormValues['payment']
  isAgeVerificationRequired: boolean
  addressId?: string
  tableId?: string
  loading?: boolean
}> = ({
  availablePaymentMethods,
  paymentValue,
  isAgeVerificationRequired,
  addressId,
  tableId,
  loading = false,
}) => {
  const marketplace = useMarketplace()
  const { setFieldValue } = useFormikContext()
  const { t } = useTranslation('checkout')
  const checkoutRouter = useCheckoutRouter()
  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()

  const canPayByCard =
    availablePaymentMethods.includes(
      DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
    ) || availablePaymentMethods.includes(DetailedPaymentMethod.CARD)
  const canPayByCash = availablePaymentMethods.includes(
    DetailedPaymentMethod.CASH
  )

  const isPaymentElementSelected = [
    DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
  ].includes(paymentValue.paymentMethod)

  const isCardSelected = [
    DetailedPaymentMethod.CARD,
    DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
  ].includes(paymentValue.paymentMethod)
  const showCheckoutText = marketplace.checkoutText !== null && isCardSelected

  return (
    <>
      <Container>
        <Description>{t('select_payment_method')}</Description>

        {checkoutRouter && checkoutRouter.errorMessage && (
          <ErrorContainer>
            <WarningTriangleSVG
              width={60}
              height={60}
              id={'accept-discount-warning'}
            />
            <ErrorMessage>{checkoutRouter.errorMessage}</ErrorMessage>
          </ErrorContainer>
        )}

        <InnerContainer
          selected={[
            DetailedPaymentMethod.CARD,
            DetailedPaymentMethod.CASH,
          ].includes(paymentValue.paymentMethod)}
        >
          <PaymentMethodContainer>
            {canPayByCash && (
              <RadioWrapper>
                <UncontrolledRadioInput
                  disabled={loading}
                  value="cash"
                  label={
                    <RadioInfoLabel>
                      {t('cash')}
                      <p>{t('cash_info')}</p>
                    </RadioInfoLabel>
                  }
                  checked={
                    paymentValue.paymentMethod === DetailedPaymentMethod.CASH
                  }
                  style={CheckboxInputStyle.TICK}
                  onChange={() => {
                    checkoutRouter.resetError()
                    setFieldValue('payment', {
                      paymentMethod: DetailedPaymentMethod.CASH,
                    })
                  }}
                />
              </RadioWrapper>
            )}
            {canPayByCard &&
              availablePaymentMethods.includes(DetailedPaymentMethod.CARD) && (
                <CheckoutPaymentCards
                  disabled={loading}
                  onSelect={(card: Card) => {
                    checkoutRouter.resetError()
                    setFieldValue('payment', {
                      paymentMethod: DetailedPaymentMethod.CARD,
                      cardPaymentToken: card.id,
                    })
                  }}
                  selectedCardToken={
                    paymentValue.paymentMethod === DetailedPaymentMethod.CARD &&
                    paymentValue.cardPaymentToken
                      ? paymentValue.cardPaymentToken
                      : null
                  }
                />
              )}
          </PaymentMethodContainer>
        </InnerContainer>

        {canPayByCard && (
          <InnerContainer selected={isPaymentElementSelected}>
            <AddCardContainer>
              <UncontrolledRadioInput
                disabled={loading}
                value="add-payment"
                label={t('add_card')}
                style={CheckboxInputStyle.ADD}
                checked={isPaymentElementSelected}
                onChange={() => {
                  checkoutRouter.resetError()
                  setFieldValue('payment', {
                    paymentMethod:
                      DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
                  })
                }}
              />
            </AddCardContainer>
            {isPaymentElementSelected && (
              <PaymentMethodContainer disabled={loading}>
                {availablePaymentMethods.includes(
                  DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
                ) && (
                  <Fragment>
                    <PaymentElement
                      businessName={marketplace.name}
                      onChange={() => {
                        checkoutRouter.resetError()
                        setFieldValue('payment', {
                          paymentMethod:
                            DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
                          cardPaymentToken: undefined,
                        })
                      }}
                    />
                  </Fragment>
                )}
              </PaymentMethodContainer>
            )}
          </InnerContainer>
        )}
        {showCheckoutText && (
          <CheckoutTextContainer>
            <IconContainer aria-label={t('information_icon')}>
              {'i'}
            </IconContainer>
            <CheckoutText>{marketplace.checkoutText}</CheckoutText>
          </CheckoutTextContainer>
        )}
        {isAgeVerificationRequired && <RestrictedItems disabled={loading} />}
        <OrderSummary addressId={addressId} tableId={tableId} />
        {(isMobile || safeAreaInsetBottom > 0) && (
          <Totals isCheckoutTextVisible={showCheckoutText} />
        )}
      </Container>
    </>
  )
}
