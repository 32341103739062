import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const SpinnerSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  height?: number
  width?: number
}> = ({ id, className, title, desc, height = 16, width = 16 }) => {
  return (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 16 16"
    >
      <title id={`${id}Title`}>{title || 'add'}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g fill="#000000" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z"
          opacity=".2"
        />
        <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z" />
      </g>
    </svg>
  )
}
// Static version of your SVG for use in CSS
const staticSVG = ReactDOMServer.renderToString(
  <SpinnerSVG id="static" height={14} width={14} />
)

// Template literal for use as a data URL in CSS
export const spinnerSVGDataURL = `url("data:image/svg+xml;utf8,${encodeURIComponent(
  staticSVG
)}")`
