import React, { ReactNode } from 'react'

import { Clamp } from '@src/components/Text/Clamp.styles'

// if the children are some html elements make sure to pass tooltip text
export const TextClamp: React.FC<{
  children: ReactNode
  clamp?: number
  tooltipText?: string
}> = ({ children, clamp = 1, tooltipText = children?.toString() }) => {
  return (
    <Clamp clamp={clamp} title={tooltipText}>
      {children}
    </Clamp>
  )
}
