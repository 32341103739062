import styled from 'styled-components'

export const ServiceTabsContainer = styled.div<{
  compactStyle: boolean
  hasOffers: boolean
}>`
  display: flex;
  overflow: hidden;
  z-index: 1;
  transition: ease-in-out 0.3s;
  background-color: white;
  width: 100vw;

  opacity: ${({ compactStyle }) => (compactStyle ? '0' : '1')};
  height: ${({ compactStyle }) => (compactStyle ? '0px' : '48px')};
  padding: 0 16px;
  cursor: pointer;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-width: ${({ compactStyle }) => (compactStyle ? '0px' : '1px')};
`
