export const policies = {
  terms_and_conditions: 'Terms & Conditions',
  allergy: 'Allergy Policy',
  privacy: 'Privacy Policy',
  cookies: 'Cookies',
  misc: 'Other Policy Information',
  // This text should be hidden if there is no policy, unless someone navigates directly to this URL
  policy_fallback: 'This marketplace has no other policy information.',
  cookies_fallback: 'This marketplace has no cookie information.',
}
