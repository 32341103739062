import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom'

import { Redirect } from '@src/components/Router/Redirect'
import {
  featuredOutletsListRoutes,
  memberDiscountRoutes,
  segmentOutletListRoutes,
} from '@src/constants/routes'
import { CurrentOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/CurrentOutletProvider'
import {
  MainRouteName,
  useAccountRouter,
  HelpChildName,
  LoginChildName,
  getQueryStringFromRoute,
} from '@src/hooks/useAccountRouter'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'
import { FeaturedOutletList } from '@src/pages/FeaturedOutletList/FeaturedOutletList'
import { FourZeroFour } from '@src/pages/FourZeroFour/FourZeroFour'
import { Home } from '@src/pages/Home'
import { OutletPageStateWrapper } from '@src/pages/OutletPage/OutletPageStateWrapper'
import { SegmentOutletList } from '@src/pages/SegmentOutletList/SegmentOutletList'

import { outletListRoutes } from './OutletListRoutes'
import { MerchantCategoryRedirect } from './redirects/MerchantCategoryRedirect'
import { OutletRedirect } from './redirects/OutletRedirect'

export const MarketplaceRoutes: React.FC = () => {
  const marketplace = useMarketplace()
  const location = useLocation()

  const { setRoute } = useAccountRouter()
  const {
    savedData: {
      where: { historicalData },
    },
  } = useFulfilmentFilter()

  const isAuthenticated = useReactiveVar(jwtVar) !== null

  const someLocationPresentInState = !!(
    historicalData.addressId ||
    historicalData.coordinates ||
    historicalData.postAndCountryCode ||
    historicalData.zoneId
  )

  useEffect(() => {
    if (location.pathname === '/password-reset') {
      const searchParams = new URLSearchParams(location.search)
      const cid = searchParams.get('cid') ?? undefined
      const token = searchParams.get('token') ?? undefined

      setRoute({
        mainRouteName: MainRouteName.LOGIN,
        childRouteName: LoginChildName.PASSWORD_RESET,
        cid,
        token,
      })
    }

    if (location.pathname === '/onboarding') {
      setRoute({ mainRouteName: MainRouteName.ADD_BUSINESS })
    }

    if (location.pathname === '/help') {
      setRoute({
        mainRouteName: MainRouteName.HELP,
        childRouteName: HelpChildName.FAQS,
      })
    }
  }, [location.pathname])

  return (
    <Switch location={location}>
      <Redirect
        exact
        passthroughQueryParams={false}
        from="/account/details"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.DETAILS,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/account/addresses"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.ADDRESSES,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/account/marketing"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.MARKETING,
        })}
      />

      <Route
        exact
        path="/account/orders/:orderId"
        render={({ match }) => (
          <Redirect
            passthroughQueryParams={false}
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ORDERS,
              pageDataId: match.params.orderId,
            })}
          />
        )}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/account/orders"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.ORDERS,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/terms"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.TERMS,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/privacy"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.PRIVACY,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/cookies"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.COOKIES,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/misc"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.MISC,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/allergy"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.ALLERGY,
        })}
      />
      <Redirect
        exact
        passthroughQueryParams={false}
        from="/help/faqs"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.HELP,
          childRouteName: HelpChildName.FAQS,
        })}
      />
      <Redirect
        exact
        passthroughQueryParams={false}
        from="/add-business"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.ADD_BUSINESS,
        })}
      />
      <Redirect
        exact
        passthroughQueryParams={false}
        from="/onboarding"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.ADD_BUSINESS,
        })}
      />

      <Redirect
        exact
        passthroughQueryParams={false}
        from="/help"
        to={getQueryStringFromRoute({
          mainRouteName: MainRouteName.HELP,
        })}
      />

      {marketplace.urlPath !== 'takeaways' && (
        <Redirect
          exact
          passthroughQueryParams={false}
          from="/takeaways"
          to={`/${marketplace.urlPath}`}
        />
      )}

      {/* Routes in here other than '/' are handled by the burger menu which 
      is designed to open over the homepage if you land on any of these routes. */}
      {/* redirect '/' if we have enabled redirect from landing page */}
      {(marketplace.enableRedirectFromLandingPage ||
        (isAuthenticated && someLocationPresentInState)) && (
        <Route
          path={'/'}
          exact
          render={() => <Redirect to={`/${marketplace.urlPath}`} />}
        />
      )}
      <Route
        path={['/', ...memberDiscountRoutes]}
        render={() => {
          if (
            marketplace.enableRedirectFromLandingPage ||
            (isAuthenticated && someLocationPresentInState)
          ) {
            return <Redirect to={`/${marketplace.urlPath}`} />
          }
          return <Home />
        }}
        exact
      />

      <Route path="/404" component={FourZeroFour} />
      <Route
        path={`/${marketplace.urlPath}/:outletId/:merchantName`}
        render={routeProps => (
          <CurrentOutletProvider outletId={routeProps.match.params.outletId}>
            <OutletPageStateWrapper />
          </CurrentOutletProvider>
        )}
      />
      {outletListRoutes({
        merchantURLPath: marketplace.urlPath,
        orderMode: marketplace.orderMode,
      })}

      <Route
        path={segmentOutletListRoutes}
        exact
        render={routeProps => {
          return (
            <SegmentOutletList segmentId={routeProps.match.params.segmentId} />
          )
        }}
      />
      <Route
        path={featuredOutletsListRoutes}
        exact
        render={() => {
          return <FeaturedOutletList />
        }}
      />
      {/* Support for redirecting old routes to new routes should go bellow here */}
      {/* Redirect old deliveryZone format */}
      {location.pathname !== '/password-reset' && (
        <Route
          path="/:deliveryZone-:merchantCategory"
          exact
          render={routeProps => {
            // `-` in a route breaks the types for the params. One of the reasons for droping this syntax.
            // So it must be cast to the correct type which is available when it gets to the browser
            const castRouteProps =
              routeProps as unknown as RouteComponentProps<{
                deliveryZone: string
                merchantCategory: string
              }>

            const { deliveryZone } = castRouteProps.match.params

            return <MerchantCategoryRedirect deliveryZone={deliveryZone} />
          }}
        />
      )}
      {/* Redirect old outlet routes */}
      <Route
        path={[
          '/:merchantName/:outletId/menu',
          '/:merchantName/:outletId/menu/*',
          '/:merchantName/:outletId/info',
          '/:deliveryZone-:merchantCategory/:merchantName/:outletId/menu',
          '/:deliveryZone-:merchantCategory/:merchantName/:outletId/menu/*',
          '/:deliveryZone-:merchantCategory/:merchantName/:outletId/info',
        ]}
        exact
        render={routeProps => {
          const params = routeProps.match.params
          return (
            <OutletRedirect
              outletId={params.outletId}
              merchantName={params.merchantName}
            />
          )
        }}
      />
      {/* Redirect old checkout routes */}
      <Route
        path={[
          '/:outletId/:merchantName-checkout',
          '/:deliveryZone-:merchantCategory/:outletId/:merchantName-checkout',
        ]}
        exact
        render={({ match }) => {
          if (isCheckoutRouteParams(match.params)) {
            return (
              <OutletRedirect
                outletId={match.params.outletId}
                merchantName={match.params.merchantName}
              />
            )
          }
          return <FourZeroFour />
        }}
      />
      {/* Redirect misc routes */}
      <Route path={'/help'} render={() => <Redirect to="/" />} />
      <Route path={'/onboarding'} exact render={() => <Redirect to="/" />} />

      <Route
        path={'/password-reset'}
        exact
        render={() => <Redirect to="/" />}
      />

      <Redirect from="/*" to="/404" />
    </Switch>
  )
}

// the path matching in react router works correctly but the inferred type is incorrect
// this ensures the correct type without a risky `as unknown`
function isCheckoutRouteParams(params: Record<string, string>): params is {
  outletId: string
  merchantName: string
} {
  return 'outletId' in params && 'merchantName' in params
}
