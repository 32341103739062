import React from 'react'
import { useTranslation } from 'react-i18next'

export const CouponSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('coupon_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <path
        d="M19.41 9.58L10.41 0.58C10.05 0.22 9.55 0 9 0H2C0.9 0 0 0.9 0 2V9C0 9.55 0.22 10.05 0.59 10.42L9.59 19.42C9.95 19.78 10.45 20 11 20C11.55 20 12.05 19.78 12.41 19.41L19.41 12.41C19.78 12.05 20 11.55 20 11C20 10.45 19.77 9.94 19.41 9.58ZM11 18.01L2 9V2H9V1.99L18 10.99L11 18.01Z"
        fill="currentColor"
      />
      <path
        d="M4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6Z"
        fill="currentColor"
      />
    </svg>
  )
}
