import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'

import { localStorageKeys } from '@src/constants/localStorageKeys'
import { jwtVar } from '@src/models/customer/jwt'
export const LocalStorageSync: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // monitors and fixes any state inconsistencies between the basketOutletId, basketItems, and outletFulfilment
  const jwt = useReactiveVar(jwtVar)

  useEffect(() => {
    if (jwt) {
      localStorage.setItem(localStorageKeys.jwt, jwt)
    } else {
      localStorage.removeItem(localStorageKeys.jwt)
    }
  }, [jwt])

  return <>{children}</>
}
