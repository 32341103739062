import { z } from 'zod'

import { CountryCodeType } from '@src/../__generated__/globalTypes'
import i18n from '@src/i18n/i18n'

const t = (key: string) => i18n.t(key, { ns: 'addBusiness' })

export const addBusinessSchema = z.object({
  businessName: z
    .string({ required_error: t('business_name.required') })
    .min(3, t('business_name.invalid_length')),
  firstLine: z
    .string({
      required_error: t('owner_address.first_line.required'),
    })
    .regex(/\w+/, t('owner_address.first_line.invalid_format')),
  secondLine: z
    .string({ required_error: t('owner_address.second_line.required') })
    .regex(/\w+/, t('owner_address.second_line.invalid_chars'))
    .min(2, t('owner_address.second_line.invalid_length')),
  city: z
    .string({ required_error: t('owner_address.city.required') })
    .min(2, t('owner_address.city.invalid_length')),
  postcode: z.string({ required_error: t('owner_address.postcode.required') }),
  countryCode: z.nativeEnum(CountryCodeType, {
    errorMap({ code }) {
      let message = 'error'
      switch (code) {
        case 'invalid_enum_value':
          message = t('owner_address.country_code.required')
      }
      return { message }
    },
  }),
  ownerName: z.string({ required_error: t('owner_name.required') }),
  ownerEmail: z
    .string({
      required_error: t('owner_email.required'),
      invalid_type_error: t('owner_email.invalid_format'),
    })
    .email(),
  ownerPhone: z
    .string({ required_error: t('owner_phone.required') })
    .min(10, t('owner_phone.required')),
})

export type AddBusiness = z.infer<typeof addBusinessSchema>
