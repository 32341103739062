import { makeVar } from '@apollo/client'

import {
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { proxyReactiveVar } from '@src/utils/proxyReactiveVar'

import {
  persistToSessionStorage,
  retrieveFromSessionStorage,
} from './persistance'

import { FulfilmentFilter, FulfilmentFilterWhenType } from '../validation'

// IMPORTANT: This variable should not be updated directly,
// but instead updated via the useFulfilmentFilter hook.
// It can be consumed directly if you do not need your component / hook to react to the changes

const DEFAULT_FULFILMENT_FILTER: FulfilmentFilter = {
  isInitialised: false,
  where: {
    location: {
      type: LocationType.EVERYWHERE,
    },
    fulfilmentMethods: [
      NarrowFulfilmentMethodInputType.DELIVERY,
      NarrowFulfilmentMethodInputType.COLLECTION,
      NarrowFulfilmentMethodInputType.TABLE,
    ],
    historicalData: {
      postAndCountryCode: null,
      coordinates: null,
      addressId: null,
      zoneId: null,
    },
  },
  when: {
    type: FulfilmentFilterWhenType.ANYTIME,
  },
}

// this state describes the options chosen by the customer for filtering outlets by where and when they can fulfil
export const fulfilmentFilterReactiveVar = proxyReactiveVar({
  reactiveVar: makeVar<FulfilmentFilter>(
    retrieveFromSessionStorage() || DEFAULT_FULFILMENT_FILTER
  ),
  onWriteSideEffect: updateData => {
    persistToSessionStorage(updateData)
  },
})

export const resetFulfilmentFilter = () =>
  // note this will also set session storage to the default (not initalised) value
  fulfilmentFilterReactiveVar(DEFAULT_FULFILMENT_FILTER)
