import React from 'react'

import { extendMarketplaceData } from './extendMarketplaceData'
import { MarketplaceContext } from './MarketplaceContext'

export const useMarketplace = () => {
  const marketplace = React.useContext(MarketplaceContext)
  if (!marketplace) {
    throw new Error('useMarketplace must be used within a MarketplaceProvider')
  }
  const extendedMarketplaceData = extendMarketplaceData(marketplace)
  return extendedMarketplaceData
}
