import { makeVar } from '@apollo/client'

type ScreenResolution = {
  width: number
  height: number
}

export const screenResolutionVar = makeVar<ScreenResolution>({
  width: 0,
  height: 0,
})
