import { DeepRequired } from 'utility-types'
import { z } from 'zod'
import { HexColorCode } from './hexValidator'

export const defaultColorsSchema = z.object({
  siteBackground: HexColorCode,
  brand: HexColorCode,
  brandDark: HexColorCode,
  brandLight: HexColorCode,
  brandHighlight: HexColorCode,
  grey: HexColorCode,
  darkGrey: HexColorCode,
  mainText: HexColorCode,
  darkText: HexColorCode,
  brandForeground: HexColorCode,
  danger: HexColorCode,
  flag: HexColorCode,
  discount: HexColorCode,
  openStatus: HexColorCode,
  preorderStatus: HexColorCode,
})

const partialThemeSchema = z.object({
  colors: defaultColorsSchema.required(),
})

export type Theme = DeepRequired<z.infer<typeof partialThemeSchema>>

export const themeSchema = partialThemeSchema.transform(theme => theme as Theme)
