import { useReactiveVar } from '@apollo/client'
import { useState } from 'react'

import { extendFulfilmentFilterData } from './extendData/extendFulfilmentFilterData'
import { setActiveFulfilmentMethods } from './hookMethods/setActiveFulfilmentMethods'
import { setLocationType } from './hookMethods/setLocationType'
import { setWhen } from './hookMethods/setWhen'
import { CurriedFulfilmentFilterHookMethodArgs } from './types'
import { fulfilmentFilterReactiveVar } from './utils/reactiveVar'
export type { FulfilmentFilterData } from './extendData/extendFulfilmentFilterData'

// hook for consuming and updating customer fulfilment data
// It is used for:
// * filtering outlets in the list view
// * providing shared values to the useOutletFulfilment hook
// if deferredUpdates is true, temporary state is used for updates until the save method is called
export const useFulfilmentFilter = (
  {
    deferredUpdates,
  }: {
    deferredUpdates: boolean
  } = {
    deferredUpdates: false,
  }
) => {
  const savedData = useReactiveVar(fulfilmentFilterReactiveVar)
  const [unsavedData, setUnsavedData] = useState(savedData)

  // in order to support immediate and deferred updates,
  // all methods are curried, pure functions which are passed the state
  // and a method to update it (ie either the reactive var or the unsaved state)
  const extendedSavedData = extendFulfilmentFilterData(savedData)
  const extendedUnsavedData = extendFulfilmentFilterData(unsavedData)
  const hookMethodArgs: CurriedFulfilmentFilterHookMethodArgs = {
    currentExtendedData: deferredUpdates
      ? extendedUnsavedData
      : extendedSavedData,
    updateFulfilmentFilter: deferredUpdates
      ? setUnsavedData
      : fulfilmentFilterReactiveVar,
  }

  return {
    data: deferredUpdates ? extendedUnsavedData : extendedSavedData,
    savedData: extendedSavedData,
    setActiveFulfilmentMethods: setActiveFulfilmentMethods(hookMethodArgs),
    setLocationType: setLocationType(hookMethodArgs),
    setWhen: setWhen(hookMethodArgs),
    save: () => {
      deferredUpdates && fulfilmentFilterReactiveVar(unsavedData)
    },
    reset: () => {
      setUnsavedData(savedData)
    },
  }
}
