import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

import { Button } from '../Button'
import { CloseButton } from '../SVGS/CloseButton'

export const DialogInner = styled.div<{ isCheckoutPage: boolean }>`
  display: flex;
  justify-content: center;

  ${({ isCheckoutPage }) =>
    isCheckoutPage &&
    css`
      left: 0;

      @media (min-width: ${breakpoints.tablet}px) {
        position: fixed;
        right: 0;
        z-index: 12;
      }
    `}
`

export const DialogOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  justify-content: center;
  position: fixed;
  z-index: 30;
`

export const DialogOverlayInner = styled.div<{ isLeftSidebar?: boolean }>`
  width: 80%;
  position: fixed;
  top: 40px;
  z-index: 30;
  display: flex;
  flex-direction: column;
  ${removeScrollbar}
  width: 300px;
  left: calc(50% - 150px);

  @media (min-width: ${breakpoints.tablet}px) and (max-width: 1100px) {
    ${({ isLeftSidebar }) =>
      isLeftSidebar
        ? 'left: calc((50% - 198px) + 215px);'
        : 'left : calc(50% - 198px);'}
  }

  @media (min-width: ${breakpoints.tablet}px) {
    top: 20%;
    width: 396px;
  }
`

export const Container = styled.div`
  background-color: white;
  border-radius: 4px;
`

export const MainContainer = styled.main<{ isTallContainer: boolean }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${({ isTallContainer }) =>
    isTallContainer ? '32px' : '0px 32px 32px 32px'};
`

export const HeaderContainer = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 16px 16px 24px 32px;
  border-radius: 4px 4px 0px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
`

export const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`

export const Text = styled.div`
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 24px;
`

export const StyledButton = styled(Button)`
  margin-top: 16px;
  display: flex;
  white-space: pre-wrap;
`

export const StyledCloseButton = styled.button`
  background-color: white;
  border-radius: 40px;
  padding: 8px;
  display: flex;
  align-self: flex-end;
  position: fixed;
  border: none;
  cursor: pointer;
`

export const StyledXSVG = styled(CloseButton)`
  height: 12px;
  width: 12px;
`

export const LoadingContainer = styled.div`
  margin: 0 16px 40px 0;
`
