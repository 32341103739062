import React from 'react'

export const CrossSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'Clear Basket', id, className, desc }) => (
  <svg
    id={id}
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title className="iconTitle">{title}</title>
    {desc && <desc className="iconDescription">{desc}</desc>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96244 5.9073L0.720758 10.149L1.66357 11.0918L5.90525 6.85011L10.1482 11.093L11.091 10.1502L6.84806 5.9073L11.0917 1.6637L10.1488 0.720891L5.90525 4.96449L1.6629 0.722134L0.720087 1.66494L4.96244 5.9073Z"
      fill="currentColor"
    />
  </svg>
)
