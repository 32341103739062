import {
  FulfilmentFilter,
  FulfilmentFilterValidationSchema,
} from '../validation'

const FULFILMENT_FILTER_SESSION_STORAGE_KEY = 'fulfilmentFilter'

export const persistToSessionStorage = (
  fulfilmentFilter: FulfilmentFilter | null
): void => {
  sessionStorage.setItem(
    FULFILMENT_FILTER_SESSION_STORAGE_KEY,
    JSON.stringify(fulfilmentFilter)
  )
}

export const retrieveFromSessionStorage = (): FulfilmentFilter | null => {
  // get outlet filter data from session storage
  const fulfilmentFilterFromSS = sessionStorage.getItem(
    FULFILMENT_FILTER_SESSION_STORAGE_KEY
  )
  if (!fulfilmentFilterFromSS) return null

  // attempt to parse
  let fulfilmentFilter
  try {
    fulfilmentFilter = JSON.parse(fulfilmentFilterFromSS)
  } catch (err) {
    console.error(
      'Failed to parse outlet filter from session storage. Removing from session storage',
      err
    )
    sessionStorage.removeItem(FULFILMENT_FILTER_SESSION_STORAGE_KEY)
    return null
  }

  // if it's null then skip validation
  if (fulfilmentFilter === null) return null

  // validate
  let parsedFulfilmentFilter
  try {
    parsedFulfilmentFilter =
      FulfilmentFilterValidationSchema.parse(fulfilmentFilter)
  } catch (err) {
    console.error(
      'Failed to validate outlet filter from session storage. Removing from session storage.',
      err,
      {
        fulfilmentFilter,
      }
    )
    sessionStorage.removeItem(FULFILMENT_FILTER_SESSION_STORAGE_KEY)
    return null
  }

  return parsedFulfilmentFilter
}
