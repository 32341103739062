import styled from 'styled-components'

import { StarSVG } from '../SVGS/StarSVG'

export const CheckboxWrapper = styled.div`
  margin: 24px 0 24px 0px;
`

export const NameTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  margin: 24px 0 18px 0;
  color: ${({ theme }) => theme.colors.mainText};
`

export const DeleteAddressButton = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.danger};
  text-decoration: underline;
  font-size: 16px;
  margin: 24px 16px;
  padding-right: 40px;
  font-weight: 700;
`

export const DefaultAddressContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding: 24px;
  background-color: #f6f6f9;
  border-radius: 8px;
  justify-content: center;
`

export const DefaultAddressFont = styled.div`
  font-weight: 700;
  display: flex;
`

export const StyledStarSVG = styled(StarSVG)`
  margin-right: 4px;
`
