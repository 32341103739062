import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

export const effectiveFulfillmentMethod = (
  availableFulfilmentInputMethods: NarrowFulfilmentMethodInputType[],
  priorityFulfilmentMethod: NarrowFulfilmentMethodInputType
): NarrowFulfilmentMethodInputType => {
  const isDeliveryAvailable = availableFulfilmentInputMethods.includes(
    NarrowFulfilmentMethodInputType.DELIVERY
  )
  const isCollectionAvailable = availableFulfilmentInputMethods.includes(
    NarrowFulfilmentMethodInputType.COLLECTION
  )

  const priorityAvailable = availableFulfilmentInputMethods.includes(
    priorityFulfilmentMethod
  )

  // if priority not available get next in hierchy
  if (priorityAvailable) {
    return priorityFulfilmentMethod
  } else if (isDeliveryAvailable) {
    return NarrowFulfilmentMethodInputType.DELIVERY
  } else if (isCollectionAvailable) {
    return NarrowFulfilmentMethodInputType.COLLECTION
  } else {
    return NarrowFulfilmentMethodInputType.TABLE
  }
}
