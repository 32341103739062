import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

import { HookMethodArgs } from '../types/types'
import { CurrentFulfilmentType } from '../validation'

// update the current fulfilment data from the narrow fulfilment data
// selecting specific type based on historical data
// priory order: saved address, postcode, coordinates, zone
export type SetCurrentFulfilmentFromNarrowFulfilment = (
  updateData: NarrowFulfilmentMethodInputType
) => void
export const setCurrentFulfilmentFromNarrowFulfilment =
  (hookMethodArgs: HookMethodArgs): SetCurrentFulfilmentFromNarrowFulfilment =>
  updateData => {
    if (updateData === NarrowFulfilmentMethodInputType.DELIVERY) {
      // prioritize saved address
      if (hookMethodArgs.existingData.historicalData.addressId) {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS,
            addressId: hookMethodArgs.existingData.historicalData.addressId,
            deliveryPreorderWindow:
              hookMethodArgs.existingData.historicalData.deliveryPreorderWindow,
            deliveryNotes:
              hookMethodArgs.existingData.historicalData.deliveryNotes,
            fulfilmentId:
              hookMethodArgs.existingData.historicalData.fulfilmentId,
          },
        })
      } else if (
        hookMethodArgs.existingData.historicalData.postAndCountryCode
      ) {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_POSTCODE,
            postAndCountryCode:
              hookMethodArgs.existingData.historicalData.postAndCountryCode,
            deliveryPreorderWindow:
              hookMethodArgs.existingData.historicalData.deliveryPreorderWindow,
          },
        })
      } else if (hookMethodArgs.existingData.historicalData.coordinates) {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_COORDINATES,
            coordinates: hookMethodArgs.existingData.historicalData.coordinates,
            deliveryPreorderWindow:
              hookMethodArgs.existingData.historicalData.deliveryPreorderWindow,
          },
        })
      } else if (hookMethodArgs.existingData.historicalData.zoneId) {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_ZONE,
            zoneId: hookMethodArgs.existingData.historicalData.zoneId,
            deliveryPreorderWindow:
              hookMethodArgs.existingData.historicalData.deliveryPreorderWindow,
            deliveryNotes:
              hookMethodArgs.existingData.historicalData.deliveryNotes,
          },
        })
      } else {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.DELIVERY_EVERYWHERE,
            deliveryPreorderWindow:
              hookMethodArgs.existingData.historicalData.deliveryPreorderWindow,
          },
        })
      }
    } else if (updateData === NarrowFulfilmentMethodInputType.COLLECTION) {
      hookMethodArgs.updateDataFn({
        ...hookMethodArgs.existingData,
        currentFulfilment: {
          type: CurrentFulfilmentType.COLLECTION,
          collectionPreorderDatetime:
            hookMethodArgs.existingData.historicalData
              .collectionPreorderDatetime,
          collectionNotes:
            hookMethodArgs.existingData.historicalData.collectionNotes,
        },
      })
    } else if (updateData === NarrowFulfilmentMethodInputType.TABLE) {
      if (hookMethodArgs.existingData.historicalData.tableId) {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.TABLE,
            tableId: hookMethodArgs.existingData.historicalData.tableId,
            tableNotes: hookMethodArgs.existingData.historicalData.tableNotes,
          },
        })
      } else {
        hookMethodArgs.updateDataFn({
          ...hookMethodArgs.existingData,
          currentFulfilment: {
            type: CurrentFulfilmentType.TABLE_UNSELECTED,
            tableNotes: hookMethodArgs.existingData.historicalData.tableNotes,
          },
        })
      }
    }
  }
