export const navigationLinks = {
  my_orders: 'My Orders',
  details: 'Details',
  saved_cards: 'Saved Cards',
  my_account: 'My Account',
  marketing_preferences: 'Marketing Preferences',
  delivery: 'Delivery',
  address_book: 'Address Book',
  orders: 'Orders',
  order_history: 'Order History',
  add_your_business: 'Add Your Business',
  terms_and_conditions: 'Terms & Conditions',
  privacy_policy: 'Privacy Policy',
  allergy_policy: 'Allergy Policy',
  help_and_support: 'Help & Support',
  cookies: 'Cookies',
  misc_policies: 'Other Policy Information',
}
