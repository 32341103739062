import styled from 'styled-components'

export const WarningTextContainer = styled.div`
  background-color: #f6f6f9;
  padding: 2px 16px;
  border-radius: 8px;
  margin-top: 16px;
`

// shown for expired delivery windows
export const WarningText = styled.p`
  font-size: 14px;
  color: #e1292f;
`

// showed for not clicking restricted items modal and invalid address
export const ErrorMessage = styled.p`
  color: #e1292f;
  border-radius: 4px;
  border: 2px solid #e1292f;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
`
