import { Dialog } from '@headlessui/react'
import { useTransition, UseTransitionProps, animated } from '@react-spring/web'
import React, { ReactNode, useEffect, useState } from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'

import {
  CloseControls,
  DialogBackButton,
  DialogOverlay,
  DialogOverlayInner,
  StyledArrowLeftSVG,
  Slash,
  StyledScrollBars,
} from './Modal.styles'

export enum ModalPosition {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  CATEGORIES = 'CATEGORIES',
}

const modalTransitionDictionary: {
  [key in ModalPosition]: UseTransitionProps
} = {
  [ModalPosition.LEFT]: {
    from: { opacity: 0, left: '-100vw' },
    enter: { opacity: 1, left: '0' },
    leave: { opacity: 0, left: '-100vw' },
    config: { duration: 300 },
    reverse: false,
    delay: 0,
  },
  [ModalPosition.CENTER]: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
    reverse: false,
    delay: 0,
  },
  [ModalPosition.RIGHT]: {
    from: { opacity: 0, right: '-100vw' },
    enter: { opacity: 1, right: '0' },
    leave: { opacity: 0, right: '-100vw' },
    config: { duration: 300 },
    reverse: false,
    delay: 0,
  },
  [ModalPosition.CATEGORIES]: {
    from: { opacity: 0, top: '70px' },
    enter: { opacity: 1, top: '97px' },
    leave: { opacity: 0, top: '-100vw' },
    config: { duration: 300 },
    delay: 0,
  },
}

type VoidFunction = () => void
export type ModalProps = {
  isOpen: boolean
  description?: string
  title?: string
  height?: number
  contentWidth?: number
  children: ReactNode
  fixedBottomContent?: ReactNode
  position?: ModalPosition
  activeHeader?: boolean
  closeType:
    | { type: 'back'; handleClose: VoidFunction; text: string }
    | { type: 'cross'; handleClose: VoidFunction }
    | { type: 'clickOutsideOnly'; handleClose: VoidFunction }
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  contentWidth,
  position = ModalPosition.LEFT,
  closeType,
  fixedBottomContent,
  activeHeader = false,
  height,
}) => {
  const [showContent, setShowContent] = useState(false)
  const basketItems = useBasketItems()
  const [query] = useQueryParams({
    checkout: withDefault(StringParam, ''),
  })

  const basketOnFarRight =
    basketItems.items.length <= 0 && query.checkout !== 'complete'

  // Animate the content out then close the modal
  const closeModal = () => {
    if (showContent) {
      setShowContent(false)
      setTimeout(function () {
        closeType.handleClose()
      }, 200)
    }
  }

  // Sync the internal state with the isOpen prop.
  useEffect(() => {
    setShowContent(isOpen)
  }, [isOpen])

  const overlayTransitions = useTransition(showContent, {
    from: { opacity: 0 },
    enter: { opacity: 0.7 },
    leave: { opacity: 0 },
    config: { duration: 500 },
    reverse: false,
    delay: 0,
  })

  const modalTransitions = useTransition(
    showContent,
    modalTransitionDictionary[position]
  )

  const AnimatedDialogOverlay = animated(DialogOverlay)
  const AnimatedOverlayInner = animated(DialogOverlayInner)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={
          // this `static` prop means this isn't called...
          () => null
        }
        static
      >
        {modalTransitions(
          (styles, item) =>
            item && (
              <AnimatedOverlayInner
                style={styles}
                position={position}
                height={height}
                categoriesOnFarRight={basketOnFarRight}
              >
                <StyledScrollBars width={contentWidth}>
                  {['back', 'cross'].includes(closeType.type) && (
                    <CloseControls activeHeader={activeHeader}>
                      {['back'].includes(closeType.type) && (
                        <DialogBackButton
                          onClick={() => {
                            closeModal()
                          }}
                          position={position}
                        >
                          <StyledArrowLeftSVG id="modal-close-button" />
                          {closeType.type === 'back' && (
                            <>
                              <Slash>{'/'}</Slash>
                              {closeType.text}
                            </>
                          )}
                        </DialogBackButton>
                      )}
                    </CloseControls>
                  )}
                  {children}
                </StyledScrollBars>
                {fixedBottomContent}
              </AnimatedOverlayInner>
            )
        )}
      </Dialog>
      {overlayTransitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay
              style={styles}
              position={position}
              categoriesOnFarRight={basketOnFarRight}
              onClick={(e: Event) => {
                e.stopPropagation()
                closeModal()
              }}
            />
          )
      )}
    </>
  )
}
