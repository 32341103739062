import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getBusinessSegmentQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type getBusinessSegmentQuery = {
  businessSegment: {
    __typename: 'BusinessSegment'
    id: string
    name: string
    description: string | null
    orderBy: string | null
    showFeaturedFlags: boolean
    showDiscountLabels: boolean
  }
}

export const getBusinessSegmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBusinessSegment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessSegment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderBy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showFeaturedFlags' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showDiscountLabels' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getBusinessSegmentQuery,
  getBusinessSegmentQueryVariables
>
