import { makeVar } from '@apollo/client'

import { proxyReactiveVar } from '@src/utils/proxyReactiveVar'

import { persistDiscounts, retrieveDiscounts } from './persistence'

import { Discounts } from '../validation'

export const reactiveVar = makeVar<Discounts>(retrieveDiscounts())

// proxy the reactive var so that whenever it is updated, it is persisted to session storage
export const discountsVar = proxyReactiveVar({
  reactiveVar,
  onWriteSideEffect: persistDiscounts,
})
