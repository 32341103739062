import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type OrderFragmentFragment = {
  __typename: 'Order'
  id: string
  orderNumber: string
  grossTotal: number
  customerDeliveryAddress: {
    __typename: 'Address'
    firstLine: string
    secondLine: string | null
    city: string
    postcode: string
  } | null
  outlet: {
    __typename: 'Outlet'
    id: string
    restaurant: { __typename: 'Restaurant'; id: string; name: string }
  }
  customer: {
    __typename: 'Customer'
    id: string
    firstName: string | null
    lastName: string | null
    email: string
    phoneNumber: string | null
  } | null
}

export type CheckoutCreateOrderMutationVariables = Types.Exact<{
  orderData: Types.CreateOrderInput
}>

export type CheckoutCreateOrderMutation = {
  createOrder:
    | {
        __typename: 'CardInPersonOrderResponse'
        orders: Array<{
          __typename: 'Order'
          id: string
          orderNumber: string
          grossTotal: number
          customerDeliveryAddress: {
            __typename: 'Address'
            firstLine: string
            secondLine: string | null
            city: string
            postcode: string
          } | null
          outlet: {
            __typename: 'Outlet'
            id: string
            restaurant: { __typename: 'Restaurant'; id: string; name: string }
          }
          customer: {
            __typename: 'Customer'
            id: string
            firstName: string | null
            lastName: string | null
            email: string
            phoneNumber: string | null
          } | null
        }>
      }
    | {
        __typename: 'CardOrderResponse'
        paymentIntentClientSecret: string | null
        orders: Array<{
          __typename: 'Order'
          id: string
          orderNumber: string
          grossTotal: number
          customerDeliveryAddress: {
            __typename: 'Address'
            firstLine: string
            secondLine: string | null
            city: string
            postcode: string
          } | null
          outlet: {
            __typename: 'Outlet'
            id: string
            restaurant: { __typename: 'Restaurant'; id: string; name: string }
          }
          customer: {
            __typename: 'Customer'
            id: string
            firstName: string | null
            lastName: string | null
            email: string
            phoneNumber: string | null
          } | null
        }>
      }
    | {
        __typename: 'CashOrderResponse'
        orders: Array<{
          __typename: 'Order'
          id: string
          orderNumber: string
          grossTotal: number
          customerDeliveryAddress: {
            __typename: 'Address'
            firstLine: string
            secondLine: string | null
            city: string
            postcode: string
          } | null
          outlet: {
            __typename: 'Outlet'
            id: string
            restaurant: { __typename: 'Restaurant'; id: string; name: string }
          }
          customer: {
            __typename: 'Customer'
            id: string
            firstName: string | null
            lastName: string | null
            email: string
            phoneNumber: string | null
          } | null
        }>
      }
    | {
        __typename: 'ManualConfirmationCardOrderResponse'
        manualConfirmationPIClientSecret: string
        orders: Array<{
          __typename: 'Order'
          id: string
          orderNumber: string
          grossTotal: number
          customerDeliveryAddress: {
            __typename: 'Address'
            firstLine: string
            secondLine: string | null
            city: string
            postcode: string
          } | null
          outlet: {
            __typename: 'Outlet'
            id: string
            restaurant: { __typename: 'Restaurant'; id: string; name: string }
          }
          customer: {
            __typename: 'Customer'
            id: string
            firstName: string | null
            lastName: string | null
            email: string
            phoneNumber: string | null
          } | null
        }>
      }
}

export const OrderFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grossTotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDeliveryAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restaurant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderFragmentFragment, unknown>
export const CheckoutCreateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckoutCreateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderData' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderData' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CardInPersonOrderResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orders' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OrderFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CashOrderResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orders' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OrderFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ManualConfirmationCardOrderResponse',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orders' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OrderFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'manualConfirmationPIClientSecret',
                        },
                        name: {
                          kind: 'Name',
                          value: 'paymentIntentClientSecret',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CardOrderResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orders' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OrderFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'paymentIntentClientSecret',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OrderFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CheckoutCreateOrderMutation,
  CheckoutCreateOrderMutationVariables
>
