import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetBusinessOutletMenuQueryVariables = Types.Exact<{
  outletId: Types.Scalars['ID']
  fulfilmentMethods:
    | Array<Types.MenuItemGroupFulfillmentMethod>
    | Types.MenuItemGroupFulfillmentMethod
  endOfPrep?: Types.InputMaybe<Types.Scalars['DateTime']>
}>

export type GetBusinessOutletMenuQuery = {
  menuItemGroupsForOutlet: Array<{
    __typename: 'OutletMenuItemGroup'
    id: string
    name: string
    description: string | null
    image: string | null
    showMenuThumbnails: boolean | null
    position: number | null
    fulfillmentMethods: Array<string>
    parentMenu: { __typename: 'MenuItemGroup'; id: string } | null
    outletMenuItems: Array<{
      __typename: 'OutletMenuItem'
      id: string
      menuItemId: string
      name: string
      price: number
      allergens: Array<Types.Allergen> | null
      soldOut: boolean | null
      ageRestricted: boolean | null
      image: string | null
      description: string | null
    }>
  }>
}

export const GetBusinessOutletMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBusinessOutletMenu' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentMethods' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'MenuItemGroupFulfillmentMethod',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endOfPrep' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'menuItemGroupsForOutlet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfillmentMethods' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fulfilmentMethods' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endOfPrep' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endOfPrep' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showMenuThumbnails' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentMethods' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentMenu' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletMenuItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'menuItemId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allergens' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'soldOut' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageRestricted' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBusinessOutletMenuQuery,
  GetBusinessOutletMenuQueryVariables
>
