import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import type { DefaultTheme } from 'styled-components'

import { baseUrl } from '@src/config/urls'
import { themeSchema } from '@src/validation/__generated__/schemas/theme/theme'

import { createTheme } from './createTheme'

type ThemeResponse = {
  loading: boolean
  theme: DefaultTheme | null
}

const useThemeLoader = (themeUrl: string): ThemeResponse => {
  const [state, setState] = useState<ThemeResponse>({
    loading: true,
    theme: null,
  })

  const fetchTheme = async (themeUrl: string): Promise<void> => {
    try {
      // TODO should retry request for theme if it fails instead of just falling back to default
      const response = await fetch(themeUrl)
      if ([404, 500].includes(response.status)) throw response.statusText
      const json: unknown = (await response.json()) as unknown
      const marketplaceThemeConfig = themeSchema.parse(json)

      setState({
        loading: false,
        theme: createTheme(marketplaceThemeConfig),
      })
    } catch (error) {
      console.warn(`Failed to build theme falling back to default theme`, error)
      setState({
        loading: false,
        theme: null,
      })
    }
  }

  useEffect(() => {
    void fetchTheme(themeUrl)
  }, [themeUrl])

  return state
}

export const BusinessThemeLoader: React.FC<{ businessId: string }> = ({
  businessId,
  children,
}) => {
  const { theme, loading } = useThemeLoader(
    `${baseUrl}/business/${businessId}/theme`
  )

  if (loading || !theme) {
    // TODO loading
    return null
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const MarketplaceThemeLoader: React.FC<{
  marketplaceId: string
}> = ({ marketplaceId, children }) => {
  const { theme, loading } = useThemeLoader(
    `${baseUrl}/api/marketplace/${marketplaceId}/theme`
  )

  if (loading || !theme) {
    return null
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
