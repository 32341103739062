export const addressBook = {
  title: 'Address Book',
  generated_name: 'Address {{number}}',
  add_address: 'Add Address',
  edit_address: 'Edit',
  error: 'Error retrieving customer addresses',
  default: { label: 'Default Address' },
} as const

export const addressBookAddAddress = {
  ok: 'Okay',
  title: 'Add Address',
  add_address_action: 'Add Address',
  success: 'Successfully added address',
  add_address_error: 'There was an error adding address',
  address_out_of_bounds:
    'We do not deliver to this address. Please contact support.',
} as const

export const addressBookEditAddress = {
  title: 'Edit Address',
  success: 'Successfully updated address',
  success_delete: 'Successfully deleted address',
  error: 'Address not found',
  edit_address_action: 'Update Address',
  delete_address: 'Delete Address',
  delete_this_address_query: 'Delete This Address?',
  confirm: 'Delete Address',
  cancel: 'Cancel',
} as const

export const addressForm = {
  name: {
    title: 'Give this address a friendly name:',
    label: 'Address Name',
    placeholder: "e.g. 'Home', 'Work', 'Grandma's House'",
  },
  house_number: {
    label: 'House Number',
    validation: {
      required_error: 'House Number is required',
      alphanumeric_characters_error:
        'House Number must only contain alphanumeric characters',
    },
  },
  street_name: {
    label: 'Street Name',
    validation: {
      required_error: 'Street Name is required',
      alphanumeric_characters_error:
        'Street Name must only contain alphanumeric characters',
      minimum_required_characters:
        'Street Name must have at least two characters',
    },
  },
  city: {
    label: 'City',
    validation: {
      required_error: 'City is required',
      minimum_required_characters: 'City must have at least two characters',
    },
  },
  country_code: {
    label: 'Country',
    validation: {
      required_error: 'Country must be selected',
    },
  },
  postcode: { label: 'Postcode' },
  default: {
    label: 'Make Default Address',
    already_defaulted: 'Default Address',
  },
  submit: 'Submit',
}
