import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import { LocationOverlayContainer } from '@src/components/LocationOverlay/LocationOverlayStyles'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import {
  MobileNavigationPopoverContentWrapper,
  MobileNavigationPopoverContent,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
  DoneButton,
  OptionContentLabels,
  OptionContentText,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { DeliveryZone } from '@src/graphql-types'

export const ZonesPopover: React.FC<{
  isOpen: boolean
  onClose: () => void
  onChange?: (zoneId: string) => void
  onDone: (zoneId: string) => void
  selectedZoneId?: string | null
  availableZones: Pick<DeliveryZone, 'id' | 'name'>[]
}> = ({
  isOpen,
  onClose,
  onChange,
  onDone,
  selectedZoneId = null,
  availableZones,
}) => {
  const { t } = useTranslation('serviceNavigation')
  const [zoneId, setZoneId] = React.useState<string | null>(selectedZoneId)

  const handleClose = () => {
    onClose()
    setZoneId(selectedZoneId)
  }

  // if state is being managed by parent, update local state when parent state changes
  useEffect(() => {
    setZoneId(selectedZoneId)
  }, [selectedZoneId])

  return (
    <ServicePopover
      open={isOpen}
      onClose={handleClose}
      headerColor="#fff"
      backgroundColor={'#fff'}
      maxWidth={584}
      showBackdrop={false}
    >
      <MobileNavigationPopoverContentWrapper>
        <MobileNavigationPopoverContent>
          <LocationOverlayContainer>
            {availableZones.map(({ id, name }) => (
              <PopoverOptionContainer key={id}>
                <UncontrolledRadioInput
                  label={
                    <>
                      <OptionLabel>
                        <OptionContentLabels>
                          <OptionContentText>{name}</OptionContentText>
                        </OptionContentLabels>
                      </OptionLabel>
                    </>
                  }
                  style={CheckboxInputStyle.TICK}
                  checked={zoneId === id}
                  onClick={() => {
                    // prevent re-renders by only updating local state
                    // if state is not being managed by parent
                    onChange ? onChange(id) : setZoneId(id)
                  }}
                />
              </PopoverOptionContainer>
            ))}
          </LocationOverlayContainer>
        </MobileNavigationPopoverContent>
      </MobileNavigationPopoverContentWrapper>
      <MobileNavigationPopoverFooter>
        <MobileNavigationPopoverFooterContent>
          <MobileNavigationResetLink onClick={handleClose}>
            {t('cancel')}
          </MobileNavigationResetLink>

          <DoneButton
            content={t('done')}
            width="180px"
            onClick={() => zoneId && onDone(zoneId)}
            type={'button'}
            disabled={!zoneId}
          />
        </MobileNavigationPopoverFooterContent>
      </MobileNavigationPopoverFooter>
    </ServicePopover>
  )
}
