import { times } from 'lodash'

import { CreateOrderInput } from '@src/graphql-types'
import { BasketItem } from '@src/hooks/useBasketItems/validation'

export const basketItemsToOrderItems = (
  basketItems: BasketItem[]
): CreateOrderInput['orderItems'] => {
  return basketItems.flatMap(basketItem =>
    times(basketItem.quantity, function () {
      const orderItem: CreateOrderInput['orderItems'][number] = {
        outletMenuItemId: basketItem.outletMenuItemId,
      }
      if (basketItem.optionItemIds.length) {
        orderItem.optionItemIds = basketItem.optionItemIds
      }
      if (basketItem.price !== undefined) {
        orderItem.price = basketItem.price
      }
      if (basketItem.singleItemNotes) {
        orderItem.singleItemNotes = basketItem.singleItemNotes
      }

      return orderItem
    })
  )
}
