import { FallbackRender } from '@sentry/react'
import React, { useCallback, useEffect } from 'react'

import {
  MaintenanceHeader,
  MaintenanceDescription,
  RedboxSquare,
  MaintenanceContainer,
  TextContainer,
  TryAgainButton,
  DevContainer,
} from './RedboxInfo.styles'

export const RedboxInfo: FallbackRender = ({
  error,
  componentStack,
  resetError,
}) => {
  // log the error to the console
  useEffect(() => {
    console.error('Sentry caught an error', {
      error,
      componentStack,
    })
  }, [error])

  const reset = useCallback(() => {
    resetError()
    // error boundary is above router, so we need to navigate to the home page
    location.href = '/'
  }, [resetError])

  // TODO design this error page
  /* eslint-disable i18next/no-literal-string */
  return (
    <div>
      <TryAgainButton onClick={reset}>Try again</TryAgainButton>
      <MaintenanceContainer>
        <RedboxSquare />
        <TextContainer>
          <MaintenanceHeader>Redbox Under Maintenance</MaintenanceHeader>
          <MaintenanceDescription>
            Redbox is currently under maintenance and will be back soon!
          </MaintenanceDescription>
        </TextContainer>
      </MaintenanceContainer>

      {/* if dev */}
      {import.meta.env.DEV && (
        <DevContainer>
          <p>{error.message}</p>
          <pre>{componentStack}</pre>
          <pre>{error.stack}</pre>
        </DevContainer>
      )}
    </div>
  )
}
