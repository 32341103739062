import React from 'react'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'

import { AuthenticationBoundary } from './AuthenticationBoundary'
import { CollectionFulfilmentStep } from './CollectionFulfilmentStep/CollectionFulfilmentStep'
import { DeliveryFulfilmentStep } from './DeliveryFulfilmentStep/DeliveryFulfilmentStep'
import { TableFulfilmentStep } from './TableFulfilmentStep/TableFulfilmentStep'

export const FulfilmentSteps: React.FC = () => {
  const {
    data: {
      currentFulfilment: { narrowType: currentNarrowFulfilmentMethod },
    },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  return (
    <AuthenticationBoundary>
      {currentNarrowFulfilmentMethod ===
        NarrowFulfilmentMethodInputType.DELIVERY && <DeliveryFulfilmentStep />}
      {currentNarrowFulfilmentMethod ===
        NarrowFulfilmentMethodInputType.COLLECTION && (
        <CollectionFulfilmentStep />
      )}
      {currentNarrowFulfilmentMethod ===
        NarrowFulfilmentMethodInputType.TABLE && <TableFulfilmentStep />}
    </AuthenticationBoundary>
  )
}
