import { createGlobalStyle } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const GlobalStyles = createGlobalStyle`

  :root {
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
  }

  body {
    background: ${({ theme }) => theme.colors.siteBackground};
    color: ${({ theme }) => theme.colors.mainText};
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;

    @media (min-width: ${breakpoints.wideDesktop}px) {
      overscroll-behavior-y: none;
    }
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.mainText};

    :hover {
      color: ${({ theme }) => theme.colors.brand};
    }

    &.active {
      color: ${({ theme }) => theme.colors.brand};
      border-color: ${({ theme }) => theme.colors.brand};
    }
  }
`
