import * as Types from '../../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type CheckoutVerifyVoucherCodeQueryVariables = Types.Exact<{
  key: Types.Scalars['String']
  marketplaceId: Types.Scalars['String']
  outletId?: Types.InputMaybe<Types.Scalars['String']>
}>

export type CheckoutVerifyVoucherCodeQuery = {
  isVoucherValid: {
    __typename: 'Discount'
    id: string
    name: string
    minimumSubtotalGross: number | null
    discountAmount: number | null
    discountPercentage: number | null
    daysOfWeek: Array<number> | null
    startDate: string | null
    endDate: string | null
  }
}

export const CheckoutVerifyVoucherCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckoutVerifyVoucherCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isVoucherValid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumSubtotalGross' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountAmount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountPercentage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'daysOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutVerifyVoucherCodeQuery,
  CheckoutVerifyVoucherCodeQueryVariables
>
