import differenceBy from 'lodash/differenceBy'

import { QueryFieldPolicy } from './__generated__/typePolicies'

export const orders: QueryFieldPolicy['orders'] = {
  // Changing the pageSize invalidates local cache because its complicated to deal with and I'm not sure we will allow the user to change the pageSize but this means we dont have to think about if they do.
  keyArgs: ['input', ['pageSize'], 'createdAt_gte', 'createdAt_lte'],
  // TODO how can we make this more type safe than just adding some not so great static types here? we could technically get the query shape of myOrders from apollo
  merge(
    existing: { edges?: { node: { __ref: string } }[] } = {},
    incoming: { edges?: { node: { __ref: string } }[] } = {},
    {
      args,
    }: {
      args: {
        input?: { startKey?: string }
        createdAt_gte?: DateTime
        createdAt_lte?: DateTime
      } | null
    }
  ) {
    const existingEdges = existing?.edges ?? []
    const incomingEdges = incoming?.edges ?? []

    const newEdges = differenceBy(
      incomingEdges,
      existingEdges,
      edge => edge.node.__ref
    )

    // When the query is run without a startKey we may get newer orders
    // If the leading edge is different return the new data.
    // This invalidates the cache and the user will have to load more again.
    const noStartKey = !args?.input?.startKey
    if (newEdges.length && noStartKey) {
      return incoming
    }

    // Nothing new so return what we have.
    if (newEdges.length === 0) {
      return existing
    }

    // Some new edges to return after the existing edges
    return {
      ...existing,
      ...incoming,
      edges: [...existingEdges, ...newEdges],
    }
  },
}
