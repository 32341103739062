import React from 'react'
import { useTranslation } from 'react-i18next'

export const CancelSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void
}> = ({ id, className, title, desc, onClick }) => {
  const { t } = useTranslation(['svgs'])
  return (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <title id={`${id}Title`}>{title || t('cancel')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <g fill="currentColor">
        <path
          d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0Zm4.95,15.536L15.536,16.95,12,13.414,8.464,16.95,7.05,15.536,10.586,12,7.05,8.464,8.464,7.05,12,10.586,15.536,7.05,16.95,8.464,13.414,12Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  )
}
