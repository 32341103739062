import { useQuery } from '@apollo/client'

import { NarrowFulfilmentMethodInputType, Outlet } from '@src/graphql-types'

import { allOutletDiscounts } from './queries/__generated__/allOutletDiscounts'
import { allOutletDiscountsDocument } from './queries/__generated__/getAllDiscounts.graphql-interface'

export type AllDiscountsData = allOutletDiscounts['allDiscountsByOutletId']

export const useAllDiscountsQuery = ({
  fulfilmentNarrowType,
  outletId,
}: {
  fulfilmentNarrowType: NarrowFulfilmentMethodInputType
  outletId: Outlet['id']
}) => {
  return useQuery(allOutletDiscountsDocument, {
    variables: {
      outletId,
      fulfilmentMethod: fulfilmentNarrowType,
    },
  })
}
