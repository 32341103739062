import { pick } from 'lodash'
import { TFunction } from 'react-i18next'
import { z } from 'zod'

import { CreateAddress } from '@src/hooks/sharedQueries/useCreateAddressMutation/useCreateAddressMutation'
import { graphqlErrorGrouper } from '@src/utils/apolloErrorParser/apolloErrorParser'

import {
  AddressType,
  DeliveryFulfilmentFormSchema,
  ExistingAddressSchema,
  existingAddressSchema,
} from './schema'

// function which creates the address, returning a zod error if it fails
// this is abstracted from the main schema so we don't try to create addresses
// if the initial validation fails
export const addressCreationZodTransform = async ({
  validatedData,
  createAddressMutation,
  t,
}: {
  validatedData: DeliveryFulfilmentFormSchema
  createAddressMutation: CreateAddress
  t: TFunction<'checkout', undefined>
}) =>
  z
    .any()
    .transform<ExistingAddressSchema>(
      async (
        validatedData: DeliveryFulfilmentFormSchema,
        refinementContext
      ) => {
        if (validatedData.addressType === AddressType.EXISTING) {
          return validatedData
        }
        // save address
        try {
          const { data: savedAddress } = await createAddressMutation({
            variables: {
              input: validatedData.addAddress,
            },
          })
          // unexpected error!
          if (!savedAddress) {
            throw new Error()
          }

          // update values to existing address
          return {
            ...pick(validatedData, Object.keys(existingAddressSchema.shape)),
            addressType: AddressType.EXISTING,
            addressId: savedAddress.createAddress.address.id,
          } as ExistingAddressSchema
        } catch (err: any) {
          // handle possibly expected errors (ie address unavailable)
          if (err?.graphQLErrors?.length) {
            const { customClientErrors } = graphqlErrorGrouper(
              err.graphQLErrors
            )
            const addressUnavailableError = customClientErrors.find(
              customClientError =>
                customClientError.data.errorResolutionType ===
                'ADDRESS_UNAVAILABLE'
            )
            if (addressUnavailableError) {
              refinementContext.addIssue({
                code: 'custom',
                message: addressUnavailableError.message,
                path: ['addressType'],
              })
              return z.NEVER
            }
          }

          refinementContext.addIssue({
            code: 'custom',
            message: t('unexpected_create_address_error'),
            path: ['addressType'],
          })

          // zod recommends returning this
          return z.NEVER
        }
      }
    )
    .safeParseAsync(validatedData)
