import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'

import { FeaturedOutletsSkeleton } from '@src/components/FeaturedOutlets/FeaturedOutletsSkeleton'

import { OutletSkeletonWrapper } from './OutletList.styles'
import {
  ImageContainer,
  OutletCard,
  OutletListContainer,
  DetailsContainer,
  HeaderSkeleton,
  ButtonSkeleton,
  InnerDetailsContainer,
  DetailsSkeleton,
} from './OutletListCompactSkeleton.styles'

export const OutletListCompactSkeleton: React.FC = () => {
  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <OutletSkeletonWrapper>
        <FeaturedOutletsSkeleton />
        <OutletListContainer>
          {Array(12)
            .fill(0)
            .map((_, i) => (
              <OutletCard key={i}>
                <ImageContainer />
                <DetailsContainer>
                  <InnerDetailsContainer>
                    <HeaderSkeleton />
                    <DetailsSkeleton />
                  </InnerDetailsContainer>
                  <ButtonSkeleton />
                </DetailsContainer>
              </OutletCard>
            ))}
        </OutletListContainer>
      </OutletSkeletonWrapper>
    </SkeletonTheme>
  )
}
