import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  width: 240px;
  height: 250px;
  flex-direction: column;
  display: flex;
  border-radius: 4px;
  position: relative;
  margin-right: 24px;
  background-color: rgba(150, 150, 150, 0.1);

  @media (min-width: ${breakpoints.tablet}px) {
    width: 294px;
    height: 292px;
    margin: 0px 32px 0px 0px;
    border-radius: 4px 4px 0px 0px;
  }
`

export const OutletDetailsContainer = styled.div`
  height: 176px;
  padding: 0px 16px;
  margin: 0px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}px) {
    width: auto;
    height: 176px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    padding-bottom: 0px;
    height: 192px;
  }
  @media (min-width: ${breakpoints.superWideDesktop}px) {
    height: 240px;
  }
`

export const OutletName = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
  padding-top: 1px;
  color: #191919;

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
  }
`

export const OutletOpeningContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
`

export const OpenStatusContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
`

export const OutletStatus = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 700;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const LongSquareButton = styled.button`
  margin: 0px;
  background-color: transparent;
  height: 40px;
  width: 104px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 17px;
    width: 129px;
    height: 48px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    width: 113px;
    height: 48px;
  }
  @media (min-width: ${breakpoints.superWideDesktop}px) {
    font-size: 20px;
    width: 145px;
    height: 56px;
    border-radius: 28px;
  }
`
