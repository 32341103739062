import React from 'react'

export const AmexSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  return (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      width="48"
      viewBox="0 0 48 48"
    >
      <title id={`${id}Title`}>{title || 'amex'}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <path
        fill="#007AC6"
        d="M44,7H4c-1.657,0-3,1.343-3,3v28c0,1.657,1.343,3,3,3h40c1.657,0,3-1.343,3-3V10C47,8.343,45.657,7,44,7z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M10.533,24.429h2.33l-1.165-2.857L10.533,24.429z M43,19h-5.969l-1.456,1.571L34.264,19H21.598l-1.165,2.571 L19.268,19h-5.096v1.143L13.59,19H9.222L5,29h5.096l0.582-1.571h1.456L12.716,29h5.678v-1.143L18.831,29h2.912l0.437-1.286V29 h11.648l1.456-1.571L36.594,29h5.969l-3.785-5L43,19z M25.383,27.571h-1.602V22l-2.475,5.571h-1.456L17.375,22v5.571h-3.349 L13.444,26H9.95l-0.582,1.571H7.475l3.057-7.143h2.475l2.766,6.714v-6.714h2.766l2.184,4.857l2.038-4.857h2.766v7.143H25.383z M39.797,27.571h-2.184l-1.893-2.429l-2.184,2.429h-6.552v-7.143h6.697l2.038,2.286l2.184-2.286h2.038L36.739,24L39.797,27.571z M28.586,21.857v1.286h3.64v1.429h-3.64V26h4.077l1.893-2.143l-1.747-2H28.586z"
      ></path>
    </svg>
  )
}
