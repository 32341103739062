import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'

export const removeItems =
  ({ updateBasketOutletId, existingItems, navigateToBasket }: HookMethodArgs) =>
  ({ basketItemIds }: { basketItemIds: string[] }): void => {
    // navigate back to the basket
    navigateToBasket()

    const updatedBasketItems = existingItems.filter(
      basketItem => !basketItemIds.includes(basketItem.id)
    )

    basketItemsVar(updatedBasketItems)
    if (updatedBasketItems.length === 0) {
      updateBasketOutletId(null)
    }
  }
