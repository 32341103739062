import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

import { DeliveryZoneInput } from './DeliveryZoneInput'

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledDeliveryZoneInput = styled(DeliveryZoneInput)`
  width: 100%;
  max-width: 291px;
  background: #fff;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 50%;
  }
`
