import { FulfilmentFilter } from '@src/hooks/useFulfilmentFilter/validation'

import { calculateExtendedCurrentFulfilment } from './calculateExtendedCurrentFulfilment'
import { ExtendDataArgs, ExtendedNonBasketOutletFulfilment } from './types'

import { CurrentFulfilmentType } from '../validation'

export type FulfilmentFilterHistoricalData =
  FulfilmentFilter['where']['historicalData']

export const extendData = (
  args: ExtendDataArgs
): ExtendedNonBasketOutletFulfilment => {
  const { data, fulfilmentFilterHistoricalData, basketOutletId } = args
  const postAndCountryCode =
    data.currentFulfilment.type === CurrentFulfilmentType.DELIVERY_POSTCODE
      ? data.currentFulfilment.postAndCountryCode
      : fulfilmentFilterHistoricalData.postAndCountryCode
  const coordinates =
    data.currentFulfilment.type === CurrentFulfilmentType.DELIVERY_COORDINATES
      ? data.currentFulfilment.coordinates
      : fulfilmentFilterHistoricalData.coordinates
  const addressId =
    data.currentFulfilment.type === CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS
      ? data.currentFulfilment.addressId
      : fulfilmentFilterHistoricalData.addressId
  const zoneId =
    data.currentFulfilment.type === CurrentFulfilmentType.DELIVERY_ZONE
      ? data.currentFulfilment.zoneId
      : fulfilmentFilterHistoricalData.zoneId

  const historicalData = {
    ...data.historicalData,
    ...fulfilmentFilterHistoricalData,
    postAndCountryCode,
    coordinates,
    addressId,
    zoneId,
  }
  return {
    ...data,
    historicalData,
    currentFulfilment: calculateExtendedCurrentFulfilment(args),
    isBasketOutlet: basketOutletId === data.outletId,
  }
}
