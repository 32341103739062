import styled from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'
import { PlusSVG } from '@src/components/SVGS/PlusSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const StyledCirclePlusSVG = styled(PlusSVG)`
  width: 12px;
  height: 12px;
  border: 12px solid #ebecf0;
  background-color: #ebecf0;
  border-radius: 20px;
  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 16px;
    height: 16px;
  }
`

export const AddAddressButton = styled(AccountSectionNavButton)`
  padding: 0 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 8px;
  width: 100%;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  color: ${({ theme }) => theme.colors.brand};
  border-color: ${({ theme }) => theme.colors.brand};

  height: 64px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.brandForeground};
    border-color: ${({ theme }) => theme.colors.brand};

    ${StyledCirclePlusSVG} {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`
