import { ApolloClient } from '@apollo/client'

import { CheckoutRouteOutletDocument } from './queries/__generated__/CheckoutRouteOutlet.graphql-interface'
import { CheckoutRoute } from './useCheckoutRouter'

import { basketOutletIdReactiveVar } from '../outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/reactiveVars'

export const getNextCheckoutRoute = async ({
  currentRoute,
  apolloClient,
}: {
  currentRoute: CheckoutRoute | undefined
  apolloClient: ApolloClient<unknown>
}): Promise<CheckoutRoute | undefined> => {
  switch (currentRoute) {
    case undefined:
      return CheckoutRoute.BASKET
    case CheckoutRoute.BASKET:
      return CheckoutRoute.FULFILMENT
    case CheckoutRoute.FULFILMENT: {
      const outletId = basketOutletIdReactiveVar()
      if (!outletId) {
        // TODO: handle this error better
        console.error('outletId is required for fulfilment stage')
        return CheckoutRoute.BASKET
      }
      const { data } = await apolloClient.query({
        query: CheckoutRouteOutletDocument,
        variables: {
          outletId,
        },
      })
      return data.outlet?.restaurant.allowAddOnItems
        ? CheckoutRoute.OFFERS
        : CheckoutRoute.PAYMENT
    }
    case CheckoutRoute.OFFERS:
      return CheckoutRoute.PAYMENT
    case CheckoutRoute.PAYMENT: {
      console.error(
        'getNextCheckoutRoute should not be called for payment stage'
      )
      return currentRoute
    }
    default:
      return currentRoute
  }
}
