export const common = {
  loading: 'Loading',
  please_wait: 'Please Wait',
  enter_your_postcode: 'e.g. {{postcode}}',
  enter_your_postcode_alt: 'Enter a valid UK postcode',
  use_current_location: 'Use Current Location',
  back: 'Back',
  back_to_homepage: 'Back to Homepage',
  categories: 'Categories',
  featured: 'Featured',
  deals: 'Deals & Offers',
  required: 'Required',
  see_more: 'See More',
  results: 'Results',
  cancel: 'cancel',
  yes: 'Yes',
  no: 'No',
  close_alert: 'Close alert',
  category_search_results: '{{urlPath}} by Category',
  search: 'Search',
  order: 'Order',
  search_near_me: 'Find {{searchTerm}}',
  search_near_me_help: 'Enter postcode to find your local {{searchTerm}}, or ',
  show_everything: 'show everything',
  ordering_from: 'Ordering from...',
  four_zero_four: "Oh no! We couldn't find that page.",
  min: '{{count}} mins',
  hour: '{{count}} hour',
  hours: '{{count}} hours',
  hour_plus: '{{count}}+ hour',
  hours_plus: '{{count}}+ hours',
  unexpected_error: 'An unexpected error occurred',
  authentication_error: 'We could not authenticate you. Please log in again.',
}
