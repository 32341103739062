import { animated } from '@react-spring/web'
import { Scrollbars } from 'react-custom-scrollbars-2'
import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const AnimationHider = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #fff; // Will use burger menu background from theme
  z-index: -1;
  box-shadow: 0px 16px 16px rgba(46, 51, 51, 0.2);
  border-top-right-radius: 8px;
`

export const AnimatedContainer = animated(
  styled.div<{ useLeftOffset: boolean; bigPanel: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    background-color: #ffffff;

    @media (min-width: ${breakpoints.largeTabletMisc}px) {
      min-width: ${props => (props.bigPanel ? '60vw' : '450px')};

      padding-bottom: 0;

      ${props =>
        props.useLeftOffset
          ? css`
              width: auto;
              margin-left: -20px;
              padding-left: 20px;
              z-index: -2;
            `
          : css`
              margin-left: 0;
            `}
    }

    ${props =>
      props.bigPanel &&
      css`
        @media (min-width: ${breakpoints.wideDesktop}px) {
          min-width: 1162px;
        }
      `}

    @media (min-width: ${breakpoints.wideDesktop}px) {
      min-width: 528px;
    }

    ${props =>
      props.bigPanel &&
      css`
        @media (min-width: ${breakpoints.wideDesktop}px) {
          min-width: 1162px;
        }
      `}
  `
)

export const StyledScrollBars = styled(Scrollbars)`
  width: 100%;
  height: 100%;
`
