import styled, { css } from 'styled-components'

import { CaretDownSVG } from '@src/components/SVGS/CarretDownSVG'
import { CaretUpSVG } from '@src/components/SVGS/CarretUpSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{ borderless: boolean; open: boolean }>`
  height: 56px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ open }) => (open ? '#000000' : '#ffffff')};
  border-radius: ${({ open }) => (open ? '8px 8px 0px 0px' : '8px')};
  position: relative;
  margin-bottom: 32px;
`

export const Button = styled.button`
  height: 100%;
  width: 100%;
  padding: 0 8px 0 24px;
  outline: 0;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.brandForeground};
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  user-select: none;

  @media (min-width: ${breakpoints.wideDesktop}px) {
    font-size: 18px;
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    font-size: 24px;
  }
`

const sharedButtonSVGstyles = css`
  margin-left: 24px;
  padding: 3px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brandForeground};
`
export const StyledCaretUpSvg = styled(CaretUpSVG)`
  ${sharedButtonSVGstyles}
`
export const StyledCaretDownSvg = styled(CaretDownSVG)`
  ${sharedButtonSVGstyles}
`

export const DropdownFade = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #191919;
  opacity: 0.6;
  z-index: 5;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const Dropdown = styled.ul`
  padding: 0;
  margin: 0 -2px;
  border-radius: 0 0 8px 8px;
  position: fixed;
  top: 22px;
  left: 0;
  right: 0;
  max-height: 30vh;
  max-width: 343px;
  display: flex;
  flex-direction: column;
  border: 2px solid #191919;
  overflow: hidden;
  z-index: 10;
  list-style: none;
  background: #fff;
  position: absolute;
  top: 54px;
`

export const DropdownItems = styled.div`
  flex-shrink: 1;
  overflow-y: auto;
`

export const DropdownItem = styled.li`
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: #fff;
  color: ${({ theme }) => theme.colors.mainText};
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.brandForeground};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    height: 64px;
    font-size: 18px;
  }
`
