import { NonBasketOutletFulfilments } from '../types/types'
import {
  BasketOutletFulfilmentSchema,
  NonBasketOutletFulfilment,
  NonBasketOutletFulfilmentSchema,
} from '../validation'

const CURRENT_OUTLET_FULFILMENT_SESSION_STORAGE_KEY = 'currentOutletFulfilment'
const BASKET_OUTLET_FULFILMENT_LOCAL_STORAGE_KEY = 'basketOutletFulfilment'
const BASKET_OUTLET_ID_LOCAL_STORAGE_KEY = 'basketOutletId'

export const persistCurrentOutlet = (
  currentOutletFulfilment: NonBasketOutletFulfilment | null
): void => {
  currentOutletFulfilment
    ? sessionStorage.setItem(
        CURRENT_OUTLET_FULFILMENT_SESSION_STORAGE_KEY,
        JSON.stringify(currentOutletFulfilment)
      )
    : sessionStorage.removeItem(CURRENT_OUTLET_FULFILMENT_SESSION_STORAGE_KEY)
}

export const persistBasketOutlet = (
  currentOutletFulfilment: NonBasketOutletFulfilment | null
): void => {
  currentOutletFulfilment
    ? localStorage.setItem(
        BASKET_OUTLET_FULFILMENT_LOCAL_STORAGE_KEY,
        JSON.stringify(currentOutletFulfilment)
      )
    : localStorage.removeItem(BASKET_OUTLET_FULFILMENT_LOCAL_STORAGE_KEY)
}

export const persistBasketOutletId = (basketOutletId: string | null): void => {
  basketOutletId
    ? localStorage.setItem(BASKET_OUTLET_ID_LOCAL_STORAGE_KEY, basketOutletId)
    : localStorage.removeItem(BASKET_OUTLET_ID_LOCAL_STORAGE_KEY)
}

export const retrieveOutletFulfilment = (): NonBasketOutletFulfilments => {
  const persistedBasketOutlet = retrieveBasketOutlet()
  const outlets: NonBasketOutletFulfilments['outlets'] = {}
  if (persistedBasketOutlet) {
    outlets[persistedBasketOutlet.outletId] = persistedBasketOutlet
  } else {
    const persistedCurrentOutlet = retrieveCurrentOutlet()

    if (persistedCurrentOutlet) {
      outlets[persistedCurrentOutlet.outletId] = persistedCurrentOutlet
    }
  }
  return {
    outlets,
    unsavedState: null,
  }
}

const retrieveCurrentOutlet = (): NonBasketOutletFulfilment | null => {
  // get the outlet fulfilment data from session storage
  const currentOutletFulfilmentFromSS = sessionStorage.getItem(
    CURRENT_OUTLET_FULFILMENT_SESSION_STORAGE_KEY
  )
  if (!currentOutletFulfilmentFromSS) return null

  // attempt to parse
  let currentOutletFulfilment
  try {
    currentOutletFulfilment = JSON.parse(currentOutletFulfilmentFromSS)
  } catch (err) {
    console.error(
      'Failed to parse outlet fulfilment from session storage. Removing from session storage',
      err
    )
    sessionStorage.removeItem(CURRENT_OUTLET_FULFILMENT_SESSION_STORAGE_KEY)
    return null
  }

  // if it's null then skip validation
  if (currentOutletFulfilment === null) return null

  // validate
  let parsedCurrentOutletFulfilment
  try {
    parsedCurrentOutletFulfilment = NonBasketOutletFulfilmentSchema.parse(
      currentOutletFulfilment
    )
  } catch (err) {
    console.error(
      'Failed to validate outlet fulfilment from session storage. Removing from session storage.',
      err,
      {
        currentOutletFulfilment,
      }
    )
    sessionStorage.removeItem(CURRENT_OUTLET_FULFILMENT_SESSION_STORAGE_KEY)
    return null
  }

  return parsedCurrentOutletFulfilment
}

const retrieveBasketOutlet = (): NonBasketOutletFulfilment | null => {
  // get the basket outlet fulfilment data from local storage
  const basketOutletFulfilmentFromLS = localStorage.getItem(
    BASKET_OUTLET_FULFILMENT_LOCAL_STORAGE_KEY
  )
  if (!basketOutletFulfilmentFromLS) return null

  // attempt to parse
  let basketOutletFulfilment
  try {
    basketOutletFulfilment = JSON.parse(basketOutletFulfilmentFromLS)
  } catch (err) {
    console.error(
      'Failed to parse basket outlet fulfilment from local storage. Removing from local storage',
      err
    )
    localStorage.removeItem(BASKET_OUTLET_FULFILMENT_LOCAL_STORAGE_KEY)
    return null
  }

  // if it's null then skip validation
  if (basketOutletFulfilment === null) return null

  // validate
  let parsedBasketOutletFulfilment
  try {
    parsedBasketOutletFulfilment = BasketOutletFulfilmentSchema.parse(
      basketOutletFulfilment
    )
  } catch (err) {
    console.error(
      'Failed to validate basket outlet fulfilment from local storage. Removing from local storage.',
      err,
      {
        basketOutletFulfilment,
      }
    )
    localStorage.removeItem(BASKET_OUTLET_FULFILMENT_LOCAL_STORAGE_KEY)
    return null
  }

  return parsedBasketOutletFulfilment
}

export const retrieveBasketOutletId = (): string | null =>
  localStorage.getItem(BASKET_OUTLET_ID_LOCAL_STORAGE_KEY)
