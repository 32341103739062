export const datePicker = {
  no_times_error: 'There are no delivery windows available for this day',
  previous: 'Previous',
  next: 'Next',
  collection: 'Collection',
  delivery: 'Delivery',
  closed_for_the_day: 'Closed on this day',
  slots_full_day_msg: 'There are no available slots for this day',
  now: 'Now',
  today: 'Today',
  tomorrow: 'Tomorrow',
  schedule: 'Schedule',
  select_date: 'Select a date',
  this_is_the_day_you_would_like_to_receive_your_order:
    'This is the day you would like to receiver your order',
  your_order_will_be_ready_to_collect_at_this_time:
    'Your order will be ready to collect at this time',
  your_delivery_will_aim_to_arrive_within_these_times:
    'Your Delivery will aim to arrive within these times',
  select_fulfilment_time: 'Select a {{fulfilment}} time',
  delivery_time_full: 'Delivery time full',
  collection_time_full: 'Collection time full',
}
