import { makeVar } from '@apollo/client'

import { proxyReactiveVar } from '@src/utils/proxyReactiveVar'

import { persistBasketItems, retrieveBasketItems } from './persistence'

import { BasketItem } from '../validation'

// create a normal reactive var
const reactiveVar = makeVar<BasketItem[]>(retrieveBasketItems())

// proxy the reactive var so that whenever it is updated, it is persisted to local storage
// and if the value is null:
// 1. attempt to retrieve it from local storage (defaults to an empty array)
// 2. update the reactive var to the retrieved value
// 3. return the retrieved value
export const basketItemsVar = proxyReactiveVar({
  reactiveVar,
  onWriteSideEffect: persistBasketItems,
})
