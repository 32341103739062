import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

const TimeContainer = styled.button<{
  selected: boolean
  disabled: boolean
}>`
  ${({ theme, selected, disabled }) =>
    css`
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      width: 100%;
      height: 40px;
      display: inline-block;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
      background-color: ${selected ? theme.colors.brand : theme.colors.grey};
      color: ${selected ? theme.colors.brandForeground : theme.colors.darkGrey};
      cursor: pointer;
      border-radius: 24px;
      border: 2px solid ${selected ? theme.colors.brand : '#fff'};
      overflow: hidden;
      ${disabled &&
      css`
        opacity: 0.5;
        background-color: #ebecf0;
        cursor: not-allowed;
      `}

      &:hover {
        background-color: ${theme.colors.brand};
        color: ${theme.colors.brandForeground} !important;
      }

      @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.largeTablet}px) {
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;
        height: 48px;
        /* width: 159px; */
      }

      @media (min-width: ${breakpoints.largeTablet}px) {
        height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 36px;
        &:hover {
          height: 40px;
          ${disabled &&
          css`
            border: 2px solid ${theme.colors.brand};
          `}
        }
      }
    `}
`

export const DeliveryWindowsOfTheDayContainer = styled.div`
  background: '#fff';
  overflow: hidden;
  height: auto;

  @media (min-width: ${breakpoints.largeTablet}px) {
    & ${TimeContainer}:nth-child(even) {
      margin-left: 8px;
    }
  }
`

export const DeliveryWindowsOfTheDayContainerInner = styled.div`
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-y: scroll;
  padding-top: 8px;
  ${removeScrollbar}
`

export const NoSlotsCard = styled.div`
  padding: 24px;
  background: #fff;
  font-weight: 700;
  margin-top: 24px;
`

export const DaysSwiperContainer = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 16px;
`

export const DaySelector = styled.div<{ selected: boolean }>`
  ${({ selected }) => {
    return css`
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
      border: ${selected ? '2px solid #000' : '2px solid #ebecf0'};
      background-color: #fff;
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      padding: 8px 12px;
      min-width: 80px;

      &:hover {
        border: 2px solid #000;
        background-color: black;
        color: white !important;
      }
    `
  }}
`
