import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{ imageUrl: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-color: #c4c4c4;
  padding-top: 60px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  @media (min-width: ${breakpoints.tablet}px) {
    background-position: 10%;
    min-height: 400px;
  }
`
