import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const PlusSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  height?: number
  width?: number
}> = ({ id, className, title, desc, height = 16, width = 16 }) => {
  return (
    <svg
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 16 16"
    >
      <title id={`${id}Title`}>{title || 'add'}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g fill="currentColor" className="nc-icon-wrapper">
        <path
          fill="currentColor"
          d="M15,7H9V1c0-0.6-0.4-1-1-1S7,0.4,7,1v6H1C0.4,7,0,7.4,0,8s0.4,1,1,1h6v6c0,0.6,0.4,1,1,1s1-0.4,1-1V9h6 c0.6,0,1-0.4,1-1S15.6,7,15,7z"
        ></path>
      </g>
    </svg>
  )
}
// Static version of your SVG for use in CSS
const staticSVG = ReactDOMServer.renderToString(
  <PlusSVG id="static" height={14} width={14} />
)

// Template literal for use as a data URL in CSS
export const plusSVGDataURL = `url("data:image/svg+xml;utf8,${encodeURIComponent(
  staticSVG
)}")`
