import { z } from 'zod'

export enum UnavailableItemReason {
  MENU_ITEM_UNAVAILABLE = 'MENU_ITEM_UNAVAILABLE',
  OPTION_ITEM_UNAVAILABLE = 'OPTION_ITEM_UNAVAILABLE',
  FULFILMENT_UNAVAILABLE = 'FULFILMENT_UNAVAILABLE',
}

const OptionItemUnavailableReasonSchema = z.discriminatedUnion(
  'isOptionItemUnavailable',
  [
    z.object({
      isOptionItemUnavailable: z.literal(false),
      unavailableOptionItemIds: z.never(),
    }),
    z.object({
      isOptionItemUnavailable: z.literal(true),
      unavailableOptionItemIds: z.array(z.string()),
    }),
  ]
)

const FulfilmentUnavailableReasonSchema = z.discriminatedUnion(
  'isFulfilmentUnavailable',
  [
    z.object({
      isFulfilmentUnavailable: z.literal(false),
      unavailableFulfilmentMethods: z.never(),
    }),
    z.object({
      isFulfilmentUnavailable: z.literal(true),
      unavailableFulfilmentMethods: z.array(z.string()),
    }),
  ]
)

const UnavailableItemReasonsSchema = z.object({
  menuItem: z.object({ isMenuItemUnavailable: z.boolean() }).optional(),
  optionItem: OptionItemUnavailableReasonSchema.optional(),
  fulfilmentMethod: FulfilmentUnavailableReasonSchema.optional(),
  isUnavailableForTime: z.boolean().optional(),
})
export type UnavailableItemReasons = z.infer<
  typeof UnavailableItemReasonsSchema
>

export const BasketItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  menuItemId: z.string(),
  outletMenuItemId: z.string(),
  optionItemIds: z.array(z.string()),
  singleItemNotes: z.string(),
  quantity: z.number(),
  price: z.number().optional(), // If price is not null, this is an add on item
  availability: UnavailableItemReasonsSchema,
})

export const BasketItemsSchema = z.array(BasketItemSchema)

export type BasketItem = z.infer<typeof BasketItemSchema>
