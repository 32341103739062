export const outletHeader = {
  service: 'Service',
  all: 'All',
  when: 'When',
  where: 'Where',
  unavailable: 'Unavailable',
  location: 'Location',
  location_table: 'Table',
  collection: 'Collection',
  delivery: 'Delivery',
  fixed_zone_delivery: 'Delivery',
  table: 'Table Service',
  mins: '{{minutes}} mins',
  mi: '{{miles}} mi',
  order_now: 'Order Now',
  within: 'Within',
  available_now: 'Available Now',
  not_currently_available: 'Not Currently Available',
  asap_by: 'ASAP by',
  select_your_table: 'Select Your Table',
  search_your_location: 'Enter Location',
  arriving_by: 'Arriving by',
  ready_by: 'Ready by',
  near_me: 'Near Me',
  enter_location: 'Enter Location',
}
