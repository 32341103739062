import { basketItemsVar } from '@src/hooks/useBasketItems/utils/reactiveVar'

import { StrictTypedTypePolicies } from './__generated__/typePolicies'
import { orders } from './orders'

export const apolloTypePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      orders: orders,
      basketItems: {
        read() {
          return basketItemsVar()
        },
      },
      outletMenuItems: {
        read(_, { args, toReference, canRead }) {
          const { outletMenuItemIds } = args ?? {}
          if (Array.isArray(outletMenuItemIds)) {
            const menuItemReferences = outletMenuItemIds?.map(
              (outletMenuItemId: string) =>
                toReference({
                  __typename: 'OutletMenuItem',
                  id: outletMenuItemId,
                })
            )
            // assert all items exist in cache
            if (menuItemReferences && menuItemReferences.every(canRead)) {
              return menuItemReferences
            }
          }
        },
      },
      outletMenuItem: {
        read(_, { args, toReference }) {
          const { menuItemId, outletId } = args ?? {}
          if (typeof outletId === 'string' && typeof menuItemId === 'string') {
            const ref = toReference({
              __typename: 'OutletMenuItem',
              id: `${outletId}:${menuItemId}`,
            })
            return ref
          }
        },
      },
      optionItemById: {
        read(_, { args, toReference }) {
          const { optionItemId } = args ?? {}
          if (typeof optionItemId === 'string') {
            return toReference({
              __typename: 'OptionItem',
              id: optionItemId,
            })
          }
        },
      },
    },
  },
}
