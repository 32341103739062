import { t } from 'i18next'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

import { useMarketplace } from './useMarketplace'

export const useFetchFulfilmentLabels = () => {
  const marketplace = useMarketplace()

  const fulfilmentLabels = [
    {
      label: t('delivery'),
      fulfilment: NarrowFulfilmentMethodInputType.DELIVERY,
    },
    {
      label: t('collection'),
      fulfilment: NarrowFulfilmentMethodInputType.COLLECTION,
    },
  ]

  if (marketplace.allowOrderToTable) {
    fulfilmentLabels.push({
      label: t('table'),
      fulfilment: NarrowFulfilmentMethodInputType.TABLE,
    })
  }

  return fulfilmentLabels
}
