import styled, { css } from 'styled-components'

import { ClockSVG } from '@src/components/SVGS/ClockSVG'
import { CRemoveSVG } from '@src/components/SVGS/CRemoveSVG'
import { DoubleArrowSVG } from '@src/components/SVGS/DoubleArrowSVG'
import { LocationPinSVG } from '@src/components/SVGS/LocationPinSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  display: flex;
  border-radius: 56px;
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

export const InnerContainer = styled.div<{
  isActive: boolean
  disabled?: boolean
}>`
  position: relative;

  height: 44px;
  border-radius: 24px;
  align-self: center;
  margin: 0 8px 0 0;
  padding-left: 12px;
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border: 2px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.brandHighlight : theme.colors.darkGrey};
  cursor: pointer;
  color: ${({ isActive, theme, disabled }) =>
    isActive && !disabled
      ? theme.colors.brandHighlight
      : theme.colors.mainText};

  &:hover {
    border: 2px solid
      ${({ isActive, theme }) =>
        isActive ? theme.colors.brandHighlight : theme.colors.brand};
    color: ${({ isActive, theme, disabled }) =>
      isActive && !disabled ? theme.colors.brandHighlight : theme.colors.brand};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: null;
      opacity: 0.2;
      cursor: not-allowed;
      &:hover {
        background-color: null;
      }
    `}
`

export const LargeLabel = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 2px 8px;
  width: 62%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow: hidden;
  line-height: 1.1;
  text-overflow: ellipsis;
`

export const DropdownInnerContainer = styled.div<{ width: string }>`
  background-color: white;
  border-radius: 20px;
  z-index: 100;
  width: ${({ width }) => width};
  @media (min-width: ${breakpoints.largeTablet}px) {
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border-radius: 20px;
  }
  filter: drop-shadow(rgba(100, 100, 100, 0.2) 0px 1px 2px);
`
export const StyledDoubleArrowSVG = styled(DoubleArrowSVG)`
  position: absolute;
  right: 20px;
`

export const StyledLocationSVG = styled(LocationPinSVG)`
  width: 20px;
  height: 24px;
`

export const StyledClockSVG = styled(ClockSVG)`
  width: 20px;
  height: 20px;
`

export const StyledCloseSVG = styled(CRemoveSVG)`
  position: absolute;
  top: 14px;
`

export const StyledButton = styled.button`
  display: flex;
  all: unset;
  padding-left: 8px;
`

export const DesktopDropdownContent = styled.div`
  padding: 16px;
`
