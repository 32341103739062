import { useQuery } from '@apollo/client'
import React from 'react'

import { ExtendedCurrentFulfilmentDeliveryZone } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'

import { OutletDeliveryZonesDocument } from './queries/__generated__/outletDeliveryZones.graphql-interface'

export const FulfilmentWhereLabelDeliveryZone: React.FC<{
  currentFulfilment: ExtendedCurrentFulfilmentDeliveryZone
  outletId: string
}> = ({ currentFulfilment, outletId }) => {
  const outletDeliveryZonesQueryResponse = useQuery(
    OutletDeliveryZonesDocument,
    {
      variables: {
        outletId: outletId,
      },
    }
  )

  if (outletDeliveryZonesQueryResponse.loading) {
    return <span>{'Loading...'}</span>
  }
  if (
    outletDeliveryZonesQueryResponse.error ||
    !outletDeliveryZonesQueryResponse.data?.outlet
  ) {
    return null
  }

  const selectedOutletDeliveryZone =
    outletDeliveryZonesQueryResponse.data.outlet.deliveryZones.find(
      deliveryZone => deliveryZone.id === currentFulfilment.zoneId
    )

  return (
    <span>{selectedOutletDeliveryZone?.name || 'Delivery zone not found'}</span>
  )
}
