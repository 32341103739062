import { useLazyQuery } from '@apollo/client'
import { Formik } from 'formik'
import { toUpper } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ButtonType } from '@src/components/Button'
import { DotSpan } from '@src/components/DotSpan/DotSpan'
import { TextInput } from '@src/components/Inputs'
import { CheckSVG } from '@src/components/SVGS/CheckSVG'
import { Discount } from '@src/graphql-types'
import { useDiscounts } from '@src/hooks/useDiscounts/useDiscounts'
import { DiscountType } from '@src/hooks/useDiscounts/validation'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { apolloErrorParser } from '@src/utils/apolloErrorParser/apolloErrorParser'

import {
  Container,
  ApplyButton,
  Title,
  SpaceBetweenRow,
  DiscountForm,
  ClearButton,
  ActiveDiscountContainer,
  ActiveDiscount,
  ActiveDiscountName,
  SpaceBetweenButton,
} from './DiscountCodeSection.styles'
import { CheckoutVerifyVoucherCodeDocument } from './queries/__generated__/CheckoutVerifyVoucherCode.graphql-interface'

export const DiscountCodeSection: React.FC<{
  basketSubTotal: number
  outletId: string
  appliedDiscount: Pick<Discount, 'id' | 'name'> | null
}> = ({ basketSubTotal, outletId, appliedDiscount }) => {
  const { t } = useTranslation('discounts')
  const marketplace = useMarketplace()
  const { discounts, updateDiscount } = useDiscounts()
  const [discountCodeOpen, setDiscountCodeOpen] = useState(false)
  const [discountError, setDiscountError] = useState<string | undefined>('')

  const [verifyVoucherCode, { loading }] = useLazyQuery(
    CheckoutVerifyVoucherCodeDocument
  )

  const formatCurrency = useFormatCurrency()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (discountError) {
      timeoutId = setTimeout(() => {
        setDiscountError('')
      }, 6000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [discountError])

  return (
    <Container>
      {appliedDiscount && appliedDiscount.id === discounts.voucherDiscountId ? (
        <SpaceBetweenRow>
          <ActiveDiscountContainer>
            <CheckSVG id="check" />
            <ActiveDiscount>{t('discount_applied')}</ActiveDiscount>
            <DotSpan />
            <ActiveDiscountName>{appliedDiscount.name}</ActiveDiscountName>
          </ActiveDiscountContainer>
          <ClearButton
            onClick={() => {
              updateDiscount({
                type: DiscountType.VOUCHER_DISCOUNT,
                data: null,
              })
            }}
            id={t('clear')}
          />
        </SpaceBetweenRow>
      ) : (
        <SpaceBetweenButton
          onClick={() => setDiscountCodeOpen(!discountCodeOpen)}
        >
          <Title>{t('have_a_discount')}</Title>
        </SpaceBetweenButton>
      )}
      {discountCodeOpen && (
        <Formik
          initialValues={{ discount: '' }}
          onSubmit={async values => {
            // no discount entered
            if (!values.discount) {
              setDiscountError(t('invalid_discount'))
              return
            }
            const { data, error } = await verifyVoucherCode({
              variables: {
                key: values.discount,
                marketplaceId: marketplace.id,
                outletId: outletId,
              },
            })
            const parsedErrors = error ? apolloErrorParser(error) : null
            // error from server
            if (!data || parsedErrors?.genericClientErrors?.length) {
              setDiscountError(
                parsedErrors?.genericClientErrors?.[0]?.message ||
                  t('discount_error')
              )
              return
            }
            if (data) {
              const minimumSubtotalGross =
                data.isVoucherValid.minimumSubtotalGross || 0
              if (basketSubTotal < minimumSubtotalGross) {
                setDiscountError(
                  t('discount_minimum_not_met_error', {
                    minimumSubtotalGross: formatCurrency(minimumSubtotalGross),
                  })
                )
                return
              }
              updateDiscount({
                type: DiscountType.VOUCHER_DISCOUNT,
                data: data.isVoucherValid.id,
              })
              toast.success(t('discount_successfully_applied'))
              setDiscountCodeOpen(false)
            }
          }}
        >
          <DiscountForm>
            <TextInput
              name="discount"
              label={t('enter_discount_code')}
              disabled={loading}
              autoComplete="off"
              fullWidth
              toUpperCase
              error={discountError}
            />
            <ApplyButton
              content={toUpper(t('apply'))}
              type="submit"
              disabled={loading}
              buttonType={ButtonType.SECONDARY}
            />
          </DiscountForm>
        </Formik>
      )}
    </Container>
  )
}
