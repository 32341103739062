import { format, isToday, isTomorrow } from 'date-fns'

export const calendarString = (dateToFormat: string | number | Date) => {
  if (isToday(new Date(dateToFormat))) {
    return 'today'
  } else if (isTomorrow(new Date(dateToFormat))) {
    return 'tomorrow'
  } else {
    return `${format(new Date(dateToFormat), 'EEEE')}`
  }
}
