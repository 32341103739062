import React from 'react'

export const CheckSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      className={className}
    >
      <title id={`${id}Title`}>{title || 'check mark'}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <rect width="24" height="24" rx="12" fill="#2E3333" />
      <path
        d="M9.93195 15.0013L7.06832 11.8669L6.11377 12.9117L9.93195 17.0908L18.1138 8.1356L17.1592 7.09082L9.93195 15.0013Z"
        fill="white"
      />
    </svg>
  )
}
