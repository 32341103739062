export const supportForm = {
  ok: 'Okay',
  help_question: 'What can we help you with?',
  faq: 'Get in touch if you need assistance or wish to query an order.',
  subject: {
    validation: {
      required_error: 'Please enter a subject',
    },
  },
  first_name: {
    label: 'First Name',
    validation: {
      required_error: 'Please enter your first name',
      minimum_required_characters: 'Needs to be 2 letters or more',
    },
  },
  last_name: {
    label: 'Last Name',
    validation: {
      required_error: 'Please enter your last name',
      minimum_required_characters: 'Needs to be 2 letters or more',
    },
  },
  phone_number: {
    label: 'Mobile Number',
    validation: {
      required_error: 'Please enter your mobile number',
      invalid_type_error: 'Invalid phone number',
    },
  },
  email_address: {
    label: 'Email Address',
    validation: {
      required_error: 'Please enter your email address',
      invalid_type_error: 'Invalid email address',
    },
  },
  order_number: {
    label: 'Order Number',
    validation: {
      required_error: 'Please enter your order number',
    },
  },
  message: {
    label: 'Message',
    validation: {
      required_error: 'Please enter your message',
      minimum_required_characters: 'Needs to be 2 letters or more',
    },
  },
  submit: {
    label: 'Send Message',
    status: {
      error: 'Error sending support message',
      error_content:
        'Something went wrong sending the support message, please try again',
      success: 'Support message sent',
      success_content:
        'Your support message has been sent, a member of our team will be in contact.',
    },
  },
  subjectOptions: {
    order_issues: 'Order Issues',
    payment_issues: 'Payment Issues',
    account_questions: 'Account Questions',
    website_questions: 'Website Questions',
    something_else: 'Something Else',
  },
}
