import React from 'react'

import { AlphabetContainer, AlphabetLetter } from './CategoriesModal.styles'

export const AlphabetTag: React.VFC<{ activeLetters: string[] }> = ({
  activeLetters,
}) => {
  const handleCategoryScroll = (letter: string) => {
    const el = document.getElementById(letter)

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <AlphabetContainer>
      {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(letter => (
        <AlphabetLetter
          onClick={() => handleCategoryScroll(letter)}
          key={letter}
          disabled={!activeLetters.includes(letter)}
        >
          {letter}
        </AlphabetLetter>
      ))}
    </AlphabetContainer>
  )
}
