import { startOfDay } from 'date-fns'

import {
  DefaultDatepickerOption,
  DefaultFulfilmentOption,
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'

import { fulfilmentFilterReactiveVar } from './utils/reactiveVar'
import { FulfilmentFilter, FulfilmentFilterWhenType } from './validation'

import { MarketplaceContextData } from '../useMarketplace'

/**
 * Initialises the `fulfilmentFilter` reactive variable state.
 *
 * This function uses data from either session storage or marketplace defaults to set the state.
 * If `resetToMarketplaceDefaults` is set to true, the function will ignore the session storage and use marketplace defaults.
 *
 * @param {MarketplaceContextData} marketplace - The marketplace data. ie the return value from the `useMarketplace` hook
 * @param {boolean} resetToMarketplaceDefaults - A flag which forces the state back to marketplace defaults, skipping session storage.
 *
 * @returns {void} This function does not return anything.
 */
export const initialiseFulfilmentFilter = (
  marketplace: MarketplaceContextData,
  customerDefaultAddressId: string | null,
  forceUpdate: () => void
): void => {
  if (fulfilmentFilterReactiveVar().isInitialised) {
    return
  }

  /**
   * Init from marketplace defaults
   */
  let when: FulfilmentFilter['when'] = {
    type: FulfilmentFilterWhenType.ANYTIME,
  }
  if (marketplace.defaultDatepickerOption === DefaultDatepickerOption.ASAP) {
    when = {
      type: FulfilmentFilterWhenType.ASAP,
    }
  }
  if (
    marketplace.defaultDatepickerOption === DefaultDatepickerOption.PREORDER
  ) {
    when = {
      type: FulfilmentFilterWhenType.PREORDER,
      preorderDate: startOfDay(new Date()),
    }
  }

  /**
   * Set the reactive var state
   */
  fulfilmentFilterReactiveVar({
    isInitialised: true,
    where: {
      location: customerDefaultAddressId
        ? {
            type: LocationType.ADDRESS,
            addressId: customerDefaultAddressId,
          }
        : {
            type: LocationType.EVERYWHERE,
          },
      fulfilmentMethods: [
        ...([
          DefaultFulfilmentOption.ALL,
          DefaultFulfilmentOption.DELIVERY,
        ].includes(marketplace.defaultFulfilmentOption)
          ? [NarrowFulfilmentMethodInputType.DELIVERY]
          : []),
        ...([
          DefaultFulfilmentOption.ALL,
          DefaultFulfilmentOption.COLLECTION,
        ].includes(marketplace.defaultFulfilmentOption)
          ? [NarrowFulfilmentMethodInputType.COLLECTION]
          : []),
        ...([
          DefaultFulfilmentOption.ALL,
          DefaultFulfilmentOption.TABLE,
        ].includes(marketplace.defaultFulfilmentOption) &&
        marketplace.allowOrderToTable
          ? [NarrowFulfilmentMethodInputType.TABLE]
          : []),
      ],
      historicalData: {
        postAndCountryCode: null,
        coordinates: null,
        addressId: customerDefaultAddressId ? customerDefaultAddressId : null,
        zoneId: null,
      },
    },
    when,
  })

  forceUpdate()
}
