import styled from 'styled-components'

import { Button } from '@src/components/Button/Button'

export const SectionContainer = styled.div`
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px 16px 4px 16px;
  background: #f6f6f9;
`

export const SectionTitle = styled.h4`
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`

export const SectionDescription = styled.div`
  margin-bottom: 18px;
  line-height: 20px;
`

export const UpdateButton = styled(Button)`
  margin-top: 16px;
`

export const Container = styled.form`
  margin-top: 18px;
`
