export const paymentDetails = {
  ok: 'Okay',
  saved_cards: 'Saved Cards',
  edit: 'Edit',
  add_card: 'Add Card',
  add: 'Add',
  expired: 'Expired',
  security_code: 'Security Code',
  security_desc: '3 digits on back or 4 digits on front of card',
  delete_card: 'Delete Card?',
  delete_card_desc: 'Are you sure you want to remove this card?',
  stripe_error: 'Stripe could not be loaded',
  card_add_error: 'Sorry, we are unable to add this card',
  card_deletion_error: 'Sorry, we are unable to delete this card',
  card_deletion_success: 'Card successfully deleted',
  please_wait: 'Please wait...',
  delete: 'Delete',
  cancel: 'Cancel',
  card_issue: 'Card Issue',
}
