import React from 'react'

import { useTablesQuery } from '@src/hooks/sharedQueries/useTables/useTables'

export const FulfilmentWhereLabelTable: React.FC<{
  tableId: string
  outletId: string
}> = ({ tableId, outletId }) => {
  const outletTablesQueryResponse = useTablesQuery({
    outletId,
  })

  if (outletTablesQueryResponse.loading) {
    return <span>{'Loading...'}</span>
  }
  if (outletTablesQueryResponse.error || !outletTablesQueryResponse.data) {
    return null
  }

  const selectedOutletTable = outletTablesQueryResponse.data.tables.find(
    table => table.id === tableId
  )

  return <span>{selectedOutletTable?.friendlyName || 'Table not found'}</span>
}
