import { Scrollbars } from 'react-custom-scrollbars-2'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { ModalPosition } from '@src/components/Modal/Modal'
import { ArrowLeftSVG } from '@src/components/SVGS/ArrowLeftSVG'
import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

const dialogOverlayInnerDictionary: {
  [key in ModalPosition]: FlattenSimpleInterpolation
} = {
  LEFT: css`
    left: 0;
    top: 0;
    height: 100vh;
    border-radius: 0px 0px 8px 0px;

    @media (min-width: ${breakpoints.tablet}px) {
      min-width: 430px;
      width: fit-content;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      width: fit-content;
    }
  `,
  RIGHT: css`
    right: 0;
    top: 0;
    height: 100vh;

    @media (min-width: ${breakpoints.tablet}px) {
      width: 565px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      padding: 0 40px;
      width: 645px;
    }
  `,
  CENTER: css`
    padding: 10pt;
    height: 90vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 80%;

    @media (min-width: ${breakpoints.tablet}px) {
      width: 616px;
      padding: 0px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      height: auto;
      max-height: 85vh;
      width: 904px;
      max-width: 776px;
    }
  `,
  CATEGORIES: css`
    right: 80px;
    top: 55%;
    border-radius: 24px;
    border-bottom: 24px solid white;
    transform: translate(0%, 0%);
    height: 60vh;
    padding: 0px;
    width: 912px;
    margin-top: 42px;

    @media (min-width: ${breakpoints.wideDesktop}px) {
      margin-top: 7px;
      margin-right: 480px;
      top: 56px !important;
    }
  `,
}

export const DialogOverlayInner = styled.div<{
  position: ModalPosition
  height: number
  $categoriesOnFarRight: boolean
}>`
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 4;
  ${removeScrollbar}

  ${({ position, $categoriesOnFarRight }) =>
    css`
      ${dialogOverlayInnerDictionary[position]}

      ${position === ModalPosition.CATEGORIES &&
      css`
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
      `}

      @media (min-width: ${breakpoints.wideDesktop}px) {
        ${position === ModalPosition.CATEGORIES &&
        $categoriesOnFarRight &&
        css`
          right: -400px;
          top: 56px !important;
        `}
      }
    `}
  

 ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #2e3333;
  opacity: 0.3;
  z-index: 4;
`

export const CloseControls = styled.div<{ activeHeader?: boolean }>`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${props => (props.activeHeader ? '#ebecf0' : '#fff')};
`

export const DialogBackButton = styled.div<{ position: ModalPosition }>`
  height: 64px;
  padding-left: 24px;
  margin-right: auto;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 64px;
  cursor: pointer;

  ${props =>
    props.position === ModalPosition.CENTER &&
    css`
      @media (min-width: ${breakpoints.tablet}px) {
        display: none;
      }
    `}

  @media (min-width: ${breakpoints.desktop}) {
    height: 80px;
    line-height: 80px;
    font-size: 16px;
  }

  img {
    vertical-align: middle;
  }
`

export const StyledArrowLeftSVG = styled(ArrowLeftSVG)`
  margin: 16px;
  vertical-align: middle;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 24px 0 24px 0;
  }
`

export const Slash = styled.span`
  padding: 0 8px;
  color: #c8c8c8;
`

export const StyledScrollBars = styled(Scrollbars)<{ width?: number }>`
  width: ${props => (props.width ? `${props.width}px` : '100%')} !important;
  max-width: 100%;
  height: 100%;
`
