import React from 'react'

import { OrderMode } from '@src/../__generated__/globalTypes'
import { Redirect } from '@src/components/Router/Redirect'
import { useMarketplace } from '@src/hooks/useMarketplace'

export const MerchantCategoryRedirect: React.FC<{
  deliveryZone: string
}> = ({ deliveryZone }) => {
  const { urlPath, orderMode } = useMarketplace()
  let path = '/'
  switch (orderMode) {
    case OrderMode.POSTCODE:
      path = `/${urlPath}/all`
      break
    case OrderMode.LIST:
      path = `/${deliveryZone}/${urlPath}/all`
      break
    // TODO add route for single outlet
    case OrderMode.SINGLE:
    default:
      break
  }

  return <Redirect to={path} />
}
