export const errors = {
  ok: 'Okay',
  marketplace_not_found: 'Marketplace not found',
  menu_item_not_found: 'Menu Item Not found',
  menu_item_error: 'Error Loading Menu Item',
  no_outlets_categories: 'No outlets are available for your search',
  getting_location_error:
    'Could not get coordinates. Check browser has location permissions enabled.',
  location_error: 'Location Error',
} as const
