import * as Types from '../../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type outletMenuItemOptionsQueryVariables = Types.Exact<{
  outletMenuItemId: Types.Scalars['String']
}>

export type outletMenuItemOptionsQuery = {
  optionsByOutletMenuItemId: Array<{
    __typename: 'Option'
    id: string
    name: string
    minOptions: number
    maxOptions: number
    optionItems: Array<{
      __typename: 'OptionItem'
      id: string
      name: string
      price: number
      soldOut: boolean | null
    }>
  }>
}

export const outletMenuItemOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'outletMenuItemOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletMenuItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optionsByOutletMenuItemId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletMenuItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletMenuItemId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minOptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxOptions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optionItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'soldOut' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  outletMenuItemOptionsQuery,
  outletMenuItemOptionsQueryVariables
>
