import React, { useState, useEffect } from 'react'

import { OpeningTime } from '@src/graphql-types'
import {
  getAsapDeliveryWindow,
  getAsapFulfilmentTime,
} from '@src/utils/fulfilmentTimes/getASAPTimes'

const ONE_MINUTE = 60 * 1000

const getASAPDeliveryOrCollectionTime = (args: LiveFormattedASAPLabelArgs) =>
  args.isDelivery
    ? getAsapDeliveryWindow(args)?.end
    : getAsapFulfilmentTime(args)

// This component displays a formatted string
// representing the current fulfilment time for ASAP delivery / collection (don't pass deliveryDuration)
// It updates every minute (though due to rounding this will only be visibly updated every ~5 minutes)
type LiveFormattedASAPLabelArgs = {
  prepTime: number
  isDelivery?: boolean
  openingTimes: OpeningTime[]
  format: (date: Date) => string
}
export const LiveFormattedASAPLabel: React.FC<LiveFormattedASAPLabelArgs> =
  args => {
    const [roundedFulfilmentTime, setRoundedFulfilmentTime] = useState(
      getASAPDeliveryOrCollectionTime(args)
    )

    useEffect(() => {
      const updateTime = () => {
        setRoundedFulfilmentTime(getASAPDeliveryOrCollectionTime(args))
      }

      updateTime()

      const interval = setInterval(updateTime, ONE_MINUTE)
      return () => clearInterval(interval)
    }, [args])

    if (!roundedFulfilmentTime) {
      return null
    }

    const formattedFulfilmentTime = args.format(roundedFulfilmentTime)

    return <>{formattedFulfilmentTime}</>
  }
