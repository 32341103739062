import * as Types from '../../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type DeliveryEstimateForAddressQueryVariables = Types.Exact<{
  addressId: Types.Scalars['String']
  outletId: Types.Scalars['String']
  preorderFor?: Types.InputMaybe<Types.Scalars['DateTime']>
}>

export type DeliveryEstimateForAddressQuery = {
  deliveryEstimateForAddress: {
    __typename: 'DeliveryCost'
    id: string
    type: Types.DeliveryCostType
  }
}

export const DeliveryEstimateForAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeliveryEstimateForAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preorderFor' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryEstimateForAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preorderFor' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'preorderFor' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeliveryEstimateForAddressQuery,
  DeliveryEstimateForAddressQueryVariables
>
