import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type AddressBookUpdateAddressMutationVariables = Types.Exact<{
  input: Types.EditAddressInput
}>

export type AddressBookUpdateAddressMutation = {
  editAddress: {
    __typename: 'EditAddressResponse'
    message: string
    address: {
      __typename: 'Address'
      id: string
      name: string
      firstLine: string
      secondLine: string | null
      thirdLine: string | null
      city: string
      countryCode: Types.CountryCodeType
      postcode: string
      default: boolean
    }
  }
}

export const AddressBookUpdateAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddressBookUpdateAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditAddressInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thirdLine' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddressBookUpdateAddressMutation,
  AddressBookUpdateAddressMutationVariables
>
