import { useQuery } from '@apollo/client'

import { CheckoutRouteOrderDocument } from './queries/__generated__/CheckoutRouteOrder.graphql-interface'

export const useVerifyPaymentRedirect = ({
  orderId,
  redirectBackToPaymentRoute,
  redirectToCompleteRoute,
}: {
  orderId: string | null
  redirectBackToPaymentRoute: (errorMessage: string) => void
  redirectToCompleteRoute: () => void
}): void => {
  useQuery(
    CheckoutRouteOrderDocument,
    orderId
      ? {
          variables: { orderId },
          // redirect to payment page with error if there was an error processing the payment
          onCompleted: data => {
            if (data.order.payment.errorMessage) {
              redirectBackToPaymentRoute(data.order.payment.errorMessage)
            } else {
              redirectToCompleteRoute()
            }
          },
          onError: () => {
            redirectBackToPaymentRoute('Failed To Verify Payment')
          },
        }
      : {
          skip: true,
        }
  )
}
