import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { Markdown } from '@src/components/Markdown/Markdown'

import { getCookiesDocument } from './queries/__generated__/getCookies.graphql-interface'

import { BurgerSection } from '../../Drawer/DrawerHeader'

export const Cookies: React.FC = () => {
  const { t } = useTranslation('policies')

  const { data, loading, error } = useQuery(getCookiesDocument, {
    variables: { cname: window.location.hostname },
  })
  if (loading) return <LoadingSpinner />
  if (error || !data) return <ErrorPage />

  const cookiesCopy = data.marketplaceByCname.legalCookies

  return (
    <BurgerSection header={{ title: t('cookies') }}>
      {cookiesCopy ? (
        <Markdown markdown={cookiesCopy} twoCols />
      ) : (
        t('cookies_fallback')
      )}
    </BurgerSection>
  )
}
