import { format, setISODay } from 'date-fns'
import { flatMap, groupBy } from 'lodash'

import { OpeningTime } from '@src/graphql-types'

export const formatOpeningTimes = (
  openingTimes: OpeningTime[]
): {
  day: string
  times: string[] | undefined
}[] => {
  // sort times in to the correct order first, as we overwrite day as number below
  const sortedOpeningTimes = [...openingTimes].sort(
    (a, b) => a.start.day - b.start.day
  )

  const timesWithFormattedDays = sortedOpeningTimes.map(({ start, end }) => {
    const startDayName = getWeekdayFromNumber(start.day)

    // If the window does not overlap 2 days, format and return
    if (start.day === end.day) {
      return {
        day: startDayName,
        time: `${start.time} - ${end.time}`,
      }
    } else {
      // otherwise return two opening times finishing and starting at midnight
      const endDayName = getWeekdayFromNumber(end.day)
      return [
        {
          day: startDayName,
          time: `${start.time} - 00:00`,
        },
        { day: endDayName, time: `00:00 - ${end.time}` },
      ]
    }
  })

  // Group times by day name and make readable
  const groupedTimes = groupBy(
    flatMap(timesWithFormattedDays),
    ({ day }) => day
  )
  const formattedTimes = Object.keys(groupedTimes).map(key => ({
    day: key,
    times: groupedTimes[key]
      ?.map(({ time }) => time)
      .sort((a, b) => a.localeCompare(b)),
  }))

  return formattedTimes
}

const getWeekdayFromNumber = (day: number) => {
  return format(setISODay(new Date(), day), 'EEEE')
}
