import styled, { css } from 'styled-components'

import { Button } from '@src/components/Button/Button'
import { Link } from '@src/components/Router/Link'

export const Container = styled.div<{ isCheckout?: boolean }>`
  padding: 0 32px 0 32px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-content: center;

  ${({ isCheckout }) =>
    isCheckout &&
    css`
      min-height: auto;
      padding: 0 32px;
    `}
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Header = styled.h1`
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors.mainText};
  margin: 16px 0px;
`

export const ForgotPasswordButton = styled(Link)`
  all: unset;
  text-decoration-line: underline;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.mainText};
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  text-underline-position: under;
`

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
  margin-top: 28px;
  margin-bottom: 32px;
`

export const SubmitButton = styled(Button)<{ isCheckout?: boolean }>`
  margin: 16px 0px;
  height: 56px;
  font-size: 18px;
  font-weight: 700;
`

export const TopRow = styled.div`
  margin-top: 32px;
  margin-bottom: 28px;
  line-height: 32px;
  text-align: left;
`

export const JoinNowLink = styled(Link)`
  all: unset;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ theme }) => theme.colors.mainText};
  cursor: pointer;
`

export const CreateAccountButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mainText};
  border: none;
  background-color: #fff;
  cursor: pointer;
  text-align: left;
  padding: 0px;
  margin: 16px 0;
`

export const SignUpLink = styled.span`
  text-decoration-line: underline;
  text-underline-position: under;
  font-weight: 700;
  font-size: 18px;
`
