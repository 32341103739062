import { differenceInDays, startOfDay } from 'date-fns'

import { ParsedCollectionPreorderTime } from '@src/utils/fulfilmentTimes/types'

export type ServiceMenuTab = {
  keyword: string
  title: string
}

export const getServiceMenuTabByDate = (
  date: Date,
  deliveryWindows: ParsedCollectionPreorderTime[],
  asapAllowed: boolean,
  isOpen: boolean,
  nowText: string,
  todayText: string,
  tommorowText: string,
  scheduleText: string
): ServiceMenuTab | null => {
  const serviceMenuTabs = getServiceMenuTabs(
    deliveryWindows,
    asapAllowed,
    isOpen,
    nowText,
    todayText,
    tommorowText,
    scheduleText
  )
  const findServiceMenuTabByKeyword = (keyword: string) =>
    serviceMenuTabs.find(tab => tab.keyword === keyword) || null
  const daysUntilSelectedDate = differenceInDays(
    startOfDay(date),
    startOfDay(new Date())
  )
  if (daysUntilSelectedDate === 0) {
    return findServiceMenuTabByKeyword('today')
  }
  if (daysUntilSelectedDate === 1) {
    return findServiceMenuTabByKeyword('tomorrow')
  }
  return findServiceMenuTabByKeyword('schedule')
}

const getServiceMenuTabs = (
  times: ParsedCollectionPreorderTime[],
  asapAllowed: boolean,
  isOpen = true,
  nowText: string,
  todayText: string,
  tommorowText: string,
  scheduleText: string
): ServiceMenuTab[] => {
  const tabsByKeyword = times.reduce<{
    today?: ServiceMenuTab
    tomorrow?: ServiceMenuTab
    schedule?: ServiceMenuTab
  }>((acc, time) => {
    const daysUntilWindow = differenceInDays(
      startOfDay(startOfDay(time)),
      startOfDay(new Date())
    )
    if (!acc.today && daysUntilWindow === 0) {
      acc.today = {
        keyword: 'today',
        title: todayText,
      }
    }
    if (!acc.tomorrow && daysUntilWindow === 1) {
      acc.tomorrow = {
        keyword: 'tomorrow',
        title: tommorowText,
      }
    }
    if (!acc.schedule && daysUntilWindow > 1) {
      acc.schedule = {
        keyword: 'schedule',
        title: scheduleText,
      }
    }
    return acc
  }, {})
  return [
    ...(isOpen && asapAllowed ? [{ keyword: 'asap', title: nowText }] : []),
    ...(tabsByKeyword.today ? [tabsByKeyword.today] : []),
    ...(tabsByKeyword.tomorrow ? [tabsByKeyword.tomorrow] : []),
    ...(tabsByKeyword.schedule ? [tabsByKeyword.schedule] : []),
  ]
}
