import { createContext } from 'react'

import { MarketplaceCnameLookupFragment } from './queries/__generated__/MarketplaceCnameLookupFragment'

export type MarketplaceContextData = MarketplaceCnameLookupFragment & {
  businessId?: string
  outletId?: string
}

// context used to store fetched marketplace data
export const MarketplaceContext =
  createContext<null | MarketplaceContextData>(null)
