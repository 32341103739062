import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { Markdown } from '@src/components/Markdown/Markdown'

import { getPrivacyDocument } from './queries/__generated__/getPrivacy.graphql-interface'

const Content = (): JSX.Element => {
  const { loading, data, error } = useQuery(getPrivacyDocument, {
    variables: { cname: window.location.hostname },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorPage errorMessage={error.message} />
  }

  if (
    !data ||
    data?.marketplaceByCname?.legalPrivacy === null ||
    data.marketplaceByCname.legalPrivacy.trim().length === 0
  ) {
    return <ErrorPage />
  }

  return <Markdown markdown={data.marketplaceByCname.legalPrivacy} twoCols />
}

export const Privacy = (): JSX.Element => {
  const { t } = useTranslation('policies')

  return (
    <BurgerSection header={{ title: t('privacy') }}>
      <Content />
    </BurgerSection>
  )
}
