import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const ResetLink = styled.a<{ disabled?: boolean }>`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px;
  text-decoration: underline;
  margin-left: 16px;
  cursor: pointer;
  ${props =>
    props.disabled
      ? `  
        pointer-events: none;
        cursor: default; 
        color: #2E3333;
        opacity: 0.2`
      : undefined};
  @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop}px) {
    font-weight: 700;
    line-height: 48px;
    margin: 0;
  }
`
