import { z } from 'zod'

// regex is a bit too heavy for this many fields, hex codes are pretty simple anyway
export const HexColorCode = z.string().refine(
  value => {
    // optional chaining is required cause the v2 client has strict null checks
    const lowercasedValue = value.split('#')?.[1]?.toLowerCase() || ''

    if (lowercasedValue.length !== 3 && lowercasedValue.length !== 6) {
      return false
    }
    return [...lowercasedValue].every(char => '0123456789abcdef'.includes(char))
  },
  {
    message: 'Invalid hex color code',
  }
)
