import * as Types from '../../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetOutletMenuItemQueryVariables = Types.Exact<{
  outletId: Types.Scalars['ID']
  menuItemId: Types.Scalars['ID']
}>

export type GetOutletMenuItemQuery = {
  outletMenuItem: {
    __typename: 'OutletMenuItem'
    id: string
    menuItemId: string
    name: string
    price: number
    allergens: Array<Types.Allergen> | null
    soldOut: boolean | null
    ageRestricted: boolean | null
    image: string | null
    description: string | null
  }
}

export const GetOutletMenuItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOutletMenuItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'menuItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletMenuItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'menuItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'menuItemId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'menuItemId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allergens' } },
                { kind: 'Field', name: { kind: 'Name', value: 'soldOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ageRestricted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOutletMenuItemQuery,
  GetOutletMenuItemQueryVariables
>
