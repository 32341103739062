import styled from 'styled-components'

import { CircleArrowLeftSVG } from '@src/components/SVGS/CircleArrowLeftSVG'
import { CircleCrossSVG } from '@src/components/SVGS/CircleCrossSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const ActionBar = styled.div`
  box-sizing: border-box;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 12;
  background: ${({ theme }) => theme.colors.brandLight};
  justify-content: space-between;
  height: 60px;

  a {
    margin-top: 4px;
  }
`

export const StyledButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-top: 4px;
`

export const StyledArrowLeftSVG = styled(CircleArrowLeftSVG)`
  circle,
  path {
    transition: 0.2s ease;
    transition-property: fill;
  }

  circle {
    fill: #fff;
    stroke: #fff;
  }

  path {
    fill: ${({ theme }) => theme.colors.brand};
  }

  &:active,
  &:focus {
    circle {
      fill: ${({ theme }) => theme.colors.brand};
      stroke: ${({ theme }) => theme.colors.brand};
    }
    path {
      fill: #fff;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      circle {
        fill: ${({ theme }) => theme.colors.brand};
        stroke: ${({ theme }) => theme.colors.brand};
      }
      path {
        fill: #fff;
      }
    }
  }
`

export const StyledCircleCrossSVG = styled(CircleCrossSVG)`
  .circle,
  .cross {
    transition: 0.2s ease;
    transition-property: fill;
  }

  .circle {
    fill: #fff;
    stroke: #fff;
  }

  .cross {
    fill: ${({ theme }) => theme.colors.brand};
  }

  :hover {
    circle {
      fill: ${({ theme }) => theme.colors.brand};
      stroke: ${({ theme }) => theme.colors.brand};
    }
    path {
      fill: #fff;
    }
  }
`

export const Container = styled.div`
  min-height: 100vh;
  color: ${({ theme }) => theme.colors.mainText};
  background-color: #fff;
`

export const Header = styled.div<{
  twoLineHeader?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 0px 16px;
  height: auto;
  position: sticky;
  background: #ffff;
  z-index: 11;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 430px;
    padding: 0px 32px;
  }
`

export const Title = styled.h1<{ bigPanel?: boolean }>`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding: 0 0 0 0px;
  color: ${({ theme }) => theme.colors.brand};

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 0 0 0 16px;
  }
`

export const SubTitle = styled.h4`
  margin: 0;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
`

export const SecondRow = styled.div`
  width: 100%;
`

export const ThirdRow = styled.div`
  width: 100%;
`

export const Content = styled.div<{
  whiteBackgroundColor?: boolean
  noPadding?: boolean
  twoLineHeader?: boolean
}>`
  padding: ${props => (props.noPadding ? '0' : '16px 16px 64px 16px;')};
  margin: ${props => (props.twoLineHeader ? '16px 0 16px 0' : '0px 0 24px 0')};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${props => (props.noPadding ? '0' : '32px 32px 64px 32px;')};
    margin: ${props =>
      props.twoLineHeader ? '64px 0 16px 0' : '0px 0 24px 0'};
  }
`
