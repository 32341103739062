import styled from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  @media (max-width: ${breakpoints.desktop}px) {
    width: 100%;
  }
`

export const SubTitle = styled.p`
  font-size: 34px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
`

export const InnerContainer = styled.div`
  margin: 0px;
  height: 70%;
  overflow-x: scroll;
  padding: 48px 32px;
`

export const FAQsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 48px 0px;

  @media (max-width: ${breakpoints.desktop}px) {
    margin: 32px 0px;
  }
`

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px;

  @media (min-width: ${breakpoints.desktop}px) {
    background-color: #ebecf0;
    padding-top: 24px;
  }
`

export const TabLink = styled(AccountSectionNavButton)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  margin: 0px 4px;
  padding: 16px;

  @media (max-width: ${breakpoints.desktop}px) {
    font-size: 16px;
    padding: 16px 24px;
    margin: 0px 8px;
  }

  &.active {
    padding: 16px 24px 12px 24px;
    border-bottom: 4px solid;
  }
`

export const FAQContainer = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.desktop}px) {
    width: 100%;
    margin-bottom: 32px;
  }
`

export const FAQHeader = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 0px 0 4px 0;

  @media (max-width: ${breakpoints.desktop}px) {
    font-size: 16px;
  }
`

export const FAQAnswer = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;

  @media (max-width: ${breakpoints.desktop}px) {
    font-size: 14px;
    line-height: 21px;
  }
`
