import React from 'react'

export const NoneFoundSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'none-found', id, className, desc }) => (
  <svg
    id={id}
    className={className}
    height="91"
    viewBox="0 0 106 91"
    width="106"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={`${id}Title`}>{title}</title>
    {desc && <desc id={id}>{desc}</desc>}
    <g fill="none" fillRule="evenodd">
      <path d="m-2-2h94v94h-94z" />
      <g fillRule="nonzero">
        <g>
          <path
            d="m33.836087.08608696c-.1862528-.05774926-.3802185-.08609902-.5752174-.08609902v74.43783816l24.8595652 7.65c.186475.0567707.3802924.0860869.5752174.0860869v-74.43782604z"
            fill="#e0dfdf"
          />
          <g fill="#eeecec">
            <path d="m32.7854348.05869565-31.30434784 7.82608696c-.8705173.21807213-1.48108696 1.0004099-1.48108696 1.89782609v70.4347826c0 .6026375.277748 1.1716431.75284379 1.5423936.47509579.3707506 1.0945385.5018734 1.67911273.3554325l30.77413048-7.6930435.0547826.0156522v-74.4378261c-.1602843 0-.3199591.01972976-.4754348.05869565z" />
            <path d="m89.235.405c-.4821489-.37021995-1.1089904-.49529905-1.6963043-.33847826l-28.8039131 7.68130435-.0391304-.01173913v74.43792504c.1702999.0016349.3400665-.0194214.5047826-.0627077l29.3478261-7.8260869c.8574541-.2289735 1.4533851-1.0064144 1.4517425-1.8939131v-70.43478256c0-.60793867-.2828045-1.18128709-.7650034-1.55152174z" />
          </g>
        </g>
        <g transform="translate(64 49)">
          <circle cx="15.5" cy="15.5" fill="#eeecec" r="13.5" />
          <g fill="currentColor">
            <path
              d="m29.1260607 23.0019992 6.9931201-.0043687-.0068799 18.3982614-6.9931201.0043687z"
              transform="matrix(.70713146 -.7070821 .7070821 .70713146 -13.214166 32.494497)"
            />
            <path d="m16 32c-8.836556 0-16-7.163444-16-16s7.163444-16 16-16 16 7.163444 16 16c-.0100769 8.8323786-7.1676214 15.9899231-16 16zm0-27.42857143c-6.31182571 0-11.42857143 5.11674572-11.42857143 11.42857143 0 6.3118257 5.11674572 11.4285714 11.42857143 11.4285714 6.3118257 0 11.4285714-5.1167457 11.4285714-11.4285714-.0075568-6.30869292-5.1198785-11.42101459-11.4285714-11.42857143z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
