import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTransition, animated } from 'react-spring'

import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  CheckoutFooter,
  CheckoutFooterContainer,
  LargeFooterContainer,
  SafeAreaContainer,
} from './BasketSummary/BasketSummary.styles'

export const CheckoutButtonWrapperMobile: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const screenResolution = useReactiveVar(screenResolutionVar)

  const footerTransitions = useTransition(true, {
    from: { opacity: '1', bottom: '-80px' },
    enter: { opacity: '1', bottom: '0' },
    config: { duration: 300 },
    reverse: false,
    delay: 200,
    immediate: true,
  })

  const AnimatedCheckoutFooter = animated(CheckoutFooter)
  const AnimatedCheckoutFooterContainer = animated(CheckoutFooterContainer)

  return (
    <>
      {screenResolution.width >= breakpoints.desktop ? (
        <SafeAreaContainer>
          <LargeFooterContainer>{children}</LargeFooterContainer>
        </SafeAreaContainer>
      ) : (
        footerTransitions(
          (styles, item) =>
            item && (
              <SafeAreaContainer>
                <AnimatedCheckoutFooterContainer style={styles}>
                  <AnimatedCheckoutFooter style={styles}>
                    {children}
                  </AnimatedCheckoutFooter>
                </AnimatedCheckoutFooterContainer>
              </SafeAreaContainer>
            )
        )
      )}
    </>
  )
}
