import React from 'react'
import { Redirect as RouterRedirect } from 'react-router-dom'

export const Redirect: React.FC<
  React.ComponentProps<typeof RouterRedirect> & {
    passthroughQueryParams?: boolean
    to: string | { pathname: string; state?: any; search?: string }
  }
> = ({ passthroughQueryParams = true, ...props }) => {
  const [pathname, search] =
    typeof props.to === 'string'
      ? props.to.split('?')
      : [props.to.pathname, props.to.search]

  return (
    <RouterRedirect
      {...props}
      to={{
        pathname,
        search: passthroughQueryParams ? window.location.search : search,
        ...(typeof props.to !== 'string' && props.to),
      }}
    />
  )
}
