import React from 'react'
import styled from 'styled-components'
import { FreeMode, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import 'swiper/css'
import 'swiper/css/free-mode'

const StyledSwiper = styled(Swiper)<{ isCheckout?: boolean }>`
  .swiper-slide {
    width: auto;
  }
`

export const Carousel: React.VFC<{
  slides: JSX.Element[]
  buttonLeftClassName?: string
  buttonRightClassName?: string
  setSwiperInstance?: (value: SwiperType | null) => void
  allowTouchMove?: boolean
}> = ({
  slides,
  buttonLeftClassName,
  buttonRightClassName,
  setSwiperInstance,
  allowTouchMove = true,
}) => {
  return (
    <StyledSwiper
      allowTouchMove={allowTouchMove}
      modules={[FreeMode, Navigation]}
      freeMode={true}
      className={`mySwiper${buttonLeftClassName ?? ''}`}
      slidesPerView={'auto'}
      simulateTouch={false}
      initialSlide={0}
      navigation={{
        prevEl: buttonLeftClassName
          ? `.${buttonLeftClassName}`
          : '.swiper-swipe-left',

        nextEl: buttonRightClassName
          ? `.${buttonRightClassName}`
          : '.swiper-swipe-right',
      }}
      onSwiper={swiper => setSwiperInstance?.(swiper)}
    >
      {slides.map(slide => (
        <SwiperSlide key={slide.key}>{slide}</SwiperSlide>
      ))}
    </StyledSwiper>
  )
}
