import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '@src/components/Carousel'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { TextClamp } from '@src/components/Text/Clamp'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'

import { getOutletSearchBySegmentVariables } from './getSegmentOutletSearchVariables'
import { getBusinessSegmentDocument } from './queries/__generated__/getBusinessSegment.graphql-interface'
import { getBusinessSegmentOutletsDocument } from './queries/__generated__/getBusinessSegmentOutlets.graphql-interface'

import { IconButton } from '../Button/IconButton'
import {
  FeaturedOutletsContainer,
  HeroContainer,
  OutletContainer,
  SliderArrowsContainer,
  HeaderLink,
  Description,
} from '../FeaturedOutlets/FeaturedOutlets.styles'
import { FeaturedOutletsSkeleton } from '../FeaturedOutlets/FeaturedOutletsSkeleton'
import { OutletSlide } from '../OutletSlide'

type Props = {
  title: string
  segmentId: string
  onboardingPage?: boolean
  index: number
}

export const BusinessSegmentOutlets: React.FC<Props> = ({
  title,
  segmentId,
  onboardingPage = false,
  index,
}) => {
  const fulfilmentFilter = useFulfilmentFilter()
  const marketplace = useMarketplace()

  const variables = getOutletSearchBySegmentVariables({
    fulfilmentFilterData: fulfilmentFilter.data,
    marketplaceId: marketplace.id,
    businessSegmentId: segmentId,
  })

  const { data, loading, error } = useQuery(
    getBusinessSegmentOutletsDocument,
    variables ? { variables } : { skip: true }
  )
  const businessSegmentData = useQuery(getBusinessSegmentDocument, {
    variables: { id: segmentId },
  })

  const segmentOutlets = useMemo(
    () => (data ? data.businessSegmentOutlets.map(parseOutletDates) : []),
    [data]
  )

  const { t } = useTranslation('navigation')
  if (loading) {
    if (index === 0) {
      return <FeaturedOutletsSkeleton />
    } else return null
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data || !businessSegmentData.data) {
    return <ErrorPage />
  }

  const circleButtonLeftClassName = `${segmentId}-segment-left`
  const circleButtonRightClassName = `${segmentId}-segment-right`
  return (
    <>
      {segmentOutlets.length > 0 && (
        <OutletContainer>
          <HeroContainer isOnboardingPage={onboardingPage}>
            <HeaderLink
              to={`/segments/${segmentId}/${convertToSlug(
                businessSegmentData.data.businessSegment.name
              )}`}
            >
              <h2>{title}</h2>
            </HeaderLink>
            <SliderArrowsContainer>
              <IconButton
                className={circleButtonLeftClassName}
                size={32}
                icon={'caretLeft'}
              />
              <IconButton
                className={circleButtonRightClassName}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </SliderArrowsContainer>
          </HeroContainer>
          {!!businessSegmentData.data?.businessSegment.description?.length && (
            <Description>
              <TextClamp clamp={5}>
                {businessSegmentData.data?.businessSegment.description}
              </TextClamp>
            </Description>
          )}
          <FeaturedOutletsContainer isOnboardingPage={onboardingPage}>
            <Carousel
              buttonLeftClassName={circleButtonLeftClassName}
              buttonRightClassName={circleButtonRightClassName}
              slides={segmentOutlets.map(outlet => (
                <OutletSlide
                  key={`segmentOutlet-${segmentId}-${outlet.id}`}
                  outlet={outlet}
                  showDiscountLabels={
                    businessSegmentData.data?.businessSegment.showDiscountLabels
                  }
                  showFeaturedFlags={
                    businessSegmentData.data?.businessSegment.showFeaturedFlags
                  }
                />
              ))}
            />
          </FeaturedOutletsContainer>
        </OutletContainer>
      )}
    </>
  )
}
