export const memberDiscountRoutes = [
  '/accept-discount',
  '/register-and-accept-discount',
]

export const featuredOutletsListRoutes = [
  '/:deliveryZone/featured',
  '/featured',
]

export const segmentOutletListRoutes = [
  '/segments/:segmentId/:segmentName',
  'segments/:segmentId/:segmentName/:deliveryZone',
]
