import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonType } from '@src/components/Button'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { alertOkModalVar } from '@src/models/basket/alertOkModal'

export const AlertOkModal: React.FC = () => {
  const alertOkModal = useReactiveVar(alertOkModalVar)
  const { t } = useTranslation('errors')

  return (
    <AlertModal
      isOpen={!!alertOkModal.alertMessage}
      title={alertOkModal.alertTitle ? t(alertOkModal.alertTitle) : ''}
      subTitle={alertOkModal.alertMessage ? t(alertOkModal.alertMessage) : ''}
      action={{
        text: t('ok'),
        intent: ButtonType.PRIMARY,
        onClick: () => {
          alertOkModalVar({
            alertTitle: undefined,
            alertMessage: undefined,
          })
        },
      }}
    />
  )
}
