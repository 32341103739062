import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type CheckoutBasketTotalsQueryVariables = Types.Exact<{
  basketData: Types.CheckoutBasketTotalsInput
}>

export type CheckoutBasketTotalsQuery = {
  checkoutBasketTotals: {
    __typename: 'CheckoutTotalsV2'
    fulfilmentId: string
    nonAddOnBasketItemsTotal: number
    addOnBasketItemsTotal: number
    fulfilmentCharge: number
    serviceCharge: number
    sumTotal: number
    expiryAt: string
    isFixedFulfilment: boolean
    appliedDiscount: {
      __typename: 'AppliedDiscount'
      reductionAmount: number
      discount: {
        __typename: 'Discount'
        id: string
        name: string
        voucher: string | null
      }
    } | null
  }
}

export const CheckoutBasketTotalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckoutBasketTotals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'basketData' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CheckoutBasketTotalsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutBasketTotals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'basketData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'basketData' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfilmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonAddOnBasketItemsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addOnBasketItemsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfilmentCharge' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceCharge' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedDiscount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'voucher' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reductionAmount' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sumTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isFixedFulfilment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutBasketTotalsQuery,
  CheckoutBasketTotalsQueryVariables
>
