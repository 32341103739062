import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { ButtonType } from '@src/components/Button'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { TextInput, SelectInput } from '@src/components/Inputs'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { isValidPhoneNumber } from '@src/utils/isValidPhoneNumber'

import { customerSupportEmailDocument } from './mutations/__generated__/customerSupportEmail.graphql-interface'
import {
  SubmitButton,
  HelpQuestion,
  SupportContainer,
  FAQsText,
} from './SupportFormStyle'

export const SupportForm: React.FC<{ marketplaceId: string }> = ({
  marketplaceId,
}) => {
  const { t } = useTranslation('supportForm')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const { setRoute } = useAccountRouter()

  const initialValues = {
    subject: t('subjectOptions.order_issues'),
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    orderNumber: '',
    message: '',
  }

  const subjectOptions = [
    {
      value: t('subjectOptions.order_issues'),
      name: t('subjectOptions.order_issues'),
    },
    {
      value: t('subjectOptions.payment_issues'),
      name: t('subjectOptions.payment_issues'),
    },
    {
      value: t('subjectOptions.account_questions'),
      name: t('subjectOptions.account_questions'),
    },
    {
      value: t('subjectOptions.website_questions'),
      name: t('subjectOptions.website_questions'),
    },
    {
      value: t('subjectOptions.something_else'),
      name: t('subjectOptions.something_else'),
    },
  ]

  const supportFormValidation = z.object({
    subject: z.string({
      required_error: t('subject.validation.required_error'),
    }),
    firstName: z
      .string({ required_error: t('first_name.validation.required_error') })
      .min(2, {
        message: t('first_name.validation.minimum_required_characters'),
      }),
    lastName: z
      .string({
        required_error: t('last_name.validation.required_error'),
      })
      .min(2, {
        message: t('last_name.validation.minimum_required_characters'),
      }),
    emailAddress: z
      .string({
        required_error: t('email_address.validation.required_error'),
        invalid_type_error: t('email_address.validation.invalid_type_error'),
      })
      .email(),
    phoneNumber: z
      .string({
        required_error: t('phone_number.validation.required_error'),
        invalid_type_error: t('phone_number.validation.invalid_type_error'),
      })
      .refine(
        val => isValidPhoneNumber(val, 'GB'),
        t('phone_number.validation.invalid_type_error')
      ),
    orderNumber: z.string().optional(),
    message: z
      .string({ required_error: t('message.validation.required_error') })
      .min(2, {
        message: t('message.validation.minimum_required_characters'),
      }),
  })

  const [sendSupportEmail] = useMutation(customerSupportEmailDocument, {
    onError() {
      setShowError(true)
    },
    onCompleted() {
      setShowSuccess(true)
      ReactPixel.track('Contact')
    },
  })

  return (
    <>
      {showSuccess ? (
        <AlertModal
          isOpen={showSuccess}
          title={t('submit.status.success')}
          subTitle={
            <>
              <LoaderCheck />
              {t('submit.status.success_content')}
            </>
          }
          action={{
            text: t('Close'),
            intent: ButtonType.PRIMARY,
            onClick: () => {
              setRoute({
                mainRouteName: MainRouteName.MENU,
                childRouteName: undefined,
              })
              setShowSuccess(false)
            },
          }}
          isLeftSidebar={true}
        />
      ) : (
        <SupportContainer>
          <HelpQuestion>{t('help_question')}</HelpQuestion>
          <FAQsText>{t('faq')}</FAQsText>
          <Formik
            initialValues={initialValues}
            validationSchema={toFormikValidationSchema(supportFormValidation)}
            validateOnChange
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
              await sendSupportEmail({
                variables: {
                  marketplaceId,
                  ...values,
                },
              })
              actions.resetForm({ values: initialValues })
            }}
          >
            <Form>
              <SelectInput name="subject" options={subjectOptions} required />
              <TextInput
                name="firstName"
                label={t('first_name.label')}
                required
                autoComplete="given-name"
              />
              <TextInput
                name="lastName"
                label={t('last_name.label')}
                required
                autoComplete="family-name"
              />
              <TextInput
                name="emailAddress"
                label={t('email_address.label')}
                required
                autoComplete="email"
              />
              <TextInput
                name="phoneNumber"
                label={t('phone_number.label')}
                required
              />
              <TextInput name="orderNumber" label={t('order_number.label')} />
              <TextInput
                name="message"
                label={t('message.label')}
                required
                isTextArea={true}
              />
              <SubmitButton type="submit" content={t('submit.label')} />
            </Form>
          </Formik>
          <AlertModal
            isOpen={showError}
            title={t('submit.status.error')}
            subTitle={t('submit.status.error_content')}
            action={{
              text: t('ok'),
              intent: ButtonType.DANGER,
              onClick: () => setShowError(false),
            }}
            isLeftSidebar={true}
          />
        </SupportContainer>
      )}
    </>
  )
}
