import React from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from '@src/components/Router/Link'

import { singleOrderCustomType } from './queries/getSingleOrderCustomType'
import {
  HelpAndSupportText,
  HelpContainer,
  HelpTitle,
  LightText,
} from './SingleOrder.styles'

export const HelpAndSupport: React.FC<{ myOrder: singleOrderCustomType }> = ({
  myOrder,
}) => {
  const { t } = useTranslation('helpAndSupport')
  return (
    <HelpContainer>
      <HelpTitle>{t('help_and_support')}</HelpTitle>
      <LightText>
        {t('check_out_our')}{' '}
        <HelpAndSupportText>
          <Link
            to="/?account=help&accountChild=faqs"
            passthroughQueryParams={false}
          >
            {t('help_and_support')}
          </Link>
        </HelpAndSupportText>{' '}
        {t('section_or_contact')}
        <HelpAndSupportText>
          {' '}
          <Link
            to="/?account=help&accountChild=support"
            passthroughQueryParams={false}
          >
            {myOrder.outlet.displayName} {t('customer_support')}
          </Link>
        </HelpAndSupportText>
      </LightText>
    </HelpContainer>
  )
}
