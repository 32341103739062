import { useQuery } from '@apollo/client'
import React from 'react'

import { SelectWithArrow } from '@src/components/ServicePopover/SelectWithArrow'
import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { allOutletDiscountsQuery } from '@src/hooks/sharedQueries/useAllDiscounts/queries/__generated__/getAllDiscounts.graphql-interface'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'

import {
  StackedMenuContainer,
  MenuTitle,
  MenuSubtitle,
  TitleAndSubtitleContainer,
  StyledLink,
  StackedMenuOuterContainer,
  ChevronContainer,
} from './OutletStackedMenu.styles'

import { SingleDiscount } from '../OutletDiscounts/SingleDiscount'
import {
  Header,
  ScrollToContainer,
} from '../OutletMenu/MenuGroup/MenuGroup.styles'
import {
  DealsAndOffersContainer,
  SingleDiscountContainer,
} from '../OutletMenu/OutletMenuStyles'
import { GetOutletParentMenuIdsDocument } from '../OutletMenu/queries/__generated__/GetParentMenuIds.graphql-interface'

export const OutletStackedMenu: React.FC<{
  allDiscounts: allOutletDiscountsQuery | undefined
}> = ({ allDiscounts }) => {
  const { urlPath } = useMarketplace()
  const {
    data: { currentFulfilment },
    outlet,
  } = useOutletFulfilment({ stateType: OutletFulfilmentStateType.GLOBAL })

  const { data: parentMenuGroupIds, previousData } = useQuery(
    GetOutletParentMenuIdsDocument,
    {
      variables: {
        outletId: outlet.id,
        endOfPrep: currentFulfilment.endOfPrep?.toISOString(),
        narrowFulfilmentMethods: currentFulfilment.narrowType,
      },
    }
  )

  const menuItemGroups = (parentMenuGroupIds || previousData)
    ?.menuItemGroupsForOutlet

  const menuItemsWithoutAddonItems = menuItemGroups
    ? menuItemGroups.filter(({ addOnItemsMenu }) => !addOnItemsMenu)
    : []
  const parentMenuItemGroups = menuItemsWithoutAddonItems.filter(
    menuItemGroup => !menuItemGroup.parentMenu
  )

  return (
    <>
      {allDiscounts?.allDiscountsByOutletId.length ? (
        <>
          <ScrollToContainer id={'discountMenuItem'} aria-hidden />
          <DealsAndOffersContainer>
            <Header hasDescription={false}>{'Deals & Offers'}</Header>
            <SingleDiscountContainer>
              {allDiscounts.allDiscountsByOutletId.map(discount => (
                <div key={discount.id}>
                  <SingleDiscount key={discount.id} allDiscounts={discount} />
                </div>
              ))}
            </SingleDiscountContainer>
          </DealsAndOffersContainer>
        </>
      ) : null}
      <StackedMenuOuterContainer>
        {parentMenuItemGroups.map(menu => (
          <StyledLink
            key={menu.parentMenu?.id}
            to={`/${urlPath}/${outlet.id}/${convertToSlug(
              outlet.displayName
            )}/menu/${menu.id}`}
          >
            <StackedMenuContainer>
              <TitleAndSubtitleContainer>
                <MenuTitle>{menu.name}</MenuTitle>
                {menu.description && (
                  <MenuSubtitle>{menu.description}</MenuSubtitle>
                )}
              </TitleAndSubtitleContainer>
              <ChevronContainer>
                <SelectWithArrow justArrow id="caret" dataPresent={false} />
              </ChevronContainer>
            </StackedMenuContainer>
          </StyledLink>
        ))}
      </StackedMenuOuterContainer>
    </>
  )
}
