import styled from 'styled-components'

export const ResponseContainer = styled.div<{ error?: boolean }>`
  padding: 24px;
  color: ${({ theme, error }) => (error ? '#e1292f' : theme.colors.mainText)};
  background-color: ${({ error }) => (error ? '#ffe7ec' : '#fff')};
  font-size: 16px;
  font-weight: 400;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0px;
  margin-bottom: 24px;
`

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
`
