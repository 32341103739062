import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { Markdown } from '@src/components/Markdown/Markdown'

import { GetLegalTermsDocument } from './queries/__generated__/GetLegalTerms.graphql-interface'

const Content = (): JSX.Element => {
  const { loading, error, data } = useQuery(GetLegalTermsDocument, {
    variables: { cname: window.location.hostname },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorPage errorMessage={error.message} />
  }

  if (!data || data?.marketplaceByCname?.legalTerms === null) {
    return <ErrorPage />
  }

  return <Markdown twoCols markdown={data.marketplaceByCname.legalTerms} />
}

export const Terms = (): JSX.Element => {
  const { t } = useTranslation(['policies'])

  return (
    <BurgerSection
      twoLineHeader={false}
      header={{ title: t('terms_and_conditions') }}
    >
      <Content />
    </BurgerSection>
  )
}
