import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getPrivacyQueryVariables = Types.Exact<{
  cname: Types.Scalars['String']
}>

export type getPrivacyQuery = {
  marketplaceByCname: {
    __typename: 'Marketplace'
    id: string
    legalPrivacy: string | null
  }
}

export const getPrivacyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivacy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceByCname' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cname' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cname' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'legalPrivacy' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<getPrivacyQuery, getPrivacyQueryVariables>
