import { customHistory } from '@src/history'
type NavigateToMainContent = (options?: { pathname?: string }) => void

export const useSecondaryNavigation = (): {
  navigateToMainContent: NavigateToMainContent
} => {
  return {
    navigateToMainContent: options => {
      customHistory.push(options?.pathname ?? '/')
    },
  }
}
