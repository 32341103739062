import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.wideDesktop}px) {
    margin-bottom: 16px;
  }
`

export const ItemContainer = styled.div`
  width: 100%;
  box-sizing: border-box;

  padding: 0px 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 50%;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 33%;
    margin: 0px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    width: calc((100% / 3)) - 4px);
  }
`

export const HeaderContainer = styled.div<{ hasDescription: boolean }>`
  padding: 16px 16px 0px 16px;
  border-top: 8px solid rgba(0, 0, 0, 0.1);

  @media (min-width: ${breakpoints.tablet}px) {
    border-top: 16px solid rgba(0, 0, 0, 0.1);
    padding: 32px 32px 0px 32px;
  }
`

export const SubMenuHeader = styled.div`
  padding: 16px;
  font-size: 18px;
  font-weight: 700;
  border-top: 2px dotted rgba(0, 0, 0, 0.1);

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 20px;
    padding: 16px 32px;
  }
`

export const Header = styled.h1<{ hasDescription: boolean }>`
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  color: #2e3333;

  @media (min-width: ${breakpoints.largeTabletMisc}px) {
    margin-bottom: ${props => (props.hasDescription ? '8px' : '0px')};
    font-size: 24px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin: 4px 0px;

  @media (min-width: ${breakpoints.largeTabletMisc}px) {
    font-size: 15px;
    line-height: 20px;
  }
`

export const ScrollToContainer = styled.div`
  position: absolute;
  height: 40px;
  width: 1px;
  background-color: transparent;
  // Scroll behaviour puts the menu behind the header, so set the container to scroll to 260px margin down
  margin-top: -220px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: -280px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-top: -200px;
  }
`

export const ObservableContainer = styled.div`
  width: 10px;
  background-color: transparent;
  height: 16px;
  position: absolute;
  z-index: 100;
`
