import styled from 'styled-components'

// TODO: Test that error container doesn't need height: 84vh
export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #ff3b4c21;
  border-radius: 8px;
  color: #ff3b4c;
  margin-bottom: 8px;
`

export const ErrorMessage = styled.h1`
  font-size: 24px;
  margin: 24px 0 0 0px;
  text-align: center;
`

export const ErrorButton = styled.button`
  background-color: #ebecf0;

  padding: 8px 20px;
  display: flex;
  margin-top: 20px;
  align-content: center;
  justify-content: center;

  border: 2px solid #191919;
  cursor: pointer;
`

export const ErrorBackArrow = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 20px;
`
