import React, { useState, useRef } from 'react'

import { useOnClickOutside } from '@src/hooks/useClickOutside'

import {
  Container,
  Button,
  StyledCaretUpSvg,
  StyledCaretDownSvg,
  Dropdown,
  DropdownFade,
  DropdownItems,
  DropdownItem,
} from './DropdownInput.styles'

type Item = { id: string; text: string }

type DropdownInputProps = {
  className?: string
  fieldName: string
  placeholder: string
  activeItemId?: string | null
  items: Item[]
  onItemClick: (item: Item) => void
  borderless?: boolean
}

export const DropdownInput: React.FC<DropdownInputProps> = ({
  className,
  fieldName,
  placeholder,
  activeItemId,
  items,
  onItemClick,
  borderless = false,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)

  const close = () => setExpanded(false)
  const toggle = () => setExpanded(currentValue => !currentValue)

  useOnClickOutside({ ref, onClickOutside: close })

  const activeItem = items.find(item => item.id === activeItemId)

  return (
    <Container
      ref={ref}
      className={className}
      borderless={borderless}
      open={expanded}
    >
      <Button aria-expanded={expanded} onClick={toggle} type="button">
        {activeItem ? activeItem.text : placeholder}

        {expanded ? (
          <StyledCaretUpSvg id={`${fieldName}-dropdown-menu-open`} />
        ) : (
          <StyledCaretDownSvg id={`${fieldName}-dropdown-menu-closed`} />
        )}
      </Button>

      {expanded && (
        <>
          <DropdownFade onClick={close} />
          <Dropdown>
            <DropdownItems>
              {items
                .filter(item => {
                  if (!activeItemId) {
                    return true
                  }
                  return activeItemId !== item.id
                })
                .map(item => (
                  <DropdownItem key={item.id} onClick={() => onItemClick(item)}>
                    {item.text}
                  </DropdownItem>
                ))}
            </DropdownItems>
          </Dropdown>
        </>
      )}
    </Container>
  )
}
