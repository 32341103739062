import React, { ReactNode } from 'react'

import { StyledAddressBookButton } from '@src/components/CheckoutModal/Checkout/DeliveryFulfilmentStep/AddAddressInputs.styles'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

export const AddressBookButton: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setRoute } = useAccountRouter()
  const checkoutRouter = useCheckoutRouter()

  return (
    <StyledAddressBookButton
      type="button"
      onClick={() => {
        setRoute({
          mainRouteName: MainRouteName.ADDRESSES,
          childRouteName: undefined,
        })
        checkoutRouter.reset()
      }}
    >
      {children}
    </StyledAddressBookButton>
  )
}
