import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetMyOrdersQueryVariables = Types.Exact<{
  input: Types.ConnectionInputObject
  createdAt_gte?: Types.InputMaybe<Types.Scalars['DateTime']>
  createdAt_lte?: Types.InputMaybe<Types.Scalars['DateTime']>
}>

export type GetMyOrdersQuery = {
  orders: {
    __typename: 'OrderConnection'
    totalCount: number
    edges: Array<{
      __typename: 'OrderEdge'
      node: {
        __typename: 'Order'
        id: string
        cleanOrderStatus: Types.CleanOrderStatus
        orderStatus: Types.OrderStatus | null
        grossTotal: number
        createdAt: string
        outlet: {
          __typename: 'Outlet'
          id: string
          outletLogoOverride: string | null
          displayName: string
          coverImage: string | null
          restaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
            image: string | null
          }
        }
      }
    }>
    pageInfo: {
      __typename: 'OrderPageInfo'
      hasNextPage: boolean
      endCursor: string | null
    }
  }
  customerDetails: {
    __typename: 'Customer'
    id: string
    registeredAt: string | null
  }
}

export const GetMyOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMyOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConnectionInputObject' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt_gte' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt_lte' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createdAt_gte' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createdAt_gte' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createdAt_lte' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createdAt_lte' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cleanOrderStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grossTotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outlet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'outletLogoOverride',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'restaurant' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'image',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registeredAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyOrdersQuery, GetMyOrdersQueryVariables>
