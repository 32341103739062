import React from 'react'
import { useTranslation } from 'react-i18next'

import { CaretRight } from '@src/components/SVGS/CaretRight'

import { SelectWithArrowSpan } from './ServicePopover.styles'

export const SelectWithArrow = ({
  id,
  dataPresent,
  justArrow = false,
}: {
  id: string
  dataPresent: boolean
  justArrow?: boolean
}): JSX.Element => {
  const { t } = useTranslation('serviceNavigation')
  let textToDisplay = ''
  if (!justArrow) {
    if (dataPresent) {
      textToDisplay = t('change')
    } else {
      textToDisplay = t('select')
    }
  }

  return (
    <SelectWithArrowSpan>
      {textToDisplay}
      <CaretRight id={id} width={16} height={16} />
    </SelectWithArrowSpan>
  )
}
