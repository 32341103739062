import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const PaymentContainer = styled.table`
  background: #f6f6f9
  display: flex;
  flex-direction: column;
  padding: 0px 14px 24px 14px;
  @media (min-width: ${breakpoints.largeTablet}px) {
    background: #fff;
  }
`

export const TableHeaderRow = styled.tr`
  // Hide the table headers visually but show them to screen readers
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
`

export const Row = styled.tr``

export const TableData = styled.td`
  width: 100%;
  font-size: 14px;
  padding: 0px 0px 0px 0px;
`
