type UseSafeAreaReturnType = {
  safeAreaInsetTop: number
  safeAreaInsetBottom: number
  safeAreaInsetLeft: number
  safeAreaInsetRight: number
}

export const useSafeArea = (): UseSafeAreaReturnType => {
  const safeAreaInsetTop = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('padding-top'),
    10
  )

  const safeAreaInsetBottom = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('padding-bottom'),
    10
  )

  const safeAreaInsetLeft = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('padding-left'),
    10
  )

  const safeAreaInsetRight = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('padding-right'),
    10
  )

  return {
    safeAreaInsetTop,
    safeAreaInsetBottom,
    safeAreaInsetLeft,
    safeAreaInsetRight,
  }
}
