import * as Sentry from '@sentry/react'
import React from 'react'

import { RedboxInfo } from './RedboxInfo'

export const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Sentry.ErrorBoundary
    showDialog // enable Sentry's user report dialog
    fallback={props => <RedboxInfo {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
)
