import { toUpper } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import {
  ActionButton,
  Container,
  ItemCount,
  Price,
  StyledMinusSVG,
  StyledPlusSVG,
  StyledPricePlusSVG,
} from './ItemCounter.styles'

export const ItemCounter: React.FC<{
  isDisabled?: boolean
  price: number
  count: number
  handleAdd?: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isMenuItem?: boolean
  allowRemoveItem?: boolean
  allowZeroCount?: boolean
}> = ({
  isDisabled = false,
  price,
  count,
  handleAdd,
  handleRemove,
  isMenuItem,
  allowRemoveItem = true,
  allowZeroCount = false,
}) => {
  const { t } = useTranslation('menuItem')
  const formatCurrency = useFormatCurrency()

  const priceText = () => {
    if (allowZeroCount) return 0
    if (isDisabled) return ''
    if (price === 0) return toUpper(t('select'))
    return <>{formatCurrency(price)}</>
  }

  const getCounterText = () => {
    if (count === 0) {
      return allowZeroCount ? 0 : <>{formatCurrency(price)}</>
    }
    return count
  }

  return allowRemoveItem && !allowZeroCount && count === 0 ? (
    <Price isDisabled={isDisabled} isMenuItem={isMenuItem}>
      <span>{priceText()}</span>
      <StyledPricePlusSVG id="plus" title={t('add_item')} />
    </Price>
  ) : (
    <Container disabled={isDisabled}>
      <ActionButton
        onClick={handleRemove}
        disabled={!allowRemoveItem && count === (allowZeroCount ? 0 : 1)}
      >
        <StyledMinusSVG id="minus" title={t('remove_item')} />
      </ActionButton>

      <ItemCount>{getCounterText()}</ItemCount>

      {!count && !allowZeroCount ? null : (
        <ActionButton onClick={handleAdd}>
          <StyledPlusSVG id="plus" title={t('add_item')} />
        </ActionButton>
      )}
    </Container>
  )
}
