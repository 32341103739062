import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { Redirect } from '@src/components/Router/Redirect'
import { memberDiscountRoutes } from '@src/constants/routes'
import { CurrentOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/CurrentOutletProvider'
import { BusinessHome } from '@src/pages/BusinessHome'
import { FourZeroFour } from '@src/pages/FourZeroFour/FourZeroFour'
import { OutletPageStateWrapper } from '@src/pages/OutletPage/OutletPageStateWrapper'

export const VendorRoutes: React.FC = () => {
  const location = useLocation()

  return (
    <Switch location={location}>
      {/* Routes in here other than '/' are handled by the burger menu which 
      is designed to open over the homepage if you land on any of these routes. */}
      <Route
        path={[
          '/',
          '/:fulfilmentMethod(all|delivery|collection)',
          ...memberDiscountRoutes,
        ]}
        component={BusinessHome}
        exact
      />

      <Route path="/404" component={FourZeroFour} />

      <Route
        path={`/:outletId/:merchantName`}
        render={routeProps => (
          <CurrentOutletProvider outletId={routeProps.match.params.outletId}>
            <OutletPageStateWrapper />
          </CurrentOutletProvider>
        )}
      />

      <Redirect from="/*" to="/404" />
    </Switch>
  )
}
