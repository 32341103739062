import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'

import {
  SubHeader,
  Container,
  Text,
  InnerContainer,
  AddressContainer,
  Description,
} from './CollectionAddress.styles'

export const CollectionAddress: React.FC = () => {
  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const { t } = useTranslation('checkout')

  const { firstLine, secondLine, thirdLine, postcode } = outlet.outletAddress
  const { restaurant } = outlet

  return (
    <>
      <Container>
        <Description>{t('collection_address')}</Description>
        <InnerContainer>
          <SubHeader>{restaurant.name}</SubHeader>
          <AddressContainer>
            <Text>
              {firstLine} {secondLine}
            </Text>
            <Text>
              {thirdLine ? `${thirdLine} •` : null} {postcode}
            </Text>
          </AddressContainer>
        </InnerContainer>
      </Container>
    </>
  )
}
