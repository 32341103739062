import { eachDayOfInterval, startOfDay, add } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DaysPicker } from '@src/components/DatePicker/DaysPicker'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'

const NUMBER_OF_DAYS_TO_SHOW = 30

export const TimePopoverContent: React.FC<{
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
}> = ({ selectedDate, setSelectedDate }) => {
  const { t } = useTranslation('serviceNavigation')

  const startOfCurrentDay = startOfDay(new Date())
  const preorderDays = useMemo(() => {
    const end = add(startOfCurrentDay, { days: NUMBER_OF_DAYS_TO_SHOW })

    const dates = eachDayOfInterval({
      start: startOfCurrentDay,
      end,
    })

    return dates
  }, [startOfCurrentDay])

  return (
    <>
      <SectionHeader
        header={t('select_date')}
        subheader={t('select_data_subtext')}
      />

      <DaysPicker
        preOrderDays={preorderDays}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />
    </>
  )
}
