import React from 'react'

import {
  MenuDescriptionContainer,
  MenuDescriptionText,
} from './OutletMenuDescriptionStyles'

export const OutletMenuDescription: React.FC<{
  description: string | null
  compactStyle: boolean
}> = ({ description, compactStyle }) => {
  return (
    <MenuDescriptionContainer>
      <MenuDescriptionText compactStyle={compactStyle}>
        {description}
      </MenuDescriptionText>
    </MenuDescriptionContainer>
  )
}
