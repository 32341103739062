import {
  PaymentElementProps,
  PaymentElement as StripePaymentElement,
} from '@stripe/react-stripe-js'
import React from 'react'

export const PaymentElement: React.FC<
  Omit<PaymentElementProps, 'options'> & { businessName: string }
> = props => (
  <StripePaymentElement
    options={{
      layout: {
        type: 'tabs',
        radios: false,
        spacedAccordionItems: false,
      },
      business: {
        name: props.businessName,
      },
      fields: {
        billingDetails: {
          // I'm hoping that if we provide and address when creating the payment intent then this will magically disappear
          // but i feel like im probably wrong
          address: 'auto',
          phone: 'never',
          email: 'never',
          name: 'never',
        },
      },
    }}
    {...props}
  />
)
