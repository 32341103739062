import { Formik, Form, Field, FieldProps } from 'formik'
import flatMap from 'lodash/flatMap'
import sum from 'lodash/sum'
import React, { useEffect, useRef, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { animated, useTransition } from 'react-spring'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { AllergenTags } from '@src/components/AllergenTags/AllergenTags'
import { ButtonType } from '@src/components/Button'
import { TextInput } from '@src/components/Inputs/TextInput/TextInput'
import { ItemCounter } from '@src/components/ItemCounter/ItemCounter'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { TreeOutletMenuItem } from '@src/pages/OutletPage/menuItemGroupTreeType'

import { AddMenuItemFormError } from './AddMenuItemFormError'
import { BasketClearRequiredAlert } from './BasketClearRequiredAlert'
import { LocationRequiredAlert } from './LocationRequiredAlert/LocationRequiredAlert'
import {
  AddToBasketButton,
  AddToBasketButtonLabel,
  AddToBasketFooter,
  AddToBasketFooterContainer,
  AllergenContainer,
  Container,
  FormContainer,
  Header,
  HeaderContainer,
  ImageContainer,
  Legend,
  RefContainer,
  SmallFont,
  UpperContainer,
} from './MenuItemModal.styles'
import { MenuItemOptionField } from './MenuItemOptionField/MenuItemOptionField'
import { outletMenuItemOptions } from './queries/__generated__/outletMenuItemOptions'
import type { FormValues } from './types'

enum AlertType {
  BASKET_CLEAR_REQUIRED = 'BASKET_CLEAR_REQUIRED',
  LOCATION_REQUIRED = 'LOCATION_REQUIRED',
}

export const AddMenuItemForm: React.FC<{
  outletMenuItem: TreeOutletMenuItem
  options: outletMenuItemOptions['optionsByOutletMenuItemId']
  showModalFooter: boolean
  setShowModalFooter: (val: boolean) => void
  close: () => void
  scrollToFooter: () => void
  imageURL: string | null
  narrowFulfilmentMethods: NarrowFulfilmentMethodInputType[]
}> = ({
  outletMenuItem,
  showModalFooter,
  setShowModalFooter,
  options,
  close,
  scrollToFooter,
  imageURL,
  narrowFulfilmentMethods,
}) => {
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { t } = useTranslation('menuItem')
  const [alertType, setAlertType] = useState<AlertType | null>(null)
  // shows a more intense error message on second click
  const [clickCount, setClickCount] = useState(0)
  const marketplace = useMarketplace()
  const basketItems = useBasketItems()
  const formatCurrency = useFormatCurrency()
  // when null form is not being submitted, else useEffect triggers form submission
  const [submissionFormValues, setSubmissionFormValues] =
    useState<FormValues | null>(null)

  const listRef = useRef<(HTMLDivElement | null)[]>([])
  const [selectedRef, setSelectedRef] = useState<string | null>(null)

  useEffect(() => {
    if (selectedRef) {
      const selectedIndex = options.findIndex(
        option => option.id === selectedRef
      )
      if (
        selectedIndex !== -1 &&
        listRef?.current &&
        listRef.current[selectedIndex + 1]
      ) {
        listRef.current[selectedIndex + 1]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
  }, [selectedRef, options])

  const currentFulfilmentType = outletFulfilment.data.currentFulfilment.type

  const footerTransitions = useTransition(showModalFooter, {
    from: { opacity: '1', bottom: '-80px' },
    enter: { opacity: '1', bottom: '0' },
    config: { duration: 300 },
    reverse: false,
    delay: 200,
  })

  useEffect(() => {
    setShowModalFooter(true)
  }, [setShowModalFooter])

  const initialValues: FormValues = {
    quantity: 1,
    optionItemIds: [],
    singleItemNotes: '',
  }

  // handleSubmit is in a useEffect so that
  // the it can be re-run from the alerts
  // Passing handleSubmit directly to the alerts
  // causes it to run before useOutletFulfilment
  // returns the updated values
  useEffect(() => {
    if (!submissionFormValues) {
      return
    }

    // cannot add items to basket while fulfilment type is EVERYWHERE
    if (
      [
        CurrentFulfilmentType.DELIVERY_EVERYWHERE,
        CurrentFulfilmentType.TABLE_UNSELECTED,
      ].includes(currentFulfilmentType)
    ) {
      setAlertType(AlertType.LOCATION_REQUIRED)
      setSubmissionFormValues(null)
      return
    }

    // cannot add items to basket until basket is cleared
    if (basketItems.items.length && !outletFulfilment.data.isBasketOutlet) {
      setAlertType(AlertType.BASKET_CLEAR_REQUIRED)
      setSubmissionFormValues(null)
      return
    }

    basketItems.addItem({
      item: {
        name: outletMenuItem.name,
        menuItemId: outletMenuItem.menuItemId,
        outletMenuItemId: outletMenuItem.id,
        optionItemIds: submissionFormValues.optionItemIds,
        singleItemNotes: submissionFormValues.singleItemNotes,
        quantity: submissionFormValues.quantity,
      },
      outletId: outletFulfilment.outlet.id,
      shouldNavigateToBasket: true,
    })

    const optionNames = options
      .flatMap(option => option.optionItems)
      .filter(optionItem =>
        submissionFormValues.optionItemIds.includes(optionItem.id)
      )
      .map(optionItem => optionItem.name)
    // if options, fire CustomizeProduct event
    if (submissionFormValues.optionItemIds.length) {
      ReactPixel.track('CustomizeProduct', {
        content_ids: submissionFormValues.optionItemIds,
        content_type: 'product',
        content_name: optionNames,
      })
    }

    ReactPixel.track('AddToCart', {
      content_ids: [outletMenuItem.menuItemId],
      content_type: 'product',
      content_name: outletMenuItem.name,
      contents: {
        quantity: submissionFormValues.quantity,
        name: outletMenuItem.name,
        id: outletMenuItem.menuItemId,
        price: outletMenuItem.price,
        options: optionNames,
      },
      value: outletMenuItem.price * submissionFormValues.quantity,
      currency: marketplace.country.currency.iso4217,
    })

    close()
  }, [
    basketItems,
    close,
    currentFulfilmentType,
    marketplace.country.currency.iso4217,
    outletFulfilment.data.isBasketOutlet,
    outletFulfilment.outlet.id,
    outletMenuItem.id,
    outletMenuItem.menuItemId,
    outletMenuItem.name,
    outletMenuItem.price,
    submissionFormValues,
  ])

  const AnimatedAddToBasketFooter = animated(AddToBasketFooter)
  const AnimatedAddToBasketFooterContainer = animated(
    AddToBasketFooterContainer
  )

  const isDisabled =
    outletMenuItem.soldOut || !outletFulfilment.outlet.isOrderable

  const sumOfOptionItemPrices = (values: FormValues): number => {
    const optionItemPrices = flatMap(options, option =>
      option.optionItems
        .filter(({ id }) => values.optionItemIds.includes(id))
        .map(({ price }) => price)
    )
    return sum(optionItemPrices) * values.quantity
  }

  const validationSchema = z
    .object({
      optionItemIds: z.array(z.string()),
    })
    .superRefine(({ optionItemIds: chosenOptionItemIds }, ctx) => {
      options.forEach(option => {
        if (option.minOptions > 0) {
          const optionItemIds = option.optionItems.map(
            optionItem => optionItem.id
          )
          let optionsChosen = 0
          for (const chosenOptionItemId of chosenOptionItemIds) {
            if (optionItemIds.includes(chosenOptionItemId)) {
              optionsChosen = optionsChosen + 1
            }
          }

          if (optionsChosen < option.minOptions) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [option.id],
              message: t('form.option_item_ids.minimum_options_error', {
                minimum_options: option.minOptions,
                option_name: option.name,
              }),
            })
          }

          if (optionsChosen > option.maxOptions) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [option.id],
              message: t('form.option_item_ids.maximum_options_error', {
                maximum_options: option.maxOptions,
                option_name: option.name,
              }),
            })
          }
        }
      })
    })

  return (
    <>
      <Formik
        onSubmit={values => setSubmissionFormValues(values)}
        initialValues={initialValues}
        validationSchema={toFormikValidationSchema(validationSchema)}
      >
        {({ setFieldValue, values, errors, submitForm }) => (
          <Form>
            <FormContainer>
              <UpperContainer>
                {Object.keys(errors).length > 0 && !!clickCount && (
                  <AddMenuItemFormError
                    extraDanger={clickCount > 1}
                    errors={errors}
                  />
                )}
                {alertType === AlertType.BASKET_CLEAR_REQUIRED && (
                  <BasketClearRequiredAlert
                    onCancel={() => {
                      setAlertType(null)
                    }}
                    onSubmit={() => setSubmissionFormValues(values)}
                  />
                )}
                {alertType === AlertType.LOCATION_REQUIRED && (
                  <LocationRequiredAlert
                    onSelected={() => {
                      setAlertType(null)
                      setSubmissionFormValues(values)
                    }}
                    narrowFulfilmentMethods={narrowFulfilmentMethods}
                  />
                )}
                {imageURL && <ImageContainer imageUrl={imageURL} />}
                <HeaderContainer>
                  <AllergenContainer
                    hasAllergens={
                      outletMenuItem.ageRestricted ||
                      (outletMenuItem.allergens?.length ?? 0) > 0
                    }
                  >
                    <Header>{outletMenuItem.name}</Header>
                    {(outletMenuItem.allergens ||
                      outletMenuItem.ageRestricted) && (
                      <AllergenTags
                        iconView
                        allergens={outletMenuItem.allergens || []}
                        ageRestricted={!!outletMenuItem.ageRestricted}
                      />
                    )}
                  </AllergenContainer>
                  {!isDisabled && (
                    <ItemCounter
                      allowRemoveItem={false}
                      price={outletMenuItem.price}
                      count={values.quantity}
                      handleAdd={e => {
                        e.preventDefault()
                        setFieldValue('quantity', values.quantity + 1)
                        scrollToFooter()
                        setShowModalFooter(true)
                      }}
                      handleRemove={e => {
                        e.preventDefault()
                        if (values.quantity > 1) {
                          setFieldValue('quantity', values.quantity - 1)
                        }
                      }}
                    />
                  )}
                </HeaderContainer>
                <SmallFont>{outletMenuItem.description}</SmallFont>

                <Container>
                  {options.map((option, index) => (
                    <RefContainer
                      key={option.id}
                      ref={ref => (listRef.current[index] = ref)}
                    >
                      <MenuItemOptionField
                        setSelectedRef={setSelectedRef}
                        option={option}
                        itemCountState={values.quantity}
                        clickCount={clickCount}
                        disabled={isDisabled}
                      />
                    </RefContainer>
                  ))}

                  {marketplace.allowSingleItemOrderNotes && !isDisabled && (
                    <>
                      <Legend>{t('add_note')}</Legend>
                      <Field name="singleItemNotes">
                        {({ field }: FieldProps) => (
                          <TextInput
                            label={t('special_instructions')}
                            placeholder={
                              marketplace.specialInstructionsText ?? ''
                            }
                            {...field}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </>
                  )}
                </Container>
              </UpperContainer>

              {footerTransitions(
                (styles, item) =>
                  item && (
                    <AnimatedAddToBasketFooterContainer style={styles}>
                      <AnimatedAddToBasketFooter style={styles}>
                        <AddToBasketButton
                          disabled={isDisabled}
                          type="button"
                          buttonType={ButtonType.PRIMARY}
                          onClick={() => {
                            setClickCount(clickCount + 1)
                            void submitForm()
                          }}
                          content={
                            <AddToBasketButtonLabel>
                              {outletMenuItem.soldOut ? (
                                <p> {t('sold_out')}</p>
                              ) : (
                                <>
                                  <p>
                                    {outletFulfilment.outlet.isOrderable
                                      ? t('add_to_basket')
                                      : t('unavailable')}
                                  </p>
                                  <p>
                                    {formatCurrency(
                                      sumOfOptionItemPrices(values) +
                                        outletMenuItem.price * values.quantity
                                    )}
                                  </p>
                                </>
                              )}
                            </AddToBasketButtonLabel>
                          }
                        />
                      </AnimatedAddToBasketFooter>
                    </AnimatedAddToBasketFooterContainer>
                  )
              )}
            </FormContainer>
          </Form>
        )}
      </Formik>
    </>
  )
}
