import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const BasketCountText = styled.p`
  font-size: 11px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 28px;
  left: 28px;
  border: 2px solid;
  line-height: 18px;

  @media (min-width: ${breakpoints.mobile}px) {
    bottom: 18px;
    left: 26px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    font-size: 14px;
    width: 28px;
    height: 28px;
    bottom: 34px;
    left: 104px;
    line-height: 26px;
  }
`
