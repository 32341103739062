import { z } from 'zod'

import { CountryCodeType } from '@src/../__generated__/globalTypes'
import i18n from '@src/i18n/i18n'

const t = (key: string) => i18n.t(key, { ns: 'addressForm' })

export const addressSchema = z.object({
  name: z.string().nullable(),
  firstLine: z
    .string({
      required_error: t('house_number.validation.required_error'),
    })
    .regex(/\w+/, t('house_number.validation.alphanumeric_characters_error')),
  secondLine: z
    .string({ required_error: t('street_name.validation.required_error') })
    .regex(/\w+/, t('street_name.validation.alphanumeric_characters_error'))
    .min(2, t('street_name.validation.minimum_required_characters')),
  city: z
    .string({ required_error: t('city.validation.required_error') })
    .min(2, t('city.validation.minimum_required_characters')),
  postcode: z.string({ required_error: 'The postcode is required' }),
  countryCode: z.nativeEnum(CountryCodeType, {
    errorMap({ code }) {
      let message = 'error'
      switch (code) {
        case 'invalid_enum_value':
          message = t('country_code.validation.required_error')
      }
      return { message }
    },
  }),
  default: z.boolean(),
})

export type Address = z.infer<typeof addressSchema>
