import { useEffect, RefObject } from 'react'

export const useOnClickOutside = ({
  ref,
  onClickOutside,
}: {
  ref: RefObject<HTMLElement>
  onClickOutside: (event: Event) => void
}): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !ref.current ||
        (event.target instanceof Element && ref.current.contains(event.target))
      ) {
        return
      }

      onClickOutside(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, onClickOutside])
}
