import React from 'react'
import { useTranslation } from 'react-i18next'

export const DoubleArrowSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <title id={`${id}Title`}>{title || t('double_arrow')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <g fill="currentColor">
        <path d="M8,15a1,1,0,0,1-.707-.293l-3-3a1,1,0,0,1,1.414-1.414L8,12.586l2.293-2.293a1,1,0,0,1,1.414,1.414l-3,3A1,1,0,0,1,8,15Z"></path>
        <path
          d="M11,6a1,1,0,0,1-.707-.293L8,3.414,5.707,5.707A1,1,0,0,1,4.293,4.293l3-3a1,1,0,0,1,1.414,0l3,3A1,1,0,0,1,11,6Z"
          fill="#currentColor"
        ></path>
      </g>
    </svg>
  )
}
