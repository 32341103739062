import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { LocationType } from '@src/graphql-types'
import { getAsapFulfilmentTime } from '@src/utils/fulfilmentTimes/getASAPTimes'

import { ExtendDataArgs, ExtendedNonBasketOutletFulfilment } from './types'

import { CurrentFulfilmentType } from '../validation'

export const calculateExtendedCurrentFulfilment = ({
  data,
  outlet,
}: ExtendDataArgs): ExtendedNonBasketOutletFulfilment['currentFulfilment'] => {
  // this is a function so it can be used in a getter
  // so that the components consuming this always get a live value
  const endOfPrep = () => {
    if (
      [
        CurrentFulfilmentType.DELIVERY_EVERYWHERE,
        CurrentFulfilmentType.DELIVERY_COORDINATES,
        CurrentFulfilmentType.DELIVERY_POSTCODE,
        CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS,
        CurrentFulfilmentType.DELIVERY_ZONE,
      ].includes(data.currentFulfilment.type) &&
      data.historicalData.deliveryPreorderWindow
    ) {
      return data.historicalData.deliveryPreorderWindow.start
    }

    if (
      CurrentFulfilmentType.COLLECTION === data.currentFulfilment.type &&
      data.currentFulfilment.collectionPreorderDatetime
    ) {
      return data.currentFulfilment.collectionPreorderDatetime
    }

    return getAsapFulfilmentTime({
      prepTime: outlet.prepTime,
      openingTimes: outlet.openingTimesArray,
    })
  }

  switch (data.currentFulfilment.type) {
    case CurrentFulfilmentType.DELIVERY_EVERYWHERE: {
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.DELIVERY,
        location: {
          type: LocationType.EVERYWHERE,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.deliveryPaymentMethods,
      }
    }
    case CurrentFulfilmentType.DELIVERY_POSTCODE: {
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.DELIVERY,
        location: {
          type: LocationType.POSTCODE,
          postAndCountryCode: data.currentFulfilment.postAndCountryCode,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.deliveryPaymentMethods,
      }
    }
    case CurrentFulfilmentType.DELIVERY_COORDINATES: {
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.DELIVERY,
        location: {
          type: LocationType.COORDINATES,
          coordinates: data.currentFulfilment.coordinates,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.deliveryPaymentMethods,
      }
    }
    case CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS: {
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.DELIVERY,
        location: {
          type: LocationType.ADDRESS,
          addressId: data.currentFulfilment.addressId,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.deliveryPaymentMethods,
      }
    }
    case CurrentFulfilmentType.DELIVERY_ZONE: {
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.DELIVERY,
        location: {
          type: LocationType.DELIVERY_ZONE,
          zoneId: data.currentFulfilment.zoneId,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.deliveryPaymentMethods,
      }
    }
    case CurrentFulfilmentType.COLLECTION:
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.COLLECTION,
        location: {
          type: LocationType.EVERYWHERE,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.collectionPaymentMethods,
      }
    case CurrentFulfilmentType.TABLE:
    case CurrentFulfilmentType.TABLE_UNSELECTED:
      return {
        ...data.currentFulfilment,
        narrowType: NarrowFulfilmentMethodInputType.TABLE,
        location: {
          type: LocationType.EVERYWHERE,
        },
        get endOfPrep() {
          return endOfPrep()
        },
        availablePaymentMethods: outlet.tablePaymentMethods,
      }
  }
}
