import React from 'react'

export const CvCSVG: React.VFC<{
  id: string
  title?: string
  desc?: string
}> = ({ title = 'cvc', id, desc }) => (
  <svg
    id={id}
    width="48"
    height="37"
    viewBox="0 0 48 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={`${id}Title`}>{title || 'cvc'}</title>
    {desc && <desc id={`${id}Desc`}>{desc}</desc>}
    <g id="Group 3369">
      <rect
        id="Rectangle 1524"
        x="8.00098"
        y="0.00195312"
        width="40"
        height="28"
        rx="4"
        fill="#ABABAB"
      />
      <rect
        id="Rectangle 1521"
        x="0.000976562"
        y="8.00195"
        width="40"
        height="28"
        rx="4"
        fill="#F0F0F0"
      />
      <rect
        id="Rectangle 1522"
        x="0.000976562"
        y="13.002"
        width="40"
        height="6"
        fill="black"
      />
      <rect
        id="Rectangle 1523"
        x="4.00098"
        y="23.002"
        width="18.5"
        height="8"
        fill="white"
      />
      <path
        id="123"
        d="M26.4602 29.002H27.345V24.7744H26.4632L25.3704 25.5332V26.3301L26.4075 25.6094H26.4602V29.002ZM28.5264 29.002H31.5762V28.29H29.7129V28.2227L30.4951 27.4932C31.2656 26.7812 31.5146 26.3828 31.5146 25.9023V25.8936C31.5146 25.1641 30.9053 24.666 30.0352 24.666C29.1094 24.666 28.4766 25.2227 28.4766 26.0342L28.4795 26.0459H29.2969V26.0312C29.2969 25.6299 29.5811 25.3545 29.9941 25.3545C30.3984 25.3545 30.6445 25.6064 30.6445 25.9668V25.9756C30.6445 26.2715 30.4834 26.4854 29.8887 27.0625L28.5264 28.4072V29.002ZM33.9968 29.0957C34.9841 29.0957 35.6433 28.5771 35.6433 27.8096V27.8037C35.6433 27.2295 35.2331 26.8662 34.6032 26.8076V26.79C35.0984 26.6875 35.4851 26.3447 35.4851 25.8174V25.8115C35.4851 25.1377 34.8991 24.6807 33.9909 24.6807C33.1032 24.6807 32.5085 25.1729 32.447 25.9287L32.4441 25.9639H33.2585L33.2614 25.9375C33.2966 25.5859 33.5749 25.3604 33.9909 25.3604C34.407 25.3604 34.6501 25.5771 34.6501 25.9287V25.9346C34.6501 26.2773 34.363 26.5117 33.9177 26.5117H33.446V27.1416H33.9294C34.4421 27.1416 34.7439 27.3643 34.7439 27.7686V27.7744C34.7439 28.1318 34.448 28.3838 33.9968 28.3838C33.5398 28.3838 33.238 28.1494 33.1999 27.8213L33.197 27.7891H32.3503L32.3532 27.8271C32.4118 28.583 33.0417 29.0957 33.9968 29.0957Z"
        fill="#2E3333"
      />
    </g>
  </svg>
)
