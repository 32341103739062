import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { calendarString } from '@src/components/DatePicker/utils/dateString'
import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import { SelectWithArrow } from '@src/components/ServicePopover/SelectWithArrow'
import {
  OptionContentLabels,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { SingleOutlet } from '@src/components/SingleOutlet/types'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  ExtendedCurrentFulfilmentTable,
  ExtendedCurrentFulfilmentTableUnselected,
  ExtendedNonBasketOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import {
  getAsapFulfilmentTime,
  getAsapDeliveryWindow,
} from '@src/utils/fulfilmentTimes/getASAPTimes'
import { ParsedDeliveryPreorderWindow } from '@src/utils/fulfilmentTimes/types'

import { TimePopover } from '../SiteHeader/ServiceNavigation/Popovers/TimePopover'

export const OutletTimeSection: React.FC<{
  setIsSecondaryPopoverOpen?: (val: boolean) => void
  outlet: Pick<
    SingleOutlet,
    | 'allowPreorders'
    | 'asapAllowed'
    | 'closedUntil'
    | 'collectionPreorderTimes'
    | 'daysOfferedInAdvanceMax'
    | 'daysOfferedInAdvanceMin'
    | 'ASAPDeliveryDuration'
    | 'deliveryPreorderWindows'
    | 'isOpen'
    | 'openingTimesArray'
    | 'prepTime'
  >
  showSelection?: boolean
  currentFulfilment: Exclude<
    ExtendedNonBasketOutletFulfilment['currentFulfilment'],
    ExtendedCurrentFulfilmentTableUnselected | ExtendedCurrentFulfilmentTable
  >
  setASAP: () => void
}> = ({ setIsSecondaryPopoverOpen, outlet, currentFulfilment, setASAP }) => {
  const { t } = useTranslation('serviceNavigation')
  const [timePopoverOpen, setTimePopoverOpen] = React.useState(false)
  const currentNarrowFulfilment = currentFulfilment.narrowType

  const {
    prepTime,
    ASAPDeliveryDuration,
    openingTimesArray,
    allowPreorders,
    isOpen,
    asapAllowed,
  } = outlet

  const defaultTimes = {
    prepTime,
    ASAPDeliveryDuration,
    openingTimes: openingTimesArray,
  }

  const getASAPDeliveryLabel = (): string => {
    const asapDeliveryTime = getAsapDeliveryWindow(defaultTimes)

    if (asapDeliveryTime) {
      return `${t('now')}: ${t('arriving_by')} ${format(
        new Date(asapDeliveryTime.end),
        'HH:mm'
      )} ${t(calendarString(new Date(asapDeliveryTime.end)))}`
    } else {
      return t('now')
    }
  }

  const getASAPCollectionLabel = (): string | null => {
    const asapCollectionTime = getAsapFulfilmentTime({
      prepTime,
      openingTimes: openingTimesArray,
    })

    if (!asapCollectionTime) {
      return null
    }

    return `${t('now')}: ${t('ready_by')} ${format(
      asapCollectionTime,
      'HH:mm'
    )} ${t(calendarString(asapCollectionTime))}`
  }

  const getPreorderDeliveryLabel = (
    deliveryPreorderWindow: Omit<ParsedDeliveryPreorderWindow, 'isFull'> | null
  ): string => {
    if (deliveryPreorderWindow) {
      const dateString = t(
        calendarString(new Date(deliveryPreorderWindow.start))
      )

      const start = format(
        new Date(deliveryPreorderWindow.start),
        'd MMM HH:mm'
      )

      const end = format(new Date(deliveryPreorderWindow.end), 'HH:mm')

      return `${dateString} ${start} - ${end}`
    } else {
      return t('schedule')
    }
  }

  const getPreorderCollectionLabel = (
    collectionPreorderDatetime: Date | null
  ): string => {
    if (collectionPreorderDatetime) {
      const dateString = t(calendarString(new Date(collectionPreorderDatetime)))

      const start = format(new Date(collectionPreorderDatetime), 'd MMM HH:mm')

      return `${dateString} ${start}`
    } else {
      return t('schedule')
    }
  }

  return (
    <>
      <PopoverOptionContainer>
        {/* asap */}
        {asapAllowed && isOpen && (
          <UncontrolledRadioInput
            label={
              <OptionLabel>
                <OptionContentLabels>
                  {currentFulfilment.narrowType === 'DELIVERY'
                    ? getASAPDeliveryLabel()
                    : getASAPCollectionLabel()}
                </OptionContentLabels>
              </OptionLabel>
            }
            style={CheckboxInputStyle.TICK}
            checked={
              currentFulfilment.narrowType === 'DELIVERY'
                ? !currentFulfilment.deliveryPreorderWindow
                : !currentFulfilment.collectionPreorderDatetime
            }
            onClick={() => setASAP()}
          />
        )}
      </PopoverOptionContainer>
      <PopoverOptionContainer>
        {/* preorder */}
        {allowPreorders && (
          <UncontrolledRadioInput
            label={
              <OptionLabel>
                <OptionContentLabels>
                  {currentFulfilment.narrowType === 'DELIVERY'
                    ? getPreorderDeliveryLabel(
                        currentFulfilment.deliveryPreorderWindow
                      )
                    : getPreorderCollectionLabel(
                        currentFulfilment.collectionPreorderDatetime
                      )}
                </OptionContentLabels>
                <SelectWithArrow
                  id="Schedule"
                  dataPresent={
                    (currentNarrowFulfilment ===
                      NarrowFulfilmentMethodInputType.DELIVERY &&
                      !!currentFulfilment.deliveryPreorderWindow) ||
                    (currentNarrowFulfilment ===
                      NarrowFulfilmentMethodInputType.COLLECTION &&
                      !!currentFulfilment.collectionPreorderDatetime)
                  }
                />
              </OptionLabel>
            }
            style={CheckboxInputStyle.TICK}
            checked={
              currentFulfilment.narrowType === 'DELIVERY'
                ? !!currentFulfilment.deliveryPreorderWindow
                : !!currentFulfilment.collectionPreorderDatetime
            }
            onClick={() => {
              setIsSecondaryPopoverOpen?.(true)
              setTimePopoverOpen(true)
            }}
          />
        )}
      </PopoverOptionContainer>
      {timePopoverOpen && (
        <TimePopover
          outlet={outlet}
          timePopoverOpen={timePopoverOpen}
          setTimePopoverOpen={setTimePopoverOpen}
          setIsSecondaryPopoverOpen={setIsSecondaryPopoverOpen}
          currentFulfilment={currentFulfilment}
        />
      )}
    </>
  )
}
