import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Helmet } from '@src/components/Helmet'
import { Modal, ModalPosition } from '@src/components/Modal/Modal'
import { breakpoints } from '@src/constants/breakpoints'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { screenResolutionVar } from '@src/models/screenResolution'

import { AccountMain } from './AccountMain/AccountMain'
import { AnimatedSwitch } from './AnimatedSwitch/AnimatedSwitch'

export const BurgerMenu: React.VFC = () => {
  const { t } = useTranslation('burgerMenu')
  const screenResolution = useReactiveVar(screenResolutionVar)
  const { route, setRoute } = useAccountRouter()

  const useStepOut = screenResolution.width >= breakpoints.largeTabletMisc

  const closeType = {
    type: useStepOut ? ('cross' as const) : ('clickOutsideOnly' as const),
    handleClose: () => setRoute(null),
  }

  return (
    <Modal
      position={ModalPosition.LEFT}
      isOpen={!!route?.mainRouteName}
      closeType={closeType}
      activeHeader={!!route && useStepOut}
      fixedBottomContent={
        useStepOut && route?.mainRouteName !== MainRouteName.MENU ? (
          <AnimatedSwitch />
        ) : undefined
      }
    >
      <>
        <Helmet title={t('title')} />
        {useStepOut && route?.mainRouteName && (
          <>
            <AccountMain />
          </>
        )}

        {!useStepOut && route?.mainRouteName && <AnimatedSwitch />}
      </>
    </Modal>
  )
}
