import { O } from 'ts-toolbelt'

import { DeliveryPreorderWindow, Outlet, Scalars } from '@src/graphql-types'

import {
  ParsedCollectionPreorderTime,
  ParsedDeliveryPreorderWindow,
} from './types'

/**
 *
 * Parses for fields which are dates but will be returned as strings from the API
 */
// collection preorder time
const parseCollectionPreorderTime = (
  collectionPreorderTime: Scalars['DateTime']
): ParsedCollectionPreorderTime => new Date(collectionPreorderTime)
// collection preorder time array
const parseCollectionPreorderTimes = (
  collectionPreorderTimes: Scalars['DateTime'][]
): ParsedCollectionPreorderTime[] =>
  collectionPreorderTimes.map(parseCollectionPreorderTime)
// delivery preorder window
const parseDeliveryPreorderWindow = (
  deliveryPreorderWindow: DeliveryPreorderWindow
): ParsedDeliveryPreorderWindow => ({
  ...deliveryPreorderWindow,
  start: new Date(deliveryPreorderWindow.start),
  end: new Date(deliveryPreorderWindow.end),
})
// delivery preorder window array
const parseDeliveryPreorderWindows = (
  deliveryPreorderWindows: DeliveryPreorderWindow[]
): ParsedDeliveryPreorderWindow[] =>
  deliveryPreorderWindows.map(parseDeliveryPreorderWindow)

// FULL OUTLET
// outlet date fields as dates
type OutletDateFields = {
  closeDate: Date | null
  nextOpenDate: Date
  closedUntil: Date | null
  deliveryPreorderWindows: ParsedDeliveryPreorderWindow[]
  collectionPreorderTimes: ParsedCollectionPreorderTime[]
}
// outlet type with all date-string fields as optional
type OutletWithOptionalDates = Partial<Pick<Outlet, keyof OutletDateFields>>
// ts util to overwrite all date-string fields in T (ie outlet) with Date type fields
export type DateifyOutlet<T extends OutletWithOptionalDates> = Omit<
  T,
  keyof OutletDateFields
> &
  O.Overwrite<T, OutletDateFields>
// takes an outlet with any of the date fields as string and returns the outlet with the date fields overwritten as Date
// uses O.Overwrite which replaces the type of each field which exists on T and OutletDateFields, with the type from OutletDateFields (ie Date)
export const parseOutletDates = <T extends OutletWithOptionalDates>({
  closeDate,
  nextOpenDate,
  closedUntil,
  deliveryPreorderWindows,
  collectionPreorderTimes,
  ...otherOutletFields
}: T): DateifyOutlet<T> =>
  ({
    ...otherOutletFields,
    ...(closeDate !== undefined && {
      closeDate: closeDate ? new Date(closeDate) : null,
    }),
    ...(nextOpenDate !== undefined && {
      nextOpenDate: new Date(nextOpenDate),
    }),
    ...(closedUntil !== undefined && {
      closedUntil: closedUntil ? new Date(closedUntil) : null,
    }),
    ...(deliveryPreorderWindows && {
      deliveryPreorderWindows: parseDeliveryPreorderWindows(
        deliveryPreorderWindows
      ),
    }),
    ...(collectionPreorderTimes && {
      collectionPreorderTimes: parseCollectionPreorderTimes(
        collectionPreorderTimes
      ),
    }),
  } as DateifyOutlet<T>)
