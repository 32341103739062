import { FulfilmentInput, FulfilmentMethodInputType } from '@src/graphql-types'
import { ExtendedNonBasketOutletFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'

export const getCreateOrderFulfilmentInput = ({
  currentFulfilment,
}: {
  currentFulfilment: ExtendedNonBasketOutletFulfilment['currentFulfilment']
}): FulfilmentInput | null => {
  switch (currentFulfilment.type) {
    case CurrentFulfilmentType.COLLECTION:
      return {
        fulfilmentMethod: FulfilmentMethodInputType.COLLECTION,
        notes: currentFulfilment.collectionNotes,
        preorderFor: currentFulfilment.collectionPreorderDatetime
          ? currentFulfilment.collectionPreorderDatetime.toISOString()
          : undefined,
      }
    case CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS:
      return {
        fulfilmentMethod: FulfilmentMethodInputType.DELIVERY,
        notes: currentFulfilment.deliveryNotes,
        fulfilmentId: currentFulfilment.fulfilmentId,
        preorderDeliveryWindow: currentFulfilment.deliveryPreorderWindow
          ? {
              start:
                currentFulfilment.deliveryPreorderWindow.start.toISOString(),
              end: currentFulfilment.deliveryPreorderWindow.end.toISOString(),
            }
          : undefined,
      }
    case CurrentFulfilmentType.TABLE:
      return {
        fulfilmentMethod: FulfilmentMethodInputType.TABLE,
        notes: currentFulfilment.tableNotes,
        tableId: currentFulfilment.tableId,
      }
    default:
      return null
  }
}
