import { z } from 'zod'

import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

// schema fields for new and existing addresses
const collectionFulfilmentFormSchema = z.object({
  collectionNotes: z.string(),
})
export type CollectionFulfilmentFormSchema = z.infer<
  typeof collectionFulfilmentFormSchema
>

// TODO this seems pretty overkill for string validation on a single field and a submit handler
export const useCollectionFulfilmentFormikProps = () => {
  const {
    data: { historicalData },
    setCurrentFulfilment,
    outlet,
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const checkoutRouter = useCheckoutRouter()

  const zodValidationSchema = collectionFulfilmentFormSchema

  // define initial values based on current fulfilment
  const initialValues: CollectionFulfilmentFormSchema = {
    collectionNotes: historicalData.collectionNotes || '',
  }

  const handleSubmit = (values: CollectionFulfilmentFormSchema) => {
    void setCurrentFulfilment({
      type: CurrentFulfilmentType.COLLECTION,
      collectionNotes: values.collectionNotes,
    })

    void checkoutRouter.override(
      outlet.restaurant.allowAddOnItems
        ? CheckoutRoute.OFFERS
        : CheckoutRoute.PAYMENT
    )
  }

  return {
    zodValidationSchema,
    initialValues,
    handleSubmit,
  } as const
}
