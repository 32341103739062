import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type EditAddressGetAddressQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']
}>

export type EditAddressGetAddressQuery = {
  addressById: {
    __typename: 'Address'
    id: string
    name: string
    firstLine: string
    secondLine: string | null
    thirdLine: string | null
    city: string
    postcode: string
    countryCode: Types.CountryCodeType
    default: boolean
  }
}

export const EditAddressGetAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditAddressGetAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditAddressGetAddressQuery,
  EditAddressGetAddressQueryVariables
>
