import React from 'react'

import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'

export const FulfilmentWhereLabelSavedAddress: React.FC<{
  addressId: string
  newAddress?: string
}> = ({ addressId, newAddress }) => {
  const { loading, error, data } = useCustomerDetailsAndAddressesQuery()

  if (loading) {
    return <span>{'Loading...'}</span>
  }

  if (error || !data) {
    return null
  }

  const selectedAddress = data.customerDetails.deliveryAddresses.find(
    address => address.id === addressId
  )

  return (
    <span>
      {newAddress
        ? newAddress
        : selectedAddress?.name ||
          selectedAddress?.firstLine ||
          'Address not found'}
    </span>
  )
}
