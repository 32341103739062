import { format, isToday, isTomorrow } from 'date-fns'
import { startCase, toLower } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CleanOrderStatus,
  NarrowFulfilmentMethodInputType,
} from '@src/../__generated__/globalTypes'
import { Button } from '@src/components/Button'
import { OrderStatus } from '@src/graphql-types'
import { imageJitURL } from '@src/utils/imageJitURL'

import { getSingleOrderQuery } from './queries/__generated__/getSingleOrder.graphql-interface'
import {
  OutletPhotoContainer,
  OutletName,
  ChargesSubContainer,
  ChargesIndividual,
  RestaurantLogo,
  StyledNewTabLink,
} from './SingleOrder.styles'

const ORDER_FULFILMENT_STATUS_DICTIONARY: Record<
  NarrowFulfilmentMethodInputType,
  Record<CleanOrderStatus, string | null>
> = {
  [NarrowFulfilmentMethodInputType.DELIVERY]: {
    [CleanOrderStatus.COMPLETE]: 'Delivered',
    [CleanOrderStatus.PREPARING]: 'Delivery',
    [CleanOrderStatus.READY]: 'Delivery',
    [CleanOrderStatus.PENDING]: 'Delivery',
    [CleanOrderStatus.REJECTED]: null,
    [CleanOrderStatus.CANCELLED]: null,
  },
  [NarrowFulfilmentMethodInputType.COLLECTION]: {
    [CleanOrderStatus.COMPLETE]: 'Collected',
    [CleanOrderStatus.PREPARING]: 'Collection',
    [CleanOrderStatus.READY]: 'Collection',
    [CleanOrderStatus.PENDING]: 'Collection',
    [CleanOrderStatus.REJECTED]: null,
    [CleanOrderStatus.CANCELLED]: null,
  },
  [NarrowFulfilmentMethodInputType.TABLE]: {
    [CleanOrderStatus.COMPLETE]: 'Collected',
    [CleanOrderStatus.PREPARING]: 'Collection',
    [CleanOrderStatus.READY]: 'Collection',
    [CleanOrderStatus.PENDING]: 'Collection',
    [CleanOrderStatus.REJECTED]: null,
    [CleanOrderStatus.CANCELLED]: null,
  },
}

export const OrderDetails: React.FC<{
  myOrder: getSingleOrderQuery['myOrder']
  fulfillmentMethod: NarrowFulfilmentMethodInputType
  createdAt: Date
  startWindow?: Date
  endWindow?: Date
  deliveryDate?: Date
  collectionDate?: Date
}> = ({
  myOrder,
  createdAt,
  startWindow,
  endWindow = new Date(), // TODO: remove default before prod. This shouldnt be null, default date for now due to bad data
  fulfillmentMethod,
  collectionDate,
  deliveryDate,
}) => {
  const { t } = useTranslation('singleOrder')
  const {
    cleanOrderStatus,
    outlet,
    orderNumber,
    asap,
    deliveryNetworkJobTrackerURL,
    orderStatus,
  } = myOrder
  const { restaurant, displayName, outletLogoOverride } = outlet
  const orderFulfilmentStatus =
    ORDER_FULFILMENT_STATUS_DICTIONARY[fulfillmentMethod][cleanOrderStatus]

  const orderFulfilmentStatusText = orderFulfilmentStatus
    ? `${orderFulfilmentStatus} Date`
    : null

  const formattedDate = () => {
    // do not show collection/delivery date if order is not fulfilled
    if (
      cleanOrderStatus === CleanOrderStatus.REJECTED ||
      cleanOrderStatus === CleanOrderStatus.CANCELLED
    )
      return null

    if (
      fulfillmentMethod === NarrowFulfilmentMethodInputType.COLLECTION &&
      collectionDate
    ) {
      const timeFormat = format(collectionDate, 'HH:mm')
      if (isToday(collectionDate)) {
        return `${t('today')} (${timeFormat})`
      } else if (isTomorrow(collectionDate)) {
        return `${t('tomorrow')} (${timeFormat})`
      }
      return format(collectionDate, 'dd/MM/yyyy (HH:mm)')
    }

    if (fulfillmentMethod === NarrowFulfilmentMethodInputType.DELIVERY) {
      const startTimeFormat = startWindow ? format(startWindow, '(HH:mm') : null
      const endTimeFormat = format(endWindow, 'HH:mm')
      if (startWindow && startTimeFormat && isToday(startWindow)) {
        if (asap) {
          return `${t('today')} ${endTimeFormat}`
        }
        return `${t('today')} ${startTimeFormat ? `${startTimeFormat} - ` : ''}
        ${endTimeFormat})`
      } else if (startWindow && startTimeFormat && isTomorrow(startWindow)) {
        return `${t('tomorrow')} ${startTimeFormat} - ${endTimeFormat})`
      } else if (deliveryDate) {
        if (asap) {
          return `${endTimeFormat}`
        }
        return `${format(deliveryDate, 'dd/MM/yyyy')} ${
          startTimeFormat ? `${startTimeFormat} - ` : ''
        }  ${endTimeFormat})`
      }
    }

    // do not show for table service
    return null
  }

  return (
    <>
      <OutletPhotoContainer>
        <RestaurantLogo
          role="img"
          aria-label={`${displayName} logo`}
          imageUrl={imageJitURL(outletLogoOverride || restaurant.image, {
            resize: {
              width: 80,
              height: 80,
              fit: 'cover',
            },
          })}
        />
      </OutletPhotoContainer>

      <OutletName>{displayName}</OutletName>
      <ChargesSubContainer>
        <ChargesIndividual>
          <dt>{t('order_number')}</dt>
          <dd>{orderNumber}</dd>
        </ChargesIndividual>
        <ChargesIndividual>
          <dt>{t('order_placed')}</dt>
          <dd>{format(createdAt, 'dd/MM/yyyy (HH:mm)')}</dd>
        </ChargesIndividual>
        {formattedDate() ? (
          <ChargesIndividual>
            <dt>{orderFulfilmentStatusText}</dt>
            <dd>
              <strong>{formattedDate()}</strong>
            </dd>
          </ChargesIndividual>
        ) : null}
        <ChargesIndividual>
          <dt>
            <strong>{t('status')}</strong>
          </dt>
          <dd>
            <strong>{startCase(toLower(cleanOrderStatus))}</strong>
          </dd>
        </ChargesIndividual>
      </ChargesSubContainer>
      {(orderStatus === OrderStatus.READY ||
        orderStatus === OrderStatus.PREPARING ||
        orderStatus === OrderStatus.COMPLETE) &&
        deliveryNetworkJobTrackerURL && (
          <StyledNewTabLink to={{ pathname: deliveryNetworkJobTrackerURL }}>
            <Button
              disabled={!myOrder.outlet.active}
              content={t('track_order')}
            />
          </StyledNewTabLink>
        )}
    </>
  )
}
