import React from 'react'
import { useTranslation } from 'react-i18next'

export const ClockSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('clock_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <path
        d="M11.9882 0C5.36424 0 0.000244141 5.376 0.000244141 12C0.000244141 18.624 5.36424 24 11.9882 24C18.6242 24 24.0002 18.624 24.0002 12C24.0002 5.376 18.6242 0 11.9882 0ZM12.0002 21.6C6.69624 21.6 2.40024 17.304 2.40024 12C2.40024 6.696 6.69624 2.4 12.0002 2.4C17.3042 2.4 21.6002 6.696 21.6002 12C21.6002 17.304 17.3042 21.6 12.0002 21.6ZM12.6002 6H10.8002V13.2L17.1002 16.98L18.0002 15.504L12.6002 12.3V6Z"
        fill="currentColor"
      />
    </svg>
  )
}
