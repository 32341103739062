import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { OutletFulfilmentSection } from '@src/components/OutletServicePopover/OutletFulfilmentSection'
import { OutletTimeSection } from '@src/components/OutletServicePopover/OutletTimeSection'
import { AllLocationSection } from '@src/components/ServicePopover/AllLocationSection'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import {
  MobileNavigationPopoverContentWrapper,
  MobileNavigationPopoverContent,
  Separator,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
  DoneButton,
  SectionContentContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { SingleOutlet } from '@src/components/SingleOutlet/types'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useOutletFulfilmentData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/useOutletFulfilmentData'
import { useServiceComponentModal } from '@src/hooks/useServiceComponentModal/useServiceComponentModal'

export const MobileOutletServicePopover: React.FC = () => {
  const { isServiceComponentOpen, closeServiceComponentModal } =
    useServiceComponentModal()
  const [isSecondaryPopoverOpen, setIsSecondaryPopoverOpen] =
    React.useState(false)
  const { t } = useTranslation('serviceNavigation')
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.LOCAL,
  })
  const { narrowType: currentNarrowFulfilment } =
    outletFulfilment.data.currentFulfilment

  const handleReset = () => {
    outletFulfilment.reset()
  }
  const handleCancel = () => {
    closeServiceComponentModal()
    outletFulfilment.reset()
  }
  const handleDone = () => {
    closeServiceComponentModal()
    outletFulfilment.save()
  }

  useEffect(() => {
    outletFulfilment.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServiceComponentOpen])

  const {
    data: updatedOutletFulfilmentData,
    previousData: previousOutletFulfilmentData,
    loading,
  } = useOutletFulfilmentData({
    outletId: outletFulfilment.data.outletId,
    fulfilmentLocation: outletFulfilment.data.currentFulfilment.location,
  })
  // this is the outlet fulfilment data for the unsaved data
  const localOutletFulfilmentData =
    updatedOutletFulfilmentData || previousOutletFulfilmentData

  // this is the outlet fulfilment data for the saved data
  const { outlet } = outletFulfilment

  return (
    <ServicePopover
      open={isServiceComponentOpen}
      onClose={handleCancel}
      headerColor={'#fff'}
      backgroundColor={'#fff'}
      maxWidth={584}
      isMinimised={isSecondaryPopoverOpen}
    >
      <MobileNavigationPopoverContentWrapper>
        <MobileNavigationPopoverContent>
          {/* Fulfilment section */}
          <SectionHeader
            header={t('service_header')}
            subheader={
              outlet.isOrderable
                ? t('service_subheader')
                : t('service_subheader_closed')
            }
          />
          <SectionContentContainer>
            <OutletFulfilmentSection />
          </SectionContentContainer>
          {outlet.isOrderable && <Separator />}
          {/* End fulfilment section */}
          {/* Time Section - only display for delivery/collection when options are available */}
          {outlet.isOrderable &&
            currentNarrowFulfilment !==
              NarrowFulfilmentMethodInputType.TABLE && (
              <>
                <SectionHeader
                  header={t('time_header')}
                  subheader={t('time_subheader')}
                />
                <SectionContentContainer>
                  <OutletTimeSection
                    currentFulfilment={outletFulfilment.data.currentFulfilment}
                    setASAP={outletFulfilment.setASAP}
                    setIsSecondaryPopoverOpen={setIsSecondaryPopoverOpen}
                    outlet={outlet as unknown as SingleOutlet}
                  />
                </SectionContentContainer>
                <Separator />
              </>
            )}
          {/* END Time section */}
          {/* LOCATION section */}
          <AllLocationSection
            setIsSecondaryPopoverOpen={setIsSecondaryPopoverOpen}
            handleCancelWhenNavigatingAway={handleCancel}
            localOutletFulfilmentData={localOutletFulfilmentData?.outlet}
          />
          {/* END LOCATION section */}
        </MobileNavigationPopoverContent>
      </MobileNavigationPopoverContentWrapper>
      {/* Footer */}
      <MobileNavigationPopoverFooter>
        <MobileNavigationPopoverFooterContent>
          <MobileNavigationResetLink onClick={handleReset}>
            {t('reset')}
          </MobileNavigationResetLink>

          <DoneButton
            content={t('done')}
            width="180px"
            onClick={handleDone}
            type={'button'}
            loading={loading}
          />
        </MobileNavigationPopoverFooterContent>
      </MobileNavigationPopoverFooter>
      {/* End Footer */}
    </ServicePopover>
  )
}
