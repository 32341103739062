import styled from 'styled-components'

export const ItemName = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
`
export const ItemPrice = styled.p<{ isOrderSummary: boolean }>`
  font-size: 12px;
  font-weight: 400;
`

export const IndividualOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18px;
  padding: 4px 0px;
`

export const Dot = styled.p.attrs(() => ({ role: 'separator' }))<{
  isOrderSummary: boolean
}>`
  margin: ${({ isOrderSummary }) =>
    isOrderSummary ? '0 4px 0 4px' : '4px 4px 4px 0px'};
`
