import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const DealsAndOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 16px 16px 0;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-left: 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 32px;
    padding: 32px 32px 32px 0;
  }
`

export const SingleDiscountContainer = styled.div`
  display: flex;
  margin-top: 16px;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 32px;
  }
`
