import React from 'react'

export const CircleCrossSVG: React.VFC<{
  id?: string
  className?: string
  title?: string
  desc?: string
  onClick?: () => void
}> = ({ title, id, className, desc, onClick }) => (
  <svg
    id={id}
    className={className}
    onClick={onClick}
    aria-labelledby={`${id}Title ${id}Desc`}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={`${id}Title`}>{title}</title>
    {desc && <desc id={`${id}Desc`}>{desc}</desc>}
    <circle
      className="circle"
      cx="24"
      cy="24"
      r="23"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      className="cross"
      d="M33 16.8129L31.1871 15L24 22.1871L16.8129 15L15 16.8129L22.1871 24L15 31.1871L16.8129 33L24 25.8129L31.1871 33L33 31.1871L25.8129 24L33 16.8129Z"
      fill="currentColor"
    />
  </svg>
)
