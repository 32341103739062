import {
  addWeeks,
  endOfMinute,
  isWithinInterval,
  setHours,
  setISODay,
  setMinutes,
  startOfMinute,
  subWeeks,
} from 'date-fns'

import { DayAndTime, OpeningTime } from '@src/graphql-types'

// get the hours and minutes from the generic hh:mm time string
const parseTime = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number)
  return [hours, minutes]
}

// get an actual date from a date and a dayAndTime object
// eg. toDateFromDayAndTime(new Date('2024-09-05T11:00:00.000+01:00'), { day: 1, time: '09:00' })
// returns the monday from the same week as the `date` with the time set to 09:00 local time
const toDateFromDayAndTime = ({
  at,
  dayAndTime,
}: {
  at: Date
  dayAndTime: DayAndTime
}) => {
  const [hours, minutes] = parseTime(dayAndTime.time)
  if (hours === undefined || minutes === undefined) {
    return null
  }
  return setMinutes(setHours(setISODay(at, dayAndTime.day), hours), minutes)
}

// takes an array of generic opening times and finds the first one that is open at the given date and time
export const findOpeningTime = ({
  openingTimes,
  at,
}: {
  openingTimes: OpeningTime[]
  at: Date
}): {
  startDate: Date
  endDate: Date
  openingTime: OpeningTime
} | null => {
  // map the generic opening times to actual date ranges for the week of the `date` arg
  const dateIntervals = openingTimes.flatMap(openingTime => {
    const { start, end } = openingTime

    // convert to actual dates in the current week
    const nullableStartDate = toDateFromDayAndTime({ at, dayAndTime: start })
    const nullableEndDate = toDateFromDayAndTime({ at, dayAndTime: end })
    if (!nullableStartDate || !nullableEndDate) {
      console.error('Invalid opening time', openingTime)
      return []
    }
    const startDate = startOfMinute(nullableStartDate)
    const endDate = endOfMinute(nullableEndDate)

    // end is after start (eg a monday to tuesday interval) so return the single range
    if (startDate.valueOf() < endDate.valueOf()) {
      const dateInterval = { startDate, endDate, openingTime }
      return [dateInterval]
    }

    // end is before start (eg a sunday to monday interval) so return two ranges:
    // 1. start is last week and end is this week
    // 2. start is this week and end is next week
    const dateIntervals = [
      { startDate: subWeeks(startDate, 1), endDate, openingTime }, // last week to this week
      { startDate, endDate: addWeeks(endDate, 1), openingTime }, // this week to next week
    ]
    return dateIntervals
  })

  return (
    dateIntervals.find(({ startDate, endDate }) =>
      isWithinInterval(at, { start: startDate, end: endDate })
    ) || null
  )
}

export const getCurrentTimeDeliveryLength = (
  openingTimes: OpeningTime[],
  at: Date = new Date()
): number | null => {
  return findOpeningTime({ at, openingTimes })?.openingTime.timeSlot || null
}
