import { makeVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import { z } from 'zod'

import { CoordinatesSchema as CoordinatesSchema } from '@src/hooks/useFulfilmentFilter/validation'
import { alertOkModalVar } from '@src/models/basket/alertOkModal'

type Position = z.infer<typeof CoordinatesSchema>

const coordinates = makeVar<Position | null>(null)

// This hook is used to request the user's location from the browser
// It returns a function which can be called to get the user's location
// and will trigger the callback with the coordinates
// it will also immediately return the coordinates if they are already available
export const useFindLocation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (position: Position) => void
  onError?: (error: string) => void
}): (() => Position | null) => {
  const [pendingCallback, setPendingCallback] = useState(false)
  // callback must be called in a useEffect because calling it in the `getCurrentPosition` callback
  // causes the React error: "Can't perform a React state update on an unmounted component"
  useEffect(() => {
    const currentCoordinates = coordinates()
    if (pendingCallback && currentCoordinates) {
      onSuccess(currentCoordinates)
      setPendingCallback(false)
    }
  }, [pendingCallback, onSuccess])

  return () => {
    const currentCoordinates = coordinates()

    // if we already have the coordinates, just call the callback immediately
    // though this does assume our customer is stationary...
    if (currentCoordinates) {
      onSuccess(currentCoordinates)
      return currentCoordinates
    }

    // otherwise we need to get the coordinates from the browser
    // which prompts the user to enable location services
    // if they haven't already
    navigator.geolocation.getCurrentPosition(
      // User approved location services
      position => {
        if (position.coords.latitude && position.coords.longitude) {
          coordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          setPendingCallback(true)
        }
        // Parse Error! Log the error and show a toast
        else {
          console.error(
            'Could not get coordinates from navigator.geolocation.getCurrentPosition',
            position
          )
          onError?.('getting_location_error')
          alertOkModalVar({
            alertTitle: 'location_error',
            alertMessage: 'getting_location_error',
          })
        }
      },
      // User denied location services
      error => {
        onError?.('getting_location_error')
        alertOkModalVar({
          alertTitle: 'location_error',
          alertMessage: 'getting_location_error',
        })
        console.error(error, 'Finding location error: ')
      }
    )

    // no coordinates yet, waiting for user to approve location services
    return null
  }
}
