import { useQueryParams, createEnumParam, StringParam } from 'use-query-params'

import { mapEnumToStrings } from '@src/utils/mapEnumToStrings'

/**
 * These are the types for the account section's:
 * main route,
 * child routes (depending on the main route),
 * page data id (eg address id, also depending on the main route)
 */
export enum MainRouteName {
  MENU = 'menu',
  DETAILS = 'details',
  PAYMENT = 'payment',
  MARKETING = 'marketing',
  ADDRESSES = 'addresses',
  ORDERS = 'orders',
  ADD_BUSINESS = 'add-business',
  TERMS = 'terms',
  PRIVACY = 'privacy',
  ALLERGY = 'allergy',
  HELP = 'help',
  COOKIES = 'cookies',
  MISC = 'misc',
  LOGIN = 'login',
  PASSWORD_RESET = 'password-reset',
  REGISTER = 'register',
}

export enum DetailsChildName {
  PASSWORD_CHANGE = 'change-password',
}

export enum LoginChildName {
  PASSWORD_RESET = 'reset-password',
}

export enum AddressesChildName {
  ADD = 'add',
  EDIT = 'edit',
}

export enum HelpChildName {
  FAQS = 'faqs',
  SUPPORT = 'support',
}

type ChildRouteNameTypeMap = {
  [MainRouteName.ADDRESSES]: AddressesChildName
  [MainRouteName.HELP]: HelpChildName
  [MainRouteName.DETAILS]: DetailsChildName
  [MainRouteName.LOGIN]: LoginChildName
}

type RouteDataIdTypeMap = {
  [MainRouteName.ADDRESSES]: null
  [MainRouteName.ORDERS]: string
}

const MainRouteNameEnumParam = createEnumParam<MainRouteName>(
  mapEnumToStrings(MainRouteName) as MainRouteName[]
)

type ChildRouteName = ChildRouteNameTypeMap[keyof ChildRouteNameTypeMap]

const ChildRouteNameEnumParam = createEnumParam<ChildRouteName>([
  ...(mapEnumToStrings(AddressesChildName) as AddressesChildName[]),
  ...(mapEnumToStrings(HelpChildName) as HelpChildName[]),
  ...(mapEnumToStrings(DetailsChildName) as DetailsChildName[]),
  ...(mapEnumToStrings(LoginChildName) as LoginChildName[]),
])
/**
 * This hook is used to route the user to the correct page / child page in the account section.
 */
export const useAccountRouter = (): {
  route: RoutingParams<MainRouteName> | null
  setRoute: SetRouteFn
  navigateToParentRoute: () => void
} => {
  const [queryParams, setQueryParams] = useQueryParams({
    account: MainRouteNameEnumParam,
    accountChild: ChildRouteNameEnumParam,
    pageDataId: StringParam,
    cid: StringParam,
    token: StringParam,
    from: StringParam,
  })

  const setRoute: SetRouteFn = route => {
    // pass cid and token for password reset
    setQueryParams({
      account: route?.mainRouteName ?? undefined,
      accountChild: route?.childRouteName ?? undefined,
      pageDataId: route?.pageDataId ?? undefined,
      cid: route?.cid ?? undefined,
      token: route?.token ?? undefined,
      from:
        route?.from === MainRouteName.REGISTER.toLowerCase() &&
        route.mainRouteName === MainRouteName.REGISTER
          ? undefined
          : route?.from,
    })
  }

  return {
    route: queryParams.account
      ? {
          mainRouteName: queryParams.account,
          childRouteName: queryParams.accountChild ?? undefined,
          pageDataId: queryParams.pageDataId ?? undefined,
          from: queryParams.account,
        }
      : null,
    setRoute,
    navigateToParentRoute: () => {
      // Help & Support is a special case where there is no main route, only child routes,
      // so the back should always go back to main menu
      if (queryParams.account === MainRouteName.HELP) {
        return setQueryParams({
          account: MainRouteName.MENU,
          accountChild: undefined,
          pageDataId: undefined,
        })
      }

      // if we're on a child route, go back to the parent route
      if (queryParams.accountChild || queryParams.pageDataId) {
        return setQueryParams({
          account: queryParams.account,
          accountChild: undefined,
          pageDataId: undefined,
        })
      }

      // otherwise go back to main menu
      return setQueryParams({
        account: MainRouteName.MENU,
        accountChild: undefined,
        pageDataId: undefined,
      })
    },
  }
}

/**
 * SetRouteFn is the type definition for the function which sets the route...
 * It uses a TypeScript Generic to ensure that the childRouteName and pageDataId values are limited
 * by the value of the mainRouteName.
 */
type SetRouteFn = <P extends MainRouteName>(
  params: (RoutingParams<P> & { cid?: string; token?: string }) | null
) => void

export type RoutingParams<P extends MainRouteName> = {
  mainRouteName: P
  childRouteName?: P extends keyof ChildRouteNameTypeMap
    ? ChildRouteNameTypeMap[P]
    : undefined
  pageDataId?: P extends keyof RouteDataIdTypeMap
    ? RouteDataIdTypeMap[P]
    : undefined
  cid?: string
  token?: string
  from?: string
}

export const getQueryStringFromRoute = <P extends MainRouteName>(
  route: RoutingParams<P>
): string => {
  return `/?account=${route.mainRouteName}${
    route.childRouteName
      ? `&accountChild=${route.childRouteName.toString()}`
      : ''
  }${route.pageDataId ? `&pageDataId=${route.pageDataId.toString()}` : ''}`
}
