import styled, { css } from 'styled-components'

import { CrossSVG } from '@src/components/SVGS/CrossSVG'
import { SearchIconSVG } from '@src/components/SVGS/SearchIconSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const StyledSearchInput = styled.input<{
  $showSearchBar: boolean
  $startAnimation: boolean
}>`
  border-radius: 44px;
  position: absolute;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  opacity: 1;
  right: 64px;
  outline: none;
  font-weight: 700;
  font-size: 15px;
 

  border: 2px solid ${({ theme }) => theme.colors.darkGrey};
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.brandHighlight};
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brand};
  }

  &:hover:focus {
    border: 2px solid ${({ theme }) => theme.colors.brandHighlight};
  }


  @media (min-width: ${breakpoints.tabletMisc}px) {
    display: flex;
    position: static;
    width: calc(100% - 12px);
    border-radius: 24px;
    padding: 0px;
    padding-left: 42px;
    margin: 0px;
    border: 2px solid ${({ theme }) => theme.colors.darkGrey};
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    display: flex;
    position: static;
    width: calc(100% - 40px);
    border-radius: 24px;
    box-sizing: content-box;
    padding: 0px;
    padding-left: 40px;
    margin: 0px;
    height: 44px;
    width: 100%;
    min-width: 300px;
    font-size: 16px;
  }

  ${({ $showSearchBar }) =>
    $showSearchBar &&
    css`
      animation: expand 0.7s;
      border: 2px solid #2e3333;
      border-radius: 24px;
      position: absolute;
      right: 0;
      width: calc(100% - 18px);
      font-size: 15px;
      padding-left: 40px;
      margin: 0 8px;
      opacity: 1;
      background-color: #fff;
    `}

  ${({ $startAnimation }) =>
    $startAnimation &&
    css`
      animation: collapse 0.7s;
    `}

  @keyframes expand {
    from {
      width: 16px;
      margin: 0;
      border-right: transparent;
      border-radius: 24px 0px 0px 24px;
      opacity: 0;
      background-color: #f6f6f9;
      border: 2px solid #ebecf0;
    }
    to {
      width: calc(100% - 16px);
      margin: 0 8px;
      border-radius: 24px;
      opacity: 1;
      background-color: #fff;
      border: 2px solid #2e3333;
    }
  }

  @keyframes collapse {
    from {
      width: calc(100% - 120px);
      margin: 0 16px;
      margin-right: 60px;
      border-radius: 24px;
      opacity: 1;
      background-color: #fff;
      border: 2px solid #2e3333;
    }
    to {
      width: 48px; 
      margin: 0; s
      border-radius: 24px; 
      opacity: 1;
      background-color: #fff;
      border: 2px solid #2e3333;
    }
  }
`

export const MobileCategoryCircle = styled.div<{ $showSearchBar: boolean }>`
  border-radius: 44px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  opacity: 1;
  margin-right: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  border: 2px solid ${({ theme }) => theme.colors.darkGrey};
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.brandHighlight};
  }
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brand};
  }

  &:hover:focus {
    border: 2px solid ${({ theme }) => theme.colors.brandHighlight};
  }

  display: ${({ $showSearchBar }) => $showSearchBar && 'none'};
`

export const Divider = styled.div<{ showOnRight?: boolean }>`
  width: 2px;
  height: 40px;
  position: absolute;
  background-color: #2e3333;
  top: -1px;
  left: -2px;
  z-index: 200;
  opacity: 0.2;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    opacity: 1;
    top: 12px;
    width: 3px;
    background-color: #f6f6f9;
    height: 40px;
    left: calc(100% - 58px);
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    top: 20px;
    height: 40px;
    left: calc(100% - 80px);
  }
`

export const StyledIconButton = styled.button<{
  $display: boolean
  $startAnimation: boolean
}>`
  width: 32px;
  height: 32px;
  background-color: #2e3333;
  position: absolute;
  border: none;
  border-radius: 24px;
  opacity: 0;
  left: 110%;
  top: 17px;
  z-index: 2;
  cursor: pointer;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    top: 24px;
    left: calc(100% - 120px);
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    left: calc(100% - 128px);
    top: 24px;
  }

  ${({ $display }) =>
    $display &&
    css`
      animation: slideInAndShow 0.7s;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      left: calc(100% - 100px);

      @media (min-width: ${breakpoints.tabletMisc}px) {
        animation: none;
        left: calc(100% - 100px);
        top: 16px;
      }

      @media (min-width: ${breakpoints.largeTablet}px) {
        animation: none;
        left: calc(100% - 128px);
        top: 24px;
      }
    `};

  ${({ $startAnimation }) =>
    $startAnimation &&
    css`
      animation: slideOutAndDisappear 0.3s;
    `}

  @keyframes slideInAndShow {
    from {
      opacity: 0;
      left: calc(100% - 100px);
    }
    to {
      opacity: 1;
      left: calc(100% - 100px);
    }
  }

  @keyframes slideOutAndDisappear {
    from {
      opacity: 1;
      left: calc(100% - 100px);
    }
    to {
      opacity: 0;
      left: calc(100% - 100px);
    }
  }

  &:hover {
    opacity: 0.7;
  }
`

export const StyledCross = styled(CrossSVG)`
  color: #fff;
  align-self: center;

  @media (min-width: ${breakpoints.largeTablet}px) {
    left: 0;
    z-index: 10;
  }
`

export const StyledSearchBarIcon = styled(SearchIconSVG)<{
  $showSearchBar: boolean
  $startAnimation: boolean
  $isFocused: boolean
}>`
  position: absolute;
  top: 22px;
  left: calc(100% - 98px);
  height: 20px;
  width: 20px;

  color: ${({ theme, $isFocused }) =>
    $isFocused ? theme.colors.flag : theme.colors.mainText};

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    height: 20px;
    width: 20px;
    top: 22px;
  }

  @media (min-width: ${breakpoints.tabletMisc}px) {
    position: relative;
    left: 36px;
    height: 22px;
    width: 22px;
    top: 12px;
  }

  ${({ $showSearchBar }) =>
    $showSearchBar &&
    css`
      animation: moveAndShrink 0.7s;
      height: 16px;
      width: 16px;
      left: 24px;
      top: 24px;

      @media (min-width: ${breakpoints.tabletMisc}px) {
        animation: none;
      }
    `}

  ${({ $startAnimation }) =>
    $startAnimation &&
    css`
      animation: moveAndExpand 0.7s;
    `}

  @keyframes moveAndShrink {
    from {
      left: calc(100% - 44px);
      height: 20px;
      width: 20px;
    }
    to {
      left: 22px;
    }
  }

  @keyframes moveAndExpand {
    from {
      left: 24px;
    }
    to {
      left: calc(100% - 98px);
    }
  }
`

export const CategoryIconButton = styled.button<{
  $showSearchBar: boolean
  $startAnimation: boolean
}>`
  width: 40px;
  height: 40px;
  padding-top: 4px;
  border-radius: 24px;
  background-color: white;
  border: transparent;
  cursor: pointer;

  z-index: 1;

  &:active {
    color: ${({ theme }) => theme.colors.flag};
  }

  ${({ $showSearchBar }) =>
    $showSearchBar &&
    css`
      animation: slideIn 1s;
      right: 0px;
      margin-right: 12px;
      background-color: #fff;
      top: 13px;
      position: absolute;

      @media (min-width: ${breakpoints.tabletMisc}px) {
        margin-right: 14px;
        animation: none;
        top: 14px;
      }

      @media (min-width: ${breakpoints.largeTablet}px) {
        margin-right: 7px;
        top: 22px;
        padding-right: 54px;
        background-color: transparent;
      }

      @keyframes slideIn {
        from {
          right: -10px;
          opacity: 0;
        }
        to {
          right: 0px;
          opacity: 1;
        }
      }
    `}
`

export const RefContainer = styled.div`
  width: 75%;
  display: flex;
`

export const SearchInputContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  margin-right: 8px;
  min-width: 350px;
  justify-self: flex-end;
  width: 100%;

  @media (min-width: ${breakpoints.largeTablet}px) {
    align-self: center;
    margin: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brand};

    ${StyledSearchBarIcon} {
      color: ${({ theme, isFocused }) => !isFocused && theme.colors.brand};
    }
  }
`
