import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  CountryCode,
} from 'libphonenumber-js'

export const isValidPhoneNumber = (
  phoneNumberString: string,
  countryCode: CountryCode
): boolean => {
  if (isPossiblePhoneNumber(phoneNumberString, countryCode)) {
    const phoneNumber = parsePhoneNumber(phoneNumberString, countryCode)

    // a lot of countries have less clear distinction between mobile and landline
    // so only block landlines for GB

    const isValid = phoneNumber.isValid()

    if (countryCode !== 'GB') {
      return isValid
    }

    const isMobile = phoneNumber.getType() === 'MOBILE'

    return isValid && isMobile
  } else {
    return false
  }
}
