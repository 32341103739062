import { useTransition, animated } from '@react-spring/web'
import { flatMap } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'

import { BasketCountText } from './BasketCounter.styles'

export const BasketCounter: React.FC = () => {
  const theme = useTheme()
  const basketItems = useBasketItems()

  const basketLength = flatMap(basketItems.items, item => item.quantity).reduce(
    (acc, quantity) => {
      return acc + quantity
    },
    0
  )

  const [basketItemCount, setBasketItemCount] = useState(basketLength)

  const transitions = useTransition(basketItemCount, {
    from: {
      color: '#fff',
      backgroundColor: '#fff',
      scale: 0,
    },
    enter: [
      {
        color: '#fff',
        backgroundColor: theme.colors.brand,
        scale: 1.2,
      },
      {
        color: theme.colors.brand,
        backgroundColor: '#fff',
        scale: 1,
      },
    ],
    leave: [
      {
        color: '#fff',
        backgroundColor: '#fff',
        scale: 0,
      },
    ],
    delay: 500,
    config: { duration: 500 },
    reverse: false,
  })

  useEffect(() => {
    setBasketItemCount(basketLength)
  }, [basketLength])

  const AnimatedBasketCountText = animated(BasketCountText)

  return (
    <>
      {transitions(
        (styles, itemCount) =>
          !!itemCount && (
            <AnimatedBasketCountText style={styles}>
              {itemCount}
            </AnimatedBasketCountText>
          )
      )}
    </>
  )
}
