import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const OuterContainer = styled.div`
  padding: 0 16px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 0 32px;
  }
`

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 8px;
  background-color: #f6f6f9;
`

export const Description = styled.p`
  font-size: 20px;
  margin-top: 28px;
  font-weight: 700;
`

export const BorderBottomContainer = styled.div`
  border-bottom: 2px solid #2e3333;
  padding-bottom: 8px;
`

export const SubHeader = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  padding-bottom: 4px;
`
