import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { UncontrolledTextInput } from '@src/components/Inputs'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import {
  MobileNavigationPopoverContentWrapper,
  MobileNavigationPopoverContent,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
  DoneButton,
} from '@src/components/ServicePopover/ServicePopover.styles'

export const PostcodePopover: React.FC<{
  isOpen: boolean
  onClose: () => void
  onChange?: (postcode: string) => void
  onDone: (postcode: string) => void
  postcode?: string | null
  isPopover: boolean
}> = ({ isOpen, onClose, onChange, onDone, postcode, isPopover }) => {
  const { t } = useTranslation('serviceNavigation')

  const defaultPostcode = postcode || ''
  const [newPostcode, setNewPostcode] = useState(defaultPostcode)

  // if state is being managed by parent, update local state when parent state changes
  useEffect(() => {
    setNewPostcode(defaultPostcode)
  }, [defaultPostcode])

  const updatePostcode = onChange ? onChange : setNewPostcode

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter') {
      newPostcode && onDone(newPostcode)
    }
  }

  const handleClose = () => {
    setNewPostcode(defaultPostcode)
    onClose()
  }

  return (
    <ServicePopover
      open={isOpen}
      onClose={handleClose}
      headerColor="#fff"
      backgroundColor={'#fff'}
      maxWidth={584}
      showBackdrop={!isPopover}
    >
      <MobileNavigationPopoverContentWrapper>
        <MobileNavigationPopoverContent>
          <SectionHeader
            header={t('location_header')}
            subheader={t('find_location_postcode')}
          />
          <br />
          <UncontrolledTextInput
            label={t('enter_postcode')}
            name={'postcode'}
            maxLength={8}
            toUpperCase
            onChange={event => updatePostcode(event.target.value.toUpperCase())}
            value={newPostcode}
            touched={false}
            required={false}
            onKeyDown={handleKeyDown}
          />
        </MobileNavigationPopoverContent>
      </MobileNavigationPopoverContentWrapper>
      <MobileNavigationPopoverFooter>
        <MobileNavigationPopoverFooterContent>
          <MobileNavigationResetLink onClick={handleClose}>
            {t('cancel')}
          </MobileNavigationResetLink>

          <DoneButton
            content={t('done')}
            width="180px"
            onClick={e => {
              e?.stopPropagation()
              newPostcode && onDone(newPostcode)
            }}
            type={'button'}
            disabled={!newPostcode}
          />
        </MobileNavigationPopoverFooterContent>
      </MobileNavigationPopoverFooter>
    </ServicePopover>
  )
}
