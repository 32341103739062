import React, { MouseEventHandler } from 'react'

import {
  BackButtonContent,
  StyledBackButton,
  StyledLeftArrowSVG,
} from './BackButton.styles'

export const BackButton: React.FC<{
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  children?: React.ReactNode
}> = ({ onClick, disabled = false, children }) => (
  <StyledBackButton onClick={onClick} disabled={disabled}>
    <BackButtonContent>
      <StyledLeftArrowSVG id="back" />
      {children}
    </BackButtonContent>
  </StyledBackButton>
)
