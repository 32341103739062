import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type addBusinessApplicationMutationVariables = Types.Exact<{
  name: Types.Scalars['String']
  contactName: Types.Scalars['String']
  contactAddress: Types.AddressCreateInput
  contactPhone: Types.Scalars['String']
  contactEmail: Types.Scalars['String']
  marketplaceKey: Types.Scalars['String']
}>

export type addBusinessApplicationMutation = {
  onboardingApply: { __typename: 'OnboardingReturnType'; message: string }
}

export const addBusinessApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addBusinessApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactAddress' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddressCreateInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactPhone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactEmail' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceKey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onboardingApply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactAddress' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactAddress' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactPhone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactPhone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceKey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  addBusinessApplicationMutation,
  addBusinessApplicationMutationVariables
>
