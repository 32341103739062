import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const FulfilmentContainer = styled.div<{ isActive?: boolean }>`
  position: relative;
  height: 44px;
  border-radius: 4px;
  align-self: center;
  margin: 0 2px 0 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  // Set a transparent border so that when/if borders are implemented on hover/selected, the spacing is not thrown
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s height 0.3s width 0.3s;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.brandHighlight : theme.colors.mainText};

  &:hover {
    background-color: #fff;
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.brandHighlight : theme.colors.brand};
  }
`

export const FulfilmentDetailsContainer = styled.div<{
  permanentCheckout: boolean
}>`
  display: flex;
  margin-top: 2px;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    margin: 2px 8px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    font-size: ${({ permanentCheckout }) =>
      permanentCheckout ? '14px' : '16px'};
  }
`

export const PaddedDotSpan = styled.span`
  margin: 0 20px;
  padding-bottom: 1px;
`
