import React from 'react'

export const CaretRight: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  width?: number
  height?: number
}> = ({ title = 'caret-right', id, className, desc, width, height }) => (
  <svg
    id={id}
    className={className}
    height={width ?? 22}
    width={height ?? 22}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={`${id}Title`}>{title}</title>
    {desc && <desc id="myIConDesc">{desc}</desc>}
    <path
      d="M2.53977 0.947266L0.000488281 3.35958L8.24865 11.2125L0.000488281 19.0653L2.53977 21.4776L13.3452 11.2125L2.53977 0.947266Z"
      fill="currentColor"
      transform="translate(12 10)"
    />
  </svg>
)
