import { ErrorMessage as FormikErrorMessage, Field, FormikProps } from 'formik'
import React from 'react'
import { TFunction } from 'react-i18next'

import {
  Label,
  PlusUncheckedRadioButton,
} from '@src/components/CheckoutModal/FormElements.styles'

import { AddAddressInputs } from './AddAddressInputs'
import {
  AddAddressContainer,
  AddressRadioButtonAndLabelContainer,
  AddressRadioButtonContainer,
} from './DeliveryFulfilmentStep.styles'
import {
  AddressType,
  DeliveryFulfilmentFormSchema,
} from './useDeliveryFulfilmentFormikProps/schema'

import { ErrorMessage } from '../WarningText.styles'

export const AddAddressSection: React.FC<
  {
    t: TFunction<'checkout', undefined>
  } & Pick<FormikProps<DeliveryFulfilmentFormSchema>, 'values'>
> = ({ values, t }) => (
  <AddressRadioButtonContainer isAddAddress={true}>
    <AddressRadioButtonAndLabelContainer>
      <Field
        id="addAddress"
        type="radio"
        name="addressType"
        value={AddressType.NEW}
        as={PlusUncheckedRadioButton}
        aria-controls="addAddressFormFields"
      />
      <Label htmlFor="addAddress">{t('add_address')}</Label>
      <FormikErrorMessage name="addressType" component={ErrorMessage} />
    </AddressRadioButtonAndLabelContainer>
    {values.addressType === AddressType.NEW && (
      <AddAddressContainer id="addAddressFormFields">
        <AddAddressInputs />
      </AddAddressContainer>
    )}
  </AddressRadioButtonContainer>
)
