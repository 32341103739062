import React from 'react'
import { useTranslation } from 'react-i18next'

export const ArrowLeftSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'back-button', id, className, desc }) => {
  const { t } = useTranslation(['svgs'])
  return (
    <svg
      id={id}
      className={className}
      height="16"
      width="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('arrow_back_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <g fill="currentColor" className="nc-icon-wrapper">
        <polygon
          fill="currentColor"
          points="6.7,14.7 8.1,13.3 3.8,9 16,9 16,7 3.8,7 8.1,2.7 6.7,1.3 0,8 "
        ></polygon>
      </g>
    </svg>
  )
}
