import React, { ReactNode } from 'react'

import { UnstyledButton } from '@src/components/Button/Button'
import {
  MainRouteName,
  RoutingParams,
  useAccountRouter,
} from '@src/hooks/useAccountRouter'

export const AccountSectionNavButton = <P extends MainRouteName>({
  route,
  children,
  className,
}: {
  route: RoutingParams<P>
  children: ReactNode
  className?: string
}) => {
  const { route: currentRoute, setRoute } = useAccountRouter()

  const isActive =
    (currentRoute?.mainRouteName === route?.mainRouteName &&
      !route.childRouteName) ||
    (currentRoute?.childRouteName &&
      (currentRoute?.mainRouteName === route?.mainRouteName &&
        (currentRoute?.childRouteName ?? null)) ===
        (route?.childRouteName ?? null) &&
      (currentRoute?.pageDataId ?? null) === (route?.pageDataId ?? null))

  return (
    <UnstyledButton
      className={`${className ?? ''} ${isActive ? ' active' : ''}`}
      type="button"
      onClick={() => {
        setRoute(route)
      }}
    >
      {children}
    </UnstyledButton>
  )
}
