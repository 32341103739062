import React from 'react'
import { useTranslation } from 'react-i18next'

export const FilterSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('filter_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <rect
        width="9"
        height="2"
        transform="matrix(-1 0 0 1 9 13)"
        fill="currentColor"
      />
      <circle
        r="3"
        transform="matrix(-1 0 0 1 12 14)"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect x="7" y="3" width="9" height="2" fill="currentColor" />
      <circle cx="4" cy="4" r="3" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}
