import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import { SelectWithArrow } from '@src/components/ServicePopover/SelectWithArrow'
import {
  OptionContentLabels,
  OptionContentSubTitle,
  OptionContentText,
  OptionContentTitle,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { breakpoints } from '@src/constants/breakpoints'
import { CountryCodeType, LocationType } from '@src/graphql-types'
import { useLazyCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { useMarketplaceDeliveryZonesLazyQuery } from '@src/hooks/sharedQueries/useMarketplaceDeliveryZones/useMarketplaceDeliveryZones'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useFindLocation } from '@src/hooks/useFindLocation'
import { FulfilmentFilter } from '@src/hooks/useFulfilmentFilter/validation'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'
import { screenResolutionVar } from '@src/models/screenResolution'

import { AddressesPopover } from './AddressesPopover'
import { DeliveryLocationSectionContainer } from './DeliveryLocationSection.styles'
import { PostcodePopover } from './PostcodePopover'
import { ZonesPopover } from './ZonesPopover'

type SecondaryPopoverType =
  | LocationType.POSTCODE
  | LocationType.DELIVERY_ZONE
  | LocationType.ADDRESS

export const DeliveryLocationSection: React.FC<{
  availableLocationTypes: LocationType[]
  basketOutletId?: string | null
  selectedLocation: FulfilmentFilter['where']['location']
  setSelectedLocation: (location: FulfilmentFilter['where']['location']) => void
  historicalData: FulfilmentFilter['where']['historicalData']
  showSubtitles?: boolean
  showSelection?: boolean
  isPopover?: boolean
  onSecondaryServicePopoverToggle?: (isOpen: boolean) => void
  handleCancelWhenNavigatingAway: () => void
  selectedFulfilmentIsAvailable?: boolean
}> = ({
  availableLocationTypes,
  selectedLocation,
  setSelectedLocation,
  historicalData,
  showSubtitles = true,
  // if false, the dynamic data (eg address / delivery zone name) won't be shown
  showSelection = true,
  isPopover = false,
  onSecondaryServicePopoverToggle,
  handleCancelWhenNavigatingAway,
  selectedFulfilmentIsAvailable = true,
  basketOutletId,
}) => {
  const isUserLoggedIn = Boolean(useReactiveVar(jwtVar))
  const { t } = useTranslation('serviceNavigation')
  const { outletId } = useParams<{ outletId?: string }>()

  const [secondaryPopover, _setSecondaryPopover] =
    useState<SecondaryPopoverType | null>(null)
  const checkoutRouter = useCheckoutRouter()

  const outletOrBasketOutletId = (outletId ?? basketOutletId) || undefined

  const [findingLocation, setFindingLocation] = useState(false)

  const findLocation = useFindLocation({
    onSuccess: coordinates => {
      setSelectedLocation({
        type: LocationType.COORDINATES,
        coordinates,
      })
      setFindingLocation(false)
    },
    onError: () => {
      setFindingLocation(false)
    },
  })
  const marketplace = useMarketplace()
  const [fetchAddresses, { data: addressesData }] =
    useLazyCustomerDetailsAndAddressesQuery({
      addressAcceptingOrdersToOutletId: outletOrBasketOutletId,
    })
  const [fetchDeliveryZones, { data: deliveryZonesData }] =
    useMarketplaceDeliveryZonesLazyQuery()
  const screenResolution = useReactiveVar(screenResolutionVar)

  const setSecondaryPopover: typeof _setSecondaryPopover = useCallback(
    secondaryPopover => {
      onSecondaryServicePopoverToggle?.(Boolean(secondaryPopover))
      _setSecondaryPopover(secondaryPopover)
    },
    [onSecondaryServicePopoverToggle]
  )

  // fetch data for available locations which display dynamic data (eg address / delivery zone name)
  useEffect(() => {
    if (
      availableLocationTypes.includes(LocationType.ADDRESS) &&
      isUserLoggedIn &&
      !addressesData
    ) {
      void fetchAddresses({ variables: { outletId: outletOrBasketOutletId } })
    }
    if (
      availableLocationTypes.includes(LocationType.DELIVERY_ZONE) &&
      !deliveryZonesData
    ) {
      void fetchDeliveryZones()
    }
  }, [
    outletId,
    showSelection,
    availableLocationTypes,
    isUserLoggedIn,
    addressesData,
    fetchAddresses,
    deliveryZonesData,
    fetchDeliveryZones,
  ])

  // takes a location type and returns the data used to populate an <option> within the <select>
  const getOptionDataForLocationType = useCallback(
    (
      locationType: LocationType
    ): {
      value: LocationType
      title: string
      subTitle: string
      onSelect?: () => void
      errorText?: string
    } => {
      switch (locationType) {
        case LocationType.EVERYWHERE:
          return {
            value: LocationType.EVERYWHERE,
            title: t('everywhere_option_title'),
            subTitle: t('everywhere_option_subtitle', {
              urlPath: marketplace.urlPath,
            }),
            onSelect: () => {
              setSelectedLocation({
                type: LocationType.EVERYWHERE,
              })
            },
          }
        case LocationType.POSTCODE: {
          return {
            value: LocationType.POSTCODE,
            title:
              showSelection && historicalData.postAndCountryCode
                ? historicalData.postAndCountryCode.postcode
                : t('postcode_option_title'),
            subTitle: t('postcode_option_subtitle'),
            onSelect: () => {
              setSecondaryPopover(LocationType.POSTCODE)
              if (screenResolution.width < breakpoints.wideDesktop) {
                checkoutRouter.reset()
              }
            },
            errorText: t('delivery_not_available', {
              locationType: t('postcode').toLowerCase(),
            }),
          }
        }
        case LocationType.COORDINATES:
          return {
            value: LocationType.COORDINATES,
            title: t('location_option_title'),
            subTitle: t('location_option_subtitle'),
            onSelect: () => {
              setFindingLocation(true)
              findLocation()
            },
            errorText: t('delivery_not_available', {
              locationType: t('location').toLowerCase(),
            }),
          }
        case LocationType.ADDRESS: {
          // if an address is or was selected, find the address
          let title = t('addresses_option_title')
          if (showSelection && historicalData.addressId) {
            const selectedAddress = (
              addressesData?.customerDetails?.deliveryAddresses || []
            ).find(address => address.id === historicalData.addressId)
            title = selectedAddress?.name || title
          }
          return {
            value: LocationType.ADDRESS,
            title,
            subTitle: t('addresses_option_subtitle'),
            onSelect: () => {
              setSecondaryPopover(LocationType.ADDRESS)
            },
            errorText: t('delivery_not_available', {
              locationType: t('address').toLowerCase(),
            }),
          }
        }
        case LocationType.DELIVERY_ZONE: {
          let title = t('delivery_zone_option_title')
          if (showSelection && historicalData.zoneId) {
            const selectedZone = (
              deliveryZonesData?.deliveryZonesByMarketplaceId || []
            ).find(zone => zone.id === historicalData.zoneId)
            title = selectedZone?.name || title
          }
          return {
            value: LocationType.DELIVERY_ZONE,
            title,
            subTitle: t('delivery_zone_option_subtitle'),
            onSelect: () => {
              setSecondaryPopover(LocationType.DELIVERY_ZONE)
            },
          }
        }
      }
    },
    [
      t,
      marketplace.urlPath,
      setSelectedLocation,
      showSelection,
      historicalData.postAndCountryCode,
      historicalData.addressId,
      historicalData.zoneId,
      setSecondaryPopover,
      findLocation,
      addressesData?.customerDetails?.deliveryAddresses,
      deliveryZonesData?.deliveryZonesByMarketplaceId,
    ]
  )

  return (
    <DeliveryLocationSectionContainer>
      {availableLocationTypes.map(locationType => {
        const locationOptionData = getOptionDataForLocationType(locationType)
        const errorText =
          !selectedFulfilmentIsAvailable &&
          locationType === selectedLocation.type
            ? locationOptionData.errorText
            : undefined
        return (
          <PopoverOptionContainer key={locationType}>
            <UncontrolledRadioInput
              label={
                <>
                  <OptionLabel>
                    <OptionContentLabels>
                      {showSubtitles && !errorText ? (
                        <>
                          <OptionContentTitle>
                            {locationOptionData.title}
                          </OptionContentTitle>
                          <OptionContentSubTitle>
                            {locationOptionData.subTitle}
                          </OptionContentSubTitle>
                        </>
                      ) : (
                        <OptionContentText>
                          {locationOptionData.title}
                        </OptionContentText>
                      )}
                    </OptionContentLabels>
                    {locationOptionData.value === LocationType.ADDRESS && (
                      <SelectWithArrow
                        id={locationOptionData.value}
                        dataPresent={Boolean(historicalData.addressId)}
                        justArrow={
                          !isPopover &&
                          screenResolution.width >= breakpoints.largeTablet
                        }
                      />
                    )}
                    {locationOptionData.value === LocationType.POSTCODE && (
                      <SelectWithArrow
                        id={locationOptionData.value}
                        dataPresent={Boolean(historicalData.postAndCountryCode)}
                        justArrow={
                          !isPopover &&
                          screenResolution.width >= breakpoints.largeTablet
                        }
                      />
                    )}
                  </OptionLabel>
                </>
              }
              meta={{
                touched: true,
                error: errorText,
              }}
              style={CheckboxInputStyle.TICK}
              checked={selectedLocation.type === locationOptionData.value}
              onClick={locationOptionData.onSelect}
              loading={
                locationType === LocationType.COORDINATES
                  ? findingLocation
                  : false
              }
            />
          </PopoverOptionContainer>
        )
      })}

      {deliveryZonesData && (
        <ZonesPopover
          isOpen={secondaryPopover === LocationType.DELIVERY_ZONE}
          onClose={() => setSecondaryPopover(null)}
          onDone={zoneId => {
            setSelectedLocation({
              type: LocationType.DELIVERY_ZONE,
              zoneId,
            })
            setSecondaryPopover(null)
          }}
          selectedZoneId={historicalData.zoneId}
          availableZones={deliveryZonesData.deliveryZonesByMarketplaceId}
        />
      )}

      <PostcodePopover
        isOpen={secondaryPopover === LocationType.POSTCODE}
        onClose={() => setSecondaryPopover(null)}
        onDone={postcode => {
          setSelectedLocation({
            type: LocationType.POSTCODE,
            postAndCountryCode: {
              postcode,
              countryCode: marketplace.country.ISO3166Alpha2 as CountryCodeType,
            },
          })
          setSecondaryPopover(null)
        }}
        postcode={historicalData.postAndCountryCode?.postcode}
        isPopover={isPopover}
      />

      {addressesData && (
        <AddressesPopover
          isOpen={secondaryPopover === LocationType.ADDRESS}
          onClose={() => setSecondaryPopover(null)}
          onDone={addressId => {
            setSelectedLocation({
              type: LocationType.ADDRESS,
              addressId,
            })
            setSecondaryPopover(null)
          }}
          selectedAddressId={historicalData.addressId}
          addresses={addressesData.customerDetails.deliveryAddresses}
          handleCancelWhenNavigatingAway={handleCancelWhenNavigatingAway}
          isPopover={isPopover}
        />
      )}
    </DeliveryLocationSectionContainer>
  )
}
