import styled from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
`

export const Name = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  margin-left: 16px;
  padding: 4px 0;
`

export const EditButton = styled(AccountSectionNavButton)`
  width: 72px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const Line = styled.span`
  color: #2e3333;
  font-weight: 400;
  line-height: 21px;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 16px;
`

export const FirstLine = styled(Line)`
  font-size: 14px;
  margin-left: 16px;
`

export const Splitter = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  border-top: 2px solid #e6e6e6;
`

export const DefaultAddressRadioInput = styled.div`
  margin: 16px 12px 0 16px;

  label {
    div {
      &:hover {
        svg {
          background: white;
          color: white;
        }
      }
      background: white;
      svg {
        height: 14px;
        width: 14px;
        margin: 3px;
      }
    }
  }
`
