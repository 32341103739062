import { NarrowFulfilmentMethodInputType } from '../graphql-types'
import { FulfilmentFilterWhenType } from '../hooks/useFulfilmentFilter/validation'

export const FULFILMENT_INPUT_METHOD_TO_FRIENDLY_NAME: Record<
  NarrowFulfilmentMethodInputType,
  string
> = {
  [NarrowFulfilmentMethodInputType.DELIVERY]: 'Delivery',
  [NarrowFulfilmentMethodInputType.COLLECTION]: 'Collection',
  [NarrowFulfilmentMethodInputType.TABLE]: 'Table',
}

export const ALL_FULFILMENT_FILTER_WHEN_TYPES = [
  FulfilmentFilterWhenType.ANYTIME,
  FulfilmentFilterWhenType.ASAP,
  FulfilmentFilterWhenType.PREORDER,
]

export const ALL_FULFILMENT_METHODS_SORTED = [
  NarrowFulfilmentMethodInputType.DELIVERY,
  NarrowFulfilmentMethodInputType.COLLECTION,
  NarrowFulfilmentMethodInputType.TABLE,
]

export const FULFILMENTS_NO_TABLE = [
  NarrowFulfilmentMethodInputType.DELIVERY,
  NarrowFulfilmentMethodInputType.COLLECTION,
]
