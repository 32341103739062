import { z } from 'zod'

export const ItemsUnavailableSchema = z.object({
  errorResolutionType: z.literal('ITEMS_UNAVAILABLE'),
  soldOutOrderItems: z.array(
    z.object({
      outletMenuItemId: z.string(),
      optionItemIds: z.array(z.string()),
      singleItemNotes: z.string().optional(),
      price: z.number().optional(),
    })
  ),
  hiddenMenuItemIds: z.array(z.string()),
})

export const ItemsUnavailableForTimeSchema = z.object({
  errorResolutionType: z.literal('ITEMS_UNAVAILABLE_FOR_TIME'),
  unavailableMenuItemIds: z.array(z.string()),
})

export const ItemsUnavailableForFulfilmentSchema = z.object({
  errorResolutionType: z.literal('ITEMS_UNAVAILABLE_FOR_FULFILLMENT'),
  unavailableMenuItemIds: z.array(z.string()),
  fulfillmentMethod: z.string(),
  menuFulfilmentMethod: z.string(),
})
