import { useQuery } from '@apollo/client'
import React from 'react'

import { MenuItemGroupFulfillmentMethod } from '@src/../__generated__/globalTypes'

import { GetBusinessOutletMenuDocument } from './queries/__generated__/GetBusinessOutletMenu.graphql-interface'

export const BusinessOutletMenu = function ({
  outletId,
}: {
  outletId: string
}): JSX.Element | null {
  const { data } = useQuery(GetBusinessOutletMenuDocument, {
    variables: {
      outletId,
      fulfilmentMethods: [
        MenuItemGroupFulfillmentMethod.DELIVERY,
        MenuItemGroupFulfillmentMethod.COLLECTION,
        MenuItemGroupFulfillmentMethod.TABLE,
      ],
    },
  })

  if (!data) return null

  // TODO this is just a place holder to show data
  return (
    <div>
      <ul>
        {data.menuItemGroupsForOutlet.map(menuItemGroup => (
          <li key={menuItemGroup.id}>
            {menuItemGroup.name}
            <ul>
              {menuItemGroup.outletMenuItems.map(menuItem => (
                <li key={menuItem.id}>{menuItem.name}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}
