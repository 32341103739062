import { Formik } from 'formik'
import { toUpper } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { basketItemsToOrderItems } from '@src/components/CheckoutModal/Checkout/utils/basketItemsToOrderItems'
import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import {
  CheckoutButton,
  FlexGrowForm,
  FlexGrowScrollContainer,
} from '@src/components/CheckoutModal/FormElements.styles'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { StripeElementsProvider } from '@src/components/Stripe/StripeElementsWrapper'
import { Totals } from '@src/components/Totals/Totals'
import {
  DetailedPaymentMethod,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { usePaymentCardsQuery } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useSafeArea } from '@src/hooks/useSafeArea'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'

import { getCreateOrderFulfilmentInput } from './getCreateOrderFulfilmentInput'
import { PaymentMethods } from './PaymentMethods/PaymentMethods'

import { useCreateOrderMutation } from '../mutations/useCreateOrderMutation/useCreateOrderMutation'
import { OutletClosedAlert } from '../OutletClosedAlert/OutletClosedAlert'

export type PaymentStepFormValues = {
  payment: {
    paymentMethod: DetailedPaymentMethod
    cardPaymentToken?: string
  }
}

//  Component which renders the loading state while the formik props and cards
// are loaded, and wrap the form in the Stripe Provider
export const PaymentStep: React.FC = () => {
  const basketTotals = useBasketTotals()

  if (
    !basketTotals.data ||
    basketTotals.loading ||
    !basketTotals.data.checkoutBasketTotals
  ) {
    return <LoadingSpinner />
  }
  return (
    <StripeElementsProvider
      amount={basketTotals.data.checkoutBasketTotals.sumTotal}
    >
      <PaymentForm />
    </StripeElementsProvider>
  )
}

const PaymentForm: React.FC = () => {
  const { t } = useTranslation('checkout')
  const {
    data: { currentFulfilment, historicalData },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const basketTotals = useBasketTotals()
  const basketItems = useBasketItems()
  const { createOrder, validationErrors, loading } = useCreateOrderMutation()

  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()
  const { data: paymentCardsData, loading: cardsLoading } =
    usePaymentCardsQuery()

  if (cardsLoading) {
    return <LoadingSpinner />
  }

  // will never happen as basket totals will already have data from previous steps
  if (!basketTotals.data?.checkoutBasketTotals) {
    return null
  }
  const isAgeVerificationRequired =
    basketTotals.data.basketItemsWithPrices.some(
      item => item.outletMenuItem.ageRestricted
    )

  if (!currentFulfilment.availablePaymentMethods.length) {
    const fulfilmentErrorMessageMap: Record<
      NarrowFulfilmentMethodInputType,
      string
    > = {
      [NarrowFulfilmentMethodInputType.DELIVERY]: t(
        'no_payment_methods_delivery'
      ),
      [NarrowFulfilmentMethodInputType.COLLECTION]: t(
        'no_payment_methods_collection'
      ),
      [NarrowFulfilmentMethodInputType.TABLE]: t('no_payment_methods_table'),
    }
    return (
      <ErrorPage
        errorMessage={fulfilmentErrorMessageMap[currentFulfilment.narrowType]}
      />
    )
  }

  const fulfilmentInput = getCreateOrderFulfilmentInput({ currentFulfilment })
  if (!fulfilmentInput) {
    return (
      <ErrorPage
        errorMessage={t('fulfilment_unavailable', {
          narrowFulfilmentMethod: t(currentFulfilment.narrowType.toLowerCase()),
        })}
      />
    )
  }

  const getDefaultPaymentMethod = () => {
    if (
      currentFulfilment.availablePaymentMethods.length === 1 &&
      currentFulfilment.availablePaymentMethods[0] ===
        DetailedPaymentMethod.CASH
    ) {
      return DetailedPaymentMethod.CASH
    }

    if (paymentCardsData?.customerDetails.cards.cards?.length) {
      return DetailedPaymentMethod.CARD
    }
    return DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
  }

  const initialValues = {
    ageVerificationConfirmed: !isAgeVerificationRequired,
    payment: {
      paymentMethod: getDefaultPaymentMethod(),
      cardPaymentToken: paymentCardsData?.customerDetails.cards.cards?.length
        ? paymentCardsData.customerDetails.cards.cards[0]?.id
        : undefined,
    },
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setErrors }) => {
          if (isAgeVerificationRequired && !values.ageVerificationConfirmed) {
            setErrors({ ageVerificationConfirmed: t('age_verification_error') })
            return
          }

          void createOrder({
            orderData: {
              fulfilment: fulfilmentInput,
              payment: {
                paymentMethod: values.payment.paymentMethod,
                ...(values.payment.paymentMethod ===
                  DetailedPaymentMethod.CARD && {
                  cardPaymentToken: values.payment.cardPaymentToken,
                }),
              },
              orderItems: basketItemsToOrderItems(basketItems.items),
              customerOrderNotes: historicalData.orderNotes,
              discountId:
                basketTotals.data?.checkoutBasketTotals?.appliedDiscount
                  ?.discount?.id,
              voucherKey:
                basketTotals.data?.checkoutBasketTotals?.appliedDiscount
                  ?.discount?.voucher,
            },
          })
        }}
      >
        {({ values }) => (
          <FlexGrowForm
            $windowHeight={window.innerHeight}
            $hasSafeArea={safeAreaInsetBottom > 0}
          >
            <FlexGrowScrollContainer>
              <PaymentMethods
                loading={loading}
                availablePaymentMethods={
                  currentFulfilment.availablePaymentMethods
                }
                paymentValue={values.payment}
                isAgeVerificationRequired={isAgeVerificationRequired}
                addressId={historicalData.addressId || undefined}
                tableId={historicalData.tableId || undefined}
              />
            </FlexGrowScrollContainer>
            <CheckoutButtonWrapperMobile>
              {!isMobile && safeAreaInsetBottom === 0 && <Totals />}
              <CheckoutButton
                content={toUpper(t('pay_now'))}
                type="submit"
                loading={loading}
              />
            </CheckoutButtonWrapperMobile>
          </FlexGrowForm>
        )}
      </Formik>
      <OutletClosedAlert message={validationErrors.outletUnavailableMessage} />
    </>
  )
}
