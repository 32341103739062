import Color from 'color'
import Scrollbars from 'react-custom-scrollbars-2'
import styled, { css } from 'styled-components'

import { Link } from '@src/components/Router/Link'
import { CancelSVG } from '@src/components/SVGS/CancelSVG'
import { DoubleArrowSVG } from '@src/components/SVGS/DoubleArrowSVG'
import { basketWidth } from '@src/constants/basketWidth'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  windowHeight: number
  hasSafeArea: boolean
}>`
  overflow-y: scroll;
  padding: 8px 16px;
  height: ${({ windowHeight }) => `${windowHeight - 160}px`};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 16px 32px;
    height: ${({ hasSafeArea, windowHeight }) =>
      hasSafeArea ? `${windowHeight - 180}px` : `100vh`};
  }
`

export const SpaceBetweenRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 8px 0 0px 0;
`

export const HeaderContainer = styled(SpaceBetweenRow)`
  height: 40px;
  padding: 16px 0px;
  @media (min-width: ${breakpoints.tabletMisc}px) {
    margin: 24px 0 0 0;
  }
`

export const Header = styled.h1`
  font-size: 34px;
  font-weight: 700;
  margin-left: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 0;
  }
`

export const LinkText = styled.p`
  font-size: 16px;
  text-decoration: underline;
  color: black;
  display: flex;
  justify-self: flex-end;
  cursor: pointer;
  padding-top: 8px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding-top: 16px;
  }
`

export const Subheader = styled(Link)`
  all: unset;
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  &:hover {
    text-decoration: underline;
  }
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
}>`
  height: 64px;
  width: 64px;
  border-radius: 8px;
  background-color: #f6f6f9;
  background-size: cover;
  background-position: 50%;
  margin-right: 12px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};
`

export const StyledClearButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: 32px;

  &:hover {
    color: ${({ theme }) => Color(theme.colors.danger).lighten(0.1).hexa()};
    text-decoration: none;
  }
`

export const FulfilmentButtonContainer = styled.button`
  width: 100%;
  border: 2px solid;
  border-color: #ebecf0;
  color: #000000;
  background-color: #fff;
  outline: 0;
  border-radius: 8px;
  height: 53px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: color, background-color, 0.3s;
  cursor: pointer;

  &:hover {
    border-color: #000000;
  }

  div {
    display: flex;

    p {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }
`

export const ExpandArrows = styled(DoubleArrowSVG)`
  height: 16px;
  margin-left: 12px;
`

export const InputContainer = styled.div`
  margin-top: 24px;
`

export const OrderNote = styled.input`
  all: initial;
  font: inherit;
  width: 90%;
`

export const FoodAllergyNotice = styled.button`
  border: none;
  background: none;
  font-size: 14px;
  text-decoration-line: underline;
  color: #2e3333;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  padding: 0;
  &:hover {
    text-decoration: none;
  }
`

export const ClearButton = styled(CancelSVG)`
  cursor: pointer;
  justify-self: flex-end;
  &:hover {
    opacity: 0.8;
  }
`

export const WarningTextContainer = styled.div`
  background-color: #f6f6f9;
  margin-top: -8px;
  padding: 1px 16px 4px 16px;
  border-radius: 0 0 8px 8px;
`

export const WarningText = styled.p`
  font-size: 14px;
  color: #e1292f;
`

export const OrderNoteTitle = styled.div`
  display: flex;
  margin: 0;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  margin: 16px 0 8px 0;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
    padding: 0;
    width: 50%;
    font-size: 18px;
  }
`

export const LargeFooterContainer = styled.div`
  padding: 8px 16px;
  border-top: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;

  table {
    padding: 0 16px 8px 16px;
  }

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 16px 16px;
  }

  //tablet in landscape can be greater than desktop breakpoint
  @media only screen and (hover: none) and (min-width: ${breakpoints.tabletMisc}px) {
    width: calc(${basketWidth.regular}px - 10px);
    box-shadow: 0px -12px 21px -6px rgba(149, 157, 165, 0.4);
  }
`

export const CheckoutFooterContainer = styled.div`
  bottom: 0;
  padding-bottom: max(30px, env(safe-area-inset-bottom, 0px) + 30px);
  position: fixed;
  opacity: 1;
  z-index: 12;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  right: 0;
  height: 70px;
  background-color: #ffffff;
  border-top: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    position: sticky;
    padding-bottom: 0px;
    height: auto;
  }

  @media only screen and (hover: none) and (min-width: ${breakpoints.tablet}px) {
    position: sticky;
    width: calc(${basketWidth.regular}px + 20px);
    height: auto;
    box-shadow: 0px -12px 21px -6px rgba(149, 157, 165, 0.4);
  }
`

export const SafeAreaContainer = styled.div`
  @media only screen and (hover: none) and (min-width: ${breakpoints.tablet}px) {
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
  }
`

export const CheckoutFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  position: fixed;
  flex-direction: column;
  padding: 8px 16px 24px 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    position: sticky;
    width: 100%;
    padding: 16px 32px;
  }
`

export const FlexGrowScrollContainer = styled(Scrollbars)`
  flex-grow: 1;
`
