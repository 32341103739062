import { isBefore, startOfDay } from 'date-fns'
import { Overwrite } from 'ts-toolbelt/out/Object/Overwrite'
import { z } from 'zod'

import {
  CountryCodeType,
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'

// Enums
export enum FulfilmentFilterWhenType {
  ANYTIME = 'ANYTIME',
  ASAP = 'ASAP',
  PREORDER = 'PREORDER',
}

// Coordinates
export const CoordinatesSchema = z.object({
  lat: z.number(),
  lng: z.number(),
})
export type Coordinates = z.infer<typeof CoordinatesSchema>

// FulfilmentFilterWhen Schema
export const FulfilmentFilterWhenAnytime = z.object({
  type: z.literal(FulfilmentFilterWhenType.ANYTIME),
})
export const FulfilmentFilterWhenASAP = z.object({
  type: z.literal(FulfilmentFilterWhenType.ASAP),
})
const FulfilmentFilterWhenPreorderUntransformed = z.object({
  type: z.literal(FulfilmentFilterWhenType.PREORDER),
  preorderDate: z.string(),
})
export const FulfilmentFilterWhenPreorder =
  FulfilmentFilterWhenPreorderUntransformed.transform<
    | Overwrite<
        z.infer<typeof FulfilmentFilterWhenPreorderUntransformed>,
        {
          preorderDate: Date
        }
      >
    | z.infer<typeof FulfilmentFilterWhenAnytime>
  >(data => {
    const preorderDate = new Date(data.preorderDate)
    // if the preorder date is in the past, move it to today
    if (isBefore(preorderDate, startOfDay(new Date()))) {
      return {
        type: FulfilmentFilterWhenType.PREORDER,
        preorderDate: startOfDay(new Date()),
      }
    }
    return { ...data, preorderDate }
  })

const FulfilmentFilterWhen = z.union([
  FulfilmentFilterWhenAnytime,
  FulfilmentFilterWhenASAP,
  FulfilmentFilterWhenPreorder,
])

// FulfilmentFilterWhere Schema
export const EverywhereLocation = z.object({
  type: z.literal(LocationType.EVERYWHERE),
})
export type EverywhereLocation = z.infer<typeof EverywhereLocation>
export const PostcodeLocation = z.object({
  type: z.literal(LocationType.POSTCODE),
  postAndCountryCode: z.object({
    postcode: z.string(),
    countryCode: z.nativeEnum(CountryCodeType),
  }),
})
export type PostcodeLocation = z.infer<typeof PostcodeLocation>
export const CoordinatesLocation = z.object({
  type: z.literal(LocationType.COORDINATES),
  coordinates: CoordinatesSchema, // Assuming Coordinates is defined elsewhere
})
export type CoordinatesLocation = z.infer<typeof CoordinatesLocation>
export const SavedAddressLocation = z.object({
  type: z.literal(LocationType.ADDRESS),
  addressId: z.string(),
})
export type SavedAddressLocation = z.infer<typeof SavedAddressLocation>
export const ZoneLocation = z.object({
  type: z.literal(LocationType.DELIVERY_ZONE),
  zoneId: z.string(),
})

const FulfilmentWhereLocationType = z.union([
  EverywhereLocation,
  PostcodeLocation,
  CoordinatesLocation,
  SavedAddressLocation,
  ZoneLocation,
])

export type FulfilmentWhereLocationType = z.infer<
  typeof FulfilmentWhereLocationType
>

export type ZoneLocation = z.infer<typeof ZoneLocation>
export const FulfilmentFilterWhere = z.object({
  location: FulfilmentWhereLocationType,
  fulfilmentMethods: z.array(z.nativeEnum(NarrowFulfilmentMethodInputType)),
  // this data is duplicated from the location object so that it is not lost when the location type is changed
  historicalData: z.object({
    postAndCountryCode: z
      .object({
        postcode: z.string(),
        countryCode: z.nativeEnum(CountryCodeType),
      })
      .nullable(),
    coordinates: CoordinatesSchema.nullable(),
    addressId: z.string().nullable(),
    zoneId: z.string().nullable(),
  }),
})

// FulfilmentFilter Schema
export const FulfilmentFilterValidationSchema = z.object({
  when: FulfilmentFilterWhen,
  where: FulfilmentFilterWhere,
  isInitialised: z.boolean(),
})

export type FulfilmentFilter = z.infer<typeof FulfilmentFilterValidationSchema>
