import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetBusinessByCnameQueryVariables = Types.Exact<{
  cname: Types.Scalars['String']
}>

export type GetBusinessByCnameQuery = {
  businessByCname: {
    __typename: 'Restaurant'
    id: string
    name: string
    outlets: Array<{ __typename: 'Outlet'; id: string; name: string }>
  }
}

export const GetBusinessByCnameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBusinessByCname' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByCname' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cname' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cname' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outlets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBusinessByCnameQuery,
  GetBusinessByCnameQueryVariables
>
