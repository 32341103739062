import { LazyQueryExecFunction } from '@apollo/client'
import { max } from 'date-fns'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { HookMethodArgs } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/types/types'
import { calculateCollectionPreorderDateTime } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/calculateCollectionPreorderDateTime'
import { calculateDeliveryPreorderWindow } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/calculateDeliveryPreorderWindow'
import {
  OutletAvailabilityQuery,
  OutletAvailabilityQueryVariables,
} from '@src/hooks/sharedQueries/outletAvailabilityQuery/queries/__generated__/OutletAvailability.graphql-interface'
import { FulfilmentFilterWhenType } from '@src/hooks/useFulfilmentFilter/validation'
import { debugLogger } from '@src/utils/debugLogger'
import { findOpeningTime } from '@src/utils/fulfilmentTimes/getCurrentTimeDeliveryLength'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'
import { ParsedDeliveryPreorderWindow } from '@src/utils/fulfilmentTimes/types'

/** Customer has no preorder time (ie asap) and the outlet closes
 *  + If outlet has preorder times available, then set to the next available time
 *  + If outlet has no preorder times available, then refetch the outlet to update the ui to closed
 **/
export const calculateFulfilmentTimesFromASAPTime = async (
  hookMethodArgs: HookMethodArgs & {
    outletAvailabilityQuery: LazyQueryExecFunction<
      OutletAvailabilityQuery,
      OutletAvailabilityQueryVariables
    >
  }
): Promise<{
  collectionPreorderDatetime: Date | null
  deliveryPreorderWindow: ParsedDeliveryPreorderWindow | null
}> => {
  const now = new Date()
  // whichever is latest, the closedUntil date or now
  const earliestPossibleOpenDateTime = hookMethodArgs.outlet.closedUntil
    ? max([new Date(hookMethodArgs.outlet.closedUntil), now])
    : now
  const isOpenNow =
    earliestPossibleOpenDateTime.valueOf() <= now.valueOf() &&
    findOpeningTime({
      openingTimes: hookMethodArgs.outlet.openingTimesArray,
      at: now,
    })

  // still open, no need to update the fulfilment times
  if (isOpenNow) {
    debugLogger('Outlet is still open. No need to update fulfilment times')
    return {
      collectionPreorderDatetime: null,
      deliveryPreorderWindow: null,
    }
  }

  if (hookMethodArgs.outlet.allowPreorders) {
    debugLogger(
      'Outlet is closed. Outlet allows preorders. Calculating next fulfilment times'
    )
    const isCollectionAvailable =
      hookMethodArgs.outlet.availableFulfilmentInputMethods.includes(
        NarrowFulfilmentMethodInputType.COLLECTION
      )
    const nextCollectionPreorderDatetime = isCollectionAvailable
      ? calculateCollectionPreorderDateTime({
          outlet: hookMethodArgs.outlet,
          fulfilmentFilterWhen: {
            type: FulfilmentFilterWhenType.PREORDER,
            preorderDate: earliestPossibleOpenDateTime,
          },
        })
      : null
    const isDeliveryAvailable =
      hookMethodArgs.outlet.availableFulfilmentInputMethods.includes(
        NarrowFulfilmentMethodInputType.DELIVERY
      )
    const nextDeliveryPreorderWindow = isDeliveryAvailable
      ? calculateDeliveryPreorderWindow({
          outlet: hookMethodArgs.outlet,
          fulfilmentFilterWhen: {
            type: FulfilmentFilterWhenType.PREORDER,
            preorderDate: earliestPossibleOpenDateTime,
          },
        })
      : null

    if (
      (!isCollectionAvailable || nextCollectionPreorderDatetime) &&
      (!isDeliveryAvailable || nextDeliveryPreorderWindow)
    ) {
      debugLogger('Calculated next fulfilment times', {
        nextCollectionPreorderDatetime,
        nextDeliveryPreorderWindow,
      })
      return {
        collectionPreorderDatetime: nextCollectionPreorderDatetime,
        deliveryPreorderWindow: nextDeliveryPreorderWindow,
      }
    }
  }

  debugLogger('No preorder times available. Refetching outlet')
  const { data } = await hookMethodArgs.outletAvailabilityQuery({
    variables: {
      outletId: hookMethodArgs.outlet.id,
      fulfilmentMethod:
        hookMethodArgs.existingData.currentFulfilment.narrowType,
    },
    fetchPolicy: 'network-only',
  })

  if (!data?.outlet) {
    debugLogger('No outlet data returned. Unable to update fulfilment times')
    return {
      collectionPreorderDatetime: null,
      deliveryPreorderWindow: null,
    }
  }

  const updatedOutlet = parseOutletDates(data.outlet)

  if (updatedOutlet.isOrderable) {
    const nextCollectionPreorderDatetime = calculateCollectionPreorderDateTime({
      outlet: updatedOutlet,
      fulfilmentFilterWhen: {
        type: FulfilmentFilterWhenType.ANYTIME,
      },
    })
    const nextDeliveryPreorderWindow = calculateDeliveryPreorderWindow({
      outlet: updatedOutlet,
      fulfilmentFilterWhen: {
        type: FulfilmentFilterWhenType.ANYTIME,
      },
    })
    debugLogger('Calculated updated fulfilment time from new data', {
      nextCollectionPreorderDatetime,
      nextDeliveryPreorderWindow,
    })
    return {
      collectionPreorderDatetime: nextCollectionPreorderDatetime,
      deliveryPreorderWindow: nextDeliveryPreorderWindow,
    }
  }

  debugLogger('Outlet is unorderable. Unable to update fulfilment times')
  return {
    collectionPreorderDatetime: null,
    deliveryPreorderWindow: null,
  }
}
