import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { breakpoints } from '@src/constants/breakpoints'
import { BasketOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/BasketOutletProvider'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'
import { screenResolutionVar } from '@src/models/screenResolution'

import { Container, StyledBasketSVG, TotalText } from './BasketButton.styles'
import { BasketCounter } from './BasketCounter'

const BasketTotal: React.FC = () => {
  const formatCurrency = useFormatCurrency()
  const { data } = useBasketTotals()

  if (!data) return null

  return (
    <>
      <TotalText>{formatCurrency(data?.basketItemsTotal)}</TotalText>
      <BasketCounter />
    </>
  )
}

export const BasketButton: React.FC<{
  isClickable?: boolean
}> = ({ isClickable = true }) => {
  const { t } = useTranslation('navigation')
  const { width } = useReactiveVar(screenResolutionVar)
  const checkoutRouter = useCheckoutRouter()
  const formatCurrency = useFormatCurrency()

  const showIcon = width < breakpoints.mobile

  return (
    <Container
      onClick={() => {
        isClickable && checkoutRouter.next()
      }}
      aria-label={t('basket_icon_alt')}
      buttonStyle={showIcon ? 'icon' : 'total'}
      isClickable={isClickable}
      as={isClickable ? 'button' : 'div'}
    >
      <StyledBasketSVG id="navigation-basket" title={t('basket_icon_alt')} />
      <BasketOutletProvider
        NoBasketComponent={<TotalText>{formatCurrency(0)}</TotalText>}
      >
        <BasketTotal />
      </BasketOutletProvider>
    </Container>
  )
}
