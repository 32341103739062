import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{ windowHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${props => props.windowHeight - 160}px;
  padding: 0px 24px 0 24px;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 32px;
    margin-top: 0px;
    height: 100%;
  }
`

export const Description = styled.p`
  margin-top: 24px;
  font-size: 20px;
  font-weight: 700;
`

export const SingleItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f9;
  border-radius: 4px;
  padding: 8px 16px;
  padding-top: 16px;
  margin: 8px 0;
`

export const Name = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin: 0px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  display: flex;
`

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PriceContainer = styled.div`
  display: flex;
`

export const StrikethroughPrice = styled.p`
  font-size: 14px;
  text-decoration: line-through;
  font-weight: 400;
  padding: 7px 5px;
  margin: 0;
`

export const Price = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 4px;
`

export const ItemImage = styled.div<{ imageURL: string }>`
  width: 96px;
  height: 96px;
  background-color: white;
  margin: 0 8px 8px 0;
  background-image: url(${({ imageURL }) => imageURL});
  background-size: cover;
`
