import { ReactiveVar } from '@apollo/client'

/**
 * proxyReactiveVar
 * This function takes a reactive var and returns a reactive var which can be used as normal,
 * except that onWriteSideEffect is called whenever the reactiveVar is updated,
 *
 * @param {ReactiveVar<T>} reactiveVar
 * @param {(newValue: U) => void} onWriteSideEffect The function to call whenever the reactiveVar is updated
 * @returns {ReactiveVar<U>}
 */
export function proxyReactiveVar<T, U = T>({
  reactiveVar,
  onWriteSideEffect,
}: {
  reactiveVar: ReactiveVar<T>
  onWriteSideEffect?: (newValue: U) => void
}): ReactiveVar<U> {
  return new Proxy(reactiveVar as unknown as ReactiveVar<U>, {
    // overwrite the `apply` method of the reactive var (ie when the reactive var is called as a function)
    apply: function (target, thisArg, argumentList) {
      if (onWriteSideEffect && argumentList.length > 0) {
        // argumentList has a length (ie reactiveVar was called as a function with some new value)
        // so trigger the onWriteSideEffect side-effect
        onWriteSideEffect(argumentList[0])
      }

      // return the result of calling the original reactiveVar function with the same arguments that the proxy was called with
      return Reflect.apply(target, thisArg, argumentList)
    },
  })
}
