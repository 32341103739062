export const myOrdersPage = {
  title: 'Order History',
  pagination_information: 'Showing 1 - {{totalOrdersShown}} of {{totalOrders}}',
  showing: 'Showing',
  of: 'of',
  placed: 'Placed',
  non_ideal_orders:
    "You'll see your orders shown here once they have been placed.",
  view_next: 'View Next',
}
