import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-top: 30px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding-left: 40px;
    padding-top: 40px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 70%;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 40px;
  }
`

export const MaintenanceHeader = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 30px;
  }
`

export const MaintenanceDescription = styled.div``

export const RedboxSquare = styled.div`
  background-color: #ff3b4c;
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
  border-radius: 4px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
`

export const TryAgainButton = styled.button`
  margin: 10px;
`

export const DevContainer = styled.div`
  margin-left: 10px;
  margin-top: 50px;
`
