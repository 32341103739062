import { LocationType } from '../../../graphql-types'
import { getBaseDataFromExtendedData } from '../extendData/extendFulfilmentFilterData'
import { CurriedFulfilmentFilterHookMethodArgs } from '../types'
import { FulfilmentFilter } from '../validation'

type LocationUpdateData = FulfilmentFilter['where']['location']

// update the location type without losing the historical data (ie postcode, coordinates, address id, zone id)
export const setLocationType =
  ({
    currentExtendedData,
    updateFulfilmentFilter,
  }: CurriedFulfilmentFilterHookMethodArgs) =>
  (locationUpdateData: LocationUpdateData): void => {
    const baseData = getBaseDataFromExtendedData(currentExtendedData)
    // pull historical fields from update data, falling back to existing data
    const historicalPostAndCountryCode =
      locationUpdateData.type === LocationType.POSTCODE
        ? locationUpdateData.postAndCountryCode
        : baseData.where.historicalData.postAndCountryCode
    const historicalAddressId =
      locationUpdateData.type === LocationType.ADDRESS
        ? locationUpdateData.addressId
        : baseData.where.historicalData.addressId
    const historicalCoordinates =
      locationUpdateData.type === LocationType.COORDINATES
        ? locationUpdateData.coordinates
        : baseData.where.historicalData.coordinates
    const historicalZoneId =
      locationUpdateData.type === LocationType.DELIVERY_ZONE
        ? locationUpdateData.zoneId
        : baseData.where.historicalData.zoneId

    // construct historical update data object
    const historicalUpdateData: FulfilmentFilter['where']['historicalData'] = {
      postAndCountryCode: historicalPostAndCountryCode,
      addressId: historicalAddressId,
      coordinates: historicalCoordinates,
      zoneId: historicalZoneId,
    }

    // update reactive var with merged data
    updateFulfilmentFilter({
      ...baseData,
      where: {
        ...baseData.where,
        location: locationUpdateData,
        historicalData: historicalUpdateData,
      },
    })
  }
