import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const MenuItemTable = styled.table`
  width: 100%;
  margin: 8px 0 4px 0px;
`

export const MenuItemsSubContainer = styled.tr`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 0px 16px;
  height: auto;

  &:last-child {
    padding-bottom: 4px;
  }
  td {
    padding: 0;
  }
`

export const OptionItemsContainerTable = styled.table`
  width: 100%;
`

export const OptionItemSubContainer = styled(MenuItemsSubContainer)`
  margin-top: 0;
  border-radius: 0;
  padding: 0 16px 0 16px;
`

export const MenuItemName = styled.td`
  font-weight: 700;
  list-style: none;
  margin: auto 0px;
  width: 60%;
  font-size: 14px;
  padding: 16px 0;
  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 14px;
  }
`

export const MenuItemPrice = styled(MenuItemName)`
  font-weight: 400;
  padding-top: 0px;
  width: auto;
`

export const MenuItemQuantity = styled(MenuItemPrice)`
  @media (min-width: ${breakpoints.largeTablet}px) {
    padding: 0;
    padding-right: 30px;
  }
`

export const OptionItemName = styled.span`
  font-size: 12px;
  margin-left: 8px;
`

export const OptionItemPrice = styled.td`
  font-size: 12px;
  text-align: right;
  line-height: 24px;
`
