import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AlertModal } from '@src/components/Modal/AlertModal'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useMarketplace } from '@src/hooks/useMarketplace'

// OutletClosedAlert is open when a `message` is passed, and closed when it is undefined
export const OutletClosedAlert: React.FC<{
  message?: string
}> = ({ message }) => {
  const marketplace = useMarketplace()
  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { t } = useTranslation('dirtyBasket')
  const history = useHistory()

  return (
    <AlertModal
      isOpen={message !== undefined}
      title={message as string}
      action={{
        text: t(`view_outlet_list`),
        onClick: () => {
          history.push(`/${marketplace.urlPath}`)
        },
      }}
      cancel={{
        text: t(`view_outlet_menu`, { restaurantName: outlet.restaurant.name }),
        onClick: () => {
          history.push(
            `/${marketplace.urlPath}/${outlet.id}/${outlet.restaurant.name}`
          )
        },
      }}
    />
  )
}
