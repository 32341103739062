import React from 'react'
import { Route } from 'react-router-dom'

import { Redirect } from '@src/components/Router/Redirect'
import { OrderMode } from '@src/graphql-types'
import { OutletList } from '@src/pages/OutletList/OutletList'

const listModeRoutes = (merchantURLPath: string) => {
  return [
    <Route
      key="1"
      path={`/:deliveryZone/:merchantCategory(${merchantURLPath})`}
      exact
      render={routeProps => (
        <OutletList deliveryZone={routeProps.match.params.deliveryZone} />
      )}
    />,
    <Route
      key="2"
      path={`/:deliveryZone/:merchantCategory(${merchantURLPath})`}
      render={({
        match: {
          params: { deliveryZone, merchantCategory },
        },
      }) => <Redirect to={`/${deliveryZone}/${merchantCategory}`} />}
    />,
  ]
}

const postcodeModeRoutes = (merchantURLPath: string) => [
  <Route
    key="1"
    path={`/:merchantCategory(${merchantURLPath})`}
    render={_routeProps => <OutletList />}
    exact
  />,
  <Route
    key="2"
    path={`/:merchantCategory(${merchantURLPath})`}
    render={routeProps => (
      <Redirect to={`/${routeProps.match.params.merchantCategory}`} />
    )}
    exact
  />,
]

export const outletListRoutes = ({
  merchantURLPath,
  orderMode,
}: {
  merchantURLPath: string
  orderMode: OrderMode
}): JSX.Element[] => {
  switch (orderMode) {
    case OrderMode.LIST:
      return listModeRoutes(merchantURLPath)
    case OrderMode.POSTCODE:
      return postcodeModeRoutes(merchantURLPath)
    default:
      return []
  }
}
