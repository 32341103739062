import { useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client'
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'

import { CustomerDetailsAndAddressesDocument } from './queries/__generated__/CustomerDetailsAndAddressesQuery.graphql-interface'

type CustomerDetailsQueryResult = ResultOf<
  typeof CustomerDetailsAndAddressesDocument
>
type CustomerDetailsQueryVariables = VariablesOf<
  typeof CustomerDetailsAndAddressesDocument
>
export type CustomerDetailsAndAddressesQueryArgs = {
  addressAcceptingOrdersToOutletId?: string
  queryOptions?: QueryHookOptions<
    CustomerDetailsQueryResult,
    CustomerDetailsQueryVariables
  >
}
export type SharedCustomerDetails =
  CustomerDetailsQueryResult['customerDetails']
export type SharedAddress = SharedCustomerDetails['deliveryAddresses'][number]

// useQuery which provides the customer details and addresses
// If addressAcceptingOrdersToOutletId is provided, the addresses'
// acceptsDelivery field will reflect whether the address can be delivered to
export const useCustomerDetailsAndAddressesQuery = ({
  addressAcceptingOrdersToOutletId,
  queryOptions,
}: CustomerDetailsAndAddressesQueryArgs = {}) =>
  useQuery(CustomerDetailsAndAddressesDocument, {
    variables: {
      outletId: addressAcceptingOrdersToOutletId,
    },
    ...queryOptions,
  })

export const useLazyCustomerDetailsAndAddressesQuery = ({
  addressAcceptingOrdersToOutletId,
}: {
  addressAcceptingOrdersToOutletId?: string
} = {}) =>
  useLazyQuery(CustomerDetailsAndAddressesDocument, {
    variables: {
      outletId: addressAcceptingOrdersToOutletId,
    },
  })
