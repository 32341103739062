import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button, ButtonType } from '@src/components/Button'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { TextInput } from '@src/components/Inputs/TextInput/TextInput'
import { useAccountRouter } from '@src/hooks/useAccountRouter'

import { ChangePasswordDocument } from './mutations/__generated__/ChangePassword.graphql-interface'
import { changePasswordSchema } from './validation/changePaswordStructure'

import { ResponseContainer, Subtitle } from '../Login/NewPasswordForm.styles'

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation('changePassword')
  const { navigateToParentRoute } = useAccountRouter()

  const [changePassword, { data, loading, error }] = useMutation(
    ChangePasswordDocument,
    {
      onCompleted() {
        setTimeout(() => {
          navigateToParentRoute()
        }, 2000)
      },
    }
  )

  return (
    <>
      <Helmet title={t('change_password')} />
      <BurgerSection header={{ title: t('change_password') }}>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
          validateOnBlur={true}
          validationSchema={toFormikValidationSchema(changePasswordSchema)}
          onSubmit={async values => {
            await changePassword({
              variables: {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                newPasswordConfirm: values.newPasswordConfirm,
              },
            })
          }}
        >
          <Form>
            <div>
              {data?.changePassword?.message ? (
                <ResponseContainer>
                  <LoaderCheck />
                  <Subtitle>{t(data.changePassword.message)}</Subtitle>
                </ResponseContainer>
              ) : (
                <>
                  <p>{t('enter_new_password')}</p>
                  <TextInput
                    name="currentPassword"
                    type="password"
                    label={t('current_password')}
                  />
                  <TextInput
                    name="newPassword"
                    type="password"
                    label={t('new_password')}
                  />
                  <TextInput
                    name="newPasswordConfirm"
                    type="password"
                    label={t('confirm_new_password')}
                  />
                  {error && (
                    <ResponseContainer error>
                      {t(error.message)}
                    </ResponseContainer>
                  )}

                  <Button
                    content={t('update_password')}
                    buttonType={ButtonType.PRIMARY}
                    type="submit"
                    loading={loading}
                  />
                </>
              )}
            </div>
          </Form>
        </Formik>
      </BurgerSection>
    </>
  )
}
