import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'

export const updateQuantity =
  ({
    existingItems,
    removeItem,
    navigateToBasket,
  }: HookMethodArgs & {
    removeItem: (args: { basketItemId: string }) => void
  }) =>
  ({
    basketItemId,
    quantity,
  }: {
    basketItemId: string
    quantity: number
  }): void => {
    const basketItemIndex = existingItems.findIndex(
      basketItem => basketItem.id === basketItemId
    )
    const basketItem = existingItems[basketItemIndex]
    if (!basketItem) {
      throw new Error('Error updating basket: basket item not found.')
    }

    // navigate back to the basket
    navigateToBasket()

    // update item quantity
    if (quantity > 0) {
      const clonedBasketItems = [...existingItems]
      clonedBasketItems[basketItemIndex] = {
        ...basketItem,
        quantity,
      }
      basketItemsVar(clonedBasketItems)
      return
    }

    // remove item
    removeItem({ basketItemId: basketItem.id })
  }
