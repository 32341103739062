import React from 'react'
import { useTranslation } from 'react-i18next'

import { TableSelect } from '@src/components/CheckoutModal/Checkout/TableFulfilmentStep/TableSelect'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useTablesQuery } from '@src/hooks/sharedQueries/useTables/useTables'

import { Underlay, Container, Title } from '../MenuItemModalAlertStyles'

// TODO: This needs a submit button and some open/close state from the parent
export const TablePickerModal: React.FC<{
  onSelected: () => void
}> = ({ onSelected }) => {
  const { t } = useTranslation('navigation')
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const { data, loading, error } = useTablesQuery({
    outletId: outletFulfilment.outlet.id,
  })

  if (loading) return <LoadingSpinner />
  // TODO: Maybe toast?
  if (error || !data) return null

  return (
    <>
      <Underlay />
      <Container isTablePicker>
        <Title>{t('choose_your_table')}</Title>
        <TableSelect
          tables={data.tables}
          selectedTableId={
            outletFulfilment.data.historicalData.tableId || undefined
          }
          setSelectedTableId={tableId => {
            outletFulfilment.setCurrentFulfilment({
              type: CurrentFulfilmentType.TABLE,
              tableId,
            })
            onSelected()
          }}
        />
      </Container>
    </>
  )
}
