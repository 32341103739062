import { capitalize, lowerCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DotSpan } from '@src/components/DotSpan/DotSpan'
import { FulfilmentWhenLabel } from '@src/components/FulfilmentWhenLabel/FulfilmentWhenLabel'
import { FulfilmentWhereLabel } from '@src/components/FulfilmentWhereLabel/FulfilmentWhereLabel'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { OutletFulfilmentStateType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { BagDeliverySVG } from '@src/images/BagDelivery'
import {
  InnerDetails,
  Container,
  InnerContainer,
  Subtitle,
  Title,
  StyledDoubleArrowSVG,
} from '@src/pages/OutletPage/OutletHeader/OutletHeaderMobileStyles'

export const OutletHeaderMobile: React.FC<{
  selectedNarrowFulfilment: NarrowFulfilmentMethodInputType
}> = ({ selectedNarrowFulfilment }) => {
  const { t } = useTranslation('serviceNavigation')

  return (
    <>
      <Container>
        <InnerContainer>
          <BagDeliverySVG id="service" width={27} height={26} />
          <InnerDetails>
            <Title>{capitalize(t(lowerCase(selectedNarrowFulfilment)))}</Title>
            <Subtitle>
              <FulfilmentWhenLabel
                stateType={OutletFulfilmentStateType.GLOBAL}
              />
              {/* only delivery has both a 'when' and 'where' */}
              {selectedNarrowFulfilment ===
                NarrowFulfilmentMethodInputType.DELIVERY && <DotSpan />}
              <FulfilmentWhereLabel
                stateType={OutletFulfilmentStateType.GLOBAL}
              />
            </Subtitle>
          </InnerDetails>
          <StyledDoubleArrowSVG id={'outletmenuarrow'} />
        </InnerContainer>
      </Container>
    </>
  )
}
