import { z } from 'zod'

import i18n from '@src/i18n/i18n'
import { isValidPhoneNumber } from '@src/utils/isValidPhoneNumber'

const t = (key: string) => i18n.t(key, { ns: 'signUp' })

export const contactDetailsValidation = z.object({
  firstName: z
    .string({ required_error: t('first_name.validation.required_error') })
    .min(2, {
      message: t('first_name.validation.minimum_required_characters'),
    }),
  lastName: z
    .string({
      required_error: t('last_name.validation.required_error'),
    })
    .min(2, {
      message: t('last_name.validation.minimum_required_characters'),
    }),
  phoneNumber: z
    .string({
      required_error: t('phone_number.validation.required_error'),
      invalid_type_error: t('phone_number.validation.invalid_type_error'),
    })
    .refine(
      val => isValidPhoneNumber(val, 'GB'),
      t('phone_number.validation.invalid_type_error')
    ),
  email: z.string().nonempty(),
})
