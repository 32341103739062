export const outletStatus = {
  open: 'Open',
  open_for_delivery: 'Open for Delivery',
  open_for_collection: 'Open for Collection',
  open_for_table: 'Open for Table Service',
  next_open: 'Next Open {{val}}',
  next_open_delivery: 'Next Open {{val}} for Delivery',
  next_open_collection: 'Next Open {{val}} for Collection',
  currently_closed: 'Currently Closed',
  tomorrow: 'Tomorrow',
  today: 'Today',
  preorder_now: 'Preorder Now',
  preorder_for_delivery: 'Preorder Now for Delivery',
  preorder_for_collection: 'Preorder Now for Collection',
  open_collection: 'Same Day Collection',
  open_delivery: 'Same Day Delivery',
  open_table: 'Table Service',
  collection_from: 'Collection From {{val}}',
  delivery_from: 'Delivery From {{val}}',
  collection_today: 'Collection Today',
  delivery_today: 'Delivery Today',
  same_day_delivery: 'Same Day Delivery',
  same_day_collection: 'Same Day Collection',
  next_day_delivery: 'Next Day Delivery',
  next_day_collection: 'Next Day Collection',
  collection_in_days: 'Collection in {{val}} days',
  delivery_in_days: 'Delivery in {{val}} days',
  delivery_from_time: 'Delivery from {{val}}',
  collection_from_time: 'Collection from {{val}}',
  open_from_time: 'Open from {{val}}',
  open_from_time_delivery: 'Open from  {{val}} for Delivery',
  open_from_time_collection: 'Open from  {{val}} for Collection',
  open_from_time_table: 'Open from  {{val}} for Table',
  loading: 'Loading...',
}
