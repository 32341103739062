import { discountsVar } from '../utils/reactiveVar'
import { DiscountType, Discounts, MemberDiscount } from '../validation'

const DISCOUNT_TYPE_TO_REACTIVE_VAR_KEY: Record<DiscountType, keyof Discounts> =
  {
    [DiscountType.MEMBER_DISCOUNT]: 'memberDiscount',
    [DiscountType.VOUCHER_DISCOUNT]: 'voucherDiscountId',
  }

export type UpdateDiscount = (
  updateData:
    | {
        type: DiscountType.MEMBER_DISCOUNT
        data: MemberDiscount
      }
    | {
        type: DiscountType.VOUCHER_DISCOUNT
        data: string | null // discount id
      }
) => void
export const updateDiscount: UpdateDiscount = updateData => {
  discountsVar({
    ...discountsVar(),
    [DISCOUNT_TYPE_TO_REACTIVE_VAR_KEY[updateData.type]]: updateData.data,
  })
}
