import styled from 'styled-components'

import { InputError } from '../Inputs/InputWrapper.styles'

// this container is only used so that we can override styles of the InputError component
export const DeliveryLocationSectionContainer = styled.div`
  ${InputError} {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }
`
