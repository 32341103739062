import { format } from 'date-fns'
import { padStart, startCase } from 'lodash'
import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import {
  PaymentContainer,
  Row,
  TableData,
  TableHeaderRow,
} from './PaymentDetails.styles'

import {
  getSingleOrder_myOrder_payment_CardPayment,
  getSingleOrder_myOrder_payment_OtherPayment,
} from '../queries/__generated__/getSingleOrder'
import { singleOrderCustomType } from '../queries/getSingleOrderCustomType'

type GetRowsDefaultArgs = {
  order: singleOrderCustomType
  t: TFunction<'singleOrder', undefined>
  formatCurrency: (number: number, stringPrefix?: string | undefined) => string
}
const getCashPaymentMethodRows = ({
  order,
  t,
  formatCurrency,
}: GetRowsDefaultArgs) => {
  return [
    { header: t('type_of_payment'), data: t('cash') },
    {
      header: t('amount_charged'),
      data: formatCurrency(order.grossTotal),
    },
    {
      header: t('date_charged'),
      data: format(new Date(order.createdAt), 'dd/MM/yyyy'),
    },
  ]
}

const getCardPaymentMethodRows = ({
  order,
  payment,
  t,
  formatCurrency,
}: GetRowsDefaultArgs & {
  payment: getSingleOrder_myOrder_payment_CardPayment
}) => {
  return [
    {
      header: t('type_of_payment'),
      data: payment.brand,
    },
    {
      header: t('card_number'),
      data: payment.last4,
    },
    {
      header: t('expiry_date'),
      data: [
        padStart(payment.exp_month.toString(), 2, '0'),
        payment.exp_year,
      ].join('/'),
    },
    {
      header: t('amount_charged'),
      data: formatCurrency(order.grossTotal),
    },
    {
      header: t('date_charged'),
      data: format(new Date(order.createdAt), 'dd/MM/yyyy'),
    },
  ]
}

const getInPersonCardPaymentMethodRows = ({
  order,
  t,
  formatCurrency,
}: GetRowsDefaultArgs) => {
  return [
    {
      header: t('type_of_payment'),
      data: t('in_person_payment'),
    },
    {
      header: t('amount_charged'),
      data: formatCurrency(order.grossTotal),
    },
    {
      header: t('date_charged'),
      data: format(new Date(order.createdAt), 'dd/MM/yyyy'),
    },
  ]
}

const getOtherPaymentMethodRows = ({
  order,
  payment,
  t,
  formatCurrency,
}: GetRowsDefaultArgs & {
  payment: getSingleOrder_myOrder_payment_OtherPayment
}) => {
  return [
    {
      header: t('type_of_payment'),
      data: startCase(payment.paymentMethodName),
    },
    {
      header: t('amount_charged'),
      data: formatCurrency(order.grossTotal),
    },
    {
      header: t('date_charged'),
      data: format(new Date(order.createdAt), 'dd/MM/yyyy'),
    },
  ]
}

const getPaymentMethodRows = ({
  order,
  t,
  formatCurrency,
}: {
  order: singleOrderCustomType
  t: TFunction<'singleOrder', undefined>
  formatCurrency: (number: number, stringPrefix?: string | undefined) => string
}) => {
  switch (order.payment.__typename) {
    case 'CashPayment':
      return getCashPaymentMethodRows({ order, t, formatCurrency })
    case 'CardPayment':
      return getCardPaymentMethodRows({
        order,
        payment: order.payment,
        t,
        formatCurrency,
      })
    case 'InPersonCardPayment':
      return getInPersonCardPaymentMethodRows({
        order,
        t,
        formatCurrency,
      })
    case 'OtherPayment':
      return getOtherPaymentMethodRows({
        order,
        payment: order.payment,
        t,
        formatCurrency,
      })
  }
}

export const PaymentDetails: React.VFC<{
  order: singleOrderCustomType
}> = ({ order }) => {
  const { t } = useTranslation('singleOrder')
  const formatCurrency = useFormatCurrency()

  const paymentMethodRows = getPaymentMethodRows({ order, t, formatCurrency })

  return (
    <PaymentContainer>
      <TableHeaderRow>
        <TableData>{t('header')}</TableData>
        <TableData>{t('detail')}</TableData>
      </TableHeaderRow>

      {paymentMethodRows.map(tableRow => (
        <Row key={tableRow.header}>
          <TableData>{tableRow.header}</TableData>
          <TableData>{tableRow.data}</TableData>
        </Row>
      ))}
    </PaymentContainer>
  )
}
