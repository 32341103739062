import { keyBy, sum } from 'lodash'

import { BasketItem } from '@src/hooks/useBasketItems/validation'

import { BasketItemWithPrices, BasketOutletMenuItem } from '../useBasketTotals'

export const mergeBasketItemsWithPrices = ({
  basketItems,
  basketOutletMenuItems,
}: {
  basketItems: BasketItem[]
  basketOutletMenuItems: BasketOutletMenuItem[]
}) =>
  basketItems.reduce<Record<string, BasketItemWithPrices>>(
    (acc, basketItem) => {
      const outletMenuItem = basketOutletMenuItems.find(
        ({ menuItemId }) => menuItemId === basketItem.menuItemId
      )
      if (outletMenuItem) {
        const outletMenuItemOptionItemsById = keyBy(
          outletMenuItem.options.flatMap(option => option.optionItems),
          'id'
        )
        const maybeSelectedOptionItems = basketItem.optionItemIds.map(
          optionItemId => outletMenuItemOptionItemsById[optionItemId]
        )

        if (maybeSelectedOptionItems.some(optionItem => !optionItem)) {
          // this should never happen, and if the option item isn't found
          // createOrder will fail and mark the item as unavailable
          console.error('Missing option item')
          return acc
        }

        const selectedOptionItems = maybeSelectedOptionItems as Exclude<
          typeof maybeSelectedOptionItems[number],
          undefined
        >[]
        const optionItemPrices = selectedOptionItems.map(
          optionItem => optionItem.price
        )
        const totalPriceForOneItem =
          (basketItem.price ?? outletMenuItem.price) + sum(optionItemPrices)

        const totalPrice = totalPriceForOneItem * basketItem.quantity

        acc[basketItem.id] = {
          basketItem,
          outletMenuItem,
          selectedOptionItems,
          isAddOnItem: basketItem.price !== undefined,
          totalPrice,
        }
      }
      return acc
    },
    {}
  )
