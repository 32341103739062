import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'

import { CategoriesContent } from '@src/components/CategoriesModal/CategoriesContent'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'

import { DesktopNavigation } from './DesktopNavigation'
import { MobileNavigation } from './MobileNavigation'

export const ServiceNavigation: React.FC = () => {
  const [showCategories, setShowCategories] = useState(false)
  const { width } = useReactiveVar(screenResolutionVar)

  return (
    <>
      <ServicePopover
        open={showCategories}
        onClose={() => setShowCategories(false)}
        height={width < breakpoints.tablet ? 0.81 : 0.84}
        maxWidth={860}
        headerColor={'brandColor'}
      >
        <CategoriesContent setShowCategories={setShowCategories} />
      </ServicePopover>

      {width >= breakpoints.largeTablet && (
        <DesktopNavigation setShowCategories={setShowCategories} />
      )}
      {width < breakpoints.largeTablet && (
        <MobileNavigation setShowCategories={setShowCategories} />
      )}
    </>
  )
}
