import { TFunction } from 'react-i18next'
import { z } from 'zod'

import { CountryCodeType } from '@src/graphql-types'

export enum AddressType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export const EMPTY_ADDRESS = {
  name: '',
  firstLine: '',
  secondLine: '',
  city: '',
  postcode: '',
  countryCode: CountryCodeType.GB,
  default: false,
}

// schema fields for new and existing addresses
export const sharedSchemaFields = {
  ageVerificationConfirmed: z.boolean(),
  deliveryNotes: z.string(),
  addressId: z.string(),
}

// schema fields for new addresses
export const createNewAddressSchema = (t: TFunction<'checkout', undefined>) =>
  z.object({
    addressType: z.literal(AddressType.NEW),
    addAddress: z.object({
      name: z.string(),
      firstLine: z
        .string()
        .min(1, t('field_required', { field: t('house_number.label') })),
      secondLine: z.string().min(1),
      city: z.string().min(1),
      postcode: z.string().min(1),
      countryCode: z.nativeEnum(CountryCodeType),
      default: z.boolean(),
    }),
    ...sharedSchemaFields,
  })
export type NewAddressSchema = z.infer<
  ReturnType<typeof createNewAddressSchema>
>

// schema fields for existing addresses
export const existingAddressSchema = z.object({
  addressType: z.literal(AddressType.EXISTING),

  ...sharedSchemaFields,
})
export type ExistingAddressSchema = z.infer<typeof existingAddressSchema>

// schema for the entire form
export const createDeliveryFulfilmentFormSchema = (
  t: TFunction<'checkout', undefined>
) =>
  z.discriminatedUnion('addressType', [
    createNewAddressSchema(t),
    existingAddressSchema,
  ])
export type DeliveryFulfilmentFormSchema = z.infer<
  ReturnType<typeof createDeliveryFulfilmentFormSchema>
>
