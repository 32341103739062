import styled from 'styled-components'

import { CouponSVG } from '@src/components/SVGS/CouponSVG'

export const Container = styled.div`
  padding: 0 0 8px;
`

export const SpaceBetweenRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin: 0px 0px 4px;
`

export const Header = styled.h1`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
`

export const SubHeader = styled.h2`
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
`

export const MainContainer = styled.div`
  background-color: #f6f6f9;
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  margin: 0 0 16px 0;
  border-radius: 8px;
`

export const FulfilmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
`

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0px;
  margin: 0px;
  padding: 0px 8px;
`

export const HiddenRow = styled.tr`
  // Hide the table headers visually but show them to screen readers
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  font-size: 0px;
`

export const RowContainer = styled.div`
  margin: 4px 0px;
`

export const Row = styled.tr`
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  display: flex;
`

export const TableData = styled.td`
  &:nth-child(even) {
    text-align: left;
  }
`

export const OptionItemContainer = styled.div`
  padding: 0;
  background-color: #f6f6f9;
  border-radius: 4px;
`

export const DiscountNotice = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  border-radius: 4px;
  padding: 8px 12px;
  align-items: center;
`

export const StyledCouponSVG = styled(CouponSVG)`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  color: #2e3333;
`

export const StyledCouponContainer = styled.div`
  margin-top: 4px;
`

export const CouponContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const LabelledCouponContainer = styled.div`
  width: 32px;
  height: 32px;
  background-color: #ebecf0;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

export const PaddedDotSpan = styled.span`
  margin: 0 6px;
  padding-bottom: 1px;
`
