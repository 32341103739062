import { snakeCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Allergen } from '@src/../__generated__/globalTypes'

import {
  AllergenContainer,
  DotContainer,
  AllergenLabel,
  AllergenIcon,
  AllergenIconContainer,
  AgeRestrictedIcon,
} from './allergen-tag.styles'

interface PropTypes {
  allergens: Array<Allergen>
  isMenuitem?: boolean
  iconView?: boolean
  ageRestricted?: boolean
}

const ALLERGEN_LABELS: Record<keyof typeof Allergen, string> = {
  VEGETARIAN: 'Vegetarian',
  VEGAN: 'Vegan',
  GLUTEN_FREE: 'Gluten Free',
  DAIRY_FREE: 'Dairy Free',
  KETO: 'Keto',
}

const ALLERGEN_TAG: Record<keyof typeof Allergen, string> = {
  VEGETARIAN: 'V',
  VEGAN: 'VE',
  GLUTEN_FREE: 'GF',
  DAIRY_FREE: 'DF',
  KETO: 'K',
}

export const AllergenTags: React.FC<PropTypes> = ({
  allergens,
  isMenuitem,
  iconView,
  ageRestricted,
}) => {
  const { t } = useTranslation('allergyTags')
  const presentAllergenLabels = allergens.map(
    allergen => ALLERGEN_LABELS[allergen]
  )
  const presentAllergenTags = allergens.map(allergen => ({
    ariaLabel: snakeCase(allergen),
    tag: ALLERGEN_TAG[allergen],
  }))

  return iconView ? (
    <AllergenIconContainer>
      {ageRestricted && (
        <AgeRestrictedIcon
          aria-label={t('age_restricted')}
          title={t('age_restricted')}
        >
          {t('age_restricted_content')}
        </AgeRestrictedIcon>
      )}
      {presentAllergenTags.map(({ ariaLabel, tag }) => (
        <AllergenIcon key={tag} aria-label={t(ariaLabel)} title={t(ariaLabel)}>
          {tag}
        </AllergenIcon>
      ))}
    </AllergenIconContainer>
  ) : (
    <AllergenContainer>
      {presentAllergenLabels.map((allergen, i) => {
        return (
          <AllergenContainer key={allergen}>
            <AllergenLabel isMenuitem={isMenuitem}>
              {t(snakeCase(allergen))}
            </AllergenLabel>
            {i + 1 !== presentAllergenLabels.length && (
              <DotContainer isMenuitem={isMenuitem}>{'•'}</DotContainer>
            )}
          </AllergenContainer>
        )
      })}
    </AllergenContainer>
  )
}
