import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'

import { HookMethodArgs } from '../types/types'
import { CurrentFulfilmentType, HistoricalData } from '../validation'

// Updated type to allow any subset of HistoricalData to be updated at once
type PartialHistoricalData = Partial<HistoricalData>

export type UpdateHistoricalData = (updateData: PartialHistoricalData) => void

export const updateHistoricalData =
  (hookMethodArgs: HookMethodArgs): UpdateHistoricalData =>
  updateData => {
    const updatedHistoricalData = {
      ...hookMethodArgs.existingData.historicalData,
      ...updateData,
    }

    const updatedData = {
      ...hookMethodArgs.existingData,
      historicalData: updatedHistoricalData,
    }

    // Check and update for specific fulfilment type changes if needed
    if (
      updateData.tableId &&
      hookMethodArgs.existingData.currentFulfilment.narrowType ===
        NarrowFulfilmentMethodInputType.TABLE
    ) {
      updatedData.currentFulfilment = {
        ...hookMethodArgs.existingData.currentFulfilment,
        type: CurrentFulfilmentType.TABLE,
        tableId: updateData.tableId,
      }
    } else if (
      updateData.collectionPreorderDatetime &&
      hookMethodArgs.existingData.currentFulfilment.type ===
        CurrentFulfilmentType.COLLECTION
    ) {
      updatedData.currentFulfilment = {
        ...hookMethodArgs.existingData.currentFulfilment,
        collectionPreorderDatetime: updateData.collectionPreorderDatetime,
      }
    } else if (
      updateData.deliveryPreorderWindow &&
      hookMethodArgs.existingData.currentFulfilment.narrowType ===
        NarrowFulfilmentMethodInputType.DELIVERY
    ) {
      updatedData.currentFulfilment = {
        ...hookMethodArgs.existingData.currentFulfilment,
        deliveryPreorderWindow: updateData.deliveryPreorderWindow,
      }
    }

    // Call updateDataFn once with all necessary updates
    hookMethodArgs.updateDataFn(updatedData)
  }
