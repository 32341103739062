import React, { useMemo } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

import {
  ActionBar,
  Container,
  Header,
  Title,
  SubTitle,
  SecondRow,
  Content,
  StyledArrowLeftSVG,
  StyledCircleCrossSVG,
  ThirdRow,
  StyledButton,
} from './Drawer.styles'

export enum DrawerHeaderActions {
  CLOSE = 'close',
  BACK = 'back',
}

export type DrawerHeaderProps = {
  action?: DrawerHeaderActions
  headerText?: string
  isCheckout?: boolean
}
export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  action,
  headerText,
  isCheckout = false,
}) => {
  const checkoutRouter = useCheckoutRouter()
  const accountRouter = useAccountRouter()

  const [queryParams] = useQueryParams({
    from: StringParam,
  })

  const HeaderTextComponent = headerText ? (
    <ActionBar>
      <Title>{headerText}</Title>
    </ActionBar>
  ) : null

  const IconButton = useMemo(() => {
    switch (action) {
      case DrawerHeaderActions.BACK: {
        const onClick = isCheckout
          ? checkoutRouter.prev
          : accountRouter.navigateToParentRoute
        return (
          <StyledButton
            onClick={() => {
              if (
                queryParams.from &&
                queryParams.from === MainRouteName.REGISTER.toLowerCase()
              ) {
                accountRouter.setRoute({
                  mainRouteName: MainRouteName.REGISTER,
                })
              } else {
                onClick()
              }
            }}
          >
            <StyledArrowLeftSVG id="back_button" />
          </StyledButton>
        )
      }
      case DrawerHeaderActions.CLOSE: {
        const onClick = isCheckout
          ? checkoutRouter.reset
          : () => accountRouter.setRoute(null)
        return (
          <StyledButton onClick={onClick}>
            <StyledCircleCrossSVG id="close" title="Close button" />
          </StyledButton>
        )
      }
      default:
        null
    }
  }, [
    action,
    isCheckout,
    checkoutRouter.prev,
    checkoutRouter.reset,
    accountRouter,
    queryParams.from,
  ])

  return (
    <ActionBar>
      {HeaderTextComponent}
      {IconButton}
    </ActionBar>
  )
}

export const BurgerSection: React.FC<{
  children: React.ReactNode
  header?: {
    title: string
    subTitle?: string
    secondRow?: React.ReactNode
    thirdRow?: React.ReactNode
  }
  action?: DrawerHeaderActions
  twoLineHeader?: boolean
  whiteBackgroundColor?: boolean
  noPadding?: boolean
}> = ({
  children,
  header,
  action = DrawerHeaderActions.BACK,
  twoLineHeader,
  whiteBackgroundColor,
  noPadding,
}) => {
  const showSecondaryHeader =
    header?.subTitle || header?.secondRow || header?.thirdRow

  return (
    <Container>
      <DrawerHeader action={action} headerText={header?.title} />
      {showSecondaryHeader && (
        <Header twoLineHeader={twoLineHeader}>
          {header.subTitle && <SubTitle>{header.subTitle}</SubTitle>}
          {header.secondRow && <SecondRow>{header.secondRow}</SecondRow>}
          {header.thirdRow && <ThirdRow>{header.thirdRow}</ThirdRow>}
        </Header>
      )}
      <Content
        twoLineHeader={twoLineHeader}
        noPadding={noPadding}
        whiteBackgroundColor={whiteBackgroundColor}
      >
        {children}
      </Content>
    </Container>
  )
}
