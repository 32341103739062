import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LocationType } from '@src/graphql-types'
import { useLazyCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { useMarketplaceDeliveryZonesLazyQuery } from '@src/hooks/sharedQueries/useMarketplaceDeliveryZones/useMarketplaceDeliveryZones'
import { FulfilmentFilter } from '@src/hooks/useFulfilmentFilter/validation'
import { jwtVar } from '@src/models/customer/jwt'

export const LocationLabel: React.FC<{
  fulfilmentFilterWhere: FulfilmentFilter['where']
}> = ({ fulfilmentFilterWhere }) => {
  const { t } = useTranslation('navigation')
  const jwt = useReactiveVar(jwtVar)
  const [fetchAddresses, { data: addressesData }] =
    useLazyCustomerDetailsAndAddressesQuery()
  const [fetchDeliveryZones, { data: deliveryZonesData }] =
    useMarketplaceDeliveryZonesLazyQuery()

  // fetch data for locations which display dynamic data
  useEffect(() => {
    if (
      fulfilmentFilterWhere.location.type === LocationType.ADDRESS &&
      jwt &&
      !addressesData
    ) {
      void fetchAddresses()
    }
    if (
      fulfilmentFilterWhere.location.type === LocationType.DELIVERY_ZONE &&
      !deliveryZonesData
    ) {
      void fetchDeliveryZones()
    }
  }, [
    fulfilmentFilterWhere.location.type,
    jwt,
    addressesData,
    fetchAddresses,
    deliveryZonesData,
    fetchDeliveryZones,
  ])

  switch (fulfilmentFilterWhere.location.type) {
    case LocationType.EVERYWHERE:
      return t('everywhere')
    case LocationType.POSTCODE:
      return <>{fulfilmentFilterWhere.location.postAndCountryCode.postcode}</>
    case LocationType.COORDINATES:
      return t('near_me')
    case LocationType.ADDRESS: {
      // if an address is or was selected, find the address
      const selectedAddressId = fulfilmentFilterWhere.location.addressId
      const selectedAddress = (
        addressesData?.customerDetails?.deliveryAddresses || []
      ).find(address => address.id === selectedAddressId)
      const label = selectedAddress?.name || t('saved_address')
      return <>{label}</>
    }
    case LocationType.DELIVERY_ZONE: {
      const selectedZoneId = fulfilmentFilterWhere.location.zoneId
      const selectedZone = (
        deliveryZonesData?.deliveryZonesByMarketplaceId || []
      ).find(zone => zone.id === selectedZoneId)
      const label = selectedZone?.name || t('delivery_zone_option_title')
      return <>{label}</>
    }
  }
}
