import { pick } from 'lodash'

import { persistCurrentOutlet } from './persistence'
import {
  basketOutletIdReactiveVar,
  outletFulfilmentReactiveVar,
} from './reactiveVars'

// Remove all non-basket outlets from reactive var and session storage
// This is likely due to a change to the fulfilment filters
export const clearNonBasketOutletFulfilments = () => {
  const basketOutletId = basketOutletIdReactiveVar()
  const outletFulfilments = outletFulfilmentReactiveVar()

  const basketOutletFulfilment = basketOutletId
    ? pick(outletFulfilments.outlets, basketOutletId)
    : {}
  outletFulfilmentReactiveVar({
    outlets: basketOutletFulfilment,
    unsavedState: null,
  })
  persistCurrentOutlet(null)
}
