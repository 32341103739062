import { useReactiveVar } from '@apollo/client'
import throttle from 'lodash/throttle'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { ONE_SECOND_IN_MILLISECONDS } from '@src/constants/time'
import { screenResolutionVar } from '@src/models/screenResolution'

import { useBreakpoint } from '../hooks/useBreakpoint'

const showBreakpointMonitor = process.env.NODE_ENV === 'development'

const updateScreenResolutionVar = throttle(
  () => {
    screenResolutionVar({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  },
  ONE_SECOND_IN_MILLISECONDS / 10,
  { leading: true, trailing: true }
)

export const CaptureWindowResize: React.FC = ({ children }) => {
  useEffect(() => {
    updateScreenResolutionVar()

    window.onresize = updateScreenResolutionVar

    return () => {
      window.onresize = null
    }
  }, [])

  return (
    <>
      {showBreakpointMonitor && <BreakpointMonitor />}
      {children}
    </>
  )
}

const BreakpointMonitor = () => {
  const { width } = useReactiveVar(screenResolutionVar)
  const { isMobile, isTablet, isDesktop, isWideDesktop } = useBreakpoint()

  const getBreakpoint = (width: number) => {
    if (isMobile) {
      return `${width} (mobile)`
    }

    if (isTablet) {
      return `${width} (tablet)`
    }

    if (isDesktop) {
      return `${width} (desktop)`
    }

    if (isWideDesktop) {
      return `${width} (wideDesktop)`
    }
  }

  return <BreakpointMonitorBox>{getBreakpoint(width)}</BreakpointMonitorBox>
}

const BreakpointMonitorBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background: red;
  z-index: 9999;
  padding: 0.1rem;
  font: 0.6rem monospace;
`
