import React from 'react'

import { CheckoutHeader } from '@src/components/CheckoutModal/CheckoutHeader'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { BasketOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/BasketOutletProvider'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

import { AddOnItemsEl } from './AddOnItems/AddOnItemsEl'
import { CheckoutSuccess } from './CheckoutSuccess'
import { FulfilmentSteps } from './FulfilmentSteps'
import { PaymentStep } from './PaymentStep/PaymentStep'

import { BasketSummary } from '../BasketSummary'
import { EmptyBasket } from '../BasketSummary/EmptyBasket'

export const Checkout: React.FC = () => {
  const checkoutRouter = useCheckoutRouter()
  const { basketOutletId } = useBasketOutletId()

  const getContent = () => {
    if (
      checkoutRouter.defaultedRoute !== CheckoutRoute.COMPLETE &&
      !basketOutletId
    ) {
      return <EmptyBasket />
    }

    switch (checkoutRouter.defaultedRoute) {
      case CheckoutRoute.BASKET:
        return <BasketSummary />
      case CheckoutRoute.FULFILMENT:
        return <FulfilmentSteps />
      case CheckoutRoute.PAYMENT:
        return <PaymentStep />
      case CheckoutRoute.OFFERS:
        return <AddOnItemsEl />
      case CheckoutRoute.PAYMENT_REDIRECT_VERIFICATION:
        // TODO: Skeleton loader
        return <LoadingSpinner />
      case CheckoutRoute.COMPLETE:
        return <CheckoutSuccess />
    }
  }

  return (
    <>
      <CheckoutHeader />
      {checkoutRouter.defaultedRoute === CheckoutRoute.COMPLETE && (
        <CheckoutSuccess />
      )}
      {checkoutRouter.defaultedRoute !== CheckoutRoute.COMPLETE &&
        !basketOutletId && <EmptyBasket />}

      {checkoutRouter.defaultedRoute !== CheckoutRoute.COMPLETE &&
        basketOutletId && (
          <BasketOutletProvider>{getContent()}</BasketOutletProvider>
        )}
    </>
  )
}
