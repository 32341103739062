import React from 'react'

import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import {
  MenuItemName,
  MenuItemQuantity,
  MenuItemPrice,
  MenuItemsSubContainer,
  MenuItemTable,
  OptionItemName,
  OptionItemSubContainer,
  OptionItemPrice,
  OptionItemsContainerTable,
} from './MenuItems.styles'

import { singleOrderCustomType } from '../queries/getSingleOrderCustomType'

export const MenuItems: React.FC<{ myOrder: singleOrderCustomType }> = ({
  myOrder,
}) => {
  const formatCurrency = useFormatCurrency()
  return (
    <MenuItemTable>
      {myOrder.groupedItems.map(groupedItem => (
        <>
          <MenuItemsSubContainer key={groupedItem.id}>
            <MenuItemName>{groupedItem.name}</MenuItemName>
            <MenuItemQuantity>
              {'x'} {groupedItem.quantity}
            </MenuItemQuantity>
            <MenuItemPrice>
              {formatCurrency(groupedItem.totalPrice)}
            </MenuItemPrice>
          </MenuItemsSubContainer>
          {groupedItem.singleItemNotes && (
            <OptionItemSubContainer>
              <OptionItemName>{groupedItem.singleItemNotes}</OptionItemName>
            </OptionItemSubContainer>
          )}
          <OptionItemsContainerTable>
            {groupedItem?.optionItems?.map(optionItem => (
              <OptionItemSubContainer key={`${groupedItem.id}${optionItem.id}`}>
                <td colSpan={2}>
                  <OptionItemName>
                    {'\u2022'} {optionItem.name}
                  </OptionItemName>
                </td>
                <OptionItemPrice>
                  {!!optionItem.price && (
                    <>{formatCurrency(optionItem.price)}</>
                  )}
                </OptionItemPrice>
              </OptionItemSubContainer>
            ))}
          </OptionItemsContainerTable>
        </>
      ))}
    </MenuItemTable>
  )
}
