import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonType } from '@src/components/Button'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'

import { BasketItem as BasketItemComponent } from './BasketItem'
import { Container } from './BasketItems.styles'

export const BasketItems: React.FC = () => {
  const marketplace = useMarketplace()
  const basketItems = useBasketItems()
  // modal needs a separate state cause the item get removed before the modal is closed
  const [showModal, setShowModal] = useState(false)

  const [removeItemIdToConfirm, setRemoveItemIdToConfirm] =
    useState<string | null>(null)

  useEffect(() => {
    if (removeItemIdToConfirm) {
      setShowModal(true)
    }
  }, [removeItemIdToConfirm])

  const { t } = useTranslation('checkout')

  const { data: basketTotals } = useBasketTotals()

  if (!basketTotals) return <LoadingSpinner />

  const allowSingleItemOrderNotes = !!marketplace.allowSingleItemOrderNotes

  return (
    <>
      <AlertModal
        isOpen={showModal}
        title={t('remove_item')}
        subTitle={t('remove_item_desc', {
          itemName: basketItems.items.find(
            item => item.id === removeItemIdToConfirm
          )?.name,
        })}
        action={{
          text: t('confirm'),
          intent: ButtonType.DANGER,
          onClick: () => {
            if (removeItemIdToConfirm) {
              setShowModal(false)
              setTimeout(() => {
                basketItems.removeItem({ basketItemId: removeItemIdToConfirm })
              }, 1000)
            }
          },
        }}
        cancel={{
          text: t('cancel'),
          intent: ButtonType.SECONDARY,
          onClick: () => {
            setShowModal(false)
            setTimeout(() => {
              setRemoveItemIdToConfirm(null)
            }, 1000)
          },
        }}
      />
      <Container>
        {basketTotals.basketItemsWithPrices.map(basketItemWithPrices => (
          <BasketItemComponent
            key={basketItemWithPrices.basketItem.id}
            basketItemWithPrices={basketItemWithPrices}
            allowSingleItemOrderNotes={allowSingleItemOrderNotes}
            setRemoveItemIdToConfirm={setRemoveItemIdToConfirm}
          />
        ))}
      </Container>
    </>
  )
}
