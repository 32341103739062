import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { debounce } from 'lodash'
import React, { useRef } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'

import { breakpoints } from '@src/constants/breakpoints'
import { MerchantType } from '@src/graphql-types'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine,
  getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet,
} from './queries/__generated__/getSearchOutletsAndCuisinesMobile'
import { getSearchOutletsAndCuisinesMobileDocument } from './queries/__generated__/getSearchOutletsAndCuisinesMobile.graphql-interface'
import { SearchHead } from './SearchHead'
import { SearchHeadMobile } from './SearchHeadMobile'
import { RefContainer } from './SearchHeadStyles'
import { SearchResults } from './SearchResults'

const MIN_SEARCH_TERM_LENGTH = 3

function isCuisine(
  outletOrCategory:
    | getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine
    | getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet
): outletOrCategory is getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine {
  return outletOrCategory.__typename === 'Cuisine'
}

function isOutlet(
  outletOrCategory:
    | getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine
    | getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet
): outletOrCategory is getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet {
  return outletOrCategory.__typename === 'Outlet'
}

// this is the main search component which provides data and wraps the input and results
export const Search: React.FC<{
  setShowCategories: (shouldShow: boolean) => void
  showSearchBar?: boolean
  startAnimation?: boolean
  startClosingAnimation?: () => void
}> = ({
  showSearchBar = false,
  startAnimation = false,
  startClosingAnimation,
  setShowCategories,
}) => {
  const marketplace = useMarketplace()
  const [sendSearchQuery, { data, error, loading }] = useLazyQuery(
    getSearchOutletsAndCuisinesMobileDocument
  )
  const { width } = useReactiveVar(screenResolutionVar)
  const [showSearchResults, setShowSearchResults] = React.useState(false)
  const searchHeadRef = useRef<HTMLDivElement>(null)
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearch = debounce((searchTerm: string) => {
    if (searchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
      setShowSearchResults(true)
      void sendSearchQuery({
        variables: {
          marketplaceId: marketplace.id,
          nameContains: searchTerm,
        },
      })
      ReactPixel.track('Search', {
        search_string: searchTerm,
      })
    }
  }, 500)

  const categories = (data?.searchOutletsAndCategoriesByName || []).filter(
    isCuisine
  )
  const outlets = (data?.searchOutletsAndCategoriesByName || []).filter(
    isOutlet
  )

  const { t } = useTranslation('serviceNavigation')

  const searchBarPlaceholder = () => {
    if (showSearchBar)
      return marketplace.merchantType === MerchantType.RETAIL
        ? t('search_retail')
        : t('search_restaurant')
    return ''
  }

  const handleClose = () => {
    if (startClosingAnimation) {
      startClosingAnimation()
    }
  }

  return (
    <>
      {width < breakpoints.tabletMisc ? (
        <SearchHeadMobile
          executeSearch={debouncedSearch}
          setShowCategories={setShowCategories}
          showSearchBar={showSearchBar}
          startAnimation={startAnimation}
          placeholder={searchBarPlaceholder()}
          startClosingAnimation={handleClose}
          closeSearchResults={() => setShowSearchResults(false)}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      ) : (
        <RefContainer ref={searchHeadRef}>
          <SearchHead
            executeSearch={debouncedSearch}
            setShowCategories={setShowCategories}
            closeSearchResults={() => setShowSearchResults(false)}
            categories={categories}
            outlets={outlets}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </RefContainer>
      )}

      {showSearchResults && (
        <SearchResults
          categories={categories}
          outlets={outlets}
          error={!!error}
          loading={loading}
          closeSearchResults={() => setShowSearchResults(false)}
          setSearchTerm={setSearchTerm}
          containerWidth={`${searchHeadRef.current?.offsetWidth}px`}
        />
      )}
    </>
  )
}
