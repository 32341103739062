import { isWithinInterval } from 'date-fns'

import { OutletFulfilmentContextData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/types'

import { getEarliestFulfilmentTime } from './getEarliestFulfilmentTime'
import { getLatestFulfilmentTime } from './getLatestFulfilmentTime'
import { ParsedDeliveryPreorderWindow } from './types'

export const filterOutUnavailableDeliveryPreorderWindows = (
  args: Pick<
    OutletFulfilmentContextData,
    | 'closedUntil'
    | 'daysOfferedInAdvanceMin'
    | 'daysOfferedInAdvanceMax'
    | 'deliveryPreorderWindows'
    | 'prepTime'
    | 'openingTimesArray'
  >
): ParsedDeliveryPreorderWindow[] => {
  const earliestPreorderWindowStart = getEarliestFulfilmentTime(args)

  const latestPreorderWindowStart = getLatestFulfilmentTime(
    args.daysOfferedInAdvanceMax
  )
  const availableDeliveryPreorderWindows = args.deliveryPreorderWindows.filter(
    preorderWindow => {
      return isWithinInterval(preorderWindow.start, {
        start: earliestPreorderWindowStart,
        end: latestPreorderWindowStart,
      })
    }
  )
  return availableDeliveryPreorderWindows
}
