export const discounts = {
  percent_off: '{{discountPercentage}}% off',
  amount_off: '{{discountAmount}} off',
  orders_over: '{{discountPhrase}} orders over {{minimumSubtotal}}',
  on_any_order: '{{discountPhrase}} on any order value',
  invalid_discount: 'Please enter a valid discount code',
  discount_error: 'Sorry, this discount could not be applied.',
  discount_minimum_not_met_error:
    "Discount minimum basket total of {{minimumSubtotalGross}} has not been met. This discount does not accept other offers or discounts to meet it's minimum requirement",
  discount_successfully_applied: 'Discount successfully applied.',
  discount_code: 'Discount Code',
  apply: 'Apply',
  discount_applied: 'Discount Applied',
  discount_enrolment:
    'Accept to activate your {{discountAmount}}% discount from {{businessName}}',
  accept_discount:
    'This will allow you to benefit from a permanent discount applied to all future orders.',
  accept: 'Accept',
  activation_problem:
    'There was a problem with activation. Please contact the business to request a new code.',
  discount_accepted:
    'Accepted, your discount will be automatically applied at checkout.',
  sign_in_to_accept_discount:
    'Please sign in to accept and activate your {{discountAmount}}% discount from {{businessName}}',
  create_account_to_accept_discount:
    'Please create an account to accept and activate your {{discountAmount}}% discount from {{businessName}}',
  email_address: 'Email Address',
  password: 'Password',
  sign_in_and_accept: 'Sign In & Accept Discount',
  sign_up_and_accept: 'Sign Up & Accept Discount',
  logged_in: 'Logged In',
  have_a_discount: 'Do you have a Discount Code?',
  enter_discount_code: 'Enter Discount Code',
  new_customer: 'New Customer?',
  create_account: 'Create Account',
  forgot_password: 'Forgot Password?',
  already_have_an_account: 'Already have an account?',
  sign_in: 'Sign In',
  x_offers_available: '{{discountCount}} Offers Available',
  cancel: 'Decline',
  close_modal: 'Cancel',
}
