import { useLazyQuery, useQuery } from '@apollo/client'

import { OutletAvailabilityVariables } from './queries/__generated__/OutletAvailability'
import { OutletAvailabilityDocument } from './queries/__generated__/OutletAvailability.graphql-interface'

// hook which gets all the availability data for the outlet
// It is used to fetch data after the outlet context has been set, and therefor the fulfilment method is known
export const useOutletAvailabilityQuery = (
  variables: OutletAvailabilityVariables
) => {
  return useQuery(OutletAvailabilityDocument, {
    variables,
  })
}

// This lazy version of the query is used to refetch the data when asap time expires in order to update the UI everywhere
export const useLazyOutletAvailabilityQuery = () => {
  return useLazyQuery(OutletAvailabilityDocument)
}
