export const navigation = {
  see_all: 'See All',
  left: 'Scroll Left',
  right: 'Scroll Right',
  navigation_back_arrow: 'Back to previous page',
  search: 'Search...',
  filter: 'Filter',
  back_to_homepage: 'Back to Homepage',
  favourite_icon_alt: 'View your favourite outlets',
  basket_icon_alt: 'View your basket',
  near_me: 'Near Me',
  go_back: 'Go Back',
  back_to_outlets: 'Back to {{urlPath}} {{searchType}}',
  everywhere: 'Everywhere',
  enter_location: 'Enter Location',
  unknown_location:
    'Please share your location with us so we can verify our services are available in your area.',
  use_current_location: 'Use Current Location',
  saved_address: 'Saved Address',
  enter_your_postcode: 'Enter Your Postcode',
  issue_with_postcode:
    'There was a problem finding your postcode. Please try again.',
  issue_with_delivery_zone: 'There was a problem, please try again.',
  delivery_not_available:
    'Delivery from this outlet is not available to your location',
  new_postcode: 'Change Postcode',
  new_delivery_zone: 'Change Location',
  order_for_collection: 'Order For Collection',
  go_back_and_find_outlets: 'Find {{merchantType}} for {{postcode}}',
  collection_from_outlet: 'Collect From Outlet',
  choose_your_table: 'Please pick your table',
  postcode: 'Postcode',
  where: 'Where',
  restaurants: 'Restaurants',
  businesses: 'Businesses',
}
