import styled from 'styled-components'

import { CouponSVG } from '@src/components/SVGS/CouponSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  compactStyle: boolean
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 150px;
  align-items: flex-end;
  margin: 0;
  transition: ease-in-out 0.3s;
  padding-bottom: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }
`

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  overflow: hidden;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.tablet}px) {
    padding-left: 64px;
    color: ${({ theme }) => theme.colors.brandForeground};
  }
`

export const SingleDiscountContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 16px 18px 12px;
  border-radius: 8px;
  min-width: 240px;
  z-index: -1;
  background-color: rgba(200, 200, 200, 0.2);
  margin-right: 16px;
  height: 52px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 18px 32px 18px 24px;
    min-width: 270px;
    height: 60px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    min-width: 240px;
  }
`

export const CouponAndTextContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
`

export const StyledCouponSVG = styled(CouponSVG)`
  min-width: 20px;
  min-height: 20px;
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.discount};
`

export const DiscountText = styled.span`
  font-size: 16px;
  font-weight: 700;
  margin-left: 12px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.discount};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 18px;
  }
`

export const TermsText = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-left: 32px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 15px;
  }
`
