import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'
import { BasketItem, UnavailableItemReasons } from '../validation'

export type UnavailableItems = {
  [basketItemId: string]: UnavailableItemReasons
}

export const patchItemAvailability =
  ({ existingItems }: HookMethodArgs) =>
  ({ unavailableItems }: { unavailableItems: UnavailableItems }) => {
    let itemsUpdated = false
    const updatedItems = existingItems.map<BasketItem>(existingItem => {
      // find item in unavailable items array
      const unavailableItem = unavailableItems[existingItem.id]

      // if not found, return existing item
      if (!unavailableItem) return existingItem

      itemsUpdated = true
      // if found, update item availability, retaining existing reasons that aren't
      return {
        ...existingItem,
        availability: {
          ...existingItem.availability,
          ...unavailableItem,
        },
      }
    })

    if (itemsUpdated) {
      basketItemsVar(updatedItems)
    }
  }
