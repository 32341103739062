import { z } from 'zod'

import i18n from '@src/i18n/i18n'

const t = (key: string) => i18n.t(key, { ns: 'signInError' })

export const loginInputSchema = z.object({
  email: z
    .string({
      required_error: t('please_enter_your_email_address'),
    })
    .email({ message: t('invalid_email_address') }),
  password: z.string({ required_error: t('please_enter_your_password') }),
})

export type LoginInput = z.infer<typeof loginInputSchema>
