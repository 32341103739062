export const outletDetails = {
  profile: 'Profile',
  collection_min: 'Min. Collection Subtotal',
  delivery_min: 'Min. Delivery Subtotal',
  hygiene_rating: 'Hygiene Rating',
  hygiene_desc:
    'The Food Standards Agency updates food hygiene ratings regularly. Visit the FSA’s website to see the most recent rating for this restaurant:',
  view_hygiene_rating: 'View Hygiene Rating',
  help_with_allergens: 'Help With Allergens',
  questions: 'Questions?',
  allergens_desc:
    'Ask the restaurant about their ingredients and cooking methods by calling the restaurant.',
  opening_times: 'Opening Times',
  day: 'Day',
  delivery: 'Delivery',
  collection: 'Collection',
  table: 'Table Service',
}
