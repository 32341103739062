import {
  LocationType,
  NarrowFulfilmentMethodInputType,
  OrderMode,
} from '@src/graphql-types'

import { MarketplaceContextData } from './MarketplaceContext'

export const extendMarketplaceData = (marketplace: MarketplaceContextData) => {
  const sortedAvailableFulfilmentMethods = [
    NarrowFulfilmentMethodInputType.DELIVERY,
    NarrowFulfilmentMethodInputType.COLLECTION,
    ...(marketplace.allowOrderToTable
      ? [NarrowFulfilmentMethodInputType.TABLE]
      : []),
  ]

  const availableLocationTypes: LocationType[] =
    marketplace.orderMode === OrderMode.LIST
      ? [LocationType.DELIVERY_ZONE]
      : [
          LocationType.ADDRESS,
          LocationType.COORDINATES,
          LocationType.POSTCODE,
          LocationType.EVERYWHERE,
        ]

  return {
    ...marketplace,
    sortedAvailableFulfilmentMethods,
    availableLocationTypes,
  }
}
