import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type UpdateMarketingPreferencesMutationMutationVariables = Types.Exact<{
  optOutNewRestaurantMarketing?: Types.InputMaybe<Types.Scalars['Boolean']>
  optOutMenuMarketing?: Types.InputMaybe<Types.Scalars['Boolean']>
  optOutPromotionMarketing?: Types.InputMaybe<Types.Scalars['Boolean']>
  optOutMarketingSMS?: Types.InputMaybe<Types.Scalars['Boolean']>
  optOutMarketingEmail?: Types.InputMaybe<Types.Scalars['Boolean']>
  optOutMarketingPush?: Types.InputMaybe<Types.Scalars['Boolean']>
}>

export type UpdateMarketingPreferencesMutationMutation = {
  updateMarketingPreferences: {
    __typename: 'Customer'
    id: string
    optOutMarketingSMS: boolean | null
    optOutMenuMarketing: boolean | null
    optOutMarketingPush: boolean | null
    optOutMarketingEmail: boolean | null
    optOutPromotionMarketing: boolean | null
    optOutNewRestaurantMarketing: boolean | null
  }
}

export const UpdateMarketingPreferencesMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingPreferencesMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optOutNewRestaurantMarketing' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optOutMenuMarketing' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optOutPromotionMarketing' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optOutMarketingSMS' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optOutMarketingEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optOutMarketingPush' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMarketingPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optOutNewRestaurantMarketing' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optOutNewRestaurantMarketing' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optOutMenuMarketing' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optOutMenuMarketing' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optOutPromotionMarketing' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optOutPromotionMarketing' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optOutMarketingSMS' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optOutMarketingSMS' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optOutMarketingEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optOutMarketingEmail' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optOutMarketingPush' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optOutMarketingPush' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMarketingSMS' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMenuMarketing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMarketingPush' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutMarketingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutPromotionMarketing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutNewRestaurantMarketing' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMarketingPreferencesMutationMutation,
  UpdateMarketingPreferencesMutationMutationVariables
>
