import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { OrderProgress } from './OrderProgress'
import {
  StatusBoxContainer,
  StatusPhotoText,
  StatusBoxText,
  StatusBoxPlaced,
  StatusBoxUpdate,
  FailedHeader,
  StatusInfoContainer,
} from './StatusBox.styles'

export const RejectedStatusBox: React.VFC<{
  restaurantName: string
  updatedAt: string
  isAccountPage: boolean
}> = ({ restaurantName, isAccountPage, updatedAt }) => {
  const { t } = useTranslation('singleOrder')
  const orderUpdatedAt = formatDistanceToNow(new Date(updatedAt), {
    addSuffix: true,
  })

  return (
    <StatusBoxContainer isAccountPage={isAccountPage}>
      <StatusPhotoText>
        <StatusInfoContainer>
          <StatusBoxPlaced>
            {format(new Date(updatedAt), 'dd MMM yyyy')}
          </StatusBoxPlaced>
          <StatusBoxUpdate>
            {t('updated')} {orderUpdatedAt}
          </StatusBoxUpdate>
          <FailedHeader isAccountPage={isAccountPage}>
            {t('rejected')}
          </FailedHeader>
          <StatusBoxText>
            {t('order_marked_rejected', { restaurantName })}
          </StatusBoxText>
        </StatusInfoContainer>
      </StatusPhotoText>
      <OrderProgress valueNow={4} isOrderCancelledOrRejected={true} />
    </StatusBoxContainer>
  )
}
