import React from 'react'

import { NarrowFulfilmentMethodInputType, OrderMode } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { DeliveryZonePickerModal } from './DeliveryZonePickerModal'
import { PostcodeInputModal } from './PostcodeInputModal'
import { TablePickerModal } from './TablePickerModal'

// modal which is shown if the user has not selected a location
export const LocationRequiredAlert: React.FC<{
  onSelected?: () => void
  narrowFulfilmentMethods?: NarrowFulfilmentMethodInputType[]
}> = ({ onSelected = () => void 0, narrowFulfilmentMethods }) => {
  const { orderMode } = useMarketplace()
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  if (orderMode === OrderMode.LIST) {
    return <DeliveryZonePickerModal onSelected={onSelected} />
  }

  if (orderMode === OrderMode.POSTCODE) {
    if (
      outletFulfilment.data.currentFulfilment.type ===
      CurrentFulfilmentType.TABLE_UNSELECTED
    ) {
      return <TablePickerModal onSelected={onSelected} />
    }
    return (
      <PostcodeInputModal
        onSelected={onSelected}
        narrowFulfilmentMethods={narrowFulfilmentMethods}
      />
    )
  }

  return null
}
