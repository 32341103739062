import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'

export const StyledBottomSheet = styled(BottomSheet)<{
  $headerColor?: string
  $maxWidth?: number
  $backgroundColor?: string
  gripperColor?: string
  isOpen: boolean
}>`
  // must set z-index on individual components
  [data-rsbs-backdrop] {
    z-index: 15;
    pointer-events: ${({ isOpen }) => (isOpen ? `auto` : 'none')};
  }
  [data-rsbs-overlay] {
    overflow: none;
    z-index: 16;
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '652px')};
    margin: auto;
  }
  [data-rsbs-header] {
    background-color: ${({ $headerColor, theme }) =>
      $headerColor === 'brandColor'
        ? theme.colors.brand
        : $headerColor ?? '#fff'};
    border-radius: 8px;
    padding: 0;
    height: 28px;
  }
  [data-rsbs-header]::before {
    background-color: ${({ theme }) => theme.colors.brand};
    width: 32px;
    height: 4px;
    border-radius: 2px;
    margin-top: 4px;
  }
  [data-rsbs-content] {
    background-color: ${({ $backgroundColor }) => $backgroundColor ?? '#fff'};
  }
  [data-rsbs-scroll] {
    background-color: ${({ $backgroundColor }) => $backgroundColor ?? '#fff'};
  }
`
