import { isBefore, isValid } from 'date-fns'
import { Merge } from 'ts-toolbelt/out/Union/Merge'
import { z } from 'zod'

import { CoordinatesSchema } from '@src/hooks/useFulfilmentFilter/validation'

import { CountryCodeType } from '../../../graphql-types'

// Enums
// merges together the where and when of FulfilmentFilter
// as the when is dependent on the where (eg delivery has a preorder window, but collection has a preorder datetime, table has a table id)
export enum CurrentFulfilmentType {
  DELIVERY_EVERYWHERE = 'DELIVERY_EVERYWHERE',
  DELIVERY_POSTCODE = 'POSTCODE',
  DELIVERY_COORDINATES = 'COORDINATES',
  DELIVERY_SAVED_ADDRESS = 'SAVED_ADDRESS',
  DELIVERY_ZONE = 'DELIVERY_ZONE',
  COLLECTION = 'COLLECTION',
  TABLE = 'TABLE',
  TABLE_UNSELECTED = 'TABLE_UNSELECTED',
}

// Schemas
// DeliveryPreorderWindow with dates as strings
const DeliveryPreorderWindowSchemaUntransformed = z
  .object({
    start: z.string(),
    end: z.string(),
  })
  .nullable()
// DeliveryPreorderWindow with dates as Date objects if they are in the future
const DeliveryPreorderWindowSchema =
  DeliveryPreorderWindowSchemaUntransformed.transform<
    | (Omit<
        z.infer<typeof DeliveryPreorderWindowSchemaUntransformed>,
        'start' | 'end'
      > & {
        start: Date
        end: Date
      })
    | null
  >(data => {
    if (data === null) return null

    // parse dates
    const startDate = new Date(data.start)
    const endDate = new Date(data.end)

    // if dates are in past, clear
    if (
      !isValid(startDate) ||
      isBefore(startDate, new Date()) ||
      !isValid(endDate) ||
      isBefore(endDate, new Date())
    ) {
      return null
    }
    return {
      ...data,
      start: startDate,
      end: endDate,
    }
  })
export type DeliveryPreorderWindow = z.infer<
  typeof DeliveryPreorderWindowSchema
>

// Date as string
const DateUntransformed = z.string()
// Date as Date object if it is in the future
const DateSchema = DateUntransformed.transform<Date | null>(data => {
  const date = new Date(data)
  if (!isValid(date) || isBefore(date, new Date())) {
    return null
  }
  return date
})

export const CurrentFulfilmentDeliveryEverywhereSchema = z.object({
  type: z.literal(CurrentFulfilmentType.DELIVERY_EVERYWHERE),
  deliveryPreorderWindow: DeliveryPreorderWindowSchema,
})
export type CurrentFulfilmentDeliveryEverywhere = z.infer<
  typeof CurrentFulfilmentDeliveryEverywhereSchema
>

export const CurrentFulfilmentDeliveryPostcodeSchema = z.object({
  type: z.literal(CurrentFulfilmentType.DELIVERY_POSTCODE),
  postAndCountryCode: z.object({
    postcode: z.string(),
    countryCode: z.nativeEnum(CountryCodeType),
  }),
  deliveryPreorderWindow: DeliveryPreorderWindowSchema,
})
export type CurrentFulfilmentDeliveryPostcode = z.infer<
  typeof CurrentFulfilmentDeliveryPostcodeSchema
>

export const CurrentFulfilmentDeliveryCoordinatesSchema = z.object({
  type: z.literal(CurrentFulfilmentType.DELIVERY_COORDINATES),
  coordinates: CoordinatesSchema,
  deliveryPreorderWindow: DeliveryPreorderWindowSchema,
})
export type CurrentFulfilmentDeliveryCoordinates = z.infer<
  typeof CurrentFulfilmentDeliveryCoordinatesSchema
>

export const CurrentFulfilmentDeliverySavedAddressSchema = z.object({
  type: z.literal(CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS),
  addressId: z.string(),
  deliveryPreorderWindow: DeliveryPreorderWindowSchema,
  deliveryNotes: z.string().nullable(),
  fulfilmentId: z.string().nullable(),
})
export type CurrentFulfilmentDeliverySavedAddress = z.infer<
  typeof CurrentFulfilmentDeliverySavedAddressSchema
>

export const CurrentFulfilmentDeliveryZoneSchema = z.object({
  type: z.literal(CurrentFulfilmentType.DELIVERY_ZONE),
  zoneId: z.string(),
  deliveryPreorderWindow: DeliveryPreorderWindowSchema,
  deliveryNotes: z.string().nullable(),
})
export type CurrentFulfilmentDeliveryZone = z.infer<
  typeof CurrentFulfilmentDeliveryZoneSchema
>

export const CurrentFulfilmentCollectionSchema = z.object({
  type: z.literal(CurrentFulfilmentType.COLLECTION),
  collectionPreorderDatetime: DateSchema.nullable(),
  collectionNotes: z.string().nullable(),
})
export type CurrentFulfilmentCollection = z.infer<
  typeof CurrentFulfilmentCollectionSchema
>

export const CurrentFulfilmentTableSchema = z.object({
  type: z.literal(CurrentFulfilmentType.TABLE),
  tableId: z.string(),
  tableNotes: z.string().nullable(),
})
export type CurrentFulfilmentTable = z.infer<
  typeof CurrentFulfilmentTableSchema
>

export const CurrentFulfilmentTableUnselectedSchema = z.object({
  type: z.literal(CurrentFulfilmentType.TABLE_UNSELECTED),
  tableNotes: z.string().nullable(),
})
export type CurrentFulfilmentTableUnselected = z.infer<
  typeof CurrentFulfilmentTableUnselectedSchema
>
const CurrentFulfilmentSchema = z.discriminatedUnion('type', [
  CurrentFulfilmentDeliveryEverywhereSchema,
  CurrentFulfilmentDeliveryPostcodeSchema,
  CurrentFulfilmentDeliveryCoordinatesSchema,
  CurrentFulfilmentDeliverySavedAddressSchema,
  CurrentFulfilmentDeliveryZoneSchema,
  CurrentFulfilmentCollectionSchema,
  CurrentFulfilmentTableSchema,
  CurrentFulfilmentTableUnselectedSchema,
])

export const HistoricalDataSchema = z.object({
  collectionPreorderDatetime: DateSchema.nullable(),
  deliveryPreorderWindow: DeliveryPreorderWindowSchema.nullable(),
  ageVerificationConfirmed: z.boolean().nullable(),
  tableId: z.string().nullable(),
  tableNotes: z.string().nullable(),
  deliveryNotes: z.string().nullable(),
  collectionNotes: z.string().nullable(),
  fulfilmentId: z.string().nullable(),
  orderNotes: z.string().nullable(),
  previousFulfilmentType: z.nativeEnum(CurrentFulfilmentType).nullable(),
})
export type HistoricalData = z.infer<typeof HistoricalDataSchema>
export type NonFulfilmentSpecificHistoricalData = Omit<
  HistoricalData,
  keyof Merge<z.infer<typeof CurrentFulfilmentSchema>>
>

// Schema of the data which is extended and returned from the
// useOutletFulfilment hook
// It represents the 'where' and 'when' that the customer has selected
// for a particular outlet on the outlet page
export const NonBasketOutletFulfilmentSchema = z.object({
  outletId: z.string(),
  currentFulfilment: CurrentFulfilmentSchema,
  historicalData: HistoricalDataSchema,
})
export type NonBasketOutletFulfilment = z.infer<
  typeof NonBasketOutletFulfilmentSchema
>

const BasketFulfilmentSchema = z.discriminatedUnion('type', [
  CurrentFulfilmentDeliveryCoordinatesSchema,
  CurrentFulfilmentDeliveryPostcodeSchema,
  CurrentFulfilmentDeliverySavedAddressSchema,
  CurrentFulfilmentDeliveryZoneSchema,
  CurrentFulfilmentCollectionSchema,
  CurrentFulfilmentTableSchema,
  CurrentFulfilmentTableUnselectedSchema,
])
// Schema of the data which is extended and returned from the
// useBasket hook
// It represents the 'where' and 'when' that the customer has selected
// in their basket
// It is a narrower version of NonBasketOutletFulfilmentSchema
// for example, it excludes the postcode based delivery option, and the table unselected option
export const BasketOutletFulfilmentSchema = z.object({
  outletId: z.string(),
  currentFulfilment: BasketFulfilmentSchema,
  historicalData: HistoricalDataSchema,
})
export type BasketOutletFulfilment = z.infer<
  typeof BasketOutletFulfilmentSchema
>
