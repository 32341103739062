import { outletFulfilmentReactiveVar } from './reactiveVars'

import { NonBasketOutletFulfilment } from '../validation'

// overwrite the existing outlet fulfilment with the new one
export const updateOutletFulfilment = ({
  outletFulfilmentData,
}: {
  outletFulfilmentData: NonBasketOutletFulfilment
}): void => {
  const { outletId } = outletFulfilmentData
  const existingData = outletFulfilmentReactiveVar()

  const currentOutletData = existingData.outlets[outletId]

  // update previousFulfilmentType if type has changed
  const previousFulfilmentType =
    currentOutletData?.currentFulfilment.type ===
    outletFulfilmentData.currentFulfilment.type
      ? currentOutletData?.historicalData.previousFulfilmentType
      : currentOutletData?.currentFulfilment.type

  outletFulfilmentReactiveVar({
    ...existingData,
    outlets: {
      ...existingData.outlets,
      [outletId]: {
        ...outletFulfilmentData,
        historicalData: {
          ...outletFulfilmentData.historicalData,
          previousFulfilmentType: previousFulfilmentType || null,
        },
      },
    },
  })
}
