import styled, { css } from 'styled-components'

export const InputContainer = styled.div<{
  disabled?: boolean
  last?: boolean
  fullWidth?: boolean
}>`
  position: relative;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${props =>
    props.last &&
    css`
      margin-bottom: 12px;
    `}
  ${props =>
    props.disabled &&
    css`
      input {
        opacity: 0.6;
        background-color: #ffffff;
        cursor: not-allowed;
      }
      ,
      label {
        cursor: not-allowed;
      }
    `}
`

export const InputError = styled.p`
  margin-top: -14px;
  margin-bottom: 8px;
  padding: 16px 0 12px 18px;
  color: #e1292f;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  border-radius: 0 0 8px 8px;
  text-shadow: 1px 1px -1px white;
`
