import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 0px;
`

const Spinner = styled.div<{ showMargin?: boolean }>`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: ${({ showMargin }) => (showMargin ? '80px' : '0px')};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #dddddd;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #dddddd transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const LoadingSpinner: React.VFC<{
  className?: string
  text?: string
  showMargin?: boolean
}> = ({ className, text, showMargin = true }) => (
  <Container className={className}>
    <Spinner showMargin={showMargin}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Spinner>
    <p>{text}</p>
  </Container>
)
