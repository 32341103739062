// one from date-fns is buggy https://github.com/date-fns/date-fns/issues/3129
// fixed in v3 but update requires typescript 5
export const roundToNearestMinutes = (
  date: Date,
  {
    nearestTo,
    roundingMethod,
  }: { nearestTo: number; roundingMethod: 'floor' | 'ceil' }
): Date => {
  // Extract the minutes from the date
  const minutes = date.getMinutes()

  // Calculate the rounded minutes based on the rounding method
  const roundedMinutes =
    roundingMethod === 'floor'
      ? Math.floor(minutes / nearestTo) * nearestTo
      : Math.ceil(minutes / nearestTo) * nearestTo

  // Return a new Date object with the rounded minutes
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    roundedMinutes
  )
}
