import React from 'react'

export const MastercardSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ title = 'mastercard', id, className, desc }) => (
  <svg
    id={id}
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={`${id}Title`}>{title}</title>
    {desc && <desc id={id}>{desc}</desc>}
    <g>
      <rect
        x="1"
        y="7"
        width="46"
        height="34"
        rx="3"
        ry="3"
        fill="#243747"
      ></rect>
      <circle cx="19" cy="24" r="8" fill="#e61c24"></circle>
      <circle cx="28.934" cy="24" r="8" fill="#f99f1b"></circle>
      <path
        d="M23.967,17.736h0a7.985,7.985,0,0,0,0,12.528h0a7.985,7.985,0,0,0,0-12.528Z"
        fill="#f26622"
      ></path>
    </g>
  </svg>
)
