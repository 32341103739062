import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { FeaturedOutletsSkeleton } from '@src/components/FeaturedOutlets/FeaturedOutletsSkeleton'
import { OutletSlideSkeleton } from '@src/components/OutletSlide/OutletSlideSkeleton'

import {
  HeaderContainer,
  OutletListContainer,
  OutletsOuterContainer,
} from './OutletList.styles'

export const OutletListSkeleton: React.VFC = () => {
  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <FeaturedOutletsSkeleton />
      <OutletsOuterContainer>
        <HeaderContainer>{<Skeleton width={300} />}</HeaderContainer>
        <OutletListContainer>
          {Array(12)
            .fill(0)
            .map((_, i) => (
              <OutletSlideSkeleton key={i} />
            ))}
        </OutletListContainer>
      </OutletsOuterContainer>
    </SkeletonTheme>
  )
}
