import {
  addDays,
  addMinutes,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  startOfDay,
} from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as SwiperType } from 'swiper/types'

import { SwiperDaysPicker } from '@src/components/DatePicker/SwiperDaysPicker'
import {
  UncontrolledRadioInput,
  CheckboxInputStyle,
} from '@src/components/Inputs'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import {
  OptionContentLabels,
  OptionContentText,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { OutletFulfilmentContextData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/types'
import { ExtendedCurrentFulfilmentCollection } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { filterOutUnavailableCollectionPreorderTimes } from '@src/utils/fulfilmentTimes/filterUnavailableCollectionPreorderTimes'
import { ParsedCollectionPreorderTime } from '@src/utils/fulfilmentTimes/types'

import {
  DeliveryWindowsOfTheDayContainer,
  DeliveryWindowsOfTheDayContainerInner,
  NoSlotsCard,
} from './DatePicker.styles'
import {
  getServiceMenuTabByDate,
  ServiceMenuTab,
} from './utils/getServiceMenuTabByDate'

export const CollectionDatePicker: React.FC<{
  outletData: Pick<
    OutletFulfilmentContextData,
    | 'daysOfferedInAdvanceMin'
    | 'daysOfferedInAdvanceMax'
    | 'prepTime'
    | 'asapAllowed'
    | 'isOpen'
    | 'collectionPreorderTimes'
    | 'closedUntil'
    | 'openingTimesArray'
  >
  currentFulfilment: ExtendedCurrentFulfilmentCollection
}> = ({ outletData, currentFulfilment }) => {
  const { prepTime, asapAllowed, isOpen } = outletData
  const { updateHistoricalData } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.LOCAL,
  })

  const { t } = useTranslation('datePicker')

  const sortedCollectionPreorderTimes: ParsedCollectionPreorderTime[] = useMemo(
    () =>
      filterOutUnavailableCollectionPreorderTimes(outletData).sort((a, b) =>
        isBefore(a, b) ? -1 : 1
      ),
    [outletData]
  )

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    sortedCollectionPreorderTimes[0] ? sortedCollectionPreorderTimes[0] : null
  )
  const selectedTime = currentFulfilment.collectionPreorderDatetime
  const [selectedServiceMenuTab, setSelectedServiceMenuTab] =
    useState<ServiceMenuTab | null>(
      currentFulfilment.collectionPreorderDatetime
        ? { keyword: 'asap', title: 'ASAP' }
        : null
    )

  const [selectedDateCollectionTimes, setSelectedDateCollectionTimes] =
    useState<ParsedCollectionPreorderTime[]>([])

  const [daysSwiperInstance, setDaysSwiperInstance] =
    useState<SwiperType | null>(null)

  useEffect(() => {
    if (!selectedServiceMenuTab && selectedDate) {
      setSelectedServiceMenuTab(
        getServiceMenuTabByDate(
          selectedDate,
          selectedDateCollectionTimes,
          asapAllowed,
          isOpen,
          t('now'),
          t('today'),
          t('tommorow'),
          t('schedule')
        )
      )
    }
  }, [
    selectedDate,
    selectedTime,
    selectedServiceMenuTab,
    selectedDateCollectionTimes,
  ])

  useEffect(() => {
    // when selectedDate changes, swipe to the correct day
    if (selectedDate && daysSwiperInstance) {
      const indexToSlideTo = differenceInDays(
        startOfDay(selectedDate),
        startOfDay(new Date())
      )

      daysSwiperInstance.slideTo(indexToSlideTo, 500)
    }
  }, [selectedDate, daysSwiperInstance])

  useEffect(() => {
    // delivery windows change, clear and wait to animate transition
    if (!selectedDate || !sortedCollectionPreorderTimes) {
      return
    }

    setSelectedDateCollectionTimes(
      sortedCollectionPreorderTimes.filter(
        preorderTime =>
          isEqual(startOfDay(preorderTime), startOfDay(selectedDate)) &&
          isAfter(
            addMinutes(preorderTime, prepTime),

            new Date()
          )
      )
    )
  }, [sortedCollectionPreorderTimes, selectedDate, prepTime])

  //   preorder days used to show sliders
  const {
    preOrderDays,
  }: {
    preOrderDays: { date: Date; full: boolean }[]
  } = useMemo(() => {
    const days = []

    for (
      let i = outletData.daysOfferedInAdvanceMin;
      i <= outletData.daysOfferedInAdvanceMax;
      i++
    ) {
      const currentDate = addDays(new Date(), i)
      days.push({
        date: currentDate,
        full: !outletData.collectionPreorderTimes.filter(preorderTime =>
          isSameDay(currentDate, preorderTime)
        ).length,
      })
    }

    days.sort((a: { date: Date }, b: { date: Date }) =>
      isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1
    )

    return {
      preOrderDays: days,
    }
  }, [outletData, outletData.daysOfferedInAdvanceMin])

  return (
    <>
      {preOrderDays.length > 0 && (
        <>
          <SectionHeader
            header={t('select_date')}
            subheader={t(
              'this_is_the_day_you_would_like_to_receive_your_order'
            )}
          />
          <SwiperDaysPicker
            setDaysSwiperInstance={setDaysSwiperInstance}
            preOrderDays={preOrderDays}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        </>
      )}

      {selectedDate && (
        <>
          <SectionHeader
            header={t('select_fulfilment_time', {
              fulfilment: currentFulfilment.narrowType.toLowerCase(),
            })}
            subheader={t('your_order_will_be_ready_to_collect_at_this_time')}
          />
          <DeliveryWindowsOfTheDayContainer
            id={'windowsOfTheDayContainer'}
            role="tabpanel"
            aria-labelledby={`day-tab-${format(selectedDate, 'ddMM')}`}
          >
            {
              // column delivery windows
              <DeliveryWindowsOfTheDayContainerInner>
                {!selectedDateCollectionTimes.length ? (
                  <NoSlotsCard>
                    {selectedDateCollectionTimes.length > 0
                      ? t('slots_full_day_msg')
                      : t('closed_for_the_day')}
                  </NoSlotsCard>
                ) : (
                  selectedDateCollectionTimes.map(preorderTime => {
                    return (
                      <>
                        <PopoverOptionContainer
                          key={preorderTime.toISOString()}
                        >
                          <UncontrolledRadioInput
                            label={
                              <>
                                <OptionLabel>
                                  <OptionContentLabels>
                                    <OptionContentText>
                                      {format(
                                        new Date(preorderTime.toISOString()),
                                        'HH:mm'
                                      )}
                                    </OptionContentText>
                                  </OptionContentLabels>
                                </OptionLabel>
                              </>
                            }
                            style={CheckboxInputStyle.TICK}
                            checked={Boolean(
                              currentFulfilment.collectionPreorderDatetime &&
                                isEqual(
                                  new Date(
                                    currentFulfilment.collectionPreorderDatetime
                                  ),
                                  new Date(preorderTime)
                                )
                            )}
                            onClick={() =>
                              updateHistoricalData({
                                collectionPreorderDatetime: preorderTime,
                              })
                            }
                          />
                        </PopoverOptionContainer>
                      </>
                    )
                  })
                )}
              </DeliveryWindowsOfTheDayContainerInner>
            }
          </DeliveryWindowsOfTheDayContainer>
        </>
      )}
    </>
  )
}
