export const marketing_preferences = {
  title: 'Marketing Preferences',
  messages_title: 'Marketing Messages',
  messages_description:
    'We would like to keep you updated, please choose the messages you would like to receive.',
  channels_title: 'Marketing Channels',
  channels_description:
    'Please choose the preferred ways you would like to receive marketing messages.',
  optOutNewRestaurantMarketing: { label: 'New Businesses' },
  optOutMenuMarketing: { label: 'Product Updates' },
  optOutPromotionMarketing: { label: 'Promotions' },
  optOutMarketingEmail: { label: 'Email' },
  optOutMarketingPush: { label: 'Mobile Push Notification' },
  optOutMarketingSMS: { label: 'SMS' },
  update: 'Update',
} as const
