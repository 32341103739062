import React from 'react'

import {
  CleanOrderStatus,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'

import { CancelledStatusBox } from './CancelledStatusBox'
import { CompleteStatusBox } from './CompleteStatusBox'
import { PendingStatusBox } from './PendingStatusBox'
import { PreparingStatusBox } from './PreparingStatusBox'
import { ReadyStatusBox } from './ReadyStatusBox'
import { RejectedStatusBox } from './RejectedStatusBox'

export const StatusBox: React.VFC<{
  myOrder: {
    updatedAt: string
    cleanOrderStatus: CleanOrderStatus
    outlet: {
      displayName: string
      restaurant: { name: string; image: string | null }
      outletLogoOverride: string | null
    }
  }
  fulfillmentMethod: NarrowFulfilmentMethodInputType
  createdAt: Date
  isAccountPage?: boolean
  startWindow?: Date
  endWindow?: Date
  formattedDeliveryDate?: Date
  formattedCollectionDate?: Date
}> = ({
  myOrder,
  fulfillmentMethod,
  startWindow,
  isAccountPage = false,
  endWindow = new Date(), // TODO: should be required when live, default date for now cause of missing data
  formattedDeliveryDate,
  formattedCollectionDate,
}) => {
  const restaurantLogo =
    myOrder.outlet.outletLogoOverride || myOrder.outlet.restaurant.image
  if (myOrder.cleanOrderStatus === CleanOrderStatus.PENDING) {
    return (
      <PendingStatusBox
        isAccountPage={isAccountPage}
        updatedAt={myOrder.updatedAt}
        restaurantName={myOrder.outlet.displayName}
        restaurantLogo={restaurantLogo}
      />
    )
  }
  if (myOrder.cleanOrderStatus === CleanOrderStatus.PREPARING) {
    return (
      <PreparingStatusBox
        isAccountPage={isAccountPage}
        updatedAt={myOrder.updatedAt}
        restaurantName={myOrder.outlet.displayName}
        startWindow={startWindow}
        endWindow={endWindow}
        formattedDeliveryDate={formattedDeliveryDate}
        formattedCollectionDate={formattedCollectionDate}
        fulfillmentMethod={fulfillmentMethod}
        restaurantLogo={restaurantLogo}
      />
    )
  }
  if (myOrder.cleanOrderStatus === CleanOrderStatus.COMPLETE) {
    return (
      <CompleteStatusBox
        isAccountPage={isAccountPage}
        updatedAt={myOrder.updatedAt}
        restaurantName={myOrder.outlet.displayName}
        restaurantLogo={restaurantLogo}
      />
    )
  }
  if (myOrder.cleanOrderStatus === CleanOrderStatus.REJECTED) {
    return (
      <RejectedStatusBox
        isAccountPage={isAccountPage}
        restaurantName={myOrder.outlet.displayName}
        updatedAt={myOrder.updatedAt}
      />
    )
  }
  if (myOrder.cleanOrderStatus === CleanOrderStatus.CANCELLED) {
    return (
      <CancelledStatusBox
        updatedAt={myOrder.updatedAt}
        isAccountPage={isAccountPage}
        restaurantName={myOrder.outlet.displayName}
      />
    )
  }
  if (myOrder.cleanOrderStatus === CleanOrderStatus.READY) {
    return (
      <ReadyStatusBox
        isAccountPage={isAccountPage}
        updatedAt={myOrder.updatedAt}
        startWindow={startWindow}
        endWindow={endWindow}
        formattedDeliveryDate={formattedDeliveryDate}
        formattedCollectionDate={formattedCollectionDate}
        fulfillmentMethod={fulfillmentMethod}
        restaurantName={myOrder.outlet.displayName}
        restaurantLogo={restaurantLogo}
      />
    )
  }
  return null
}
