import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const GroupHeader = styled.h4`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.brand};
`

export const GroupContainer = styled.div<{ last?: boolean }>`
  margin-bottom: 36px;
  margin-right: 32px;

  ${props =>
    props.last &&
    css`
      @media (min-width: ${breakpoints.largeTablet}px) {
        margin-bottom: 76px;
      }
    `}
`
