import React from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Markdown } from '@src/components/Markdown/Markdown'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { BurgerSection } from '../../Drawer/DrawerHeader'

export const Misc: React.FC = () => {
  const { t } = useTranslation('policies')

  const marketplace = useMarketplace()

  if (
    !marketplace.legalPolicy ||
    marketplace?.legalPolicy?.trim()?.length === 0
  )
    return <ErrorPage />

  return (
    <BurgerSection header={{ title: t('misc') }}>
      <Markdown markdown={marketplace.legalPolicy} twoCols />
    </BurgerSection>
  )
}
