import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getMarketplaceNameAddBusinessQueryVariables = Types.Exact<{
  cname: Types.Scalars['String']
}>

export type getMarketplaceNameAddBusinessQuery = {
  marketplaceByCname: {
    __typename: 'Marketplace'
    id: string
    name: string
    key: string
    onboardingDescription: string | null
    allowOnboarding: boolean
  }
}

export const getMarketplaceNameAddBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMarketplaceNameAddBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceByCname' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cname' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cname' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOnboarding' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getMarketplaceNameAddBusinessQuery,
  getMarketplaceNameAddBusinessQueryVariables
>
