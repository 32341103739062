import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

import { Button } from '../Button'

type IsDisabled = {
  disabled: boolean
}

export const CategoriesContainer = styled.div`
  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
    display: flex;
    overflow: hidden;
  }
`

export const AlphabetContainer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.desktop}px) {
    display: flex;
    margin-bottom: 8px;
    height: 32px;
  }
`

export const AlphabetLetter = styled.p<IsDisabled>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.brandLight};
  font-weight: 700;
  width: min-content;
  margin-right: 8px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`

export const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 0;

  @media (min-width: ${breakpoints.tablet}px) {
    padding-top: 4px;
    font-weight: 700;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brandLight};
  position: absolute;
  width: 100%;
  top: 20px;
  border-radius: 8px 8px 0 0;
  flex-direction: column;
  padding: 16px;
  z-index: 2;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 16px 24px;
    max-width: 860px;
    box-sizing: border-box;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    padding: 24px;
    max-width: 912px;
    box-sizing: border-box;
    top: 0px;
  }
`

export const InnerHeaderContainer = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const TopCategoryContainer = styled(CategoryContainer)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const TopCategoryCheckboxContainer = styled.div`
  margin-right: 14%;
`

export const LetterContainer = styled.div`
  margin: 0px;
`

export const CategoryHeader = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin: 16px 0px 8px 0px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 18px;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;

  align-items: center;
  width: 30%;
  font-weight: 400;
  min-width: 207px;
  margin-bottom: 4px;

  @media (max-width: ${breakpoints.mobile}px) {
    width: 30%;
  }
`

export const ApplySelectionContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 8px 8px 16px;
  border-top: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 16px 8px 48px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
  }
`

export const ApplySelectionButton = styled(Button)`
  width: 100%;
  min-height: 40px;
  margin: 18px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  border-radius: 40px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 254px;
    min-height: 48px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    width: 280px;
    margin-right: 32px;
  }
`

export const CategoriesContentContainer = styled.div`
  padding: 16px;
  margin: 80px 0px;
  width: 100%;
  background-color: #fff;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 72px 0px;
    padding: 24px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 40px 24px;
    margin: 100px 0px;
    margin-bottom: 48px;
    overflow-y: scroll;
  }
`
