import { useField } from 'formik'
import React from 'react'

import { LoadingSpinner } from '@src/components/LoadingSpinner'
import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useTablesQuery } from '@src/hooks/sharedQueries/useTables/useTables'

import { TableSelect } from './TableSelect'

import { ErrorMessage } from '../WarningText.styles'

export const TableLoader: React.FC = () => {
  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { data, loading, error } = useTablesQuery({
    outletId: outlet.id,
  })
  const [, meta, helpers] = useField('tableId')

  // TODO: Skeleton
  if (loading) return <LoadingSpinner />
  // eslint-disable-next-line i18next/no-literal-string
  if (error || !data) return <ErrorMessage>Failed to load tables</ErrorMessage>

  return (
    <TableSelect
      tables={data.tables}
      selectedTableId={meta.value}
      setSelectedTableId={tableId => helpers.setValue(tableId)}
    />
  )
}
