import { useReactiveVar } from '@apollo/client'
import { format } from 'date-fns'
import { toLower, without } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { DotSpan } from '@src/components/DotSpan/DotSpan'
import { FulfilmentOverlay } from '@src/components/FulfilmentOverlay/FulfilmentOverlay'
import { DeliveryLocationSection } from '@src/components/ServicePopover/DeliveryLocationSection'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import {
  DoneButton,
  MobileNavigationPopoverContent,
  MobileNavigationPopoverContentWrapper,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
  SectionContentContainer,
  Separator,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { TimeSection } from '@src/components/ServicePopover/TimeSection'
import { breakpoints } from '@src/constants/breakpoints'
import {
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { useCategories } from '@src/hooks/useCategories'
import { useFetchFulfilmentLabels } from '@src/hooks/useFetchFulfilmentLabels'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { FulfilmentFilterWhenType } from '@src/hooks/useFulfilmentFilter/validation'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'
import { screenResolutionVar } from '@src/models/screenResolution'
import { ALL_FULFILMENT_FILTER_WHEN_TYPES } from '@src/utils/fulfilment'

import {
  Container,
  ExpandButton,
  IconContainer,
  InnerContainer,
  StyledDoubleArrowSVG,
  OuterContainer,
  MobileSearchContainer,
} from './MobileNavigationStyles'

import { Search } from '../SearchBar/Search'

export const MobileNavigation: React.FC<{
  setShowCategories: (val: boolean) => void
}> = ({ setShowCategories }) => {
  const jwt = useReactiveVar(jwtVar)
  const [isServiceComponentOpen, setIsServiceComponentOpen] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const { width } = useReactiveVar(screenResolutionVar)
  const fulfilmentFilter = useFulfilmentFilter({
    deferredUpdates: true,
  })
  const { t } = useTranslation('serviceNavigation')
  const marketplace = useMarketplace()
  const merchantURLPath = marketplace.urlPath
  const fulfilmentLabels = useFetchFulfilmentLabels()
  const [isSecondaryPopoverOpen, setIsSecondaryPopoverOpen] = useState(false)

  const isOutletListRoute = useRouteMatch([
    `/:merchantCategory(${merchantURLPath})`,
    `/:deliveryZone/:merchantCategory(${merchantURLPath})`,
  ])
  const categories = useCategories()

  // categories page does not allow service selection
  // and so the button which opens the popover is only enabled
  // if there are no categories selected
  const enableServicePopover = !categories.selectedCategories.length

  useEffect(() => {
    if (!categories.selectedCategories.length) {
      setShowSearchBar(false)
    }
  }, [categories.selectedCategories.length])

  useEffect(() => {
    if (width >= breakpoints.phablet) {
      setShowSearchBar(false)
      setStartAnimation(false)
    }
  }, [width])

  const startClosingAnimation = () => {
    setStartAnimation(true)
    setShowSearchBar(false)
    setTimeout(() => {
      setStartAnimation(false)
    }, 1000)
  }

  const handleDone = () => {
    setIsServiceComponentOpen(false)
    fulfilmentFilter.save()
  }

  const handleCancel = () => {
    setIsServiceComponentOpen(false)
    fulfilmentFilter.reset()
  }

  const isNotTable = fulfilmentFilter.data.nonEmptyFulfilmentMethods.some(
    fulfilmentMethod =>
      [
        NarrowFulfilmentMethodInputType.DELIVERY,
        NarrowFulfilmentMethodInputType.COLLECTION,
      ].includes(fulfilmentMethod)
  )

  return (
    <>
      <OuterContainer>
        <Container
          showSearchBar={showSearchBar}
          startAnimation={startAnimation}
        >
          <InnerContainer
            onClick={() => {
              enableServicePopover && setIsServiceComponentOpen(true)
            }}
            disabled={!enableServicePopover}
            isActive={isServiceComponentOpen}
          >
            <div>
              {fulfilmentLabels.every(({ fulfilment }) =>
                fulfilmentFilter.savedData.nonEmptyFulfilmentMethods.includes(
                  fulfilment
                )
              ) || !enableServicePopover
                ? marketplace.allOutletsText || t('all')
                : fulfilmentFilter.savedData.sortedFulfilmentMethods.map(
                    (fulfilment, index) => (
                      <span key={fulfilment}>
                        {index > 0 && <DotSpan />}
                        {t(toLower(fulfilment))}
                      </span>
                    )
                  )}
              {isOutletListRoute &&
                fulfilmentFilter.savedData.when.type ===
                  FulfilmentFilterWhenType.PREORDER &&
                isNotTable && (
                  <span>
                    {' '}
                    {format(
                      fulfilmentFilter.savedData.when.preorderDate,
                      `EEE dd MMM`
                    )}
                  </span>
                )}
            </div>
            <IconContainer
              onClick={() => {
                enableServicePopover && setIsServiceComponentOpen(true)
              }}
              disabled={!enableServicePopover}
            >
              {enableServicePopover && (
                <StyledDoubleArrowSVG id="filterDoubleArrow" />
              )}
            </IconContainer>
          </InnerContainer>

          {width >= breakpoints.tabletMisc && (
            <Search setShowCategories={setShowCategories} />
          )}
        </Container>
        {/* mobile - expand search bar on click, show categories on expansion
          phablet to desktop - show search bar 50/50
      */}
        {width < breakpoints.tabletMisc && (
          <>
            <ExpandButton
              onClick={() => {
                if (!showSearchBar) {
                  setShowSearchBar(true)
                }
              }}
              $display={!showSearchBar}
            />
            <MobileSearchContainer>
              <Search
                setShowCategories={setShowCategories}
                showSearchBar={showSearchBar}
                startAnimation={startAnimation}
                startClosingAnimation={startClosingAnimation}
              />
            </MobileSearchContainer>
          </>
        )}
      </OuterContainer>
      {/* SERVICE Popover */}
      {enableServicePopover && (
        <ServicePopover
          open={isServiceComponentOpen}
          onClose={handleCancel}
          headerColor="#fff"
          backgroundColor={'#fff'}
          maxWidth={584}
          isMinimised={isSecondaryPopoverOpen}
        >
          <MobileNavigationPopoverContentWrapper>
            <MobileNavigationPopoverContent>
              <SectionHeader
                header={t('service_header')}
                subheader={t('service_subheader')}
              />
              <SectionContentContainer>
                <FulfilmentOverlay
                  availableFulfilmentMethods={
                    marketplace.sortedAvailableFulfilmentMethods
                  }
                  selectedFulfilmentMethods={
                    fulfilmentFilter.data.sortedFulfilmentMethods
                  }
                  setSelectedFulfilmentMethods={
                    fulfilmentFilter.setActiveFulfilmentMethods
                  }
                />
              </SectionContentContainer>
              <Separator />
              {isNotTable && (
                <>
                  <SectionHeader
                    header={t('time_header')}
                    subheader={t('time_subheader')}
                  />
                  <SectionContentContainer>
                    <TimeSection
                      availableWhenTypes={ALL_FULFILMENT_FILTER_WHEN_TYPES}
                      selectedWhen={fulfilmentFilter.data.when}
                      onDone={fulfilmentFilter.setWhen}
                      onSecondaryServicePopoverToggle={
                        setIsSecondaryPopoverOpen
                      }
                    />
                  </SectionContentContainer>{' '}
                  <Separator />
                </>
              )}

              <SectionHeader
                header={t('location_header')}
                subheader={t('location_subheader')}
              />
              <SectionContentContainer>
                <DeliveryLocationSection
                  availableLocationTypes={
                    jwt
                      ? marketplace.availableLocationTypes
                      : without(
                          marketplace.availableLocationTypes,
                          LocationType.ADDRESS
                        )
                  }
                  selectedLocation={fulfilmentFilter.data.where.location}
                  setSelectedLocation={fulfilmentFilter.setLocationType}
                  historicalData={fulfilmentFilter.data.where.historicalData}
                  showSubtitles={false}
                  onSecondaryServicePopoverToggle={setIsSecondaryPopoverOpen}
                  handleCancelWhenNavigatingAway={handleCancel}
                />
              </SectionContentContainer>
            </MobileNavigationPopoverContent>
          </MobileNavigationPopoverContentWrapper>
          <MobileNavigationPopoverFooter>
            <MobileNavigationPopoverFooterContent>
              <MobileNavigationResetLink
                // TODO: maybe disable if unchanged
                onClick={fulfilmentFilter.reset}
              >
                {t('reset')}
              </MobileNavigationResetLink>

              <DoneButton
                content={t('done')}
                width="180px"
                onClick={() => handleDone()}
                type={'button'}
              />
            </MobileNavigationPopoverFooterContent>
          </MobileNavigationPopoverFooter>
        </ServicePopover>
      )}
    </>
  )
}
