import { useQuery, useMutation } from '@apollo/client'
import { Reference } from '@apollo/client/cache'
import omit from 'lodash/omit'
import React, { useState } from 'react'
import { useTranslation, TFunction } from 'react-i18next'
import { toast } from 'react-toastify'

import { AddressForm } from '@src/components/AddressForm'
import { DeleteAddressButton } from '@src/components/AddressForm/AddressForm.styles'
import { ButtonType } from '@src/components/Button'
import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Helmet } from '@src/components/Helmet'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { CustomerDetailsAndAddressesDocument } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddressesQuery.graphql-interface'
import { MainRouteName } from '@src/hooks/useAccountRouter'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'

import { EditAddressDeleteAddressDocument } from './mutations/__generated__/EditAddressDeleteAddress.graphql-interface'
import { EditAddressUpdateAddressDocument } from './mutations/__generated__/EditAddressUpdateAddress.graphql-interface'
import { EditAddressGetAddressDocument } from './queries/__generated__/EditAddressGetAddress.graphql-interface'

import { SuccessScreen } from '../SuccessScreen'

const Content: React.FC<{
  t: TFunction<'addressBookEditAddress'[], undefined>
  addressId: string
}> = ({ t, addressId }) => {
  const { data, error } = useQuery(EditAddressGetAddressDocument, {
    variables: { addressId },
  })

  const [deleteAddressAlertOpen, setDeleteAddressAlertOpen] = useState(false)
  const [updateAddress, { data: updatedAddress, loading: updatingAddress }] =
    useMutation(EditAddressUpdateAddressDocument, {
      onError(error) {
        toast.error(error.message)
      },
      update(cache, result) {
        // Updates all the existing deliveryAddresses in the cache to no longer be default after we set a new one.
        const defaultAddressId = result.data?.editAddress.address.id
        if (defaultAddressId) {
          cache.modify({
            id: cache.identify({ ...data?.addressById }),
            fields: {
              deliveryAddresses(
                existing: readonly Record<string, any>[] | Reference = []
              ) {
                if (!Array.isArray(existing)) return existing

                existing.forEach(addressRef => {
                  cache.modify({
                    id: cache.identify(addressRef),
                    fields: {
                      default(_existing, { readField }) {
                        const id = readField('id', addressRef)
                        return id === defaultAddressId
                      },
                    },
                  })
                })
                return existing
              },
            },
          })
        }
      },
    })

  const [deleteAddress, { data: deletedAddress, loading: deletingAddress }] =
    useMutation(EditAddressDeleteAddressDocument, {
      update(cache, { data }) {
        const archivedAddressId = data?.deleteAddress.deletedId
        const newDefaultAddressId = data?.deleteAddress.newDefaultAddressId
        if (archivedAddressId) {
          const addressBookQuery = cache.readQuery({
            query: CustomerDetailsAndAddressesDocument,
          })
          if (addressBookQuery) {
            cache.modify({
              id: cache.identify(addressBookQuery.customerDetails),
              fields: {
                deliveryAddresses(
                  existingItems: readonly Record<string, any>[] = [],
                  { readField }
                ) {
                  const filteredAddresses = existingItems.filter(
                    addressRef =>
                      archivedAddressId !== readField('id', addressRef)
                  )
                  // update default address if previous default has been deleted
                  if (newDefaultAddressId) {
                    filteredAddresses.forEach(addressRef => {
                      cache.modify({
                        id: cache.identify(addressRef),
                        fields: {
                          default(_existing, { readField }) {
                            const id = readField('id', addressRef)
                            return id === newDefaultAddressId
                          },
                        },
                      })
                    })
                  }
                  return filteredAddresses
                },
              },
            })
          }
        }
      },
    })

  if (error) {
    return <ErrorPage logError={error} errorMessage={t('error')} />
  }

  if (deletingAddress) return <LoadingSpinner />

  if (data) {
    if (updatedAddress) {
      return (
        <SuccessScreen
          successText={t('success')}
          returnToRoute={{ mainRouteName: MainRouteName.ADDRESSES }}
        />
      )
    }

    if (deletedAddress) {
      return (
        <SuccessScreen
          successText={t('success_delete')}
          returnToRoute={{ mainRouteName: MainRouteName.ADDRESSES }}
        />
      )
    }

    return (
      <>
        <AlertModal
          isOpen={deleteAddressAlertOpen}
          title={t('delete_this_address_query')}
          action={{
            text: t('confirm'),
            intent: ButtonType.DANGER,
            onClick: () => {
              void deleteAddress({
                variables: { addressId: data.addressById.id },
              })
              setDeleteAddressAlertOpen(false)
            },
          }}
          cancel={{
            text: t('cancel'),
            onClick: () => setDeleteAddressAlertOpen(false),
          }}
          isLeftSidebar={true}
        />
        <AddressForm
          initialValues={omit(data.addressById, '__typename')}
          onSubmit={editedAddress => {
            void updateAddress({
              variables: {
                input: { ...editedAddress, id: data.addressById.id },
              },
            })
          }}
          loading={updatingAddress}
          submitButtonText={t('edit_address_action')}
        />
        <DeleteAddressButton onClick={() => setDeleteAddressAlertOpen(true)}>
          {t('delete_address')}
        </DeleteAddressButton>
      </>
    )
  }

  return <LoadingSpinner />
}

export const EditAddress: React.FC<{
  addressId: string
}> = ({ addressId }) => {
  useNotLoggedInRedirect()
  const { t } = useTranslation('addressBookEditAddress')

  return (
    <>
      <Helmet title={t('title')} />
      <BurgerSection header={{ title: t('title') }}>
        <Content addressId={addressId} t={t} />
      </BurgerSection>
    </>
  )
}
