import { useReactiveVar } from '@apollo/client'
import React, { FC } from 'react'

import { Login } from '@src/components/BurgerMenu/Login/Login'
import { PasswordReset } from '@src/components/BurgerMenu/Login/PasswordReset'
import { Register } from '@src/components/BurgerMenu/Register/Register'
import { jwtVar } from '@src/models/customer/jwt'

enum AuthType {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export const AuthenticationBoundary: FC = ({ children }) => {
  const jwt = useReactiveVar(jwtVar)
  const [requiredAuthType, setRequiredAuthType] =
    React.useState<AuthType | null>(jwt ? null : AuthType.LOGIN)

  switch (requiredAuthType) {
    case null:
      return <>{children}</>
    case AuthType.LOGIN:
      return (
        <Login
          isCheckout
          onLoggingIn={() => setRequiredAuthType(null)}
          navigateToSignUp={() => setRequiredAuthType(AuthType.REGISTER)}
          navigateToForgotPassword={() =>
            setRequiredAuthType(AuthType.FORGOT_PASSWORD)
          }
        />
      )
    case AuthType.REGISTER:
      return (
        <Register
          isCheckout
          onSigningUp={() => setRequiredAuthType(null)}
          navigateToLogin={() => setRequiredAuthType(AuthType.LOGIN)}
        />
      )
    case AuthType.FORGOT_PASSWORD:
      return <PasswordReset isCheckout />
  }
}
