import { isString, keys, omit, pick } from 'lodash'
import { O } from 'ts-toolbelt'

import { syncCurrentFulfilmentWithLocation } from '@src/hooks/useFulfilmentFilter/syncCurrentFulfilmentWithLocation'

import { HookMethodArgs } from '../types/types'
import { getKeysForCurrentFulfilmentType } from '../utils/getKeysForCurrentFulfilmentType'
import {
  HistoricalData,
  HistoricalDataSchema,
  NonBasketOutletFulfilment,
  NonFulfilmentSpecificHistoricalData,
} from '../validation'

type MakeNullFieldsOptional<T extends Record<string, unknown>> = O.Optional<
  T,
  O.NullableKeys<T>
>

type CurrentFulfilmentWithOptionalNullFields = MakeNullFieldsOptional<
  NonBasketOutletFulfilment['currentFulfilment']
>

// update the current fulfilment data
// preserving historical data from the existing data before the change
// and updating the fulfilment filter with the historical data (shared things like postcode)
export type SetCurrentFulfilment = (
  updateData: CurrentFulfilmentWithOptionalNullFields &
    Partial<NonFulfilmentSpecificHistoricalData>
) => void
export const setCurrentFulfilment =
  (hookMethodArgs: HookMethodArgs): SetCurrentFulfilment =>
  updateData => {
    const existingHistoricalData = hookMethodArgs.existingData.historicalData

    // get keys in update data which are undefined
    const nonUndefinedKeysInUpdateData = Object.entries(updateData)
      .map(([key, value]) => (value === undefined ? null : key))
      .filter(isString)
    // use keys to omit undefined fields from update data (undefined fields should be overwritten by historical data)
    const nonUndefinedUpdateData = pick(
      updateData,
      nonUndefinedKeysInUpdateData
    )
    // take all existing historical data and overwrite with new data (only the fields which were not passed as undefined / excluded from the update data object)
    const historicalUpdateData = {
      ...existingHistoricalData,
      ...omit(nonUndefinedUpdateData, 'type'),
    }

    // construct updated current fulfilment data, which consists of:
    // * the type from the updateData,
    // * the fields which apply to that type from the up-to-date historical data
    const currentFulfilmentSchemaKeys = getKeysForCurrentFulfilmentType(
      updateData.type
    )
    const currentFulfilmentUpdateData = {
      type: updateData.type,
      ...pick(historicalUpdateData, currentFulfilmentSchemaKeys),
    } as NonBasketOutletFulfilment['currentFulfilment']

    // omit from historical data, all fields not in this outletFulfilment historical data schema (ie the ones added from the fulfilmentFilter in the extendData function)
    const outletFulfilmentHistoricalUpdateData = pick(
      historicalUpdateData,
      keys(HistoricalDataSchema.shape)
    ) as HistoricalData

    // update reactive var with merged data
    hookMethodArgs.updateDataFn({
      ...hookMethodArgs.existingData,
      currentFulfilment: currentFulfilmentUpdateData,
      historicalData: outletFulfilmentHistoricalUpdateData,
    })

    // update location and historical data in fulfilment filter
    syncCurrentFulfilmentWithLocation({
      outletFulfilment: currentFulfilmentUpdateData,
    })
  }
