import styled from 'styled-components'

import { Link } from '@src/components/Router/Link'

export const StackedMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  height: auto;
  cursor: pointer;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const StyledLink = styled(Link)`
  all: unset;
`

export const TitleAndSubtitleContainer = styled.div`
  width: 85%;
`

export const ChevronContainer = styled.div`
  color: ${({ theme }) => theme.colors.mainText};
`

export const MenuTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  color: ${({ theme }) => theme.colors.mainText};
`

export const MenuSubtitle = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin: 2px 0px;
`

export const BackToOutletContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  position: fixed;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.4) 0px -8px 24px;
  height: 40px;
`

export const BackToOutletTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  padding-left: 4px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
`

export const StackedMenuViewContainer = styled.div`
  padding-top: 60px;
`

export const MenuGroupContainer = styled.div`
  padding-top: 60px;
`
export const StackedMenuOuterContainer = styled.div`
  border-top: 4px solid rgba(0, 0, 0, 0.1);
`
