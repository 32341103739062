import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AccountSectionNavButtonContent,
  StyledAccountSectionNavButton,
} from '@src/components/BurgerMenu/AccountMain/AccountMain.styles'
import { MainRouteName } from '@src/hooks/useAccountRouter'

import { GroupContainer, GroupHeader } from './AccountNavigationLinks.styles'

export const AccountNavigationLinks: React.FC = () => {
  const { t } = useTranslation('navigationLinks')

  return (
    <>
      <GroupContainer>
        <GroupHeader>{t('details')}</GroupHeader>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.DETAILS }}
        >
          <AccountSectionNavButtonContent>
            {t('my_account')}
          </AccountSectionNavButtonContent>
        </StyledAccountSectionNavButton>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.PAYMENT }}
        >
          <AccountSectionNavButtonContent>
            {t('saved_cards')}
          </AccountSectionNavButtonContent>
        </StyledAccountSectionNavButton>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.MARKETING }}
        >
          <AccountSectionNavButtonContent>
            {t('marketing_preferences')}
          </AccountSectionNavButtonContent>
        </StyledAccountSectionNavButton>
      </GroupContainer>

      <GroupContainer>
        <GroupHeader>{t('delivery')}</GroupHeader>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.ADDRESSES }}
        >
          <AccountSectionNavButtonContent>
            {t('address_book')}
          </AccountSectionNavButtonContent>
        </StyledAccountSectionNavButton>
      </GroupContainer>

      <GroupContainer last>
        <GroupHeader>{t('orders')}</GroupHeader>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.ORDERS }}
        >
          <AccountSectionNavButtonContent>
            {t('order_history')}
          </AccountSectionNavButtonContent>
        </StyledAccountSectionNavButton>
      </GroupContainer>
    </>
  )
}
