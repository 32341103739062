import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button, ButtonType } from '@src/components/Button/Button'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { TextInput } from '@src/components/Inputs'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { SignInRequestPasswordResetDocument } from './mutations/__generated__/SignInRequestPasswordReset.graphql-interface'
import { NewPasswordForm } from './NewPasswordForm'
import { ResponseContainer } from './NewPasswordForm.styles'
import {
  Container,
  ContentContainer,
  Copy,
  Header,
  ResetPasswordConfirm,
  Subtitle,
  UserEmail,
  StyledButton,
  HeaderContainer,
} from './PasswordReset.styles'
import { passwordResetSchema } from './validation/passwordResetStructure'

import { StyledArrowLeftSVG } from '../../Drawer/Drawer.styles'
import { DrawerHeader, DrawerHeaderActions } from '../../Drawer/DrawerHeader'

export const PasswordReset: React.FC<{
  isCheckout?: boolean
}> = ({ isCheckout = false }) => {
  const marketplace = useMarketplace()
  const { t } = useTranslation('resetPassword')
  const checkoutRouter = useCheckoutRouter()
  const [sentMessage, setSentMessage] = useState(false)
  const [searchParams, setSearchParams] = useState('')

  const [resetPassword, { loading: passwordLoading, error }] = useMutation(
    SignInRequestPasswordResetDocument
  )

  if (window.location.search.length && searchParams === '') {
    setSearchParams(window.location.search)
  }

  return (
    <>
      {!isCheckout && <DrawerHeader action={DrawerHeaderActions.BACK} />}
      <Container>
        <HeaderContainer isCheckout={isCheckout}>
          <Header>{t('forgot_password')}</Header>
          {isCheckout && (
            <StyledButton onClick={checkoutRouter.prev}>
              <StyledArrowLeftSVG id="back_button" />
            </StyledButton>
          )}
        </HeaderContainer>
        <ContentContainer>
          {new URLSearchParams(searchParams).get('cid') &&
          new URLSearchParams(searchParams).get('token') ? (
            <>
              <NewPasswordForm />
            </>
          ) : (
            <Formik
              validationSchema={toFormikValidationSchema(passwordResetSchema)}
              initialValues={{
                email: '',
              }}
              validateOnBlur={false}
              onSubmit={async values => {
                const resetPasswordResponse = await resetPassword({
                  variables: {
                    email: values.email,
                    marketplaceKey: marketplace.key,
                  },
                })

                if (!resetPasswordResponse.errors) {
                  setSentMessage(true)
                }
              }}
            >
              {({ values }) => (
                <Form>
                  {sentMessage ? (
                    <>
                      <ResetPasswordConfirm>
                        <LoaderCheck />
                        <Subtitle>{t('check_inbox')}</Subtitle>
                        <UserEmail>{values.email}</UserEmail>
                      </ResetPasswordConfirm>
                      <Button
                        buttonType={ButtonType.SECONDARY}
                        content={t('button.resend')}
                        loading={passwordLoading}
                        disabled={passwordLoading}
                        type="submit"
                      />
                    </>
                  ) : (
                    <>
                      <Copy>{t('copy')}</Copy>
                      <TextInput
                        label={t('email.label')}
                        name="email"
                        type="text"
                        required={true}
                        autoComplete="email"
                        focusOnLoad
                      />
                      {error && (
                        <ResponseContainer error>
                          {t(error.message)}
                        </ResponseContainer>
                      )}
                      <Button
                        buttonType={ButtonType.PRIMARY}
                        content={t('button.reset')}
                        loading={passwordLoading}
                        disabled={passwordLoading}
                        type="submit"
                      />
                    </>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </ContentContainer>
      </Container>
    </>
  )
}
