import { isWithinInterval } from 'date-fns'

import { OutletFulfilmentContextData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/types'

import { getEarliestFulfilmentTime } from './getEarliestFulfilmentTime'
import { getLatestFulfilmentTime } from './getLatestFulfilmentTime'

export const filterOutUnavailableCollectionPreorderTimes = (
  args: Pick<
    OutletFulfilmentContextData,
    | 'prepTime'
    | 'openingTimesArray'
    | 'daysOfferedInAdvanceMin'
    | 'daysOfferedInAdvanceMax'
    | 'closedUntil'
    | 'collectionPreorderTimes'
  >
): Date[] => {
  const earliestCollectionTime = getEarliestFulfilmentTime(args)
  const { daysOfferedInAdvanceMax, collectionPreorderTimes } = args
  const latestCollectionTime = getLatestFulfilmentTime(daysOfferedInAdvanceMax)
  const availableCollectionPreorderTimes = collectionPreorderTimes.filter(
    collectionPreorderTime => {
      return isWithinInterval(collectionPreorderTime, {
        start: earliestCollectionTime,
        end: latestCollectionTime,
      })
    }
  )
  return availableCollectionPreorderTimes
}
