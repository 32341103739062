import { useField } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxInput } from '@src/components/Inputs'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { Container } from './RestrictedItems.styles'

import { ErrorMessage } from '../WarningText.styles'

export const RestrictedItems: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const [, meta] = useField('ageVerificationConfirmed')
  const { t } = useTranslation('checkout')
  const containerRef = useRef<HTMLDivElement>(null)
  const marketplace = useMarketplace()

  const showError = meta.touched && Boolean(meta.error)

  useEffect(() => {
    if (containerRef && containerRef.current && showError) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [showError])

  return (
    <Container ref={containerRef} shake={showError}>
      {showError && <ErrorMessage>{t('please_confirm')}</ErrorMessage>}
      <CheckboxInput
        name="ageVerificationConfirmed"
        label={t('restricted_items')}
        disabled={disabled}
        description={marketplace.ageRestrictionText ?? t('age_statement')}
        labelOnLeft
        showMetaError={false}
      />
    </Container>
  )
}
