import { useMarketplace } from '@src/hooks/useMarketplace'

export const useFormatCurrency = (showDecimal = true) => {
  const marketplace = useMarketplace()
  const {
    country: { currency },
  } = marketplace

  const formatCurrency = (number: number, stringPrefix?: string): string => {
    const decimalPlaces = () => {
      return currency.decimalPlaces ?? 2
    }

    const numberWithDecimals = Number(
      (number / 10 ** decimalPlaces()).toFixed(decimalPlaces())
    )

    const generateNumber = () =>
      new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency.iso4217,
        maximumSignificantDigits: !showDecimal ? 1 : undefined,
      }).format(numberWithDecimals)

    return `${stringPrefix ?? ''}${generateNumber()}${currency?.suffix ?? ''}`
  }

  return formatCurrency
}
