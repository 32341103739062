import { useReactiveVar } from '@apollo/client'

import { UpdateDiscount, updateDiscount } from './hookMethods/updateDiscount'
import { discountsVar } from './utils/reactiveVar'
import { Discounts } from './validation'

type UseDiscounts = () => {
  discounts: Discounts
  updateDiscount: UpdateDiscount
}
export const useDiscounts: UseDiscounts = () => {
  const discounts = useReactiveVar(discountsVar)

  return {
    discounts,
    updateDiscount,
  }
}
