import React from 'react'
import { useTranslation } from 'react-i18next'

export const LocationSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation('svgs')

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('location_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <rect
        className="circle"
        x="1"
        y="1"
        width="46"
        height="46"
        rx="23"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        className="arrow"
        d="M33 15L13 23.3667V24.4556L20.6 27.4L23.5333 35H24.6222L33 15Z"
        fill="currentColor"
      />
    </svg>
  )
}
