import ReactMarkdown from 'react-markdown'
import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const StyledReactMarkdown = styled(ReactMarkdown)<{ twoCols: boolean }>`
  color: #2e3333;
  width: 100%;
  overflow: hidden;

  ${props =>
    props.twoCols &&
    css`
      @media (min-width: ${breakpoints.wideDesktop}px) {
        column-count: 2;
        column-gap: 8%;
        padding-bottom: 100px;
      }
    `}

  h2 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin: 0px 0px 24px 0px;
    padding-top: 24px;
  }

  h5 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0px 0px 24px 0px;
  }

  a {
    color: #2e3333;
    font-weight: 700;
  }

  blockquote > p {
    font-size: 14px;
    font-weight: 700;
  }

  ul > li > a {
    line-height: 24px;
  }

  li::marker {
    font-weight: 700;
  }

  strong {
    font-weight: 700;
    font-size: 14px;
  }
`
